export const NAV_ITEM_TITLES = Object.freeze({
  DASHBOARD: 'Dashboard',
  HELP: 'Help',
  SERVICES: 'Services',
  RECEPTORS: 'Receptors',
  COMPANY_INFO: 'Company Info',
  POLICIES: 'Policies',
  CONTROLS: 'Controls',
  RISKS: 'Risk Register',
  AUDITS: 'Audits',
  REQUESTS: 'Requests',
  REPORT_SCAN: 'SOC 2 Report Summary',
  USERS: 'Users',
  ACTIVITY_LOG: 'Activity Log',
  ACCOUNT_SWITCHER: 'Switch Acct.',
  AUDIT_BOT: 'Examine and Test',
  ROADMAP: 'Roadmap',
  AI_GRC_QA: 'GRC Questions',
  VENDORS: 'Vendors Management',
  SCOPE: 'Scope',
  ACCOUNT_SETTINGS: 'Account',
  MANAGE_DOCUMENTS: 'Manage Documents',
  EVIDENCE: 'Evidence',
  GRC_PLATFORMS: 'GRC Platforms',
  DOCUMENT_MANAGEMENT: 'Document Management',
  USAGE: 'AI Usage',
})

export enum NAV_ITEM_KEYS {
  DASHBOARD = 'dashboard',
  HELP = 'help',
  SERVICES = 'services',
  RECEPTORS = 'receptors',
  COMPANY_INFO = 'company_info',
  POLICIES = 'policies',
  CONTROLS = 'controls',
  RISKS = 'risks',
  AUDITS = 'audits',
  REQUESTS = 'requests',
  AI_SOC2_REPORT_SUMMARY = 'ai_soc2_report_summary',
  USERS = 'users',
  ACCOUNTS = 'accounts',
  AI_EXAMINATION_AND_TEST = 'ai_examination_and_test',
  ROADMAP = 'roadmap',
  AI_GRC_QA = 'ai_grc_qa',
  VENDORS = 'vendors',
  SCOPE = 'scope',
  ACCOUNT_SETTINGS = 'account_settings',
  EVIDENCE = 'evidence',
  DOCUMENT_MANAGEMENT = 'document_management',
  GRC_PLATFORMS = 'grc_platforms',
  USAGE = 'usage',
  MANAGE_DOCUMENTS = 'manage_documents',
}

export type NavbarState = {
  [value in NAV_ITEM_KEYS]: boolean
}

// Be sure to also update app/src/components/Reusable/Accordions/NavBar/NavBarAccordions.constants.tsx
export const NavbarInitialState: NavbarState = {
  [NAV_ITEM_KEYS.DASHBOARD]: false,
  [NAV_ITEM_KEYS.HELP]: false,
  [NAV_ITEM_KEYS.SERVICES]: false,
  [NAV_ITEM_KEYS.RECEPTORS]: false,
  [NAV_ITEM_KEYS.COMPANY_INFO]: false,
  [NAV_ITEM_KEYS.POLICIES]: false,
  [NAV_ITEM_KEYS.CONTROLS]: false,
  [NAV_ITEM_KEYS.RISKS]: false,
  [NAV_ITEM_KEYS.AUDITS]: false,
  [NAV_ITEM_KEYS.REQUESTS]: false,
  [NAV_ITEM_KEYS.USERS]: false,
  [NAV_ITEM_KEYS.ACCOUNTS]: false,
  [NAV_ITEM_KEYS.ROADMAP]: false,
  [NAV_ITEM_KEYS.AI_GRC_QA]: false,
  [NAV_ITEM_KEYS.VENDORS]: false,
  [NAV_ITEM_KEYS.SCOPE]: false,
  [NAV_ITEM_KEYS.ACCOUNT_SETTINGS]: false,
  [NAV_ITEM_KEYS.EVIDENCE]: false,
  [NAV_ITEM_KEYS.DOCUMENT_MANAGEMENT]: false,
  [NAV_ITEM_KEYS.GRC_PLATFORMS]: false,
  [NAV_ITEM_KEYS.USAGE]: false,
  [NAV_ITEM_KEYS.MANAGE_DOCUMENTS]: false,
  [NAV_ITEM_KEYS.AI_EXAMINATION_AND_TEST]: false,
  [NAV_ITEM_KEYS.AI_SOC2_REPORT_SUMMARY]: false,
}

export const AUDIT_ICON_COLOR = 'white'
export const BREAK_HEIGHT = 900
export const LOGO_HEIGHT = 40
