import React from 'react'
import { themeGet } from '@styled-system/theme-get'
import styled, { keyframes, css } from 'styled-components/macro'
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  GridTemplateColumnsProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textStyle,
} from 'styled-system'
import palette from 'src/designSystem/variables/palette'

export type ReusableGridProps = LayoutProps &
  GridProps &
  FlexboxProps &
  SpaceProps & {
    show?: boolean
    gridTemplateColumns: GridTemplateColumnsProps
  }

export const Grid = styled.div.attrs<ReusableGridProps>((props) => ({
  className: 'grid',
  show: true,
  ...props,
}))<ReusableGridProps>`
  ${compose(layout, grid, flexbox, space)}
  display: ${(props) => (props.show ? 'grid' : 'none')};
  position: relative;
`

const aiGlowAnimation = keyframes`
  0% {
    background-color: ${palette.neutral['30']}
  }
  16% {
    background-color: ${palette.purple['100']};
  }
  33% {
    background-color: ${palette.purple['30']};
  }
  50% {
    background-color: ${palette.purple['100']};
  }
  66% {
    background-color: ${palette.purple['30']};
  }
  83% {
    background-color: ${palette.purple['50']};
  }
  100% {
    background-color: ${palette.neutral['30']}
  }
`

export const GridRow = styled.div<{ selected?: boolean; $aiglow?: boolean }>`
  display: contents;

  > a:not(:hover) {
    text-decoration: none;
  }

  > .grid-column {
    border-top: ${(props) => (props.selected ? '1px solid green' : '')};
    border-bottom: ${(props) => (props.selected ? '1px solid green' : '')};
  }

  .grid-column:first-child {
    border-left: ${(props) => (props.selected ? '1px solid green' : '')};
  }
  .grid-column:last-child {
    border-right: ${(props) => (props.selected ? '1px solid green' : '')};
  }
  &:hover {
    > a {
      ${themeGet('colorStyles.link.primary.:hover')}
      text-decoration: underline;
    }

    > .accordion-collapse:hover ~ a {
      color: unset;
      text-decoration: none;
    }

    > .grid-column {
      background-color: ${themeGet('colors.bg.neutralHover')};
    }
  }

  > .grid-column {
    ${({ $aiglow }) =>
      $aiglow &&
      css`
        animation: ${aiGlowAnimation} 9s ease-in-out;
      `}
  }
`

export type GridItemProps = GridProps &
  FlexboxProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  BorderProps &
  ColorProps

const GridItem = styled.div.attrs<GridItemProps>((props) => ({
  alignItems: props.alignItems || 'center',
  style: { gridRow: props.gridRow, ...props.style },
}))<GridItemProps>`
  display: flex;
  position: relative;
  text-align: left;
  ${({ gridRow: _unused, ...propsWithoutGridRow }) =>
    compose(
      grid,
      flexbox,
      layout,
      position,
      space,
      border,
      color,
      textStyle,
    )(propsWithoutGridRow)};
`

export const GridHeader = styled(GridItem).attrs<GridItemProps>((props) => ({
  className: 'grid-header',
  pt: 's',
  pb: 'xs',
  px: 'm',
  bg: 'white',
  color: 'text.med',
  textStyle: 'subtitle.3',
  ...props,
}))``

export const GridColumn = styled(GridItem).attrs<GridItemProps>((props) => ({
  className: 'grid-column',
  border: 'none',
  mb: 'xxs',
  px: 'm',
  py: 'xs',
  bg: 'bg.neutral',
  color: 'text.default',
  textStyle: 'body.default',
  minHeight: '48px',
  ...props,
}))`
  &:first-child,
  &:first-child:before {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-child,
  &:last-child:before {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

export const EmptyGrid = ({ message }: { message: string }): JSX.Element => (
  <GridRow>
    <GridColumn gridColumn="1 / -1" justifyContent="center">
      {message}
    </GridColumn>
  </GridRow>
)
