import {
  COMPANY_INFO_CHECKS,
  CONTROL_CHECKS,
  CompanyInfoCheck,
  ControlCheck,
  FRAMEWORK_CHECKS,
  FrameworkCheck,
  POLICY_CHECKS,
  PolicyCheck,
  REQUEST_CHECKS,
  RISK_CHECKS,
  RequestCheck,
  RiskCheck,
  SERVICE_CHECKS,
  SERVICE_ROLE_CHECKS,
  ServiceCheck,
  ServiceRoleCheck,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import { ModalFormId } from 'src/components/ModalForms'
import { PERMISSIONS } from 'src/config/roleConfig'

export type ROADMAP_WIDGET_SECTION_CHECKS =
  | SERVICE_CHECKS
  | POLICY_CHECKS
  | CONTROL_CHECKS
  | COMPANY_INFO_CHECKS
  | FRAMEWORK_CHECKS
  | RISK_CHECKS
  | REQUEST_CHECKS

export type ROADMAP_WIDGET_SECTION_CHECK =
  | ServiceCheck
  | PolicyCheck
  | ControlCheck
  | CompanyInfoCheck
  | FrameworkCheck
  | RiskCheck
  | RequestCheck
  | ServiceRoleCheck

export enum ROADMAP_WIDGET_LOCATION {
  DASHBOARD = 'dashboard',
  RISK_ASSESSMENT = 'riskAssessment',
  DESIGN = 'design',
  IMPLEMENTATION = 'implementation',
  HANDLE_REQUESTS = 'handleRequests',
  OPERATING_EFFECTIVENESS = 'operatingEffectiveness',
}

export enum ROADMAP_WIDGET_SECTION {
  SERVICE = 'service',
  SERVICE_ROLE = 'serviceRole',
  POLICY = 'policy',
  CONTROL = 'control',
  FRAMEWORK = 'framework',
  COMPANY_INFO = 'companyInfo',
  RISK = 'risk',
  REQUEST = 'request',
}

export type ROADMAP_CHECK_CONFIG = ROADMAP_CHECK_CONFIG_BASE_PROPS &
  ROADMAP_CHECK_CONFIG_CONDITIONAL_PROPS

export type ROADMAP_CHECK_CONFIG_BASE_PROPS = {
  text: string
  hoverText: string
  useFinishedCount?: boolean
  requiredPermissions: PERMISSIONS[]
}

export type ROADMAP_CHECK_CONFIG_CONDITIONAL_PROPS =
  | {
      link: string
      modalId?: never
      modal?: never
    }
  | {
      link?: never
      modalId: ModalFormId
      modal: JSX.Element
    }

export type ROADMAP_WIDGET_SECTION_CONFIG_TYPE = {
  title: string
  icon: JSX.Element
  ownerEmail?: string
  ownerId?: string
  path: string
  pageContext: string
  tooltip?: JSX.Element
  requiredPermissions: PERMISSIONS[]
}

export type ROADMAP_WIDGET_LOCATION_CONFIG_TYPE = Record<
  ROADMAP_WIDGET_SECTION,
  ROADMAP_WIDGET_SECTION_CONFIG_TYPE | null
>

export type ROADMAP_WIDGET_DATA_TYPE = Record<
  ROADMAP_WIDGET_SECTION,
  ROADMAP_WIDGET_SECTION_DATA
>

export type ROADMAP_WIDGET_SECTION_DATA = {
  finishedCount: number
  totalCount: number
  checks: ROADMAP_WIDGET_SECTION_CHECK[]
}

export type SERVICE_REQUEST_CONFIG = {
  [key in SERVICE_CHECKS]: boolean
}

export type POLICY_REQUEST_CONFIG = {
  [key in POLICY_CHECKS]: boolean
}

export type CONTROL_REQUEST_CONFIG = {
  [key in CONTROL_CHECKS]: boolean
}

export type FRAMEWORK_REQUEST_CONFIG = {
  [key in FRAMEWORK_CHECKS]: boolean
}

export type COMPANY_INFO_REQUEST_CONFIG = {
  [key in COMPANY_INFO_CHECKS]: boolean
}

export type RISK_REQUEST_CONFIG = {
  [key in RISK_CHECKS]: boolean
}

export type REQUEST_REQUEST_CONFIG = {
  [key in REQUEST_CHECKS]: boolean
}

export type SERVICE_ROLE_REQUEST_CONFIG = {
  [key in SERVICE_ROLE_CHECKS]: boolean
}

export type ROADMAP_WIDGET_REQUEST_CONFIG = {
  [ROADMAP_WIDGET_SECTION.SERVICE]: SERVICE_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.POLICY]: POLICY_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.CONTROL]: CONTROL_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.FRAMEWORK]: FRAMEWORK_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.COMPANY_INFO]: COMPANY_INFO_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.RISK]: RISK_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.REQUEST]: REQUEST_REQUEST_CONFIG | null
  [ROADMAP_WIDGET_SECTION.SERVICE_ROLE]: SERVICE_ROLE_REQUEST_CONFIG | null
}

export const COUNT_PLACEHOLDER = '-'
