import React from 'react'
import EroBlinkGif from 'src/components/Icons/Brand/ero_blink.gif'
import {
  AuditBotThrobberIcon,
  AuditBotThrobberText,
} from './AuditBotThrobber.styles'

export const AuditBotThrobber = ({ text }: { text: string }): JSX.Element => {
  return (
    <>
      <AuditBotThrobberIcon src={EroBlinkGif} />
      <AuditBotThrobberText>{text}</AuditBotThrobberText>
    </>
  )
}
