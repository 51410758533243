import React, { FormEventHandler, useContext, useRef, useState } from 'react'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  EvidenceGenerator,
  ModelEvidenceGenerator,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { RunAccountTestsByReceptorModelIDRequest } from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { toast } from 'react-toastify'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import log from 'loglevel'
import {
  ModalForm,
  ModalFormId,
  ModalFormIdQueryParam,
} from '../../../ModalForm'
import { Grid, GridColumn, GridRow } from '../../../../Reusable/Grid'
import {
  useEvidenceGenerators,
  useInvalidateEvidenceGeneratorsCache,
} from '../../../../async/attachment/useEvidenceGenerators'
import { Throbber } from '../../../../../Throbber'
import { useAuthorizedGrpcClient } from '../../../../../adapter'
import { EvidenceDetailsContainer } from '../forms/AddEvidenceForm.styles'
import { EvidenceDisplayName } from '../../../../../pages/Controls/ControlsShowPage/ConnectedGrid/EvidenceGeneratorsGrid'
import ReceptorScanContext from '../../../../../context/ReceptorScanStatus'
import { Row } from './Row'

export const AddEvidenceGeneratorQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  CONTROL_ID: 'controlId',
}

type Props = {
  controlId: string
  modalFormId: ModalFormId
}

export const AddConnectionModal = ({
  modalFormId,
  controlId,
}: Props): JSX.Element => {
  const submitRef = useRef<HTMLButtonElement>(null)
  const invalidateEvidenceGeneratorsCache =
    useInvalidateEvidenceGeneratorsCache()
  const [selected, updateSelected] = useState<ModelEvidenceGenerator[]>([])
  const [selectedEG, updatedSelectedEG] = useState<EvidenceGenerator[]>([])
  const { setReceptorScanRunning } = useContext(ReceptorScanContext)
  const show = useIsShowModal(modalFormId)
  const hide = useHideModal({ modalId: modalFormId })

  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const testingClient = useAuthorizedGrpcClient(TestingPromiseClient)
  const generatorsResponse = useEvidenceGenerators({
    modelType: MODEL_TYPE.CONTROL,
    modelId: controlId,
    connected: false,
    shouldFetch: show,
  })

  // [AP-5006] TODO: Improve error handling
  const onSubmit: FormEventHandler = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
      if (selected.length === 0) {
        return
      }
      setReceptorScanRunning(true)
      toast.info('Evidence collection started.', { autoClose: 2_000 })
      await Promise.all(
        selected.map((mapping) =>
          attachmentClient.updateModelEvidenceGenerator(mapping),
        ),
      )
      // Not sure if this is needed twice but if we don't invalidate here then the user sees stale state for a long time
      await invalidateEvidenceGeneratorsCache()
      hide()
      const receptorModelIds = selectedEG.reduce(
        (acc: string[], p: EvidenceGenerator) => {
          if (!acc.includes(p.getReceptorModelId())) {
            acc.push(p.getReceptorModelId())
          }
          return acc
        },
        [],
      )
      await Promise.all(
        receptorModelIds.map((receptorModelId: string) =>
          testingClient.runAccountTestsByReceptorModelID(
            new RunAccountTestsByReceptorModelIDRequest().setReceptormodelid(
              receptorModelId,
            ),
          ),
        ),
      )
      await generatorsResponse.mutate()
      await invalidateEvidenceGeneratorsCache()
      setReceptorScanRunning(false)
      toast.success('Evidence collection complete.', { autoClose: 2_000 })
    } catch (e) {
      log.error('Error running receptor scan', e)
    }
  }
  if (generatorsResponse.loading) {
    return <Throbber />
  }

  const onChange = (p: EvidenceGenerator) => {
    const index = selected.findIndex(
      (q) => q.getEvidenceGeneratorId() === p.getId(),
    )
    if (index >= 0) {
      selected.splice(index, 1)
    } else {
      selected.push(
        new ModelEvidenceGenerator()
          .setEvidenceGeneratorId(p.getId())
          .setControlModelIdentifier(
            new Identifier()
              .setModeltype(MODEL_TYPE.CONTROL)
              .setModelid(controlId),
          )
          .setIsEnabled(true),
      )
      selectedEG.push(p)
    }
    updateSelected([...selected])
    updatedSelectedEG([...selectedEG])
  }

  const evidenceGenerators = generatorsResponse.data?.getItemsList()
  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={modalFormId}
      title="Add Automated Evidence"
      description={
        'Connect an evidence type to this control. The associated receptor will post evidence here when it gathers new evidence from your systems.'
      }
      submitRef={submitRef}
      submitText={'Connect Automated Evidence'}
      hideButtons={evidenceGenerators?.length === 0}
    >
      <form
        id={modalFormId}
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={onSubmit}
      >
        <EvidenceDetailsContainer>
          <Grid gridTemplateColumns={'repeat(2, min-content) 1fr'}>
            {evidenceGenerators?.length === 0 ? (
              <GridRow>
                <GridColumn gridColumn="1 / -1" justifyContent="center">
                  {'No New Connections Available'}
                </GridColumn>
              </GridRow>
            ) : (
              <>
                {evidenceGenerators?.map((p) => {
                  const checked =
                    selected.find(
                      (q) => q.getEvidenceGeneratorId() === p.getId(),
                    ) !== undefined
                  return (
                    <Row
                      key={p.getId()}
                      receptorModelId={p.getReceptorModelId()}
                      evidenceId={EvidenceDisplayName(
                        p.getEvidenceId(),
                        p.getReceptorModelId(),
                      )}
                      checked={checked}
                      onChange={() => onChange(p)}
                    />
                  )
                })}
              </>
            )}
          </Grid>
        </EvidenceDetailsContainer>
      </form>
    </ModalForm>
  )
}
