import { useNavigate, useParams } from 'react-router-dom'
import { linkWithSearch } from 'src/components/PageLayout/Permalink'
import {
  ControlsAbsoluteRoutes,
  ExamineAndTestAbsoluteRoutes,
  PoliciesAbsoluteRoutes,
  ROOT_ID_PARAM_TYPE,
  ReceptorsAbsoluteRoutes,
  RisksAbsoluteRoutes,
  ServicesAbsoluteRoutes,
  VendorsAbsoluteRoutes,
} from '../RootPage/RootPage.constants'

type usePrevNextHook = {
  routeToNext: (() => void) | null
  routeToPrev: (() => void) | null
  current: number
  total: number
}

export const BASE_ROUTES = Object.freeze({
  POLICIES: `../../${PoliciesAbsoluteRoutes.SHOW}`,
  CONTROLS: `../../../${ControlsAbsoluteRoutes.SHOW}`,
  RISKS: `../../../${RisksAbsoluteRoutes.SHOW}`,
  SERVICES: `../../../${ServicesAbsoluteRoutes.SHOW}`,
  RECEPTORS: `../../../${ReceptorsAbsoluteRoutes.SHOW}`,
  VENDORS: `../../../${VendorsAbsoluteRoutes.SHOW}`,
  AUDIT_BOT: `../../../${ExamineAndTestAbsoluteRoutes.SHOW}`,
})

export type PrevNextProps = {
  baseRoute: string
  idArray: string[]
  param: ROOT_ID_PARAM_TYPE
  idxOverride?: number
  includeHash?: boolean
  useUrlEncodedId?: boolean
}

/***
 * This hook is used to navigate between the previous and next items in an array of ids
 * @param baseRoute - The base route to navigate to
 * @param idArray - The array of ids to navigate between
 * @param param - The param to use in the url
 * @returns routeToNext - A function to navigate to the next item in the array (null if the current index is the last one in the array)
 * @returns routeToPrev - A function to navigate to the previous item in the array (null if the current index is the first one in the array)
 * @returns current - The current index in the array + 1 (not zero indexed)
 * @returns total - The total number of items in the array
 */
export const usePrevNext = ({
  baseRoute,
  idArray,
  param,
  idxOverride = 0,
  includeHash = false,
  useUrlEncodedId = false,
}: PrevNextProps): usePrevNextHook => {
  const params = useParams()
  const modelId = useUrlEncodedId
    ? encodeURIComponent(params[param] || '')
    : params[param] || ''
  const navigate = useNavigate()

  const idx = idArray.indexOf(modelId)
  const currentIndex: number = idx === -1 ? idxOverride : idx

  const prevModelId: string = idArray[currentIndex - 1]
  const nextModelId: string = idArray[currentIndex + 1]

  const getLinkFunction = (modelId: string) => () =>
    navigate(linkWithSearch(`${baseRoute}/${modelId}`, undefined, includeHash))

  // If the current index is the last one in the array then we want to return null instead of a function
  const routeToNext =
    currentIndex === idArray.length - 1 ? null : getLinkFunction(nextModelId)
  // If the current index is the first one in the array then we want to return null instead of a function
  const routeToPrev = currentIndex === 0 ? null : getLinkFunction(prevModelId)

  return {
    routeToNext,
    routeToPrev,
    current: currentIndex + 1,
    total: idArray.length,
  }
}
