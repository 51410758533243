import React, { useEffect, useRef, useState } from 'react'
import { ModalFormId } from 'src/components/ModalForms'
import { useModalState } from 'src/Modal/ModalStateContext'
import isFunction from 'lodash/isFunction'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useFileTypeFilterContext } from 'src/pages/Evidence/context/FileTypeFilterContext'
import { useEvidenceGenerators } from 'src/components/async/attachment/useEvidenceGenerators'
import { useAppliedFilters } from 'src/Utils/globalHooks'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import {
  CsvIcon,
  ImageIcon,
  PdfIcon,
  PowerpointIcon,
  TextIcon,
  WordIcon,
  CustomIcon,
} from '../../../../Icons/FileType'
import { ReactComponent as PermalinkIcon } from '../../../../Icons/assets/permalink-icon.svg'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'
import { DropdownItemType, NestedFilterType } from './FilterDropdown.constants'
import { LoadingFilterDropdown } from './FilterDropdown.components'

type FileTypeIcon = React.FunctionComponent<React.SVGProps<SVGSVGElement>> & {
  title?: string
}

export enum EvidenceTypeFilterValues {
  AUTOMATED = 'automated',
  MANUAL = 'manual',
}

enum EvidenceTypeFilterNames {
  AUTOMATED = 'Automated',
  MANUAL = 'Manual',
}

const evidenceTypeFilterNameToValue = Object.freeze({
  [EvidenceTypeFilterNames.AUTOMATED]: EvidenceTypeFilterValues.AUTOMATED,
  [EvidenceTypeFilterNames.MANUAL]: EvidenceTypeFilterValues.MANUAL,
})

enum FileTypeFilterNames {
  PDF = 'PDF',
  DOCUMENT = 'Document',
  IMAGE = 'Image',
  SPREADSHEET = 'Spreadsheet',
  PRESENTATION = 'Presentation',
  CUSTOM = 'Custom',
  TEXT = 'Text',
  LINK = 'Link',
}
export enum FileTypeFilterValues {
  PDF = 'pdf',
  DOCUMENT = 'document',
  IMAGE = 'image',
  SPREADSHEET = 'spreadsheet',
  PRESENTATION = 'presentation',
  CUSTOM = 'custom',
  TEXT = 'text',
  LINK = 'link',
}

export const FileTypeFilterNameToValue = Object.freeze({
  [FileTypeFilterNames.PDF]: FileTypeFilterValues.PDF,
  [FileTypeFilterNames.DOCUMENT]: FileTypeFilterValues.DOCUMENT,
  [FileTypeFilterNames.IMAGE]: FileTypeFilterValues.IMAGE,
  [FileTypeFilterNames.SPREADSHEET]: FileTypeFilterValues.SPREADSHEET,
  [FileTypeFilterNames.PRESENTATION]: FileTypeFilterValues.PRESENTATION,
  [FileTypeFilterNames.CUSTOM]: FileTypeFilterValues.CUSTOM,
  [FileTypeFilterNames.TEXT]: FileTypeFilterValues.TEXT,
  [FileTypeFilterNames.LINK]: FileTypeFilterValues.LINK,
})

export const FileTypeFilterValueToMimeTypes = Object.freeze({
  [FileTypeFilterValues.PDF]: [MIME_TYPE.APPLICATION_PDF],
  [FileTypeFilterValues.DOCUMENT]: [
    MIME_TYPE.APPLICATION_DOCUMENT,
    MIME_TYPE.APPLICATION_WORD,
    MIME_TYPE.APPLICATION_RTF,
  ],
  [FileTypeFilterValues.IMAGE]: [MIME_TYPE.IMAGE_JPEG, MIME_TYPE.IMAGE_PNG],
  [FileTypeFilterValues.SPREADSHEET]: [
    MIME_TYPE.APPLICATION_SPREADSHEET,
    MIME_TYPE.APPLICATION_EXCEL,
    MIME_TYPE.APPLICATION_VND_OPENXML_SPREADSHEET,
    MIME_TYPE.TEXT_CSV,
  ],
  [FileTypeFilterValues.PRESENTATION]: [
    MIME_TYPE.APPLE_KEYNOTE,
    MIME_TYPE.APPLE_KEYNOTE_2,
  ],
  [FileTypeFilterValues.CUSTOM]: [],
  [FileTypeFilterValues.TEXT]: [MIME_TYPE.TEXT_MARKDOWN],
  [FileTypeFilterValues.LINK]: [MIME_TYPE.TEXT_URI_LIST],
})

export const FileTypeFilterValueToIcon = Object.freeze({
  [FileTypeFilterValues.PDF]: PdfIcon,
  [FileTypeFilterValues.DOCUMENT]: WordIcon,
  [FileTypeFilterValues.IMAGE]: ImageIcon,
  [FileTypeFilterValues.SPREADSHEET]: CsvIcon,
  [FileTypeFilterValues.PRESENTATION]: PowerpointIcon,
  [FileTypeFilterValues.CUSTOM]: CustomIcon,
  [FileTypeFilterValues.TEXT]: TextIcon,
  [FileTypeFilterValues.LINK]: PermalinkIcon,
})

export const CustomFileIcon =
  FileTypeFilterValueToIcon[FileTypeFilterValues.CUSTOM]

export const getFileTypeFilterItem = (
  filterName: string,
  filterValue: string,
  FileIcon: FileTypeIcon,
  customOnClick?: () => void,
): DropdownItemType => {
  const filterObj = {
    value: filterValue,
    label: (
      <FlexRow gap={10} justify={FlexAlign.FLEX_START} align={FlexAlign.CENTER}>
        <FileIcon height={24} width={24} />
        {filterName}
      </FlexRow>
    ),
  } as DropdownItemType
  if (isFunction(customOnClick) && filterName === FileTypeFilterNames.CUSTOM) {
    filterObj.customOnClick = customOnClick
  }
  return filterObj
}

export const EvidenceTypeFilterDropdown = (): JSX.Element => {
  const appliedFilters = useAppliedFilters(FilterParam.FILE_TYPE)
  const { openModal } = useModalState()
  const {
    filterValues: fileTypeFilterValues,
    setFilterValues: setFileTypeFilterValues,
  } = useFileTypeFilterContext()
  const [show, setShow] = useState<boolean>(false)
  const isInitialized = useRef<boolean>(false)
  const { data, isLoading, error } = useEvidenceGenerators({
    modelType: MODEL_TYPE.EVIDENCE,
    connected: true,
  })

  useEffect(() => {
    const customOnClick = () => {
      openModal(ModalFormId.CUSTOM_FILE_TYPE_FILTER)
      setShow(false)
    }
    const initalValues: DropdownItemType[] = Object.values(
      FileTypeFilterNames,
    ).map((filterName: FileTypeFilterNames) => {
      const filterValue = FileTypeFilterNameToValue[filterName]
      const FileIcon = FileTypeFilterValueToIcon[filterValue]
      return getFileTypeFilterItem(
        filterName,
        filterValue,
        FileIcon,
        customOnClick,
      )
    })
    appliedFilters.forEach((filter) => {
      if (!initalValues.find((val) => val.value === filter)) {
        initalValues.push(getFileTypeFilterItem(filter, filter, CustomFileIcon))
      }
    })
    if (!isInitialized.current) {
      setFileTypeFilterValues(initalValues)
      isInitialized.current = true
    }
  }, [setFileTypeFilterValues, appliedFilters, openModal])

  if (error) return <></>
  if (!data || isLoading) {
    return <LoadingFilterDropdown filterName={FilterName.EVIDENCE_TYPE} />
  }
  const automatedEvidenceFilterValues = data.getItemsList().map((item) => {
    return {
      value: item.getEvidenceId(),
      label: formatEvidenceCaption(item.getEvidenceId(), true),
    }
  })

  const handleToggle = () => setShow(!show)

  const evidenceTypeFilterNameToNestedFilter: Record<
    EvidenceTypeFilterNames,
    NestedFilterType
  > = {
    [EvidenceTypeFilterNames.AUTOMATED]: {
      filterParam: FilterParam.AUTOMATED,
      filterValues: automatedEvidenceFilterValues,
    },
    [EvidenceTypeFilterNames.MANUAL]: {
      filterParam: FilterParam.FILE_TYPE,
      filterValues: fileTypeFilterValues,
    },
  }
  const filterValues = Object.values(EvidenceTypeFilterNames).map(
    (filterName: EvidenceTypeFilterNames) => {
      const filterValue = {
        value: evidenceTypeFilterNameToValue[filterName],
        label: filterName,
        nestedFilter: evidenceTypeFilterNameToNestedFilter[filterName],
      }
      return filterValue
    },
  )
  return (
    <FilterDropdown
      filterParam={FilterParam.EVIDENCE_TYPE}
      filterName={FilterName.EVIDENCE_TYPE}
      filterValues={filterValues}
      show={show}
      handleToggle={handleToggle}
      hasNested
    />
  )
}
