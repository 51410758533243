// source: audit/auditbot.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var audit_audit_pb = require('../audit/audit_pb.js');
goog.object.extend(proto, audit_audit_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
var attachment_attachment_pb = require('../attachment/attachment_pb.js');
goog.object.extend(proto, attachment_attachment_pb);
goog.exportSymbol('proto.audit.AUDIT_RESULT', null, global);
goog.exportSymbol('proto.audit.AUDIT_STATUS', null, global);
goog.exportSymbol('proto.audit.AuditReadinessRecord', null, global);
goog.exportSymbol('proto.audit.AuditReadinessTestRecord', null, global);
goog.exportSymbol('proto.audit.AuditRun', null, global);
goog.exportSymbol('proto.audit.AuditRunProgress', null, global);
goog.exportSymbol('proto.audit.AuditRunSummaries', null, global);
goog.exportSymbol('proto.audit.AuditRunSummary', null, global);
goog.exportSymbol('proto.audit.CONTROL_CHECK_SORT_COLUMN', null, global);
goog.exportSymbol('proto.audit.CONTROL_CHECK_SORT_ORDER', null, global);
goog.exportSymbol('proto.audit.CompanyInfoStatus', null, global);
goog.exportSymbol('proto.audit.ControlAuditRunSummary', null, global);
goog.exportSymbol('proto.audit.ControlCheckSort', null, global);
goog.exportSymbol('proto.audit.ControlStatus', null, global);
goog.exportSymbol('proto.audit.ControlTest', null, global);
goog.exportSymbol('proto.audit.DeleteAuditRunsRequest', null, global);
goog.exportSymbol('proto.audit.EditControlTestResultRequest', null, global);
goog.exportSymbol('proto.audit.FrameworkStatus', null, global);
goog.exportSymbol('proto.audit.GetAccountBalanceResponse', null, global);
goog.exportSymbol('proto.audit.GetAuditControlTestRunsRequest', null, global);
goog.exportSymbol('proto.audit.GetAuditControlTestRunsResponse', null, global);
goog.exportSymbol('proto.audit.GetAuditReadinessRequest', null, global);
goog.exportSymbol('proto.audit.GetAuditReadinessResponse', null, global);
goog.exportSymbol('proto.audit.GetAuditRunProgressRequest', null, global);
goog.exportSymbol('proto.audit.GetAuditRunRequest', null, global);
goog.exportSymbol('proto.audit.GetHasValidFrameworksForScanRequest', null, global);
goog.exportSymbol('proto.audit.GetOperatingEffectivenessDetailsRequest', null, global);
goog.exportSymbol('proto.audit.GetSmartChecksTestInformationRequest', null, global);
goog.exportSymbol('proto.audit.GetSmartChecksTestInformationResponse', null, global);
goog.exportSymbol('proto.audit.GetValidAuditsForScanRequest', null, global);
goog.exportSymbol('proto.audit.ListAuditRunsRequest', null, global);
goog.exportSymbol('proto.audit.OEEvidenceResult', null, global);
goog.exportSymbol('proto.audit.OETestProcedureResult', null, global);
goog.exportSymbol('proto.audit.OperatingEffectivenessDetails', null, global);
goog.exportSymbol('proto.audit.PRE_AUDIT_RESULT', null, global);
goog.exportSymbol('proto.audit.PRE_AUDIT_SECTION', null, global);
goog.exportSymbol('proto.audit.PolicyStatus', null, global);
goog.exportSymbol('proto.audit.PreAudit', null, global);
goog.exportSymbol('proto.audit.PreAuditCheckRequest', null, global);
goog.exportSymbol('proto.audit.REASON_SOURCE', null, global);
goog.exportSymbol('proto.audit.RequestStatus', null, global);
goog.exportSymbol('proto.audit.RiskStatus', null, global);
goog.exportSymbol('proto.audit.ServiceStatus', null, global);
goog.exportSymbol('proto.audit.SmartCheckRecord', null, global);
goog.exportSymbol('proto.audit.SmartControlTestRecord', null, global);
goog.exportSymbol('proto.audit.StartAuditRunRequest', null, global);
goog.exportSymbol('proto.audit.StartAuditRunResponse', null, global);
goog.exportSymbol('proto.audit.StopAuditRunRequest', null, global);
goog.exportSymbol('proto.audit.TestResultFilter', null, global);
goog.exportSymbol('proto.audit.ValidateAccountBalanceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAccountBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetAccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAccountBalanceResponse.displayName = 'proto.audit.GetAccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ValidateAccountBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.ValidateAccountBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ValidateAccountBalanceResponse.displayName = 'proto.audit.ValidateAccountBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.StartAuditRunRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.StartAuditRunRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.StartAuditRunRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.StartAuditRunRequest.displayName = 'proto.audit.StartAuditRunRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.StartAuditRunResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.StartAuditRunResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.StartAuditRunResponse.displayName = 'proto.audit.StartAuditRunResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.PreAuditCheckRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.PreAuditCheckRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.PreAuditCheckRequest.displayName = 'proto.audit.PreAuditCheckRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.PreAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.PreAudit.repeatedFields_, null);
};
goog.inherits(proto.audit.PreAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.PreAudit.displayName = 'proto.audit.PreAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.CompanyInfoStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.CompanyInfoStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.CompanyInfoStatus.displayName = 'proto.audit.CompanyInfoStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.PolicyStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.PolicyStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.PolicyStatus.displayName = 'proto.audit.PolicyStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ControlStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.ControlStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ControlStatus.displayName = 'proto.audit.ControlStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ServiceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.ServiceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ServiceStatus.displayName = 'proto.audit.ServiceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.FrameworkStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.FrameworkStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.FrameworkStatus.displayName = 'proto.audit.FrameworkStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.RiskStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.RiskStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.RiskStatus.displayName = 'proto.audit.RiskStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.RequestStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.RequestStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.RequestStatus.displayName = 'proto.audit.RequestStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.EditControlTestResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.EditControlTestResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.EditControlTestResultRequest.displayName = 'proto.audit.EditControlTestResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ListAuditRunsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.ListAuditRunsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ListAuditRunsRequest.displayName = 'proto.audit.ListAuditRunsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditRunSummaries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AuditRunSummaries.repeatedFields_, null);
};
goog.inherits(proto.audit.AuditRunSummaries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditRunSummaries.displayName = 'proto.audit.AuditRunSummaries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditRunSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AuditRunSummary.repeatedFields_, null);
};
goog.inherits(proto.audit.AuditRunSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditRunSummary.displayName = 'proto.audit.AuditRunSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditRunRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetAuditRunRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditRunRequest.displayName = 'proto.audit.GetAuditRunRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AuditRun.repeatedFields_, null);
};
goog.inherits(proto.audit.AuditRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditRun.displayName = 'proto.audit.AuditRun';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ControlTest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.ControlTest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ControlTest.displayName = 'proto.audit.ControlTest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.StopAuditRunRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.StopAuditRunRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.StopAuditRunRequest.displayName = 'proto.audit.StopAuditRunRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.DeleteAuditRunsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.DeleteAuditRunsRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.DeleteAuditRunsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.DeleteAuditRunsRequest.displayName = 'proto.audit.DeleteAuditRunsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetValidAuditsForScanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetValidAuditsForScanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetValidAuditsForScanRequest.displayName = 'proto.audit.GetValidAuditsForScanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetHasValidFrameworksForScanRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetHasValidFrameworksForScanRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetHasValidFrameworksForScanRequest.displayName = 'proto.audit.GetHasValidFrameworksForScanRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditControlTestRunsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetAuditControlTestRunsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditControlTestRunsRequest.displayName = 'proto.audit.GetAuditControlTestRunsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ControlAuditRunSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.ControlAuditRunSummary.repeatedFields_, null);
};
goog.inherits(proto.audit.ControlAuditRunSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ControlAuditRunSummary.displayName = 'proto.audit.ControlAuditRunSummary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditControlTestRunsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.GetAuditControlTestRunsResponse.repeatedFields_, null);
};
goog.inherits(proto.audit.GetAuditControlTestRunsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditControlTestRunsResponse.displayName = 'proto.audit.GetAuditControlTestRunsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetSmartChecksTestInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.GetSmartChecksTestInformationRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.GetSmartChecksTestInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetSmartChecksTestInformationRequest.displayName = 'proto.audit.GetSmartChecksTestInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.SmartControlTestRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.SmartControlTestRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.SmartControlTestRecord.displayName = 'proto.audit.SmartControlTestRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.SmartCheckRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.SmartCheckRecord.repeatedFields_, null);
};
goog.inherits(proto.audit.SmartCheckRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.SmartCheckRecord.displayName = 'proto.audit.SmartCheckRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetSmartChecksTestInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.GetSmartChecksTestInformationResponse.repeatedFields_, null);
};
goog.inherits(proto.audit.GetSmartChecksTestInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetSmartChecksTestInformationResponse.displayName = 'proto.audit.GetSmartChecksTestInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.ControlCheckSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.ControlCheckSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.ControlCheckSort.displayName = 'proto.audit.ControlCheckSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.TestResultFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.TestResultFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.TestResultFilter.displayName = 'proto.audit.TestResultFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditReadinessRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.GetAuditReadinessRequest.repeatedFields_, null);
};
goog.inherits(proto.audit.GetAuditReadinessRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditReadinessRequest.displayName = 'proto.audit.GetAuditReadinessRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditReadinessTestRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.AuditReadinessTestRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditReadinessTestRecord.displayName = 'proto.audit.AuditReadinessTestRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditReadinessRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.AuditReadinessRecord.repeatedFields_, null);
};
goog.inherits(proto.audit.AuditReadinessRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditReadinessRecord.displayName = 'proto.audit.AuditReadinessRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditReadinessResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.GetAuditReadinessResponse.repeatedFields_, null);
};
goog.inherits(proto.audit.GetAuditReadinessResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditReadinessResponse.displayName = 'proto.audit.GetAuditReadinessResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetAuditRunProgressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetAuditRunProgressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetAuditRunProgressRequest.displayName = 'proto.audit.GetAuditRunProgressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.AuditRunProgress = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.AuditRunProgress, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.AuditRunProgress.displayName = 'proto.audit.AuditRunProgress';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.GetOperatingEffectivenessDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.GetOperatingEffectivenessDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.GetOperatingEffectivenessDetailsRequest.displayName = 'proto.audit.GetOperatingEffectivenessDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.OEEvidenceResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.audit.OEEvidenceResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.OEEvidenceResult.displayName = 'proto.audit.OEEvidenceResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.OETestProcedureResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.OETestProcedureResult.repeatedFields_, null);
};
goog.inherits(proto.audit.OETestProcedureResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.OETestProcedureResult.displayName = 'proto.audit.OETestProcedureResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.audit.OperatingEffectivenessDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.audit.OperatingEffectivenessDetails.repeatedFields_, null);
};
goog.inherits(proto.audit.OperatingEffectivenessDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.audit.OperatingEffectivenessDetails.displayName = 'proto.audit.OperatingEffectivenessDetails';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAccountBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAccountBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAccountBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAccountBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    balance: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allocationTotal: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAccountBalanceResponse}
 */
proto.audit.GetAccountBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAccountBalanceResponse;
  return proto.audit.GetAccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAccountBalanceResponse}
 */
proto.audit.GetAccountBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBalance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAllocationTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAccountBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAccountBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAccountBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalance();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAllocationTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 balance = 1;
 * @return {number}
 */
proto.audit.GetAccountBalanceResponse.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAccountBalanceResponse} returns this
 */
proto.audit.GetAccountBalanceResponse.prototype.setBalance = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 allocation_total = 2;
 * @return {number}
 */
proto.audit.GetAccountBalanceResponse.prototype.getAllocationTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAccountBalanceResponse} returns this
 */
proto.audit.GetAccountBalanceResponse.prototype.setAllocationTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ValidateAccountBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ValidateAccountBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ValidateAccountBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ValidateAccountBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    allowControlTest: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ValidateAccountBalanceResponse}
 */
proto.audit.ValidateAccountBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ValidateAccountBalanceResponse;
  return proto.audit.ValidateAccountBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ValidateAccountBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ValidateAccountBalanceResponse}
 */
proto.audit.ValidateAccountBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.ALLOW_TRANSACTION} */ (reader.readEnum());
      msg.setAllowControlTest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ValidateAccountBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ValidateAccountBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ValidateAccountBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ValidateAccountBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAllowControlTest();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional common.ALLOW_TRANSACTION allow_control_test = 1;
 * @return {!proto.common.ALLOW_TRANSACTION}
 */
proto.audit.ValidateAccountBalanceResponse.prototype.getAllowControlTest = function() {
  return /** @type {!proto.common.ALLOW_TRANSACTION} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.ALLOW_TRANSACTION} value
 * @return {!proto.audit.ValidateAccountBalanceResponse} returns this
 */
proto.audit.ValidateAccountBalanceResponse.prototype.setAllowControlTest = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.StartAuditRunRequest.repeatedFields_ = [4,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.StartAuditRunRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.StartAuditRunRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.StartAuditRunRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.StartAuditRunRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    startDate: (f = msg.getStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    skipPreAudit: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    auditRunId: (f = msg.getAuditRunId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlTestsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.StartAuditRunRequest}
 */
proto.audit.StartAuditRunRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.StartAuditRunRequest;
  return proto.audit.StartAuditRunRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.StartAuditRunRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.StartAuditRunRequest}
 */
proto.audit.StartAuditRunRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipPreAudit(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditRunId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.StartAuditRunRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.StartAuditRunRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.StartAuditRunRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.StartAuditRunRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getSkipPreAudit();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getAuditRunId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.StartAuditRunRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
*/
proto.audit.StartAuditRunRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.StartAuditRunRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp start_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.StartAuditRunRequest.prototype.getStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
*/
proto.audit.StartAuditRunRequest.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.StartAuditRunRequest.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.StartAuditRunRequest.prototype.getEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
*/
proto.audit.StartAuditRunRequest.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.StartAuditRunRequest.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string control_ids = 4;
 * @return {!Array<string>}
 */
proto.audit.StartAuditRunRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * optional bool skip_pre_audit = 5;
 * @return {boolean}
 */
proto.audit.StartAuditRunRequest.prototype.getSkipPreAudit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.setSkipPreAudit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional google.protobuf.StringValue audit_run_id = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.StartAuditRunRequest.prototype.getAuditRunId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
*/
proto.audit.StartAuditRunRequest.prototype.setAuditRunId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.clearAuditRunId = function() {
  return this.setAuditRunId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.StartAuditRunRequest.prototype.hasAuditRunId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated string control_tests = 7;
 * @return {!Array<string>}
 */
proto.audit.StartAuditRunRequest.prototype.getControlTestsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.setControlTestsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.addControlTests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.StartAuditRunRequest} returns this
 */
proto.audit.StartAuditRunRequest.prototype.clearControlTestsList = function() {
  return this.setControlTestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.StartAuditRunResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.StartAuditRunResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.StartAuditRunResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.StartAuditRunResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.StartAuditRunResponse}
 */
proto.audit.StartAuditRunResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.StartAuditRunResponse;
  return proto.audit.StartAuditRunResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.StartAuditRunResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.StartAuditRunResponse}
 */
proto.audit.StartAuditRunResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.StartAuditRunResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.StartAuditRunResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.StartAuditRunResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.StartAuditRunResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.audit.StartAuditRunResponse.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.StartAuditRunResponse} returns this
 */
proto.audit.StartAuditRunResponse.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.audit.StartAuditRunResponse.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.StartAuditRunResponse} returns this
 */
proto.audit.StartAuditRunResponse.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.PreAuditCheckRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.PreAuditCheckRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.PreAuditCheckRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.PreAuditCheckRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.PreAuditCheckRequest}
 */
proto.audit.PreAuditCheckRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.PreAuditCheckRequest;
  return proto.audit.PreAuditCheckRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.PreAuditCheckRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.PreAuditCheckRequest}
 */
proto.audit.PreAuditCheckRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.PreAuditCheckRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.PreAuditCheckRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.PreAuditCheckRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.PreAuditCheckRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.PreAuditCheckRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.PreAuditCheckRequest} returns this
 */
proto.audit.PreAuditCheckRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.PreAudit.repeatedFields_ = [2,3,4,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.PreAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.PreAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.PreAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.PreAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    perSectionResultsMap: (f = msg.getPerSectionResultsMap()) ? f.toObject(includeInstance, undefined) : [],
    companyInfosList: jspb.Message.toObjectList(msg.getCompanyInfosList(),
    proto.audit.CompanyInfoStatus.toObject, includeInstance),
    policiesList: jspb.Message.toObjectList(msg.getPoliciesList(),
    proto.audit.PolicyStatus.toObject, includeInstance),
    controlsList: jspb.Message.toObjectList(msg.getControlsList(),
    proto.audit.ControlStatus.toObject, includeInstance),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.audit.ServiceStatus.toObject, includeInstance),
    frameworksList: jspb.Message.toObjectList(msg.getFrameworksList(),
    proto.audit.FrameworkStatus.toObject, includeInstance),
    risksList: jspb.Message.toObjectList(msg.getRisksList(),
    proto.audit.RiskStatus.toObject, includeInstance),
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.audit.RequestStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.PreAudit}
 */
proto.audit.PreAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.PreAudit;
  return proto.audit.PreAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.PreAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.PreAudit}
 */
proto.audit.PreAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPerSectionResultsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readEnum, null, "", 0);
         });
      break;
    case 2:
      var value = new proto.audit.CompanyInfoStatus;
      reader.readMessage(value,proto.audit.CompanyInfoStatus.deserializeBinaryFromReader);
      msg.addCompanyInfos(value);
      break;
    case 3:
      var value = new proto.audit.PolicyStatus;
      reader.readMessage(value,proto.audit.PolicyStatus.deserializeBinaryFromReader);
      msg.addPolicies(value);
      break;
    case 4:
      var value = new proto.audit.ControlStatus;
      reader.readMessage(value,proto.audit.ControlStatus.deserializeBinaryFromReader);
      msg.addControls(value);
      break;
    case 5:
      var value = new proto.audit.ServiceStatus;
      reader.readMessage(value,proto.audit.ServiceStatus.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 6:
      var value = new proto.audit.FrameworkStatus;
      reader.readMessage(value,proto.audit.FrameworkStatus.deserializeBinaryFromReader);
      msg.addFrameworks(value);
      break;
    case 7:
      var value = new proto.audit.RiskStatus;
      reader.readMessage(value,proto.audit.RiskStatus.deserializeBinaryFromReader);
      msg.addRisks(value);
      break;
    case 8:
      var value = new proto.audit.RequestStatus;
      reader.readMessage(value,proto.audit.RequestStatus.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.PreAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.PreAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.PreAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.PreAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPerSectionResultsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeEnum);
  }
  f = message.getCompanyInfosList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.audit.CompanyInfoStatus.serializeBinaryToWriter
    );
  }
  f = message.getPoliciesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.audit.PolicyStatus.serializeBinaryToWriter
    );
  }
  f = message.getControlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.audit.ControlStatus.serializeBinaryToWriter
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.audit.ServiceStatus.serializeBinaryToWriter
    );
  }
  f = message.getFrameworksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.audit.FrameworkStatus.serializeBinaryToWriter
    );
  }
  f = message.getRisksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.audit.RiskStatus.serializeBinaryToWriter
    );
  }
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.audit.RequestStatus.serializeBinaryToWriter
    );
  }
};


/**
 * map<string, PRE_AUDIT_RESULT> per_section_results = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.audit.PRE_AUDIT_RESULT>}
 */
proto.audit.PreAudit.prototype.getPerSectionResultsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.audit.PRE_AUDIT_RESULT>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearPerSectionResultsMap = function() {
  this.getPerSectionResultsMap().clear();
  return this;};


/**
 * repeated CompanyInfoStatus company_infos = 2;
 * @return {!Array<!proto.audit.CompanyInfoStatus>}
 */
proto.audit.PreAudit.prototype.getCompanyInfosList = function() {
  return /** @type{!Array<!proto.audit.CompanyInfoStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.CompanyInfoStatus, 2));
};


/**
 * @param {!Array<!proto.audit.CompanyInfoStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setCompanyInfosList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.audit.CompanyInfoStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.CompanyInfoStatus}
 */
proto.audit.PreAudit.prototype.addCompanyInfos = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.audit.CompanyInfoStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearCompanyInfosList = function() {
  return this.setCompanyInfosList([]);
};


/**
 * repeated PolicyStatus policies = 3;
 * @return {!Array<!proto.audit.PolicyStatus>}
 */
proto.audit.PreAudit.prototype.getPoliciesList = function() {
  return /** @type{!Array<!proto.audit.PolicyStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.PolicyStatus, 3));
};


/**
 * @param {!Array<!proto.audit.PolicyStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setPoliciesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.audit.PolicyStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.PolicyStatus}
 */
proto.audit.PreAudit.prototype.addPolicies = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.audit.PolicyStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearPoliciesList = function() {
  return this.setPoliciesList([]);
};


/**
 * repeated ControlStatus controls = 4;
 * @return {!Array<!proto.audit.ControlStatus>}
 */
proto.audit.PreAudit.prototype.getControlsList = function() {
  return /** @type{!Array<!proto.audit.ControlStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.ControlStatus, 4));
};


/**
 * @param {!Array<!proto.audit.ControlStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setControlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.audit.ControlStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ControlStatus}
 */
proto.audit.PreAudit.prototype.addControls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.audit.ControlStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearControlsList = function() {
  return this.setControlsList([]);
};


/**
 * repeated ServiceStatus services = 5;
 * @return {!Array<!proto.audit.ServiceStatus>}
 */
proto.audit.PreAudit.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.audit.ServiceStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.ServiceStatus, 5));
};


/**
 * @param {!Array<!proto.audit.ServiceStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.audit.ServiceStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ServiceStatus}
 */
proto.audit.PreAudit.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.audit.ServiceStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * repeated FrameworkStatus frameworks = 6;
 * @return {!Array<!proto.audit.FrameworkStatus>}
 */
proto.audit.PreAudit.prototype.getFrameworksList = function() {
  return /** @type{!Array<!proto.audit.FrameworkStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.FrameworkStatus, 6));
};


/**
 * @param {!Array<!proto.audit.FrameworkStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setFrameworksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.audit.FrameworkStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.FrameworkStatus}
 */
proto.audit.PreAudit.prototype.addFrameworks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.audit.FrameworkStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearFrameworksList = function() {
  return this.setFrameworksList([]);
};


/**
 * repeated RiskStatus risks = 7;
 * @return {!Array<!proto.audit.RiskStatus>}
 */
proto.audit.PreAudit.prototype.getRisksList = function() {
  return /** @type{!Array<!proto.audit.RiskStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.RiskStatus, 7));
};


/**
 * @param {!Array<!proto.audit.RiskStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setRisksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.audit.RiskStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.RiskStatus}
 */
proto.audit.PreAudit.prototype.addRisks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.audit.RiskStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearRisksList = function() {
  return this.setRisksList([]);
};


/**
 * repeated RequestStatus requests = 8;
 * @return {!Array<!proto.audit.RequestStatus>}
 */
proto.audit.PreAudit.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.audit.RequestStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.RequestStatus, 8));
};


/**
 * @param {!Array<!proto.audit.RequestStatus>} value
 * @return {!proto.audit.PreAudit} returns this
*/
proto.audit.PreAudit.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.audit.RequestStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.RequestStatus}
 */
proto.audit.PreAudit.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.audit.RequestStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.PreAudit} returns this
 */
proto.audit.PreAudit.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.CompanyInfoStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.CompanyInfoStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.CompanyInfoStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.CompanyInfoStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyInfoId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasDocumentation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isFilledIn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.CompanyInfoStatus}
 */
proto.audit.CompanyInfoStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.CompanyInfoStatus;
  return proto.audit.CompanyInfoStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.CompanyInfoStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.CompanyInfoStatus}
 */
proto.audit.CompanyInfoStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyInfoId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDocumentation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFilledIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.CompanyInfoStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.CompanyInfoStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.CompanyInfoStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.CompanyInfoStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyInfoId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasDocumentation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsFilledIn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string company_info_id = 1;
 * @return {string}
 */
proto.audit.CompanyInfoStatus.prototype.getCompanyInfoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CompanyInfoStatus} returns this
 */
proto.audit.CompanyInfoStatus.prototype.setCompanyInfoId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.CompanyInfoStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.CompanyInfoStatus} returns this
 */
proto.audit.CompanyInfoStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_documentation = 3;
 * @return {boolean}
 */
proto.audit.CompanyInfoStatus.prototype.getHasDocumentation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.CompanyInfoStatus} returns this
 */
proto.audit.CompanyInfoStatus.prototype.setHasDocumentation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_filled_in = 4;
 * @return {boolean}
 */
proto.audit.CompanyInfoStatus.prototype.getIsFilledIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.CompanyInfoStatus} returns this
 */
proto.audit.CompanyInfoStatus.prototype.setIsFilledIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.PolicyStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.PolicyStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.PolicyStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.PolicyStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasDocumentation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    isFilledIn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isNotApplicable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.PolicyStatus}
 */
proto.audit.PolicyStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.PolicyStatus;
  return proto.audit.PolicyStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.PolicyStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.PolicyStatus}
 */
proto.audit.PolicyStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDocumentation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFilledIn(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNotApplicable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.PolicyStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.PolicyStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.PolicyStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.PolicyStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasDocumentation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIsFilledIn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsNotApplicable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string policy_id = 1;
 * @return {string}
 */
proto.audit.PolicyStatus.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.PolicyStatus} returns this
 */
proto.audit.PolicyStatus.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.PolicyStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.PolicyStatus} returns this
 */
proto.audit.PolicyStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_documentation = 3;
 * @return {boolean}
 */
proto.audit.PolicyStatus.prototype.getHasDocumentation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.PolicyStatus} returns this
 */
proto.audit.PolicyStatus.prototype.setHasDocumentation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool is_filled_in = 4;
 * @return {boolean}
 */
proto.audit.PolicyStatus.prototype.getIsFilledIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.PolicyStatus} returns this
 */
proto.audit.PolicyStatus.prototype.setIsFilledIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_not_applicable = 5;
 * @return {boolean}
 */
proto.audit.PolicyStatus.prototype.getIsNotApplicable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.PolicyStatus} returns this
 */
proto.audit.PolicyStatus.prototype.setIsNotApplicable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ControlStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ControlStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ControlStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    evidenceCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hasPolicy: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    hasFramework: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    passingStatus: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    modelId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isNotApplicable: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ControlStatus}
 */
proto.audit.ControlStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ControlStatus;
  return proto.audit.ControlStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ControlStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ControlStatus}
 */
proto.audit.ControlStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEvidenceCount(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPolicy(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFramework(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassingStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNotApplicable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ControlStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ControlStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ControlStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEvidenceCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHasPolicy();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getHasFramework();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPassingStatus();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsNotApplicable();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.audit.ControlStatus.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.ControlStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 evidence_count = 3;
 * @return {number}
 */
proto.audit.ControlStatus.prototype.getEvidenceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setEvidenceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool has_policy = 4;
 * @return {boolean}
 */
proto.audit.ControlStatus.prototype.getHasPolicy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setHasPolicy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool has_framework = 5;
 * @return {boolean}
 */
proto.audit.ControlStatus.prototype.getHasFramework = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setHasFramework = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool passing_status = 6;
 * @return {boolean}
 */
proto.audit.ControlStatus.prototype.getPassingStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setPassingStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string model_id = 7;
 * @return {string}
 */
proto.audit.ControlStatus.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool is_not_applicable = 8;
 * @return {boolean}
 */
proto.audit.ControlStatus.prototype.getIsNotApplicable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.ControlStatus} returns this
 */
proto.audit.ControlStatus.prototype.setIsNotApplicable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ServiceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ServiceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ServiceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ServiceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ServiceStatus}
 */
proto.audit.ServiceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ServiceStatus;
  return proto.audit.ServiceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ServiceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ServiceStatus}
 */
proto.audit.ServiceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ServiceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ServiceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ServiceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ServiceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.audit.ServiceStatus.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ServiceStatus} returns this
 */
proto.audit.ServiceStatus.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.ServiceStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ServiceStatus} returns this
 */
proto.audit.ServiceStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.FrameworkStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.FrameworkStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.FrameworkStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.FrameworkStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    frameworkId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkedControls: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.FrameworkStatus}
 */
proto.audit.FrameworkStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.FrameworkStatus;
  return proto.audit.FrameworkStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.FrameworkStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.FrameworkStatus}
 */
proto.audit.FrameworkStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrameworkId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLinkedControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.FrameworkStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.FrameworkStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.FrameworkStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.FrameworkStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkedControls();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string framework_id = 1;
 * @return {string}
 */
proto.audit.FrameworkStatus.prototype.getFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.FrameworkStatus} returns this
 */
proto.audit.FrameworkStatus.prototype.setFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.FrameworkStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.FrameworkStatus} returns this
 */
proto.audit.FrameworkStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 linked_controls = 3;
 * @return {number}
 */
proto.audit.FrameworkStatus.prototype.getLinkedControls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.FrameworkStatus} returns this
 */
proto.audit.FrameworkStatus.prototype.setLinkedControls = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.RiskStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.RiskStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.RiskStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RiskStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    riskId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasAssignee: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    inherentRiskDetermined: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    residualRiskDetermined: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    passingStatus: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.RiskStatus}
 */
proto.audit.RiskStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.RiskStatus;
  return proto.audit.RiskStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.RiskStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.RiskStatus}
 */
proto.audit.RiskStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRiskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAssignee(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInherentRiskDetermined(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResidualRiskDetermined(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.RiskStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.RiskStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.RiskStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RiskStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRiskId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasAssignee();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getInherentRiskDetermined();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getResidualRiskDetermined();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPassingStatus();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string risk_id = 1;
 * @return {string}
 */
proto.audit.RiskStatus.prototype.getRiskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.RiskStatus} returns this
 */
proto.audit.RiskStatus.prototype.setRiskId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.RiskStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.RiskStatus} returns this
 */
proto.audit.RiskStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_assignee = 3;
 * @return {boolean}
 */
proto.audit.RiskStatus.prototype.getHasAssignee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.RiskStatus} returns this
 */
proto.audit.RiskStatus.prototype.setHasAssignee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool inherent_risk_determined = 4;
 * @return {boolean}
 */
proto.audit.RiskStatus.prototype.getInherentRiskDetermined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.RiskStatus} returns this
 */
proto.audit.RiskStatus.prototype.setInherentRiskDetermined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool residual_risk_determined = 5;
 * @return {boolean}
 */
proto.audit.RiskStatus.prototype.getResidualRiskDetermined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.RiskStatus} returns this
 */
proto.audit.RiskStatus.prototype.setResidualRiskDetermined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool passing_status = 6;
 * @return {boolean}
 */
proto.audit.RiskStatus.prototype.getPassingStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.RiskStatus} returns this
 */
proto.audit.RiskStatus.prototype.setPassingStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.RequestStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.RequestStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.RequestStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RequestStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    evidenceCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    controlCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    passingStatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.RequestStatus}
 */
proto.audit.RequestStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.RequestStatus;
  return proto.audit.RequestStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.RequestStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.RequestStatus}
 */
proto.audit.RequestStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEvidenceCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.RequestStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.RequestStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.RequestStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.RequestStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEvidenceCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getControlCount();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPassingStatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.audit.RequestStatus.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.RequestStatus} returns this
 */
proto.audit.RequestStatus.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.audit.RequestStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.RequestStatus} returns this
 */
proto.audit.RequestStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 evidence_count = 3;
 * @return {number}
 */
proto.audit.RequestStatus.prototype.getEvidenceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.RequestStatus} returns this
 */
proto.audit.RequestStatus.prototype.setEvidenceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 control_count = 4;
 * @return {number}
 */
proto.audit.RequestStatus.prototype.getControlCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.RequestStatus} returns this
 */
proto.audit.RequestStatus.prototype.setControlCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool passing_status = 5;
 * @return {boolean}
 */
proto.audit.RequestStatus.prototype.getPassingStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.RequestStatus} returns this
 */
proto.audit.RequestStatus.prototype.setPassingStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.EditControlTestResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.EditControlTestResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.EditControlTestResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.EditControlTestResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isResultPassing: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auditControlTestId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.EditControlTestResultRequest}
 */
proto.audit.EditControlTestResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.EditControlTestResultRequest;
  return proto.audit.EditControlTestResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.EditControlTestResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.EditControlTestResultRequest}
 */
proto.audit.EditControlTestResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsResultPassing(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditControlTestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.EditControlTestResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.EditControlTestResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.EditControlTestResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.EditControlTestResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsResultPassing();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuditControlTestId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool is_result_passing = 1;
 * @return {boolean}
 */
proto.audit.EditControlTestResultRequest.prototype.getIsResultPassing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.EditControlTestResultRequest} returns this
 */
proto.audit.EditControlTestResultRequest.prototype.setIsResultPassing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.audit.EditControlTestResultRequest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.EditControlTestResultRequest} returns this
 */
proto.audit.EditControlTestResultRequest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string audit_control_test_id = 3;
 * @return {string}
 */
proto.audit.EditControlTestResultRequest.prototype.getAuditControlTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.EditControlTestResultRequest} returns this
 */
proto.audit.EditControlTestResultRequest.prototype.setAuditControlTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ListAuditRunsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ListAuditRunsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ListAuditRunsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ListAuditRunsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ListAuditRunsRequest}
 */
proto.audit.ListAuditRunsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ListAuditRunsRequest;
  return proto.audit.ListAuditRunsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ListAuditRunsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ListAuditRunsRequest}
 */
proto.audit.ListAuditRunsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ListAuditRunsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ListAuditRunsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ListAuditRunsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ListAuditRunsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.ListAuditRunsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.ListAuditRunsRequest} returns this
*/
proto.audit.ListAuditRunsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.ListAuditRunsRequest} returns this
 */
proto.audit.ListAuditRunsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.ListAuditRunsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AuditRunSummaries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditRunSummaries.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditRunSummaries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditRunSummaries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRunSummaries.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunsList: jspb.Message.toObjectList(msg.getAuditRunsList(),
    proto.audit.AuditRunSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditRunSummaries}
 */
proto.audit.AuditRunSummaries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditRunSummaries;
  return proto.audit.AuditRunSummaries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditRunSummaries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditRunSummaries}
 */
proto.audit.AuditRunSummaries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.AuditRunSummary;
      reader.readMessage(value,proto.audit.AuditRunSummary.deserializeBinaryFromReader);
      msg.addAuditRuns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditRunSummaries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditRunSummaries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditRunSummaries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRunSummaries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audit.AuditRunSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditRunSummary audit_runs = 1;
 * @return {!Array<!proto.audit.AuditRunSummary>}
 */
proto.audit.AuditRunSummaries.prototype.getAuditRunsList = function() {
  return /** @type{!Array<!proto.audit.AuditRunSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.AuditRunSummary, 1));
};


/**
 * @param {!Array<!proto.audit.AuditRunSummary>} value
 * @return {!proto.audit.AuditRunSummaries} returns this
*/
proto.audit.AuditRunSummaries.prototype.setAuditRunsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audit.AuditRunSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.AuditRunSummary}
 */
proto.audit.AuditRunSummaries.prototype.addAuditRuns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audit.AuditRunSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRunSummaries} returns this
 */
proto.audit.AuditRunSummaries.prototype.clearAuditRunsList = function() {
  return this.setAuditRunsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AuditRunSummary.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditRunSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditRunSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditRunSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRunSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sampleStartDate: (f = msg.getSampleStartDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    sampleEndDate: (f = msg.getSampleEndDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    userEmail: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 7, 0),
    result: jspb.Message.getFieldWithDefault(msg, 8, 0),
    startTime: (f = msg.getStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endTime: (f = msg.getEndTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    numControls: jspb.Message.getFieldWithDefault(msg, 11, 0),
    numControlsCompleted: jspb.Message.getFieldWithDefault(msg, 12, 0),
    passedPreAuditCheck: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    isPartialRun: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    skippedPreAuditCheck: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    systemError: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditRunSummary}
 */
proto.audit.AuditRunSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditRunSummary;
  return proto.audit.AuditRunSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditRunSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditRunSummary}
 */
proto.audit.AuditRunSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSampleStartDate(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSampleEndDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 7:
      var value = /** @type {!proto.audit.AUDIT_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 8:
      var value = /** @type {!proto.audit.AUDIT_RESULT} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartTime(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumControls(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumControlsCompleted(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPassedPreAuditCheck(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPartialRun(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkippedPreAuditCheck(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditRunSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditRunSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditRunSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRunSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSampleStartDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSampleEndDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getStartTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndTime();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNumControls();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getNumControlsCompleted();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPassedPreAuditCheck();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIsPartialRun();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getSkippedPreAuditCheck();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
  f = message.getSystemError();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.audit.AuditRunSummary.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string task_id = 2;
 * @return {string}
 */
proto.audit.AuditRunSummary.prototype.getTaskId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setTaskId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue audit_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.AuditRunSummary.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.AuditRunSummary} returns this
*/
proto.audit.AuditRunSummary.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp sample_start_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.AuditRunSummary.prototype.getSampleStartDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.AuditRunSummary} returns this
*/
proto.audit.AuditRunSummary.prototype.setSampleStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.clearSampleStartDate = function() {
  return this.setSampleStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.hasSampleStartDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp sample_end_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.AuditRunSummary.prototype.getSampleEndDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.AuditRunSummary} returns this
*/
proto.audit.AuditRunSummary.prototype.setSampleEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.clearSampleEndDate = function() {
  return this.setSampleEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.hasSampleEndDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string user_email = 6;
 * @return {string}
 */
proto.audit.AuditRunSummary.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional AUDIT_STATUS status = 7;
 * @return {!proto.audit.AUDIT_STATUS}
 */
proto.audit.AuditRunSummary.prototype.getStatus = function() {
  return /** @type {!proto.audit.AUDIT_STATUS} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.audit.AUDIT_STATUS} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional AUDIT_RESULT result = 8;
 * @return {!proto.audit.AUDIT_RESULT}
 */
proto.audit.AuditRunSummary.prototype.getResult = function() {
  return /** @type {!proto.audit.AUDIT_RESULT} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.audit.AUDIT_RESULT} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp start_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.AuditRunSummary.prototype.getStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.AuditRunSummary} returns this
*/
proto.audit.AuditRunSummary.prototype.setStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.clearStartTime = function() {
  return this.setStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.hasStartTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Timestamp end_time = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.AuditRunSummary.prototype.getEndTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.AuditRunSummary} returns this
*/
proto.audit.AuditRunSummary.prototype.setEndTime = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.clearEndTime = function() {
  return this.setEndTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.hasEndTime = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional int32 num_controls = 11;
 * @return {number}
 */
proto.audit.AuditRunSummary.prototype.getNumControls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setNumControls = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 num_controls_completed = 12;
 * @return {number}
 */
proto.audit.AuditRunSummary.prototype.getNumControlsCompleted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setNumControlsCompleted = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool passed_pre_audit_check = 13;
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.getPassedPreAuditCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setPassedPreAuditCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool is_partial_run = 14;
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.getIsPartialRun = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setIsPartialRun = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool skipped_pre_audit_check = 15;
 * @return {boolean}
 */
proto.audit.AuditRunSummary.prototype.getSkippedPreAuditCheck = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setSkippedPreAuditCheck = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * repeated string control_ids = 16;
 * @return {!Array<string>}
 */
proto.audit.AuditRunSummary.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * optional string system_error = 17;
 * @return {string}
 */
proto.audit.AuditRunSummary.prototype.getSystemError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditRunSummary} returns this
 */
proto.audit.AuditRunSummary.prototype.setSystemError = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditRunRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditRunRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditRunRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditRunRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditRunRequest}
 */
proto.audit.GetAuditRunRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditRunRequest;
  return proto.audit.GetAuditRunRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditRunRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditRunRequest}
 */
proto.audit.GetAuditRunRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditRunRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditRunRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditRunRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditRunRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.audit.GetAuditRunRequest.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetAuditRunRequest} returns this
 */
proto.audit.GetAuditRunRequest.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AuditRun.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditRun.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: (f = msg.getSummary()) && proto.audit.AuditRunSummary.toObject(includeInstance, f),
    controlTestsList: jspb.Message.toObjectList(msg.getControlTestsList(),
    proto.audit.ControlTest.toObject, includeInstance),
    preAuditChecks: (f = msg.getPreAuditChecks()) && proto.audit.PreAudit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditRun}
 */
proto.audit.AuditRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditRun;
  return proto.audit.AuditRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditRun}
 */
proto.audit.AuditRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.AuditRunSummary;
      reader.readMessage(value,proto.audit.AuditRunSummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 2:
      var value = new proto.audit.ControlTest;
      reader.readMessage(value,proto.audit.ControlTest.deserializeBinaryFromReader);
      msg.addControlTests(value);
      break;
    case 3:
      var value = new proto.audit.PreAudit;
      reader.readMessage(value,proto.audit.PreAudit.deserializeBinaryFromReader);
      msg.setPreAuditChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.audit.AuditRunSummary.serializeBinaryToWriter
    );
  }
  f = message.getControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.audit.ControlTest.serializeBinaryToWriter
    );
  }
  f = message.getPreAuditChecks();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audit.PreAudit.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuditRunSummary summary = 1;
 * @return {?proto.audit.AuditRunSummary}
 */
proto.audit.AuditRun.prototype.getSummary = function() {
  return /** @type{?proto.audit.AuditRunSummary} */ (
    jspb.Message.getWrapperField(this, proto.audit.AuditRunSummary, 1));
};


/**
 * @param {?proto.audit.AuditRunSummary|undefined} value
 * @return {!proto.audit.AuditRun} returns this
*/
proto.audit.AuditRun.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRun} returns this
 */
proto.audit.AuditRun.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRun.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated ControlTest control_tests = 2;
 * @return {!Array<!proto.audit.ControlTest>}
 */
proto.audit.AuditRun.prototype.getControlTestsList = function() {
  return /** @type{!Array<!proto.audit.ControlTest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.ControlTest, 2));
};


/**
 * @param {!Array<!proto.audit.ControlTest>} value
 * @return {!proto.audit.AuditRun} returns this
*/
proto.audit.AuditRun.prototype.setControlTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.audit.ControlTest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ControlTest}
 */
proto.audit.AuditRun.prototype.addControlTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.audit.ControlTest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditRun} returns this
 */
proto.audit.AuditRun.prototype.clearControlTestsList = function() {
  return this.setControlTestsList([]);
};


/**
 * optional PreAudit pre_audit_checks = 3;
 * @return {?proto.audit.PreAudit}
 */
proto.audit.AuditRun.prototype.getPreAuditChecks = function() {
  return /** @type{?proto.audit.PreAudit} */ (
    jspb.Message.getWrapperField(this, proto.audit.PreAudit, 3));
};


/**
 * @param {?proto.audit.PreAudit|undefined} value
 * @return {!proto.audit.AuditRun} returns this
*/
proto.audit.AuditRun.prototype.setPreAuditChecks = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.AuditRun} returns this
 */
proto.audit.AuditRun.prototype.clearPreAuditChecks = function() {
  return this.setPreAuditChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.AuditRun.prototype.hasPreAuditChecks = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ControlTest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ControlTest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ControlTest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlTest.toObject = function(includeInstance, msg) {
  var f, obj = {
    result: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controlId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    testName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sampleDate: (f = msg.getSampleDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reason: jspb.Message.getFieldWithDefault(msg, 6, ""),
    error: jspb.Message.getFieldWithDefault(msg, 7, ""),
    controlModelId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isStale: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    reasonSource: jspb.Message.getFieldWithDefault(msg, 10, 0),
    id: jspb.Message.getFieldWithDefault(msg, 11, ""),
    auditRunId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ControlTest}
 */
proto.audit.ControlTest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ControlTest;
  return proto.audit.ControlTest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ControlTest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ControlTest}
 */
proto.audit.ControlTest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.audit.AUDIT_RESULT} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSampleDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setError(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlModelId(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStale(value);
      break;
    case 10:
      var value = /** @type {!proto.audit.REASON_SOURCE} */ (reader.readEnum());
      msg.setReasonSource(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ControlTest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ControlTest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ControlTest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlTest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSampleDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getError();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getControlModelId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsStale();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getReasonSource();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
};


/**
 * optional AUDIT_RESULT result = 1;
 * @return {!proto.audit.AUDIT_RESULT}
 */
proto.audit.ControlTest.prototype.getResult = function() {
  return /** @type {!proto.audit.AUDIT_RESULT} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.audit.AUDIT_RESULT} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string control_id = 2;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_name = 3;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getControlName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setControlName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string test_name = 4;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp sample_date = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.ControlTest.prototype.getSampleDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.ControlTest} returns this
*/
proto.audit.ControlTest.prototype.setSampleDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.clearSampleDate = function() {
  return this.setSampleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.ControlTest.prototype.hasSampleDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string reason = 6;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string error = 7;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getError = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setError = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string control_model_id = 8;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getControlModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setControlModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_stale = 9;
 * @return {boolean}
 */
proto.audit.ControlTest.prototype.getIsStale = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setIsStale = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional REASON_SOURCE reason_source = 10;
 * @return {!proto.audit.REASON_SOURCE}
 */
proto.audit.ControlTest.prototype.getReasonSource = function() {
  return /** @type {!proto.audit.REASON_SOURCE} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.audit.REASON_SOURCE} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setReasonSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string id = 11;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string audit_run_id = 12;
 * @return {string}
 */
proto.audit.ControlTest.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 updated_at = 13;
 * @return {number}
 */
proto.audit.ControlTest.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.ControlTest} returns this
 */
proto.audit.ControlTest.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.StopAuditRunRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.StopAuditRunRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.StopAuditRunRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.StopAuditRunRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.StopAuditRunRequest}
 */
proto.audit.StopAuditRunRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.StopAuditRunRequest;
  return proto.audit.StopAuditRunRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.StopAuditRunRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.StopAuditRunRequest}
 */
proto.audit.StopAuditRunRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.StopAuditRunRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.StopAuditRunRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.StopAuditRunRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.StopAuditRunRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.audit.StopAuditRunRequest.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.StopAuditRunRequest} returns this
 */
proto.audit.StopAuditRunRequest.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.DeleteAuditRunsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.DeleteAuditRunsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.DeleteAuditRunsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.DeleteAuditRunsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.DeleteAuditRunsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.DeleteAuditRunsRequest}
 */
proto.audit.DeleteAuditRunsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.DeleteAuditRunsRequest;
  return proto.audit.DeleteAuditRunsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.DeleteAuditRunsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.DeleteAuditRunsRequest}
 */
proto.audit.DeleteAuditRunsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditRunIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.DeleteAuditRunsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.DeleteAuditRunsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.DeleteAuditRunsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.DeleteAuditRunsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string audit_run_ids = 1;
 * @return {!Array<string>}
 */
proto.audit.DeleteAuditRunsRequest.prototype.getAuditRunIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.DeleteAuditRunsRequest} returns this
 */
proto.audit.DeleteAuditRunsRequest.prototype.setAuditRunIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.DeleteAuditRunsRequest} returns this
 */
proto.audit.DeleteAuditRunsRequest.prototype.addAuditRunIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.DeleteAuditRunsRequest} returns this
 */
proto.audit.DeleteAuditRunsRequest.prototype.clearAuditRunIdsList = function() {
  return this.setAuditRunIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetValidAuditsForScanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetValidAuditsForScanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetValidAuditsForScanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetValidAuditsForScanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetValidAuditsForScanRequest}
 */
proto.audit.GetValidAuditsForScanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetValidAuditsForScanRequest;
  return proto.audit.GetValidAuditsForScanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetValidAuditsForScanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetValidAuditsForScanRequest}
 */
proto.audit.GetValidAuditsForScanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetValidAuditsForScanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetValidAuditsForScanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetValidAuditsForScanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetValidAuditsForScanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.audit.GetValidAuditsForScanRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetValidAuditsForScanRequest} returns this
 */
proto.audit.GetValidAuditsForScanRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetHasValidFrameworksForScanRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetHasValidFrameworksForScanRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetHasValidFrameworksForScanRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetHasValidFrameworksForScanRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetHasValidFrameworksForScanRequest}
 */
proto.audit.GetHasValidFrameworksForScanRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetHasValidFrameworksForScanRequest;
  return proto.audit.GetHasValidFrameworksForScanRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetHasValidFrameworksForScanRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetHasValidFrameworksForScanRequest}
 */
proto.audit.GetHasValidFrameworksForScanRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetHasValidFrameworksForScanRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetHasValidFrameworksForScanRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetHasValidFrameworksForScanRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetHasValidFrameworksForScanRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.audit.GetHasValidFrameworksForScanRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetHasValidFrameworksForScanRequest} returns this
 */
proto.audit.GetHasValidFrameworksForScanRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditControlTestRunsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditControlTestRunsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditControlTestRunsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditControlTestRunsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditControlTestRunsRequest}
 */
proto.audit.GetAuditControlTestRunsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditControlTestRunsRequest;
  return proto.audit.GetAuditControlTestRunsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditControlTestRunsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditControlTestRunsRequest}
 */
proto.audit.GetAuditControlTestRunsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditControlTestRunsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditControlTestRunsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditControlTestRunsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditControlTestRunsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.audit.GetAuditControlTestRunsRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetAuditControlTestRunsRequest} returns this
 */
proto.audit.GetAuditControlTestRunsRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_id = 2;
 * @return {string}
 */
proto.audit.GetAuditControlTestRunsRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetAuditControlTestRunsRequest} returns this
 */
proto.audit.GetAuditControlTestRunsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.ControlAuditRunSummary.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ControlAuditRunSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ControlAuditRunSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ControlAuditRunSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlAuditRunSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditRunId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    auditRunStartTime: (f = msg.getAuditRunStartTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    controlTestsList: jspb.Message.toObjectList(msg.getControlTestsList(),
    proto.audit.ControlTest.toObject, includeInstance),
    preAuditChecks: (f = msg.getPreAuditChecks()) && proto.audit.ControlStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ControlAuditRunSummary}
 */
proto.audit.ControlAuditRunSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ControlAuditRunSummary;
  return proto.audit.ControlAuditRunSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ControlAuditRunSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ControlAuditRunSummary}
 */
proto.audit.ControlAuditRunSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAuditRunStartTime(value);
      break;
    case 4:
      var value = new proto.audit.ControlTest;
      reader.readMessage(value,proto.audit.ControlTest.deserializeBinaryFromReader);
      msg.addControlTests(value);
      break;
    case 5:
      var value = new proto.audit.ControlStatus;
      reader.readMessage(value,proto.audit.ControlStatus.deserializeBinaryFromReader);
      msg.setPreAuditChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ControlAuditRunSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ControlAuditRunSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ControlAuditRunSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlAuditRunSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAuditRunStartTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.audit.ControlTest.serializeBinaryToWriter
    );
  }
  f = message.getPreAuditChecks();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audit.ControlStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional string audit_name = 1;
 * @return {string}
 */
proto.audit.ControlAuditRunSummary.prototype.getAuditName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlAuditRunSummary} returns this
 */
proto.audit.ControlAuditRunSummary.prototype.setAuditName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_run_id = 2;
 * @return {string}
 */
proto.audit.ControlAuditRunSummary.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.ControlAuditRunSummary} returns this
 */
proto.audit.ControlAuditRunSummary.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp audit_run_start_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.audit.ControlAuditRunSummary.prototype.getAuditRunStartTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.audit.ControlAuditRunSummary} returns this
*/
proto.audit.ControlAuditRunSummary.prototype.setAuditRunStartTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.ControlAuditRunSummary} returns this
 */
proto.audit.ControlAuditRunSummary.prototype.clearAuditRunStartTime = function() {
  return this.setAuditRunStartTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.ControlAuditRunSummary.prototype.hasAuditRunStartTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated ControlTest control_tests = 4;
 * @return {!Array<!proto.audit.ControlTest>}
 */
proto.audit.ControlAuditRunSummary.prototype.getControlTestsList = function() {
  return /** @type{!Array<!proto.audit.ControlTest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.ControlTest, 4));
};


/**
 * @param {!Array<!proto.audit.ControlTest>} value
 * @return {!proto.audit.ControlAuditRunSummary} returns this
*/
proto.audit.ControlAuditRunSummary.prototype.setControlTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.audit.ControlTest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ControlTest}
 */
proto.audit.ControlAuditRunSummary.prototype.addControlTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.audit.ControlTest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.ControlAuditRunSummary} returns this
 */
proto.audit.ControlAuditRunSummary.prototype.clearControlTestsList = function() {
  return this.setControlTestsList([]);
};


/**
 * optional ControlStatus pre_audit_checks = 5;
 * @return {?proto.audit.ControlStatus}
 */
proto.audit.ControlAuditRunSummary.prototype.getPreAuditChecks = function() {
  return /** @type{?proto.audit.ControlStatus} */ (
    jspb.Message.getWrapperField(this, proto.audit.ControlStatus, 5));
};


/**
 * @param {?proto.audit.ControlStatus|undefined} value
 * @return {!proto.audit.ControlAuditRunSummary} returns this
*/
proto.audit.ControlAuditRunSummary.prototype.setPreAuditChecks = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.ControlAuditRunSummary} returns this
 */
proto.audit.ControlAuditRunSummary.prototype.clearPreAuditChecks = function() {
  return this.setPreAuditChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.ControlAuditRunSummary.prototype.hasPreAuditChecks = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.GetAuditControlTestRunsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditControlTestRunsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditControlTestRunsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditControlTestRunsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditControlTestRunsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlAuditRunsList: jspb.Message.toObjectList(msg.getControlAuditRunsList(),
    proto.audit.ControlAuditRunSummary.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditControlTestRunsResponse}
 */
proto.audit.GetAuditControlTestRunsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditControlTestRunsResponse;
  return proto.audit.GetAuditControlTestRunsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditControlTestRunsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditControlTestRunsResponse}
 */
proto.audit.GetAuditControlTestRunsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.ControlAuditRunSummary;
      reader.readMessage(value,proto.audit.ControlAuditRunSummary.deserializeBinaryFromReader);
      msg.addControlAuditRuns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditControlTestRunsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditControlTestRunsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditControlTestRunsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditControlTestRunsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlAuditRunsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audit.ControlAuditRunSummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ControlAuditRunSummary control_audit_runs = 1;
 * @return {!Array<!proto.audit.ControlAuditRunSummary>}
 */
proto.audit.GetAuditControlTestRunsResponse.prototype.getControlAuditRunsList = function() {
  return /** @type{!Array<!proto.audit.ControlAuditRunSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.ControlAuditRunSummary, 1));
};


/**
 * @param {!Array<!proto.audit.ControlAuditRunSummary>} value
 * @return {!proto.audit.GetAuditControlTestRunsResponse} returns this
*/
proto.audit.GetAuditControlTestRunsResponse.prototype.setControlAuditRunsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audit.ControlAuditRunSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.ControlAuditRunSummary}
 */
proto.audit.GetAuditControlTestRunsResponse.prototype.addControlAuditRuns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audit.ControlAuditRunSummary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.GetAuditControlTestRunsResponse} returns this
 */
proto.audit.GetAuditControlTestRunsResponse.prototype.clearControlAuditRunsList = function() {
  return this.setControlAuditRunsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.GetSmartChecksTestInformationRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetSmartChecksTestInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetSmartChecksTestInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetSmartChecksTestInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlTestsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetSmartChecksTestInformationRequest}
 */
proto.audit.GetSmartChecksTestInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetSmartChecksTestInformationRequest;
  return proto.audit.GetSmartChecksTestInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetSmartChecksTestInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetSmartChecksTestInformationRequest}
 */
proto.audit.GetSmartChecksTestInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetSmartChecksTestInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetSmartChecksTestInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetSmartChecksTestInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetSmartChecksTestInformationRequest} returns this
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string control_tests = 2;
 * @return {!Array<string>}
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.getControlTestsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.GetSmartChecksTestInformationRequest} returns this
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.setControlTestsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.GetSmartChecksTestInformationRequest} returns this
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.addControlTests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.GetSmartChecksTestInformationRequest} returns this
 */
proto.audit.GetSmartChecksTestInformationRequest.prototype.clearControlTestsList = function() {
  return this.setControlTestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.SmartControlTestRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.SmartControlTestRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.SmartControlTestRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SmartControlTestRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlModelId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controlName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    result: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isStale: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isControlNa: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isMissingChecks: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.SmartControlTestRecord}
 */
proto.audit.SmartControlTestRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.SmartControlTestRecord;
  return proto.audit.SmartControlTestRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.SmartControlTestRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.SmartControlTestRecord}
 */
proto.audit.SmartControlTestRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlModelId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlName(value);
      break;
    case 5:
      var value = /** @type {!proto.audit.AUDIT_RESULT} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStale(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsControlNa(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMissingChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.SmartControlTestRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.SmartControlTestRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.SmartControlTestRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SmartControlTestRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControlName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getIsStale();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsControlNa();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsMissingChecks();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.audit.SmartControlTestRecord.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string control_id = 2;
 * @return {string}
 */
proto.audit.SmartControlTestRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_model_id = 3;
 * @return {string}
 */
proto.audit.SmartControlTestRecord.prototype.getControlModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setControlModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string control_name = 4;
 * @return {string}
 */
proto.audit.SmartControlTestRecord.prototype.getControlName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setControlName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional AUDIT_RESULT result = 5;
 * @return {!proto.audit.AUDIT_RESULT}
 */
proto.audit.SmartControlTestRecord.prototype.getResult = function() {
  return /** @type {!proto.audit.AUDIT_RESULT} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.audit.AUDIT_RESULT} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional bool is_stale = 6;
 * @return {boolean}
 */
proto.audit.SmartControlTestRecord.prototype.getIsStale = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setIsStale = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_control_na = 7;
 * @return {boolean}
 */
proto.audit.SmartControlTestRecord.prototype.getIsControlNa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setIsControlNa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_missing_checks = 8;
 * @return {boolean}
 */
proto.audit.SmartControlTestRecord.prototype.getIsMissingChecks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.SmartControlTestRecord} returns this
 */
proto.audit.SmartControlTestRecord.prototype.setIsMissingChecks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.SmartCheckRecord.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.SmartCheckRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.SmartCheckRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.SmartCheckRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SmartCheckRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    testName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    testControlsList: jspb.Message.toObjectList(msg.getTestControlsList(),
    proto.audit.SmartControlTestRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.SmartCheckRecord}
 */
proto.audit.SmartCheckRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.SmartCheckRecord;
  return proto.audit.SmartCheckRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.SmartCheckRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.SmartCheckRecord}
 */
proto.audit.SmartCheckRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 2:
      var value = new proto.audit.SmartControlTestRecord;
      reader.readMessage(value,proto.audit.SmartControlTestRecord.deserializeBinaryFromReader);
      msg.addTestControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.SmartCheckRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.SmartCheckRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.SmartCheckRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.SmartCheckRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTestControlsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.audit.SmartControlTestRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional string test_name = 1;
 * @return {string}
 */
proto.audit.SmartCheckRecord.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.SmartCheckRecord} returns this
 */
proto.audit.SmartCheckRecord.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SmartControlTestRecord test_controls = 2;
 * @return {!Array<!proto.audit.SmartControlTestRecord>}
 */
proto.audit.SmartCheckRecord.prototype.getTestControlsList = function() {
  return /** @type{!Array<!proto.audit.SmartControlTestRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.SmartControlTestRecord, 2));
};


/**
 * @param {!Array<!proto.audit.SmartControlTestRecord>} value
 * @return {!proto.audit.SmartCheckRecord} returns this
*/
proto.audit.SmartCheckRecord.prototype.setTestControlsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.audit.SmartControlTestRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.SmartControlTestRecord}
 */
proto.audit.SmartCheckRecord.prototype.addTestControls = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.audit.SmartControlTestRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.SmartCheckRecord} returns this
 */
proto.audit.SmartCheckRecord.prototype.clearTestControlsList = function() {
  return this.setTestControlsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.GetSmartChecksTestInformationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetSmartChecksTestInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetSmartChecksTestInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetSmartChecksTestInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetSmartChecksTestInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    smartChecksList: jspb.Message.toObjectList(msg.getSmartChecksList(),
    proto.audit.SmartCheckRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetSmartChecksTestInformationResponse}
 */
proto.audit.GetSmartChecksTestInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetSmartChecksTestInformationResponse;
  return proto.audit.GetSmartChecksTestInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetSmartChecksTestInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetSmartChecksTestInformationResponse}
 */
proto.audit.GetSmartChecksTestInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.SmartCheckRecord;
      reader.readMessage(value,proto.audit.SmartCheckRecord.deserializeBinaryFromReader);
      msg.addSmartChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetSmartChecksTestInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetSmartChecksTestInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetSmartChecksTestInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetSmartChecksTestInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSmartChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audit.SmartCheckRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SmartCheckRecord smart_checks = 1;
 * @return {!Array<!proto.audit.SmartCheckRecord>}
 */
proto.audit.GetSmartChecksTestInformationResponse.prototype.getSmartChecksList = function() {
  return /** @type{!Array<!proto.audit.SmartCheckRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.SmartCheckRecord, 1));
};


/**
 * @param {!Array<!proto.audit.SmartCheckRecord>} value
 * @return {!proto.audit.GetSmartChecksTestInformationResponse} returns this
*/
proto.audit.GetSmartChecksTestInformationResponse.prototype.setSmartChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audit.SmartCheckRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.SmartCheckRecord}
 */
proto.audit.GetSmartChecksTestInformationResponse.prototype.addSmartChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audit.SmartCheckRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.GetSmartChecksTestInformationResponse} returns this
 */
proto.audit.GetSmartChecksTestInformationResponse.prototype.clearSmartChecksList = function() {
  return this.setSmartChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.ControlCheckSort.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.ControlCheckSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.ControlCheckSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlCheckSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOrder: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.ControlCheckSort}
 */
proto.audit.ControlCheckSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.ControlCheckSort;
  return proto.audit.ControlCheckSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.ControlCheckSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.ControlCheckSort}
 */
proto.audit.ControlCheckSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.audit.CONTROL_CHECK_SORT_ORDER} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.audit.CONTROL_CHECK_SORT_COLUMN} */ (reader.readEnum());
      msg.setSortColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.ControlCheckSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.ControlCheckSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.ControlCheckSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.ControlCheckSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortColumn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional CONTROL_CHECK_SORT_ORDER sort_order = 1;
 * @return {!proto.audit.CONTROL_CHECK_SORT_ORDER}
 */
proto.audit.ControlCheckSort.prototype.getSortOrder = function() {
  return /** @type {!proto.audit.CONTROL_CHECK_SORT_ORDER} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.audit.CONTROL_CHECK_SORT_ORDER} value
 * @return {!proto.audit.ControlCheckSort} returns this
 */
proto.audit.ControlCheckSort.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional CONTROL_CHECK_SORT_COLUMN sort_column = 2;
 * @return {!proto.audit.CONTROL_CHECK_SORT_COLUMN}
 */
proto.audit.ControlCheckSort.prototype.getSortColumn = function() {
  return /** @type {!proto.audit.CONTROL_CHECK_SORT_COLUMN} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.audit.CONTROL_CHECK_SORT_COLUMN} value
 * @return {!proto.audit.ControlCheckSort} returns this
 */
proto.audit.ControlCheckSort.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.TestResultFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.TestResultFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.TestResultFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.TestResultFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeFailing: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includePassing: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeStale: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.TestResultFilter}
 */
proto.audit.TestResultFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.TestResultFilter;
  return proto.audit.TestResultFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.TestResultFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.TestResultFilter}
 */
proto.audit.TestResultFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeFailing(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludePassing(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeStale(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.TestResultFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.TestResultFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.TestResultFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.TestResultFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeFailing();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludePassing();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeStale();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bool include_failing = 1;
 * @return {boolean}
 */
proto.audit.TestResultFilter.prototype.getIncludeFailing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.TestResultFilter} returns this
 */
proto.audit.TestResultFilter.prototype.setIncludeFailing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_passing = 2;
 * @return {boolean}
 */
proto.audit.TestResultFilter.prototype.getIncludePassing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.TestResultFilter} returns this
 */
proto.audit.TestResultFilter.prototype.setIncludePassing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_stale = 3;
 * @return {boolean}
 */
proto.audit.TestResultFilter.prototype.getIncludeStale = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.TestResultFilter} returns this
 */
proto.audit.TestResultFilter.prototype.setIncludeStale = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.GetAuditReadinessRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditReadinessRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditReadinessRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditReadinessRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditReadinessRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlTestsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    testResultFilter: (f = msg.getTestResultFilter()) && proto.audit.TestResultFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.audit.ControlCheckSort.toObject(includeInstance, f),
    controlId: (f = msg.getControlId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditReadinessRequest}
 */
proto.audit.GetAuditReadinessRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditReadinessRequest;
  return proto.audit.GetAuditReadinessRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditReadinessRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditReadinessRequest}
 */
proto.audit.GetAuditReadinessRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlTests(value);
      break;
    case 3:
      var value = new proto.audit.TestResultFilter;
      reader.readMessage(value,proto.audit.TestResultFilter.deserializeBinaryFromReader);
      msg.setTestResultFilter(value);
      break;
    case 5:
      var value = new proto.audit.ControlCheckSort;
      reader.readMessage(value,proto.audit.ControlCheckSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditReadinessRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditReadinessRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditReadinessRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditReadinessRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTestResultFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.audit.TestResultFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.audit.ControlCheckSort.serializeBinaryToWriter
    );
  }
  f = message.getControlId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.audit.GetAuditReadinessRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string control_tests = 2;
 * @return {!Array<string>}
 */
proto.audit.GetAuditReadinessRequest.prototype.getControlTestsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.setControlTestsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.addControlTests = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.clearControlTestsList = function() {
  return this.setControlTestsList([]);
};


/**
 * optional TestResultFilter test_result_filter = 3;
 * @return {?proto.audit.TestResultFilter}
 */
proto.audit.GetAuditReadinessRequest.prototype.getTestResultFilter = function() {
  return /** @type{?proto.audit.TestResultFilter} */ (
    jspb.Message.getWrapperField(this, proto.audit.TestResultFilter, 3));
};


/**
 * @param {?proto.audit.TestResultFilter|undefined} value
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
*/
proto.audit.GetAuditReadinessRequest.prototype.setTestResultFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.clearTestResultFilter = function() {
  return this.setTestResultFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditReadinessRequest.prototype.hasTestResultFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ControlCheckSort sort = 5;
 * @return {?proto.audit.ControlCheckSort}
 */
proto.audit.GetAuditReadinessRequest.prototype.getSort = function() {
  return /** @type{?proto.audit.ControlCheckSort} */ (
    jspb.Message.getWrapperField(this, proto.audit.ControlCheckSort, 5));
};


/**
 * @param {?proto.audit.ControlCheckSort|undefined} value
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
*/
proto.audit.GetAuditReadinessRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditReadinessRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue control_id = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.audit.GetAuditReadinessRequest.prototype.getControlId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
*/
proto.audit.GetAuditReadinessRequest.prototype.setControlId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.GetAuditReadinessRequest} returns this
 */
proto.audit.GetAuditReadinessRequest.prototype.clearControlId = function() {
  return this.setControlId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.GetAuditReadinessRequest.prototype.hasControlId = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditReadinessTestRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditReadinessTestRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditReadinessTestRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditReadinessTestRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    testName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isStale: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    id: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reasonSource: jspb.Message.getFieldWithDefault(msg, 6, 0),
    controlId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    auditRunId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditReadinessTestRecord}
 */
proto.audit.AuditReadinessTestRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditReadinessTestRecord;
  return proto.audit.AuditReadinessTestRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditReadinessTestRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditReadinessTestRecord}
 */
proto.audit.AuditReadinessTestRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 3:
      var value = /** @type {!proto.audit.AUDIT_RESULT} */ (reader.readEnum());
      msg.setResult(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsStale(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 6:
      var value = /** @type {!proto.audit.REASON_SOURCE} */ (reader.readEnum());
      msg.setReasonSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditReadinessTestRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditReadinessTestRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditReadinessTestRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditReadinessTestRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getIsStale();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReasonSource();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
};


/**
 * optional string test_name = 1;
 * @return {string}
 */
proto.audit.AuditReadinessTestRecord.prototype.getTestName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setTestName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.audit.AuditReadinessTestRecord.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional AUDIT_RESULT result = 3;
 * @return {!proto.audit.AUDIT_RESULT}
 */
proto.audit.AuditReadinessTestRecord.prototype.getResult = function() {
  return /** @type {!proto.audit.AUDIT_RESULT} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.audit.AUDIT_RESULT} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setResult = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_stale = 4;
 * @return {boolean}
 */
proto.audit.AuditReadinessTestRecord.prototype.getIsStale = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setIsStale = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.audit.AuditReadinessTestRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional REASON_SOURCE reason_source = 6;
 * @return {!proto.audit.REASON_SOURCE}
 */
proto.audit.AuditReadinessTestRecord.prototype.getReasonSource = function() {
  return /** @type {!proto.audit.REASON_SOURCE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.audit.REASON_SOURCE} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setReasonSource = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string control_id = 7;
 * @return {string}
 */
proto.audit.AuditReadinessTestRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string audit_run_id = 8;
 * @return {string}
 */
proto.audit.AuditReadinessTestRecord.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 updated_at = 9;
 * @return {number}
 */
proto.audit.AuditReadinessTestRecord.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.AuditReadinessTestRecord} returns this
 */
proto.audit.AuditReadinessTestRecord.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.AuditReadinessRecord.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditReadinessRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditReadinessRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditReadinessRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditReadinessRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    controlModelId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controlNa: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    controlTestsList: jspb.Message.toObjectList(msg.getControlTestsList(),
    proto.audit.AuditReadinessTestRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditReadinessRecord}
 */
proto.audit.AuditReadinessRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditReadinessRecord;
  return proto.audit.AuditReadinessRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditReadinessRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditReadinessRecord}
 */
proto.audit.AuditReadinessRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlModelId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setControlNa(value);
      break;
    case 5:
      var value = new proto.audit.AuditReadinessTestRecord;
      reader.readMessage(value,proto.audit.AuditReadinessTestRecord.deserializeBinaryFromReader);
      msg.addControlTests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditReadinessRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditReadinessRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditReadinessRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditReadinessRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getControlModelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControlNa();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getControlTestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.audit.AuditReadinessTestRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.audit.AuditReadinessRecord.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessRecord} returns this
 */
proto.audit.AuditReadinessRecord.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string control_model_id = 2;
 * @return {string}
 */
proto.audit.AuditReadinessRecord.prototype.getControlModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessRecord} returns this
 */
proto.audit.AuditReadinessRecord.prototype.setControlModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_name = 3;
 * @return {string}
 */
proto.audit.AuditReadinessRecord.prototype.getControlName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.AuditReadinessRecord} returns this
 */
proto.audit.AuditReadinessRecord.prototype.setControlName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool control_NA = 4;
 * @return {boolean}
 */
proto.audit.AuditReadinessRecord.prototype.getControlNa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.AuditReadinessRecord} returns this
 */
proto.audit.AuditReadinessRecord.prototype.setControlNa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated AuditReadinessTestRecord control_tests = 5;
 * @return {!Array<!proto.audit.AuditReadinessTestRecord>}
 */
proto.audit.AuditReadinessRecord.prototype.getControlTestsList = function() {
  return /** @type{!Array<!proto.audit.AuditReadinessTestRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.AuditReadinessTestRecord, 5));
};


/**
 * @param {!Array<!proto.audit.AuditReadinessTestRecord>} value
 * @return {!proto.audit.AuditReadinessRecord} returns this
*/
proto.audit.AuditReadinessRecord.prototype.setControlTestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.audit.AuditReadinessTestRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.AuditReadinessTestRecord}
 */
proto.audit.AuditReadinessRecord.prototype.addControlTests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.audit.AuditReadinessTestRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.AuditReadinessRecord} returns this
 */
proto.audit.AuditReadinessRecord.prototype.clearControlTestsList = function() {
  return this.setControlTestsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.GetAuditReadinessResponse.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditReadinessResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditReadinessResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditReadinessResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditReadinessResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlsTotal: jspb.Message.getFieldWithDefault(msg, 1, 0),
    controlsPassed: jspb.Message.getFieldWithDefault(msg, 2, 0),
    controlsFailed: jspb.Message.getFieldWithDefault(msg, 3, 0),
    controlsNotTested: jspb.Message.getFieldWithDefault(msg, 4, 0),
    controlsNotApplicable: jspb.Message.getFieldWithDefault(msg, 5, 0),
    controlsResultsUnknown: jspb.Message.getFieldWithDefault(msg, 6, 0),
    controlsStale: jspb.Message.getFieldWithDefault(msg, 7, 0),
    controlsMissingSuggestions: jspb.Message.getFieldWithDefault(msg, 8, 0),
    auditReadinessList: jspb.Message.toObjectList(msg.getAuditReadinessList(),
    proto.audit.AuditReadinessRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditReadinessResponse}
 */
proto.audit.GetAuditReadinessResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditReadinessResponse;
  return proto.audit.GetAuditReadinessResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditReadinessResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditReadinessResponse}
 */
proto.audit.GetAuditReadinessResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsPassed(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsFailed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsNotTested(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsNotApplicable(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsResultsUnknown(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsStale(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsMissingSuggestions(value);
      break;
    case 9:
      var value = new proto.audit.AuditReadinessRecord;
      reader.readMessage(value,proto.audit.AuditReadinessRecord.deserializeBinaryFromReader);
      msg.addAuditReadiness(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditReadinessResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditReadinessResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditReadinessResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditReadinessResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlsTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getControlsPassed();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getControlsFailed();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getControlsNotTested();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getControlsNotApplicable();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getControlsResultsUnknown();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getControlsStale();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getControlsMissingSuggestions();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getAuditReadinessList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.audit.AuditReadinessRecord.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 controls_total = 1;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 controls_passed = 2;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsPassed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsPassed = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 controls_failed = 3;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsFailed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsFailed = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 controls_not_tested = 4;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsNotTested = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsNotTested = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 controls_not_applicable = 5;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsNotApplicable = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsNotApplicable = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 controls_results_unknown = 6;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsResultsUnknown = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsResultsUnknown = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 controls_stale = 7;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsStale = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsStale = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 controls_missing_suggestions = 8;
 * @return {number}
 */
proto.audit.GetAuditReadinessResponse.prototype.getControlsMissingSuggestions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.setControlsMissingSuggestions = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated AuditReadinessRecord audit_readiness = 9;
 * @return {!Array<!proto.audit.AuditReadinessRecord>}
 */
proto.audit.GetAuditReadinessResponse.prototype.getAuditReadinessList = function() {
  return /** @type{!Array<!proto.audit.AuditReadinessRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.AuditReadinessRecord, 9));
};


/**
 * @param {!Array<!proto.audit.AuditReadinessRecord>} value
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
*/
proto.audit.GetAuditReadinessResponse.prototype.setAuditReadinessList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.audit.AuditReadinessRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.AuditReadinessRecord}
 */
proto.audit.GetAuditReadinessResponse.prototype.addAuditReadiness = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.audit.AuditReadinessRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.GetAuditReadinessResponse} returns this
 */
proto.audit.GetAuditReadinessResponse.prototype.clearAuditReadinessList = function() {
  return this.setAuditReadinessList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetAuditRunProgressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetAuditRunProgressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetAuditRunProgressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditRunProgressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetAuditRunProgressRequest}
 */
proto.audit.GetAuditRunProgressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetAuditRunProgressRequest;
  return proto.audit.GetAuditRunProgressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetAuditRunProgressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetAuditRunProgressRequest}
 */
proto.audit.GetAuditRunProgressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetAuditRunProgressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetAuditRunProgressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetAuditRunProgressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetAuditRunProgressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.audit.GetAuditRunProgressRequest.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetAuditRunProgressRequest} returns this
 */
proto.audit.GetAuditRunProgressRequest.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.AuditRunProgress.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.AuditRunProgress.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.AuditRunProgress} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRunProgress.toObject = function(includeInstance, msg) {
  var f, obj = {
    completedControls: jspb.Message.getFieldWithDefault(msg, 1, 0),
    totalControls: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.AuditRunProgress}
 */
proto.audit.AuditRunProgress.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.AuditRunProgress;
  return proto.audit.AuditRunProgress.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.AuditRunProgress} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.AuditRunProgress}
 */
proto.audit.AuditRunProgress.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompletedControls(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.AuditRunProgress.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.AuditRunProgress.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.AuditRunProgress} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.AuditRunProgress.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompletedControls();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotalControls();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 completed_controls = 1;
 * @return {number}
 */
proto.audit.AuditRunProgress.prototype.getCompletedControls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.AuditRunProgress} returns this
 */
proto.audit.AuditRunProgress.prototype.setCompletedControls = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total_controls = 2;
 * @return {number}
 */
proto.audit.AuditRunProgress.prototype.getTotalControls = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.audit.AuditRunProgress} returns this
 */
proto.audit.AuditRunProgress.prototype.setTotalControls = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.GetOperatingEffectivenessDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.GetOperatingEffectivenessDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlTestId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.GetOperatingEffectivenessDetailsRequest}
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.GetOperatingEffectivenessDetailsRequest;
  return proto.audit.GetOperatingEffectivenessDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.GetOperatingEffectivenessDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.GetOperatingEffectivenessDetailsRequest}
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlTestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.GetOperatingEffectivenessDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.GetOperatingEffectivenessDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlTestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string control_test_id = 1;
 * @return {string}
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.prototype.getControlTestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.GetOperatingEffectivenessDetailsRequest} returns this
 */
proto.audit.GetOperatingEffectivenessDetailsRequest.prototype.setControlTestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.OEEvidenceResult.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.OEEvidenceResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.OEEvidenceResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.OEEvidenceResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroupId: (f = msg.getEvidenceGroupId()) && attachment_attachment_pb.EvidenceGroupId.toObject(includeInstance, f),
    result: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    summary: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.OEEvidenceResult}
 */
proto.audit.OEEvidenceResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.OEEvidenceResult;
  return proto.audit.OEEvidenceResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.OEEvidenceResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.OEEvidenceResult}
 */
proto.audit.OEEvidenceResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new attachment_attachment_pb.EvidenceGroupId;
      reader.readMessage(value,attachment_attachment_pb.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setEvidenceGroupId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.OEEvidenceResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.OEEvidenceResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.OEEvidenceResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.OEEvidenceResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroupId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      attachment_attachment_pb.EvidenceGroupId.serializeBinaryToWriter
    );
  }
  f = message.getResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional attachment.EvidenceGroupId evidence_group_id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.audit.OEEvidenceResult.prototype.getEvidenceGroupId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, attachment_attachment_pb.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.audit.OEEvidenceResult} returns this
*/
proto.audit.OEEvidenceResult.prototype.setEvidenceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.audit.OEEvidenceResult} returns this
 */
proto.audit.OEEvidenceResult.prototype.clearEvidenceGroupId = function() {
  return this.setEvidenceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.audit.OEEvidenceResult.prototype.hasEvidenceGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool result = 2;
 * @return {boolean}
 */
proto.audit.OEEvidenceResult.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.OEEvidenceResult} returns this
 */
proto.audit.OEEvidenceResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string summary = 3;
 * @return {string}
 */
proto.audit.OEEvidenceResult.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.OEEvidenceResult} returns this
 */
proto.audit.OEEvidenceResult.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.OETestProcedureResult.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.OETestProcedureResult.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.OETestProcedureResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.OETestProcedureResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.OETestProcedureResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    result: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    summary: jspb.Message.getFieldWithDefault(msg, 3, ""),
    evidenceResultsList: jspb.Message.toObjectList(msg.getEvidenceResultsList(),
    proto.audit.OEEvidenceResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.OETestProcedureResult}
 */
proto.audit.OETestProcedureResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.OETestProcedureResult;
  return proto.audit.OETestProcedureResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.OETestProcedureResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.OETestProcedureResult}
 */
proto.audit.OETestProcedureResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setResult(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 4:
      var value = new proto.audit.OEEvidenceResult;
      reader.readMessage(value,proto.audit.OEEvidenceResult.deserializeBinaryFromReader);
      msg.addEvidenceResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.OETestProcedureResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.OETestProcedureResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.OETestProcedureResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.OETestProcedureResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResult();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEvidenceResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.audit.OEEvidenceResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.audit.OETestProcedureResult.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.OETestProcedureResult} returns this
 */
proto.audit.OETestProcedureResult.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool result = 2;
 * @return {boolean}
 */
proto.audit.OETestProcedureResult.prototype.getResult = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.audit.OETestProcedureResult} returns this
 */
proto.audit.OETestProcedureResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string summary = 3;
 * @return {string}
 */
proto.audit.OETestProcedureResult.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.audit.OETestProcedureResult} returns this
 */
proto.audit.OETestProcedureResult.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated OEEvidenceResult evidence_results = 4;
 * @return {!Array<!proto.audit.OEEvidenceResult>}
 */
proto.audit.OETestProcedureResult.prototype.getEvidenceResultsList = function() {
  return /** @type{!Array<!proto.audit.OEEvidenceResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.OEEvidenceResult, 4));
};


/**
 * @param {!Array<!proto.audit.OEEvidenceResult>} value
 * @return {!proto.audit.OETestProcedureResult} returns this
*/
proto.audit.OETestProcedureResult.prototype.setEvidenceResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.audit.OEEvidenceResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.OEEvidenceResult}
 */
proto.audit.OETestProcedureResult.prototype.addEvidenceResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.audit.OEEvidenceResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.OETestProcedureResult} returns this
 */
proto.audit.OETestProcedureResult.prototype.clearEvidenceResultsList = function() {
  return this.setEvidenceResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.audit.OperatingEffectivenessDetails.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.audit.OperatingEffectivenessDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.audit.OperatingEffectivenessDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.audit.OperatingEffectivenessDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.OperatingEffectivenessDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    testProcedureResultsList: jspb.Message.toObjectList(msg.getTestProcedureResultsList(),
    proto.audit.OETestProcedureResult.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.audit.OperatingEffectivenessDetails}
 */
proto.audit.OperatingEffectivenessDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.audit.OperatingEffectivenessDetails;
  return proto.audit.OperatingEffectivenessDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.audit.OperatingEffectivenessDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.audit.OperatingEffectivenessDetails}
 */
proto.audit.OperatingEffectivenessDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.audit.OETestProcedureResult;
      reader.readMessage(value,proto.audit.OETestProcedureResult.deserializeBinaryFromReader);
      msg.addTestProcedureResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.audit.OperatingEffectivenessDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.audit.OperatingEffectivenessDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.audit.OperatingEffectivenessDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.audit.OperatingEffectivenessDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestProcedureResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.audit.OETestProcedureResult.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OETestProcedureResult test_procedure_results = 1;
 * @return {!Array<!proto.audit.OETestProcedureResult>}
 */
proto.audit.OperatingEffectivenessDetails.prototype.getTestProcedureResultsList = function() {
  return /** @type{!Array<!proto.audit.OETestProcedureResult>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.audit.OETestProcedureResult, 1));
};


/**
 * @param {!Array<!proto.audit.OETestProcedureResult>} value
 * @return {!proto.audit.OperatingEffectivenessDetails} returns this
*/
proto.audit.OperatingEffectivenessDetails.prototype.setTestProcedureResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.audit.OETestProcedureResult=} opt_value
 * @param {number=} opt_index
 * @return {!proto.audit.OETestProcedureResult}
 */
proto.audit.OperatingEffectivenessDetails.prototype.addTestProcedureResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.audit.OETestProcedureResult, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.audit.OperatingEffectivenessDetails} returns this
 */
proto.audit.OperatingEffectivenessDetails.prototype.clearTestProcedureResultsList = function() {
  return this.setTestProcedureResultsList([]);
};


/**
 * @enum {number}
 */
proto.audit.AUDIT_RESULT = {
  PASS: 0,
  FAIL: 1,
  UNKNOWN: 3,
  NOT_APPLICABLE: 4,
  NOT_RUN: 5,
  LAMBDA_ERROR: 6,
  MISSING_SUGGESTIONS: 7
};

/**
 * @enum {number}
 */
proto.audit.AUDIT_STATUS = {
  INITIATED: 0,
  ERRORED: 1,
  COMPLETED: 2,
  STOPPED: 3,
  CANCELLING: 4
};

/**
 * @enum {number}
 */
proto.audit.PRE_AUDIT_SECTION = {
  SERVICE: 0,
  CONTROL: 1,
  POLICY: 2,
  COMPANY_INFO: 3,
  FRAMEWORK: 4,
  RISK: 5,
  REQUEST: 6
};

/**
 * @enum {number}
 */
proto.audit.PRE_AUDIT_RESULT = {
  PRE_AUDIT_PASS: 0,
  PRE_AUDIT_FAIL: 1,
  PRE_AUDIT_NOT_APPLICABLE: 2
};

/**
 * @enum {number}
 */
proto.audit.REASON_SOURCE = {
  GENERATED: 0,
  USER: 1
};

/**
 * @enum {number}
 */
proto.audit.CONTROL_CHECK_SORT_ORDER = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.audit.CONTROL_CHECK_SORT_COLUMN = {
  CONTROL_MATCHES_POLICY: 0,
  COMPLETENESS: 1,
  SPOT_CHECK: 2
};

goog.object.extend(exports, proto.audit);
