// source: attachment/attachment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
var common_time_pb = require('../common/time_pb.js');
goog.object.extend(proto, common_time_pb);
var common_payload_pb = require('../common/payload_pb.js');
goog.object.extend(proto, common_payload_pb);
var common_sorting_pb = require('../common/sorting_pb.js');
goog.object.extend(proto, common_sorting_pb);
var common_pagination_pb = require('../common/pagination_pb.js');
goog.object.extend(proto, common_pagination_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
goog.exportSymbol('proto.attachment.ACTOR_TYPE', null, global);
goog.exportSymbol('proto.attachment.ATTACHMENT_TYPE', null, global);
goog.exportSymbol('proto.attachment.ActorFilter', null, global);
goog.exportSymbol('proto.attachment.AddDocumentRequest', null, global);
goog.exportSymbol('proto.attachment.AddEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.AddOrUpdateEvidenceGroupRequest', null, global);
goog.exportSymbol('proto.attachment.AllExclusions', null, global);
goog.exportSymbol('proto.attachment.AuditModelID', null, global);
goog.exportSymbol('proto.attachment.AuditModelIDs', null, global);
goog.exportSymbol('proto.attachment.AuditPeriod', null, global);
goog.exportSymbol('proto.attachment.AuditPeriods', null, global);
goog.exportSymbol('proto.attachment.AutomatedEvidenceFilter', null, global);
goog.exportSymbol('proto.attachment.BulkAddEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest', null, global);
goog.exportSymbol('proto.attachment.BulkUnlinkEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.Caption', null, global);
goog.exportSymbol('proto.attachment.CloseAudit', null, global);
goog.exportSymbol('proto.attachment.CompleteAudit', null, global);
goog.exportSymbol('proto.attachment.ControlFilter', null, global);
goog.exportSymbol('proto.attachment.DOCUMENT_TYPE', null, global);
goog.exportSymbol('proto.attachment.DeleteAuditPeriodRequest', null, global);
goog.exportSymbol('proto.attachment.DeleteControlEvidencesByCaptionRequest', null, global);
goog.exportSymbol('proto.attachment.DeleteDocumentRequest', null, global);
goog.exportSymbol('proto.attachment.DeleteEvidenceGroupRequest', null, global);
goog.exportSymbol('proto.attachment.Document', null, global);
goog.exportSymbol('proto.attachment.DocumentPeriod', null, global);
goog.exportSymbol('proto.attachment.DocumentTypeQuery', null, global);
goog.exportSymbol('proto.attachment.Documents', null, global);
goog.exportSymbol('proto.attachment.EVIDENCE_LINKAGE_TYPE', null, global);
goog.exportSymbol('proto.attachment.EVIDENCE_SORT_COLUMN', null, global);
goog.exportSymbol('proto.attachment.Evidence', null, global);
goog.exportSymbol('proto.attachment.EvidenceGenerator', null, global);
goog.exportSymbol('proto.attachment.EvidenceGenerators', null, global);
goog.exportSymbol('proto.attachment.EvidenceGroup', null, global);
goog.exportSymbol('proto.attachment.EvidenceGroupId', null, global);
goog.exportSymbol('proto.attachment.EvidenceGroups', null, global);
goog.exportSymbol('proto.attachment.EvidenceLinkage', null, global);
goog.exportSymbol('proto.attachment.EvidenceSort', null, global);
goog.exportSymbol('proto.attachment.EvidenceTypeFilter', null, global);
goog.exportSymbol('proto.attachment.Excluded', null, global);
goog.exportSymbol('proto.attachment.FileTypeFilter', null, global);
goog.exportSymbol('proto.attachment.GetAuditPeriodByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetChangeByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetChangeLogEntriesRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentByCaptionRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceBodyByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceBodyByOIDResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceDiscoveryRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceDiscoveryResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupControlsRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupControlsResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupDRLRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupDRLResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupDocumentsRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupDocumentsResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceGroupResponse', null, global);
goog.exportSymbol('proto.attachment.GetExclusionsRequest', null, global);
goog.exportSymbol('proto.attachment.GetExclusionsResponse', null, global);
goog.exportSymbol('proto.attachment.GetLatestEvidenceGroupsRequest', null, global);
goog.exportSymbol('proto.attachment.GetLatestEvidenceGroupsResponse', null, global);
goog.exportSymbol('proto.attachment.GetLatestEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.GetLatestEvidenceResponse', null, global);
goog.exportSymbol('proto.attachment.GetModelEvidenceGeneratorRequest', null, global);
goog.exportSymbol('proto.attachment.GetPolicyAcknowledgementFormRequest', null, global);
goog.exportSymbol('proto.attachment.GetPolicyAcknowledgementFormResponse', null, global);
goog.exportSymbol('proto.attachment.GetPolicyDocumentsByPolicyIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetRiskRegisterCSVResponse', null, global);
goog.exportSymbol('proto.attachment.HeaderValue', null, global);
goog.exportSymbol('proto.attachment.IDCount', null, global);
goog.exportSymbol('proto.attachment.IDCounts', null, global);
goog.exportSymbol('proto.attachment.IDsQuery', null, global);
goog.exportSymbol('proto.attachment.IncludeModelIDs', null, global);
goog.exportSymbol('proto.attachment.LatestEvidence', null, global);
goog.exportSymbol('proto.attachment.LatestEvidenceGroup', null, global);
goog.exportSymbol('proto.attachment.LinkDocumentRequest', null, global);
goog.exportSymbol('proto.attachment.LinkDocumentsRequest', null, global);
goog.exportSymbol('proto.attachment.ListConnectedEvidenceGeneratorsRequest', null, global);
goog.exportSymbol('proto.attachment.ListEvidenceGroupsRequest', null, global);
goog.exportSymbol('proto.attachment.ListEvidenceGroupsResponse', null, global);
goog.exportSymbol('proto.attachment.ListEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.ListEvidenceResponse', null, global);
goog.exportSymbol('proto.attachment.LogEntries', null, global);
goog.exportSymbol('proto.attachment.LogEntry', null, global);
goog.exportSymbol('proto.attachment.LogEntryCount', null, global);
goog.exportSymbol('proto.attachment.LookUpChangeLogEntryRequest', null, global);
goog.exportSymbol('proto.attachment.LookUpChangeLogEntryResponse', null, global);
goog.exportSymbol('proto.attachment.ModelEvidenceGenerator', null, global);
goog.exportSymbol('proto.attachment.ModelTypeQuery', null, global);
goog.exportSymbol('proto.attachment.PolicyAcknowledgementForm', null, global);
goog.exportSymbol('proto.attachment.PolicyAcknowledgementFormValues', null, global);
goog.exportSymbol('proto.attachment.ServiceDiscovery', null, global);
goog.exportSymbol('proto.attachment.TimeQuery', null, global);
goog.exportSymbol('proto.attachment.URL', null, global);
goog.exportSymbol('proto.attachment.UnlinkEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.UpdateDocumentRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetPolicyDocumentsByPolicyIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetPolicyDocumentsByPolicyIDRequest.displayName = 'proto.attachment.GetPolicyDocumentsByPolicyIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetChangeByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetChangeByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetChangeByOIDRequest.displayName = 'proto.attachment.GetChangeByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.displayName = 'proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.displayName = 'proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetDocumentByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentByOIDRequest.displayName = 'proto.attachment.GetDocumentByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentByCaptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetDocumentByCaptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentByCaptionRequest.displayName = 'proto.attachment.GetDocumentByCaptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetAuditPeriodByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetAuditPeriodByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetAuditPeriodByOIDRequest.displayName = 'proto.attachment.GetAuditPeriodByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteAuditPeriodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteAuditPeriodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteAuditPeriodRequest.displayName = 'proto.attachment.DeleteAuditPeriodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LogEntry.displayName = 'proto.attachment.LogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetChangeLogEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetChangeLogEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetChangeLogEntriesRequest.displayName = 'proto.attachment.GetChangeLogEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LogEntries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.LogEntries.repeatedFields_, null);
};
goog.inherits(proto.attachment.LogEntries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LogEntries.displayName = 'proto.attachment.LogEntries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.Document.repeatedFields_, null);
};
goog.inherits(proto.attachment.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Document.displayName = 'proto.attachment.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AddDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AddDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AddDocumentRequest.displayName = 'proto.attachment.AddDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LinkDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LinkDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LinkDocumentRequest.displayName = 'proto.attachment.LinkDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LinkDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.LinkDocumentsRequest.repeatedFields_, null);
};
goog.inherits(proto.attachment.LinkDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LinkDocumentsRequest.displayName = 'proto.attachment.LinkDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Documents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.Documents.repeatedFields_, null);
};
goog.inherits(proto.attachment.Documents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Documents.displayName = 'proto.attachment.Documents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AddEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AddEvidenceRequest.repeatedFields_, null);
};
goog.inherits(proto.attachment.AddEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AddEvidenceRequest.displayName = 'proto.attachment.AddEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.BulkAddEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.BulkAddEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.BulkAddEvidenceRequest.displayName = 'proto.attachment.BulkAddEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DocumentPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DocumentPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DocumentPeriod.displayName = 'proto.attachment.DocumentPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Caption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.Caption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Caption.displayName = 'proto.attachment.Caption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.UpdateDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.UpdateDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.UpdateDocumentRequest.displayName = 'proto.attachment.UpdateDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AuditPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditPeriod.displayName = 'proto.attachment.AuditPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditModelID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AuditModelID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditModelID.displayName = 'proto.attachment.AuditModelID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditModelIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AuditModelIDs.repeatedFields_, null);
};
goog.inherits(proto.attachment.AuditModelIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditModelIDs.displayName = 'proto.attachment.AuditModelIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditPeriods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AuditPeriods.repeatedFields_, null);
};
goog.inherits(proto.attachment.AuditPeriods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditPeriods.displayName = 'proto.attachment.AuditPeriods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IncludeModelIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.IncludeModelIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IncludeModelIDs.displayName = 'proto.attachment.IncludeModelIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.CloseAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.CloseAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.CloseAudit.displayName = 'proto.attachment.CloseAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.CompleteAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.CompleteAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.CompleteAudit.displayName = 'proto.attachment.CompleteAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.TimeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.TimeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.TimeQuery.displayName = 'proto.attachment.TimeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ModelTypeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ModelTypeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ModelTypeQuery.displayName = 'proto.attachment.ModelTypeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DocumentTypeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DocumentTypeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DocumentTypeQuery.displayName = 'proto.attachment.DocumentTypeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IDsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.IDsQuery.repeatedFields_, null);
};
goog.inherits(proto.attachment.IDsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IDsQuery.displayName = 'proto.attachment.IDsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IDCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.IDCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IDCount.displayName = 'proto.attachment.IDCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IDCounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.IDCounts.repeatedFields_, null);
};
goog.inherits(proto.attachment.IDCounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IDCounts.displayName = 'proto.attachment.IDCounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LogEntryCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LogEntryCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LogEntryCount.displayName = 'proto.attachment.LogEntryCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceBodyByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceBodyByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceBodyByOIDRequest.displayName = 'proto.attachment.GetEvidenceBodyByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceBodyByOIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceBodyByOIDResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceBodyByOIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceBodyByOIDResponse.displayName = 'proto.attachment.GetEvidenceBodyByOIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetLatestEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetLatestEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetLatestEvidenceRequest.displayName = 'proto.attachment.GetLatestEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AutomatedEvidenceFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AutomatedEvidenceFilter.repeatedFields_, null);
};
goog.inherits(proto.attachment.AutomatedEvidenceFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AutomatedEvidenceFilter.displayName = 'proto.attachment.AutomatedEvidenceFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.FileTypeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.FileTypeFilter.repeatedFields_, null);
};
goog.inherits(proto.attachment.FileTypeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.FileTypeFilter.displayName = 'proto.attachment.FileTypeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceTypeFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceTypeFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceTypeFilter.displayName = 'proto.attachment.EvidenceTypeFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ControlFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ControlFilter.repeatedFields_, null);
};
goog.inherits(proto.attachment.ControlFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ControlFilter.displayName = 'proto.attachment.ControlFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ActorFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ActorFilter.repeatedFields_, null);
};
goog.inherits(proto.attachment.ActorFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ActorFilter.displayName = 'proto.attachment.ActorFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceSort.displayName = 'proto.attachment.EvidenceSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetLatestEvidenceGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetLatestEvidenceGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetLatestEvidenceGroupsRequest.displayName = 'proto.attachment.GetLatestEvidenceGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Excluded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.Excluded.repeatedFields_, null);
};
goog.inherits(proto.attachment.Excluded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Excluded.displayName = 'proto.attachment.Excluded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AllExclusions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AllExclusions.repeatedFields_, null);
};
goog.inherits(proto.attachment.AllExclusions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AllExclusions.displayName = 'proto.attachment.AllExclusions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceDiscoveryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceDiscoveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceDiscoveryRequest.displayName = 'proto.attachment.GetEvidenceDiscoveryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceDiscoveryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceDiscoveryResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceDiscoveryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceDiscoveryResponse.displayName = 'proto.attachment.GetEvidenceDiscoveryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ServiceDiscovery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ServiceDiscovery.repeatedFields_, null);
};
goog.inherits(proto.attachment.ServiceDiscovery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ServiceDiscovery.displayName = 'proto.attachment.ServiceDiscovery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ListEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListEvidenceRequest.displayName = 'proto.attachment.ListEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListEvidenceGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ListEvidenceGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListEvidenceGroupsRequest.displayName = 'proto.attachment.ListEvidenceGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListEvidenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ListEvidenceResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.ListEvidenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListEvidenceResponse.displayName = 'proto.attachment.ListEvidenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListEvidenceGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ListEvidenceGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.ListEvidenceGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListEvidenceGroupsResponse.displayName = 'proto.attachment.ListEvidenceGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetLatestEvidenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetLatestEvidenceResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetLatestEvidenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetLatestEvidenceResponse.displayName = 'proto.attachment.GetLatestEvidenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetLatestEvidenceGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetLatestEvidenceGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetLatestEvidenceGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetLatestEvidenceGroupsResponse.displayName = 'proto.attachment.GetLatestEvidenceGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ModelEvidenceGenerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ModelEvidenceGenerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ModelEvidenceGenerator.displayName = 'proto.attachment.ModelEvidenceGenerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetModelEvidenceGeneratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetModelEvidenceGeneratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetModelEvidenceGeneratorRequest.displayName = 'proto.attachment.GetModelEvidenceGeneratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ListConnectedEvidenceGeneratorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListConnectedEvidenceGeneratorsRequest.displayName = 'proto.attachment.ListConnectedEvidenceGeneratorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LatestEvidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LatestEvidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LatestEvidence.displayName = 'proto.attachment.LatestEvidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LatestEvidenceGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LatestEvidenceGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LatestEvidenceGroup.displayName = 'proto.attachment.LatestEvidenceGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Evidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.Evidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Evidence.displayName = 'proto.attachment.Evidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGroupId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceGroupId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGroupId.displayName = 'proto.attachment.EvidenceGroupId';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGroups = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.EvidenceGroups.repeatedFields_, null);
};
goog.inherits(proto.attachment.EvidenceGroups, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGroups.displayName = 'proto.attachment.EvidenceGroups';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGroup.displayName = 'proto.attachment.EvidenceGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGenerators = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.EvidenceGenerators.repeatedFields_, null);
};
goog.inherits(proto.attachment.EvidenceGenerators, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGenerators.displayName = 'proto.attachment.EvidenceGenerators';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGenerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceGenerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGenerator.displayName = 'proto.attachment.EvidenceGenerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteDocumentRequest.displayName = 'proto.attachment.DeleteDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.PolicyAcknowledgementFormValues = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.PolicyAcknowledgementFormValues, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.PolicyAcknowledgementFormValues.displayName = 'proto.attachment.PolicyAcknowledgementFormValues';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetPolicyAcknowledgementFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetPolicyAcknowledgementFormRequest.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetPolicyAcknowledgementFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetPolicyAcknowledgementFormRequest.displayName = 'proto.attachment.GetPolicyAcknowledgementFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetPolicyAcknowledgementFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetPolicyAcknowledgementFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetPolicyAcknowledgementFormResponse.displayName = 'proto.attachment.GetPolicyAcknowledgementFormResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.PolicyAcknowledgementForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.PolicyAcknowledgementForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.PolicyAcknowledgementForm.displayName = 'proto.attachment.PolicyAcknowledgementForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetRiskRegisterCSVResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetRiskRegisterCSVResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetRiskRegisterCSVResponse.displayName = 'proto.attachment.GetRiskRegisterCSVResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetExclusionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetExclusionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetExclusionsRequest.displayName = 'proto.attachment.GetExclusionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetExclusionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetExclusionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetExclusionsResponse.displayName = 'proto.attachment.GetExclusionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteControlEvidencesByCaptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteControlEvidencesByCaptionRequest.displayName = 'proto.attachment.DeleteControlEvidencesByCaptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LookUpChangeLogEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LookUpChangeLogEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LookUpChangeLogEntryRequest.displayName = 'proto.attachment.LookUpChangeLogEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LookUpChangeLogEntryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LookUpChangeLogEntryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LookUpChangeLogEntryResponse.displayName = 'proto.attachment.LookUpChangeLogEntryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.URL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.URL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.URL.displayName = 'proto.attachment.URL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.HeaderValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.HeaderValue.repeatedFields_, null);
};
goog.inherits(proto.attachment.HeaderValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.HeaderValue.displayName = 'proto.attachment.HeaderValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AddOrUpdateEvidenceGroupRequest.repeatedFields_, null);
};
goog.inherits(proto.attachment.AddOrUpdateEvidenceGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AddOrUpdateEvidenceGroupRequest.displayName = 'proto.attachment.AddOrUpdateEvidenceGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.repeatedFields_, null);
};
goog.inherits(proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.displayName = 'proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteEvidenceGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteEvidenceGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteEvidenceGroupRequest.displayName = 'proto.attachment.DeleteEvidenceGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceLinkage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceLinkage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceLinkage.displayName = 'proto.attachment.EvidenceLinkage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.UnlinkEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.UnlinkEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.UnlinkEvidenceRequest.displayName = 'proto.attachment.UnlinkEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.BulkUnlinkEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.BulkUnlinkEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.BulkUnlinkEvidenceRequest.displayName = 'proto.attachment.BulkUnlinkEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupControlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupControlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupControlsRequest.displayName = 'proto.attachment.GetEvidenceGroupControlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupControlsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceGroupControlsResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupControlsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupControlsResponse.displayName = 'proto.attachment.GetEvidenceGroupControlsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupDRLRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupDRLRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupDRLRequest.displayName = 'proto.attachment.GetEvidenceGroupDRLRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupDRLResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceGroupDRLResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupDRLResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupDRLResponse.displayName = 'proto.attachment.GetEvidenceGroupDRLResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupDocumentsRequest.displayName = 'proto.attachment.GetEvidenceGroupDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceGroupDocumentsResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupDocumentsResponse.displayName = 'proto.attachment.GetEvidenceGroupDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupRequest.displayName = 'proto.attachment.GetEvidenceGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceGroupResponse.displayName = 'proto.attachment.GetEvidenceGroupResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetPolicyDocumentsByPolicyIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyModelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetPolicyDocumentsByPolicyIDRequest;
  return proto.attachment.GetPolicyDocumentsByPolicyIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyModelId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetPolicyDocumentsByPolicyIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string policy_model_id = 1;
 * @return {string}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.getPolicyModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} returns this
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.setPolicyModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} returns this
*/
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyDocumentsByPolicyIDRequest} returns this
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyDocumentsByPolicyIDRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetChangeByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetChangeByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetChangeByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetChangeByOIDRequest}
 */
proto.attachment.GetChangeByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetChangeByOIDRequest;
  return proto.attachment.GetChangeByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetChangeByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetChangeByOIDRequest}
 */
proto.attachment.GetChangeByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetChangeByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetChangeByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetChangeByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetChangeByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetChangeByOIDRequest} returns this
 */
proto.attachment.GetChangeByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest;
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse;
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeBody: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentByOIDRequest}
 */
proto.attachment.GetDocumentByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentByOIDRequest;
  return proto.attachment.GetDocumentByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentByOIDRequest}
 */
proto.attachment.GetDocumentByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeBody(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeBody();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetDocumentByOIDRequest} returns this
 */
proto.attachment.GetDocumentByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_body = 2;
 * @return {boolean}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.getIncludeBody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.GetDocumentByOIDRequest} returns this
 */
proto.attachment.GetDocumentByOIDRequest.prototype.setIncludeBody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue audit_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetDocumentByOIDRequest} returns this
*/
proto.attachment.GetDocumentByOIDRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetDocumentByOIDRequest} returns this
 */
proto.attachment.GetDocumentByOIDRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentByCaptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentByCaptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentByCaptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentByCaptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentByCaptionRequest}
 */
proto.attachment.GetDocumentByCaptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentByCaptionRequest;
  return proto.attachment.GetDocumentByCaptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentByCaptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentByCaptionRequest}
 */
proto.attachment.GetDocumentByCaptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentByCaptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentByCaptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentByCaptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentByCaptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string caption = 1;
 * @return {string}
 */
proto.attachment.GetDocumentByCaptionRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetDocumentByCaptionRequest} returns this
 */
proto.attachment.GetDocumentByCaptionRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetAuditPeriodByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetAuditPeriodByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetAuditPeriodByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetAuditPeriodByOIDRequest}
 */
proto.attachment.GetAuditPeriodByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetAuditPeriodByOIDRequest;
  return proto.attachment.GetAuditPeriodByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetAuditPeriodByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetAuditPeriodByOIDRequest}
 */
proto.attachment.GetAuditPeriodByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetAuditPeriodByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetAuditPeriodByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetAuditPeriodByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetAuditPeriodByOIDRequest} returns this
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteAuditPeriodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteAuditPeriodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteAuditPeriodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteAuditPeriodRequest}
 */
proto.attachment.DeleteAuditPeriodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteAuditPeriodRequest;
  return proto.attachment.DeleteAuditPeriodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteAuditPeriodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteAuditPeriodRequest}
 */
proto.attachment.DeleteAuditPeriodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteAuditPeriodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteAuditPeriodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteAuditPeriodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteAuditPeriodRequest} returns this
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subjectmodeltype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subjectmodelid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actortype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    actor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    message: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attachmenttype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attachmentid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subjectmodelname: (f = msg.getSubjectmodelname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subjectid: (f = msg.getSubjectid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    actorid: (f = msg.getActorid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LogEntry}
 */
proto.attachment.LogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LogEntry;
  return proto.attachment.LogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LogEntry}
 */
proto.attachment.LogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 4:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setSubjectmodeltype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectmodelid(value);
      break;
    case 6:
      var value = /** @type {!proto.attachment.ACTOR_TYPE} */ (reader.readEnum());
      msg.setActortype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 9:
      var value = /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (reader.readEnum());
      msg.setAttachmenttype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentid(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubjectmodelname(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubjectid(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActorid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubjectmodeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSubjectmodelid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActortype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttachmenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAttachmentid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubjectmodelname();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubjectid();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActorid();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.attachment.LogEntry.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string AccountID = 3;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.MODEL_TYPE SubjectModelType = 4;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.LogEntry.prototype.getSubjectmodeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setSubjectmodeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string SubjectModelID = 5;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getSubjectmodelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setSubjectmodelid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ACTOR_TYPE ActorType = 6;
 * @return {!proto.attachment.ACTOR_TYPE}
 */
proto.attachment.LogEntry.prototype.getActortype = function() {
  return /** @type {!proto.attachment.ACTOR_TYPE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.attachment.ACTOR_TYPE} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setActortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string Actor = 7;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Message = 8;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional ATTACHMENT_TYPE AttachmentType = 9;
 * @return {!proto.attachment.ATTACHMENT_TYPE}
 */
proto.attachment.LogEntry.prototype.getAttachmenttype = function() {
  return /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.attachment.ATTACHMENT_TYPE} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setAttachmenttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string AttachmentID = 10;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getAttachmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setAttachmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue SubjectModelName = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.LogEntry.prototype.getSubjectmodelname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.LogEntry} returns this
*/
proto.attachment.LogEntry.prototype.setSubjectmodelname = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.clearSubjectmodelname = function() {
  return this.setSubjectmodelname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LogEntry.prototype.hasSubjectmodelname = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue SubjectId = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.LogEntry.prototype.getSubjectid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.LogEntry} returns this
*/
proto.attachment.LogEntry.prototype.setSubjectid = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.clearSubjectid = function() {
  return this.setSubjectid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LogEntry.prototype.hasSubjectid = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue ActorId = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.LogEntry.prototype.getActorid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.LogEntry} returns this
*/
proto.attachment.LogEntry.prototype.setActorid = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.clearActorid = function() {
  return this.setActorid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LogEntry.prototype.hasActorid = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetChangeLogEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetChangeLogEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeLogEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesPerPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subjectModelIds: (f = msg.getSubjectModelIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    subjectIds: (f = msg.getSubjectIds()) && common_collections_pb.StringList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetChangeLogEntriesRequest}
 */
proto.attachment.GetChangeLogEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetChangeLogEntriesRequest;
  return proto.attachment.GetChangeLogEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetChangeLogEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetChangeLogEntriesRequest}
 */
proto.attachment.GetChangeLogEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntriesPerPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setSubjectModelIds(value);
      break;
    case 4:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setSubjectIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetChangeLogEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetChangeLogEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeLogEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesPerPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubjectModelIds();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getSubjectIds();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 entries_per_page = 1;
 * @return {number}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getEntriesPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.setEntriesPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional common.StringList subject_model_ids = 3;
 * @return {?proto.common.StringList}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getSubjectModelIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 3));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
*/
proto.attachment.GetChangeLogEntriesRequest.prototype.setSubjectModelIds = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.clearSubjectModelIds = function() {
  return this.setSubjectModelIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.hasSubjectModelIds = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional common.StringList subject_ids = 4;
 * @return {?proto.common.StringList}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getSubjectIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 4));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
*/
proto.attachment.GetChangeLogEntriesRequest.prototype.setSubjectIds = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.clearSubjectIds = function() {
  return this.setSubjectIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.hasSubjectIds = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.LogEntries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LogEntries.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LogEntries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LogEntries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntries.toObject = function(includeInstance, msg) {
  var f, obj = {
    logentriesList: jspb.Message.toObjectList(msg.getLogentriesList(),
    proto.attachment.LogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LogEntries}
 */
proto.attachment.LogEntries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LogEntries;
  return proto.attachment.LogEntries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LogEntries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LogEntries}
 */
proto.attachment.LogEntries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LogEntry;
      reader.readMessage(value,proto.attachment.LogEntry.deserializeBinaryFromReader);
      msg.addLogentries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LogEntries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LogEntries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LogEntries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LogEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogEntry LogEntries = 1;
 * @return {!Array<!proto.attachment.LogEntry>}
 */
proto.attachment.LogEntries.prototype.getLogentriesList = function() {
  return /** @type{!Array<!proto.attachment.LogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LogEntry, 1));
};


/**
 * @param {!Array<!proto.attachment.LogEntry>} value
 * @return {!proto.attachment.LogEntries} returns this
*/
proto.attachment.LogEntries.prototype.setLogentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LogEntry}
 */
proto.attachment.LogEntries.prototype.addLogentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.LogEntries} returns this
 */
proto.attachment.LogEntries.prototype.clearLogentriesList = function() {
  return this.setLogentriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.Document.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subjectmodeltype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subjectmodelid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actortype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    actor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    auditperiodidsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    doctype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    body: msg.getBody_asB64(),
    bodysize: jspb.Message.getFieldWithDefault(msg, 12, 0),
    caption: jspb.Message.getFieldWithDefault(msg, 13, ""),
    discoveryid: (f = msg.getDiscoveryid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    relevantdate: (f = msg.getRelevantdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    requestid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    allexclusions: (f = msg.getAllexclusions()) && proto.attachment.AllExclusions.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 18, ""),
    subjectid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    contentid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isCustomized: (f = msg.getIsCustomized()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    linkerModelId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    documentpath: jspb.Message.getFieldWithDefault(msg, 23, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 24, ""),
    sourceServiceModelId: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Document}
 */
proto.attachment.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Document;
  return proto.attachment.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Document}
 */
proto.attachment.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 4:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setSubjectmodeltype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectmodelid(value);
      break;
    case 6:
      var value = /** @type {!proto.attachment.ACTOR_TYPE} */ (reader.readEnum());
      msg.setActortype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditperiodids(value);
      break;
    case 9:
      var value = /** @type {!proto.attachment.DOCUMENT_TYPE} */ (reader.readEnum());
      msg.setDoctype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBodysize(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDiscoveryid(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantdate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 17:
      var value = new proto.attachment.AllExclusions;
      reader.readMessage(value,proto.attachment.AllExclusions.deserializeBinaryFromReader);
      msg.setAllexclusions(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentid(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsCustomized(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkerModelId(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentpath(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceServiceModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubjectmodeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSubjectmodelid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActortype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuditperiodidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getDoctype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getBodysize();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDiscoveryid();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRelevantdate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAllexclusions();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.attachment.AllExclusions.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSubjectid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getContentid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsCustomized();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLinkerModelId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getDocumentpath();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSourceServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.attachment.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.attachment.Document.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string AccountID = 3;
 * @return {string}
 */
proto.attachment.Document.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.MODEL_TYPE SubjectModelType = 4;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.Document.prototype.getSubjectmodeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSubjectmodeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string SubjectModelID = 5;
 * @return {string}
 */
proto.attachment.Document.prototype.getSubjectmodelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSubjectmodelid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ACTOR_TYPE ActorType = 6;
 * @return {!proto.attachment.ACTOR_TYPE}
 */
proto.attachment.Document.prototype.getActortype = function() {
  return /** @type {!proto.attachment.ACTOR_TYPE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.attachment.ACTOR_TYPE} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setActortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string Actor = 7;
 * @return {string}
 */
proto.attachment.Document.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string AuditPeriodIDs = 8;
 * @return {!Array<string>}
 */
proto.attachment.Document.prototype.getAuditperiodidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setAuditperiodidsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.addAuditperiodids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearAuditperiodidsList = function() {
  return this.setAuditperiodidsList([]);
};


/**
 * optional DOCUMENT_TYPE DocType = 9;
 * @return {!proto.attachment.DOCUMENT_TYPE}
 */
proto.attachment.Document.prototype.getDoctype = function() {
  return /** @type {!proto.attachment.DOCUMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.attachment.DOCUMENT_TYPE} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setDoctype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string Mime = 10;
 * @return {string}
 */
proto.attachment.Document.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bytes Body = 11;
 * @return {!(string|Uint8Array)}
 */
proto.attachment.Document.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes Body = 11;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.attachment.Document.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes Body = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.attachment.Document.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional int64 BodySize = 12;
 * @return {number}
 */
proto.attachment.Document.prototype.getBodysize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setBodysize = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string Caption = 13;
 * @return {string}
 */
proto.attachment.Document.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.StringValue DiscoveryId = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.Document.prototype.getDiscoveryid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setDiscoveryid = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearDiscoveryid = function() {
  return this.setDiscoveryid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasDiscoveryid = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp RelevantDate = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.Document.prototype.getRelevantdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setRelevantdate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearRelevantdate = function() {
  return this.setRelevantdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasRelevantdate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string RequestId = 16;
 * @return {string}
 */
proto.attachment.Document.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional AllExclusions AllExclusions = 17;
 * @return {?proto.attachment.AllExclusions}
 */
proto.attachment.Document.prototype.getAllexclusions = function() {
  return /** @type{?proto.attachment.AllExclusions} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AllExclusions, 17));
};


/**
 * @param {?proto.attachment.AllExclusions|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setAllexclusions = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearAllexclusions = function() {
  return this.setAllexclusions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasAllexclusions = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string Description = 18;
 * @return {string}
 */
proto.attachment.Document.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string SubjectID = 19;
 * @return {string}
 */
proto.attachment.Document.prototype.getSubjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSubjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ContentID = 20;
 * @return {string}
 */
proto.attachment.Document.prototype.getContentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setContentid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional google.protobuf.BoolValue is_customized = 21;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.Document.prototype.getIsCustomized = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 21));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setIsCustomized = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearIsCustomized = function() {
  return this.setIsCustomized(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasIsCustomized = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string linker_model_id = 22;
 * @return {string}
 */
proto.attachment.Document.prototype.getLinkerModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setLinkerModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string DocumentPath = 23;
 * @return {string}
 */
proto.attachment.Document.prototype.getDocumentpath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setDocumentpath = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string file_name = 24;
 * @return {string}
 */
proto.attachment.Document.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string source_service_model_id = 25;
 * @return {string}
 */
proto.attachment.Document.prototype.getSourceServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSourceServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AddDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AddDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AddDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.attachment.Document.toObject(includeInstance, f),
    overrideActor: (f = msg.getOverrideActor()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    overrideActorType: (f = msg.getOverrideActorType()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AddDocumentRequest}
 */
proto.attachment.AddDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AddDocumentRequest;
  return proto.attachment.AddDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AddDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AddDocumentRequest}
 */
proto.attachment.AddDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOverrideActor(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOverrideActorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AddDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AddDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AddDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
  f = message.getOverrideActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOverrideActorType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.attachment.Document}
 */
proto.attachment.AddDocumentRequest.prototype.getDocument = function() {
  return /** @type{?proto.attachment.Document} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {?proto.attachment.Document|undefined} value
 * @return {!proto.attachment.AddDocumentRequest} returns this
*/
proto.attachment.AddDocumentRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddDocumentRequest} returns this
 */
proto.attachment.AddDocumentRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddDocumentRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue override_actor = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.AddDocumentRequest.prototype.getOverrideActor = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.AddDocumentRequest} returns this
*/
proto.attachment.AddDocumentRequest.prototype.setOverrideActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddDocumentRequest} returns this
 */
proto.attachment.AddDocumentRequest.prototype.clearOverrideActor = function() {
  return this.setOverrideActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddDocumentRequest.prototype.hasOverrideActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue override_actor_type = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.AddDocumentRequest.prototype.getOverrideActorType = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.AddDocumentRequest} returns this
*/
proto.attachment.AddDocumentRequest.prototype.setOverrideActorType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddDocumentRequest} returns this
 */
proto.attachment.AddDocumentRequest.prototype.clearOverrideActorType = function() {
  return this.setOverrideActorType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddDocumentRequest.prototype.hasOverrideActorType = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LinkDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LinkDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LinkDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.attachment.Document.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LinkDocumentRequest}
 */
proto.attachment.LinkDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LinkDocumentRequest;
  return proto.attachment.LinkDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LinkDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LinkDocumentRequest}
 */
proto.attachment.LinkDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LinkDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LinkDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LinkDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.attachment.Document}
 */
proto.attachment.LinkDocumentRequest.prototype.getDocument = function() {
  return /** @type{?proto.attachment.Document} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {?proto.attachment.Document|undefined} value
 * @return {!proto.attachment.LinkDocumentRequest} returns this
*/
proto.attachment.LinkDocumentRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LinkDocumentRequest} returns this
 */
proto.attachment.LinkDocumentRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LinkDocumentRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.attachment.LinkDocumentRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LinkDocumentRequest} returns this
 */
proto.attachment.LinkDocumentRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.LinkDocumentsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LinkDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LinkDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LinkDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.attachment.LinkDocumentRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LinkDocumentsRequest}
 */
proto.attachment.LinkDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LinkDocumentsRequest;
  return proto.attachment.LinkDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LinkDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LinkDocumentsRequest}
 */
proto.attachment.LinkDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LinkDocumentRequest;
      reader.readMessage(value,proto.attachment.LinkDocumentRequest.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LinkDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LinkDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LinkDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LinkDocumentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LinkDocumentRequest documents = 1;
 * @return {!Array<!proto.attachment.LinkDocumentRequest>}
 */
proto.attachment.LinkDocumentsRequest.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.attachment.LinkDocumentRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LinkDocumentRequest, 1));
};


/**
 * @param {!Array<!proto.attachment.LinkDocumentRequest>} value
 * @return {!proto.attachment.LinkDocumentsRequest} returns this
*/
proto.attachment.LinkDocumentsRequest.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LinkDocumentRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LinkDocumentRequest}
 */
proto.attachment.LinkDocumentsRequest.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LinkDocumentRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.LinkDocumentsRequest} returns this
 */
proto.attachment.LinkDocumentsRequest.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.Documents.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Documents.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Documents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Documents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Documents.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.attachment.Document.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Documents}
 */
proto.attachment.Documents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Documents;
  return proto.attachment.Documents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Documents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Documents}
 */
proto.attachment.Documents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Documents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Documents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Documents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Documents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Document Documents = 1;
 * @return {!Array<!proto.attachment.Document>}
 */
proto.attachment.Documents.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.attachment.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {!Array<!proto.attachment.Document>} value
 * @return {!proto.attachment.Documents} returns this
*/
proto.attachment.Documents.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Document}
 */
proto.attachment.Documents.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.Documents} returns this
 */
proto.attachment.Documents.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AddEvidenceRequest.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AddEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AddEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AddEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.attachment.Document.toObject(includeInstance, f),
    discoveryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receptorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isManual: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    controlsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    sourceServiceModelId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AddEvidenceRequest}
 */
proto.attachment.AddEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AddEvidenceRequest;
  return proto.attachment.AddEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AddEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AddEvidenceRequest}
 */
proto.attachment.AddEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscoveryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceModelId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManual(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addControls(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceServiceModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AddEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AddEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AddEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
  f = message.getDiscoveryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceptorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsManual();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getControlsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getSourceServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.attachment.Document}
 */
proto.attachment.AddEvidenceRequest.prototype.getDocument = function() {
  return /** @type{?proto.attachment.Document} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {?proto.attachment.Document|undefined} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
*/
proto.attachment.AddEvidenceRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddEvidenceRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string discovery_id = 2;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getDiscoveryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setDiscoveryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string receptor_id = 3;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getReceptorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setReceptorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_model_id = 4;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool is_manual = 5;
 * @return {boolean}
 */
proto.attachment.AddEvidenceRequest.prototype.getIsManual = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setIsManual = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated string controls = 6;
 * @return {!Array<string>}
 */
proto.attachment.AddEvidenceRequest.prototype.getControlsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setControlsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.addControls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.clearControlsList = function() {
  return this.setControlsList([]);
};


/**
 * optional string source_service_model_id = 7;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getSourceServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setSourceServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.BulkAddEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.BulkAddEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkAddEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documents: (f = msg.getDocuments()) && proto.attachment.Documents.toObject(includeInstance, f),
    identifier: (f = msg.getIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f),
    requestId: (f = msg.getRequestId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    uploadSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.BulkAddEvidenceRequest}
 */
proto.attachment.BulkAddEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.BulkAddEvidenceRequest;
  return proto.attachment.BulkAddEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.BulkAddEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.BulkAddEvidenceRequest}
 */
proto.attachment.BulkAddEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Documents;
      reader.readMessage(value,proto.attachment.Documents.deserializeBinaryFromReader);
      msg.setDocuments(value);
      break;
    case 2:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setIdentifier(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequestId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUploadSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.BulkAddEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.BulkAddEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkAddEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocuments();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Documents.serializeBinaryToWriter
    );
  }
  f = message.getIdentifier();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getRequestId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUploadSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Documents documents = 1;
 * @return {?proto.attachment.Documents}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getDocuments = function() {
  return /** @type{?proto.attachment.Documents} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Documents, 1));
};


/**
 * @param {?proto.attachment.Documents|undefined} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
*/
proto.attachment.BulkAddEvidenceRequest.prototype.setDocuments = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.clearDocuments = function() {
  return this.setDocuments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.hasDocuments = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.Identifier identifier = 2;
 * @return {?proto.common.Identifier}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 2));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
*/
proto.attachment.BulkAddEvidenceRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.clearIdentifier = function() {
  return this.setIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.hasIdentifier = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue request_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getRequestId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
*/
proto.attachment.BulkAddEvidenceRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.clearRequestId = function() {
  return this.setRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 upload_size = 4;
 * @return {number}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getUploadSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.setUploadSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DocumentPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DocumentPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DocumentPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditperiodid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DocumentPeriod}
 */
proto.attachment.DocumentPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DocumentPeriod;
  return proto.attachment.DocumentPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DocumentPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DocumentPeriod}
 */
proto.attachment.DocumentPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditperiodid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DocumentPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DocumentPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DocumentPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditperiodid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string DocumentID = 1;
 * @return {string}
 */
proto.attachment.DocumentPeriod.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DocumentPeriod} returns this
 */
proto.attachment.DocumentPeriod.prototype.setDocumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AuditPeriodID = 2;
 * @return {string}
 */
proto.attachment.DocumentPeriod.prototype.getAuditperiodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DocumentPeriod} returns this
 */
proto.attachment.DocumentPeriod.prototype.setAuditperiodid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Caption.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Caption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Caption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Caption.toObject = function(includeInstance, msg) {
  var f, obj = {
    oid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Caption}
 */
proto.attachment.Caption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Caption;
  return proto.attachment.Caption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Caption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Caption}
 */
proto.attachment.Caption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Caption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Caption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Caption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Caption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string OID = 1;
 * @return {string}
 */
proto.attachment.Caption.prototype.getOid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Caption} returns this
 */
proto.attachment.Caption.prototype.setOid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Caption = 2;
 * @return {string}
 */
proto.attachment.Caption.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Caption} returns this
 */
proto.attachment.Caption.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.UpdateDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.UpdateDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.UpdateDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.UpdateDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    caption: (f = msg.getCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    requestId: (f = msg.getRequestId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.UpdateDocumentRequest}
 */
proto.attachment.UpdateDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.UpdateDocumentRequest;
  return proto.attachment.UpdateDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.UpdateDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.UpdateDocumentRequest}
 */
proto.attachment.UpdateDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCaption(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.UpdateDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.UpdateDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.UpdateDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.UpdateDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCaption();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRequestId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.UpdateDocumentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
 */
proto.attachment.UpdateDocumentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp relevant_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.UpdateDocumentRequest.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
*/
proto.attachment.UpdateDocumentRequest.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
 */
proto.attachment.UpdateDocumentRequest.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.UpdateDocumentRequest.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue caption = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.UpdateDocumentRequest.prototype.getCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
*/
proto.attachment.UpdateDocumentRequest.prototype.setCaption = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
 */
proto.attachment.UpdateDocumentRequest.prototype.clearCaption = function() {
  return this.setCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.UpdateDocumentRequest.prototype.hasCaption = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue request_id = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.UpdateDocumentRequest.prototype.getRequestId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
*/
proto.attachment.UpdateDocumentRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.UpdateDocumentRequest} returns this
 */
proto.attachment.UpdateDocumentRequest.prototype.clearRequestId = function() {
  return this.setRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.UpdateDocumentRequest.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subjectModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    beginTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 8, ""),
    modelVersion: jspb.Message.getFieldWithDefault(msg, 9, ""),
    modelIds: (f = msg.getModelIds()) && proto.attachment.AuditModelIDs.toObject(includeInstance, f),
    auditor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    auditTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditPeriod}
 */
proto.attachment.AuditPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditPeriod;
  return proto.attachment.AuditPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditPeriod}
 */
proto.attachment.AuditPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeginTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelVersion(value);
      break;
    case 10:
      var value = new proto.attachment.AuditModelIDs;
      reader.readMessage(value,proto.attachment.AuditModelIDs.deserializeBinaryFromReader);
      msg.setModelIds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAuditTime(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubjectModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBeginTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModelVersion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getModelIds();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.attachment.AuditModelIDs.serializeBinaryToWriter
    );
  }
  f = message.getAuditor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAuditTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject_model_id = 4;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getSubjectModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setSubjectModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actor = 5;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 begin_time = 6;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getBeginTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setBeginTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 end_time = 7;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string notes = 8;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string model_version = 9;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getModelVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setModelVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional AuditModelIDs model_ids = 10;
 * @return {?proto.attachment.AuditModelIDs}
 */
proto.attachment.AuditPeriod.prototype.getModelIds = function() {
  return /** @type{?proto.attachment.AuditModelIDs} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AuditModelIDs, 10));
};


/**
 * @param {?proto.attachment.AuditModelIDs|undefined} value
 * @return {!proto.attachment.AuditPeriod} returns this
*/
proto.attachment.AuditPeriod.prototype.setModelIds = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.clearModelIds = function() {
  return this.setModelIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AuditPeriod.prototype.hasModelIds = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string auditor = 11;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getAuditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setAuditor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 audit_time = 12;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getAuditTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setAuditTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool closed = 13;
 * @return {boolean}
 */
proto.attachment.AuditPeriod.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditModelID.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditModelID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditModelID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelID.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modeltype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    modelid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditModelID}
 */
proto.attachment.AuditModelID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditModelID;
  return proto.attachment.AuditModelID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditModelID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditModelID}
 */
proto.attachment.AuditModelID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditid(value);
      break;
    case 2:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditModelID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditModelID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditModelID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string AuditID = 1;
 * @return {string}
 */
proto.attachment.AuditModelID.prototype.getAuditid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditModelID} returns this
 */
proto.attachment.AuditModelID.prototype.setAuditid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.MODEL_TYPE ModelType = 2;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.AuditModelID.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.AuditModelID} returns this
 */
proto.attachment.AuditModelID.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string ModelID = 3;
 * @return {string}
 */
proto.attachment.AuditModelID.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditModelID} returns this
 */
proto.attachment.AuditModelID.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AuditModelIDs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditModelIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditModelIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditModelIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    proto.attachment.AuditModelID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditModelIDs}
 */
proto.attachment.AuditModelIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditModelIDs;
  return proto.attachment.AuditModelIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditModelIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditModelIDs}
 */
proto.attachment.AuditModelIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AuditModelID;
      reader.readMessage(value,proto.attachment.AuditModelID.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditModelIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditModelIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditModelIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.AuditModelID.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditModelID IDs = 1;
 * @return {!Array<!proto.attachment.AuditModelID>}
 */
proto.attachment.AuditModelIDs.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.attachment.AuditModelID>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.AuditModelID, 1));
};


/**
 * @param {!Array<!proto.attachment.AuditModelID>} value
 * @return {!proto.attachment.AuditModelIDs} returns this
*/
proto.attachment.AuditModelIDs.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.AuditModelID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.AuditModelID}
 */
proto.attachment.AuditModelIDs.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.AuditModelID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AuditModelIDs} returns this
 */
proto.attachment.AuditModelIDs.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AuditPeriods.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditPeriods.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditPeriods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditPeriods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriods.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodsList: jspb.Message.toObjectList(msg.getPeriodsList(),
    proto.attachment.AuditPeriod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditPeriods}
 */
proto.attachment.AuditPeriods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditPeriods;
  return proto.attachment.AuditPeriods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditPeriods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditPeriods}
 */
proto.attachment.AuditPeriods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AuditPeriod;
      reader.readMessage(value,proto.attachment.AuditPeriod.deserializeBinaryFromReader);
      msg.addPeriods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditPeriods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditPeriods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditPeriods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.AuditPeriod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditPeriod Periods = 1;
 * @return {!Array<!proto.attachment.AuditPeriod>}
 */
proto.attachment.AuditPeriods.prototype.getPeriodsList = function() {
  return /** @type{!Array<!proto.attachment.AuditPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.AuditPeriod, 1));
};


/**
 * @param {!Array<!proto.attachment.AuditPeriod>} value
 * @return {!proto.attachment.AuditPeriods} returns this
*/
proto.attachment.AuditPeriods.prototype.setPeriodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.AuditPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.AuditPeriod}
 */
proto.attachment.AuditPeriods.prototype.addPeriods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.AuditPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AuditPeriods} returns this
 */
proto.attachment.AuditPeriods.prototype.clearPeriodsList = function() {
  return this.setPeriodsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IncludeModelIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IncludeModelIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IncludeModelIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IncludeModelIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    includemodelids: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IncludeModelIDs}
 */
proto.attachment.IncludeModelIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IncludeModelIDs;
  return proto.attachment.IncludeModelIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IncludeModelIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IncludeModelIDs}
 */
proto.attachment.IncludeModelIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludemodelids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IncludeModelIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IncludeModelIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IncludeModelIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IncludeModelIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludemodelids();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool IncludeModelIDs = 1;
 * @return {boolean}
 */
proto.attachment.IncludeModelIDs.prototype.getIncludemodelids = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.IncludeModelIDs} returns this
 */
proto.attachment.IncludeModelIDs.prototype.setIncludemodelids = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.CloseAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.CloseAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.CloseAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CloseAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditperiodid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.CloseAudit}
 */
proto.attachment.CloseAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.CloseAudit;
  return proto.attachment.CloseAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.CloseAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.CloseAudit}
 */
proto.attachment.CloseAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditperiodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelversion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.CloseAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.CloseAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.CloseAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CloseAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditperiodid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string AuditPeriodID = 1;
 * @return {string}
 */
proto.attachment.CloseAudit.prototype.getAuditperiodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CloseAudit} returns this
 */
proto.attachment.CloseAudit.prototype.setAuditperiodid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ModelVersion = 2;
 * @return {string}
 */
proto.attachment.CloseAudit.prototype.getModelversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CloseAudit} returns this
 */
proto.attachment.CloseAudit.prototype.setModelversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 EndTime = 3;
 * @return {number}
 */
proto.attachment.CloseAudit.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.CloseAudit} returns this
 */
proto.attachment.CloseAudit.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.CompleteAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.CompleteAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.CompleteAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CompleteAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditperiodid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.CompleteAudit}
 */
proto.attachment.CompleteAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.CompleteAudit;
  return proto.attachment.CompleteAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.CompleteAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.CompleteAudit}
 */
proto.attachment.CompleteAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditperiodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.CompleteAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.CompleteAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.CompleteAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CompleteAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditperiodid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string AuditPeriodID = 1;
 * @return {string}
 */
proto.attachment.CompleteAudit.prototype.getAuditperiodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CompleteAudit} returns this
 */
proto.attachment.CompleteAudit.prototype.setAuditperiodid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Auditor = 2;
 * @return {string}
 */
proto.attachment.CompleteAudit.prototype.getAuditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CompleteAudit} returns this
 */
proto.attachment.CompleteAudit.prototype.setAuditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Notes = 3;
 * @return {string}
 */
proto.attachment.CompleteAudit.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CompleteAudit} returns this
 */
proto.attachment.CompleteAudit.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.TimeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.TimeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.TimeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.TimeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    begintime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.TimeQuery}
 */
proto.attachment.TimeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.TimeQuery;
  return proto.attachment.TimeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.TimeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.TimeQuery}
 */
proto.attachment.TimeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.TimeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.TimeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.TimeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.TimeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 BeginTime = 1;
 * @return {number}
 */
proto.attachment.TimeQuery.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.TimeQuery} returns this
 */
proto.attachment.TimeQuery.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 EndTime = 2;
 * @return {number}
 */
proto.attachment.TimeQuery.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.TimeQuery} returns this
 */
proto.attachment.TimeQuery.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ModelTypeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ModelTypeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ModelTypeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelTypeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    modeltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    begintime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    excludebody: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ModelTypeQuery}
 */
proto.attachment.ModelTypeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ModelTypeQuery;
  return proto.attachment.ModelTypeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ModelTypeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ModelTypeQuery}
 */
proto.attachment.ModelTypeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludebody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ModelTypeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ModelTypeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ModelTypeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelTypeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExcludebody();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional common.MODEL_TYPE ModelType = 1;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.ModelTypeQuery.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 BeginTime = 2;
 * @return {number}
 */
proto.attachment.ModelTypeQuery.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 EndTime = 3;
 * @return {number}
 */
proto.attachment.ModelTypeQuery.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool ExcludeBody = 4;
 * @return {boolean}
 */
proto.attachment.ModelTypeQuery.prototype.getExcludebody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setExcludebody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DocumentTypeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DocumentTypeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DocumentTypeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentTypeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    documenttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    excludebody: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DocumentTypeQuery}
 */
proto.attachment.DocumentTypeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DocumentTypeQuery;
  return proto.attachment.DocumentTypeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DocumentTypeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DocumentTypeQuery}
 */
proto.attachment.DocumentTypeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.attachment.DOCUMENT_TYPE} */ (reader.readEnum());
      msg.setDocumenttype(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludebody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DocumentTypeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DocumentTypeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DocumentTypeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentTypeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExcludebody();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional DOCUMENT_TYPE DocumentType = 1;
 * @return {!proto.attachment.DOCUMENT_TYPE}
 */
proto.attachment.DocumentTypeQuery.prototype.getDocumenttype = function() {
  return /** @type {!proto.attachment.DOCUMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.attachment.DOCUMENT_TYPE} value
 * @return {!proto.attachment.DocumentTypeQuery} returns this
 */
proto.attachment.DocumentTypeQuery.prototype.setDocumenttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool ExcludeBody = 2;
 * @return {boolean}
 */
proto.attachment.DocumentTypeQuery.prototype.getExcludebody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.DocumentTypeQuery} returns this
 */
proto.attachment.DocumentTypeQuery.prototype.setExcludebody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.IDsQuery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IDsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IDsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IDsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectidsList: jspb.Message.toObjectList(msg.getSubjectidsList(),
    common_model_pb.Identifier.toObject, includeInstance),
    begintime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    excludebody: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IDsQuery}
 */
proto.attachment.IDsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IDsQuery;
  return proto.attachment.IDsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IDsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IDsQuery}
 */
proto.attachment.IDsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.addSubjectids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludebody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IDsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IDsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IDsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExcludebody();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * repeated common.Identifier SubjectIDs = 1;
 * @return {!Array<!proto.common.Identifier>}
 */
proto.attachment.IDsQuery.prototype.getSubjectidsList = function() {
  return /** @type{!Array<!proto.common.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {!Array<!proto.common.Identifier>} value
 * @return {!proto.attachment.IDsQuery} returns this
*/
proto.attachment.IDsQuery.prototype.setSubjectidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Identifier}
 */
proto.attachment.IDsQuery.prototype.addSubjectids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.clearSubjectidsList = function() {
  return this.setSubjectidsList([]);
};


/**
 * optional int64 BeginTime = 2;
 * @return {number}
 */
proto.attachment.IDsQuery.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 EndTime = 3;
 * @return {number}
 */
proto.attachment.IDsQuery.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool ExcludeBody = 4;
 * @return {boolean}
 */
proto.attachment.IDsQuery.prototype.getExcludebody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.setExcludebody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IDCount.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IDCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IDCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    modeltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    modelid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IDCount}
 */
proto.attachment.IDCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IDCount;
  return proto.attachment.IDCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IDCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IDCount}
 */
proto.attachment.IDCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IDCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IDCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IDCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional common.MODEL_TYPE ModelType = 1;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.IDCount.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.IDCount} returns this
 */
proto.attachment.IDCount.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string ModelID = 2;
 * @return {string}
 */
proto.attachment.IDCount.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.IDCount} returns this
 */
proto.attachment.IDCount.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 Count = 3;
 * @return {number}
 */
proto.attachment.IDCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.IDCount} returns this
 */
proto.attachment.IDCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.IDCounts.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IDCounts.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IDCounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IDCounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    countsList: jspb.Message.toObjectList(msg.getCountsList(),
    proto.attachment.IDCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IDCounts}
 */
proto.attachment.IDCounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IDCounts;
  return proto.attachment.IDCounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IDCounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IDCounts}
 */
proto.attachment.IDCounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.IDCount;
      reader.readMessage(value,proto.attachment.IDCount.deserializeBinaryFromReader);
      msg.addCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IDCounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IDCounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IDCounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.IDCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IDCount Counts = 1;
 * @return {!Array<!proto.attachment.IDCount>}
 */
proto.attachment.IDCounts.prototype.getCountsList = function() {
  return /** @type{!Array<!proto.attachment.IDCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.IDCount, 1));
};


/**
 * @param {!Array<!proto.attachment.IDCount>} value
 * @return {!proto.attachment.IDCounts} returns this
*/
proto.attachment.IDCounts.prototype.setCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.IDCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.IDCount}
 */
proto.attachment.IDCounts.prototype.addCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.IDCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.IDCounts} returns this
 */
proto.attachment.IDCounts.prototype.clearCountsList = function() {
  return this.setCountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LogEntryCount.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LogEntryCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LogEntryCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntryCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LogEntryCount}
 */
proto.attachment.LogEntryCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LogEntryCount;
  return proto.attachment.LogEntryCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LogEntryCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LogEntryCount}
 */
proto.attachment.LogEntryCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LogEntryCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LogEntryCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LogEntryCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntryCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 Count = 1;
 * @return {number}
 */
proto.attachment.LogEntryCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LogEntryCount} returns this
 */
proto.attachment.LogEntryCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceBodyByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    format: jspb.Message.getFieldWithDefault(msg, 2, 0),
    evidenceTestResultId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceBodyByOIDRequest;
  return proto.attachment.GetEvidenceBodyByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceTestResultId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceBodyByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEvidenceTestResultId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT = {
  CSV: 0,
  MARKDOWN: 1,
  PROTOBUF: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest} returns this
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FORMAT format = 2;
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.getFormat = function() {
  return /** @type {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest} returns this
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string evidence_test_result_id = 3;
 * @return {string}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.getEvidenceTestResultId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest} returns this
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.setEvidenceTestResultId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceBodyByOIDResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceBodyByOIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceBodyByOIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidence: (f = msg.getEvidence()) && common_payload_pb.Payload.toObject(includeInstance, f),
    exclusionsList: jspb.Message.toObjectList(msg.getExclusionsList(),
    proto.attachment.Excluded.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceBodyByOIDResponse;
  return proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceBodyByOIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_payload_pb.Payload;
      reader.readMessage(value,common_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setEvidence(value);
      break;
    case 2:
      var value = new proto.attachment.Excluded;
      reader.readMessage(value,proto.attachment.Excluded.deserializeBinaryFromReader);
      msg.addExclusions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceBodyByOIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceBodyByOIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_payload_pb.Payload.serializeBinaryToWriter
    );
  }
  f = message.getExclusionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.attachment.Excluded.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Payload evidence = 1;
 * @return {?proto.common.Payload}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.getEvidence = function() {
  return /** @type{?proto.common.Payload} */ (
    jspb.Message.getWrapperField(this, common_payload_pb.Payload, 1));
};


/**
 * @param {?proto.common.Payload|undefined} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
*/
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.setEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.clearEvidence = function() {
  return this.setEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.hasEvidence = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Excluded exclusions = 2;
 * @return {!Array<!proto.attachment.Excluded>}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.getExclusionsList = function() {
  return /** @type{!Array<!proto.attachment.Excluded>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Excluded, 2));
};


/**
 * @param {!Array<!proto.attachment.Excluded>} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
*/
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.setExclusionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.attachment.Excluded=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.addExclusions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.attachment.Excluded, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.clearExclusionsList = function() {
  return this.setExclusionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetLatestEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetLatestEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeRange: (f = msg.getTimeRange()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    documentRequestId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetLatestEvidenceRequest}
 */
proto.attachment.GetLatestEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetLatestEvidenceRequest;
  return proto.attachment.GetLatestEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetLatestEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetLatestEvidenceRequest}
 */
proto.attachment.GetLatestEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentRequestId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetLatestEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetLatestEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getDocumentRequestId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.TimeRange time_range = 2;
 * @return {?proto.common.TimeRange}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 2));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
*/
proto.attachment.GetLatestEvidenceRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string document_request_id = 3;
 * @return {string}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getDocumentRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.setDocumentRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue audit_id = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
*/
proto.attachment.GetLatestEvidenceRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AutomatedEvidenceFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AutomatedEvidenceFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AutomatedEvidenceFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AutomatedEvidenceFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AutomatedEvidenceFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AutomatedEvidenceFilter}
 */
proto.attachment.AutomatedEvidenceFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AutomatedEvidenceFilter;
  return proto.attachment.AutomatedEvidenceFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AutomatedEvidenceFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AutomatedEvidenceFilter}
 */
proto.attachment.AutomatedEvidenceFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEvidenceIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AutomatedEvidenceFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AutomatedEvidenceFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AutomatedEvidenceFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AutomatedEvidenceFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string evidence_ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.AutomatedEvidenceFilter.prototype.getEvidenceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.AutomatedEvidenceFilter} returns this
 */
proto.attachment.AutomatedEvidenceFilter.prototype.setEvidenceIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.AutomatedEvidenceFilter} returns this
 */
proto.attachment.AutomatedEvidenceFilter.prototype.addEvidenceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AutomatedEvidenceFilter} returns this
 */
proto.attachment.AutomatedEvidenceFilter.prototype.clearEvidenceIdsList = function() {
  return this.setEvidenceIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.FileTypeFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.FileTypeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.FileTypeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.FileTypeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.FileTypeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    mimeTypesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.FileTypeFilter}
 */
proto.attachment.FileTypeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.FileTypeFilter;
  return proto.attachment.FileTypeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.FileTypeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.FileTypeFilter}
 */
proto.attachment.FileTypeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addMimeTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.FileTypeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.FileTypeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.FileTypeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.FileTypeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMimeTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string mime_types = 1;
 * @return {!Array<string>}
 */
proto.attachment.FileTypeFilter.prototype.getMimeTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.FileTypeFilter} returns this
 */
proto.attachment.FileTypeFilter.prototype.setMimeTypesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.FileTypeFilter} returns this
 */
proto.attachment.FileTypeFilter.prototype.addMimeTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.FileTypeFilter} returns this
 */
proto.attachment.FileTypeFilter.prototype.clearMimeTypesList = function() {
  return this.setMimeTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceTypeFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceTypeFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceTypeFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceTypeFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    automatedEvidenceFilter: (f = msg.getAutomatedEvidenceFilter()) && proto.attachment.AutomatedEvidenceFilter.toObject(includeInstance, f),
    fileTypeFilter: (f = msg.getFileTypeFilter()) && proto.attachment.FileTypeFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceTypeFilter}
 */
proto.attachment.EvidenceTypeFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceTypeFilter;
  return proto.attachment.EvidenceTypeFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceTypeFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceTypeFilter}
 */
proto.attachment.EvidenceTypeFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AutomatedEvidenceFilter;
      reader.readMessage(value,proto.attachment.AutomatedEvidenceFilter.deserializeBinaryFromReader);
      msg.setAutomatedEvidenceFilter(value);
      break;
    case 2:
      var value = new proto.attachment.FileTypeFilter;
      reader.readMessage(value,proto.attachment.FileTypeFilter.deserializeBinaryFromReader);
      msg.setFileTypeFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceTypeFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceTypeFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceTypeFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceTypeFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAutomatedEvidenceFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.AutomatedEvidenceFilter.serializeBinaryToWriter
    );
  }
  f = message.getFileTypeFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.attachment.FileTypeFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutomatedEvidenceFilter automated_evidence_filter = 1;
 * @return {?proto.attachment.AutomatedEvidenceFilter}
 */
proto.attachment.EvidenceTypeFilter.prototype.getAutomatedEvidenceFilter = function() {
  return /** @type{?proto.attachment.AutomatedEvidenceFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AutomatedEvidenceFilter, 1));
};


/**
 * @param {?proto.attachment.AutomatedEvidenceFilter|undefined} value
 * @return {!proto.attachment.EvidenceTypeFilter} returns this
*/
proto.attachment.EvidenceTypeFilter.prototype.setAutomatedEvidenceFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceTypeFilter} returns this
 */
proto.attachment.EvidenceTypeFilter.prototype.clearAutomatedEvidenceFilter = function() {
  return this.setAutomatedEvidenceFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceTypeFilter.prototype.hasAutomatedEvidenceFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FileTypeFilter file_type_filter = 2;
 * @return {?proto.attachment.FileTypeFilter}
 */
proto.attachment.EvidenceTypeFilter.prototype.getFileTypeFilter = function() {
  return /** @type{?proto.attachment.FileTypeFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.FileTypeFilter, 2));
};


/**
 * @param {?proto.attachment.FileTypeFilter|undefined} value
 * @return {!proto.attachment.EvidenceTypeFilter} returns this
*/
proto.attachment.EvidenceTypeFilter.prototype.setFileTypeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceTypeFilter} returns this
 */
proto.attachment.EvidenceTypeFilter.prototype.clearFileTypeFilter = function() {
  return this.setFileTypeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceTypeFilter.prototype.hasFileTypeFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ControlFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ControlFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ControlFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ControlFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ControlFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ControlFilter}
 */
proto.attachment.ControlFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ControlFilter;
  return proto.attachment.ControlFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ControlFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ControlFilter}
 */
proto.attachment.ControlFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ControlFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ControlFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ControlFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ControlFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string control_ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.ControlFilter.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.ControlFilter} returns this
 */
proto.attachment.ControlFilter.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.ControlFilter} returns this
 */
proto.attachment.ControlFilter.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ControlFilter} returns this
 */
proto.attachment.ControlFilter.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ActorFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ActorFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ActorFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ActorFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ActorFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    actorsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ActorFilter}
 */
proto.attachment.ActorFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ActorFilter;
  return proto.attachment.ActorFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ActorFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ActorFilter}
 */
proto.attachment.ActorFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addActors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ActorFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ActorFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ActorFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ActorFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActorsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string actors = 1;
 * @return {!Array<string>}
 */
proto.attachment.ActorFilter.prototype.getActorsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.ActorFilter} returns this
 */
proto.attachment.ActorFilter.prototype.setActorsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.ActorFilter} returns this
 */
proto.attachment.ActorFilter.prototype.addActors = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ActorFilter} returns this
 */
proto.attachment.ActorFilter.prototype.clearActorsList = function() {
  return this.setActorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceSort.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOrder: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceSort}
 */
proto.attachment.EvidenceSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceSort;
  return proto.attachment.EvidenceSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceSort}
 */
proto.attachment.EvidenceSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.SortOrder} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.attachment.EVIDENCE_SORT_COLUMN} */ (reader.readEnum());
      msg.setSortColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortColumn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional common.SortOrder sort_order = 1;
 * @return {!proto.common.SortOrder}
 */
proto.attachment.EvidenceSort.prototype.getSortOrder = function() {
  return /** @type {!proto.common.SortOrder} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.SortOrder} value
 * @return {!proto.attachment.EvidenceSort} returns this
 */
proto.attachment.EvidenceSort.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional EVIDENCE_SORT_COLUMN sort_column = 2;
 * @return {!proto.attachment.EVIDENCE_SORT_COLUMN}
 */
proto.attachment.EvidenceSort.prototype.getSortColumn = function() {
  return /** @type {!proto.attachment.EVIDENCE_SORT_COLUMN} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.attachment.EVIDENCE_SORT_COLUMN} value
 * @return {!proto.attachment.EvidenceSort} returns this
 */
proto.attachment.EvidenceSort.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetLatestEvidenceGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetLatestEvidenceGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dateRangeFilter: (f = msg.getDateRangeFilter()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    controlFilter: (f = msg.getControlFilter()) && proto.attachment.ControlFilter.toObject(includeInstance, f),
    actorFilter: (f = msg.getActorFilter()) && proto.attachment.ActorFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.attachment.EvidenceSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && common_pagination_pb.PaginationConfig.toObject(includeInstance, f),
    evidenceTypeFilter: (f = msg.getEvidenceTypeFilter()) && proto.attachment.EvidenceTypeFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetLatestEvidenceGroupsRequest;
  return proto.attachment.GetLatestEvidenceGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetLatestEvidenceGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 4:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setDateRangeFilter(value);
      break;
    case 5:
      var value = new proto.attachment.ControlFilter;
      reader.readMessage(value,proto.attachment.ControlFilter.deserializeBinaryFromReader);
      msg.setControlFilter(value);
      break;
    case 6:
      var value = new proto.attachment.ActorFilter;
      reader.readMessage(value,proto.attachment.ActorFilter.deserializeBinaryFromReader);
      msg.setActorFilter(value);
      break;
    case 7:
      var value = new proto.attachment.EvidenceSort;
      reader.readMessage(value,proto.attachment.EvidenceSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 8:
      var value = new common_pagination_pb.PaginationConfig;
      reader.readMessage(value,common_pagination_pb.PaginationConfig.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 9:
      var value = new proto.attachment.EvidenceTypeFilter;
      reader.readMessage(value,proto.attachment.EvidenceTypeFilter.deserializeBinaryFromReader);
      msg.setEvidenceTypeFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetLatestEvidenceGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetLatestEvidenceGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDateRangeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getControlFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.attachment.ControlFilter.serializeBinaryToWriter
    );
  }
  f = message.getActorFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.attachment.ActorFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.attachment.EvidenceSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      common_pagination_pb.PaginationConfig.serializeBinaryToWriter
    );
  }
  f = message.getEvidenceTypeFilter();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.attachment.EvidenceTypeFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.TimeRange date_range_filter = 4;
 * @return {?proto.common.TimeRange}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getDateRangeFilter = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 4));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setDateRangeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearDateRangeFilter = function() {
  return this.setDateRangeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasDateRangeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ControlFilter control_filter = 5;
 * @return {?proto.attachment.ControlFilter}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getControlFilter = function() {
  return /** @type{?proto.attachment.ControlFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.ControlFilter, 5));
};


/**
 * @param {?proto.attachment.ControlFilter|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setControlFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearControlFilter = function() {
  return this.setControlFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasControlFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ActorFilter actor_filter = 6;
 * @return {?proto.attachment.ActorFilter}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getActorFilter = function() {
  return /** @type{?proto.attachment.ActorFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.ActorFilter, 6));
};


/**
 * @param {?proto.attachment.ActorFilter|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setActorFilter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearActorFilter = function() {
  return this.setActorFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasActorFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EvidenceSort sort = 7;
 * @return {?proto.attachment.EvidenceSort}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getSort = function() {
  return /** @type{?proto.attachment.EvidenceSort} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceSort, 7));
};


/**
 * @param {?proto.attachment.EvidenceSort|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional common.PaginationConfig pagination = 8;
 * @return {?proto.common.PaginationConfig}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getPagination = function() {
  return /** @type{?proto.common.PaginationConfig} */ (
    jspb.Message.getWrapperField(this, common_pagination_pb.PaginationConfig, 8));
};


/**
 * @param {?proto.common.PaginationConfig|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EvidenceTypeFilter evidence_type_filter = 9;
 * @return {?proto.attachment.EvidenceTypeFilter}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.getEvidenceTypeFilter = function() {
  return /** @type{?proto.attachment.EvidenceTypeFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceTypeFilter, 9));
};


/**
 * @param {?proto.attachment.EvidenceTypeFilter|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
*/
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.setEvidenceTypeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceGroupsRequest} returns this
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.clearEvidenceTypeFilter = function() {
  return this.setEvidenceTypeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsRequest.prototype.hasEvidenceTypeFilter = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.Excluded.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Excluded.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Excluded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Excluded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Excluded.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instanceNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.Excluded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Excluded;
  return proto.attachment.Excluded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Excluded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.Excluded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addInstanceNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Excluded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Excluded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Excluded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Excluded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstanceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.attachment.Excluded.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string instance_names = 2;
 * @return {!Array<string>}
 */
proto.attachment.Excluded.prototype.getInstanceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.setInstanceNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.addInstanceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.clearInstanceNamesList = function() {
  return this.setInstanceNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AllExclusions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AllExclusions.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AllExclusions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AllExclusions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AllExclusions.toObject = function(includeInstance, msg) {
  var f, obj = {
    exclusionsList: jspb.Message.toObjectList(msg.getExclusionsList(),
    proto.attachment.Excluded.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AllExclusions}
 */
proto.attachment.AllExclusions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AllExclusions;
  return proto.attachment.AllExclusions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AllExclusions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AllExclusions}
 */
proto.attachment.AllExclusions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Excluded;
      reader.readMessage(value,proto.attachment.Excluded.deserializeBinaryFromReader);
      msg.addExclusions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AllExclusions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AllExclusions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AllExclusions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AllExclusions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExclusionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.Excluded.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Excluded exclusions = 1;
 * @return {!Array<!proto.attachment.Excluded>}
 */
proto.attachment.AllExclusions.prototype.getExclusionsList = function() {
  return /** @type{!Array<!proto.attachment.Excluded>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Excluded, 1));
};


/**
 * @param {!Array<!proto.attachment.Excluded>} value
 * @return {!proto.attachment.AllExclusions} returns this
*/
proto.attachment.AllExclusions.prototype.setExclusionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.Excluded=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.AllExclusions.prototype.addExclusions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.Excluded, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AllExclusions} returns this
 */
proto.attachment.AllExclusions.prototype.clearExclusionsList = function() {
  return this.setExclusionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceDiscoveryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceDiscoveryRequest}
 */
proto.attachment.GetEvidenceDiscoveryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceDiscoveryRequest;
  return proto.attachment.GetEvidenceDiscoveryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceDiscoveryRequest}
 */
proto.attachment.GetEvidenceDiscoveryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceDiscoveryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string evidence_id = 1;
 * @return {string}
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.getEvidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetEvidenceDiscoveryRequest} returns this
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.setEvidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceDiscoveryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceDiscoveryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceDiscoveryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceDiscoveriesList: jspb.Message.toObjectList(msg.getServiceDiscoveriesList(),
    proto.attachment.ServiceDiscovery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse}
 */
proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceDiscoveryResponse;
  return proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceDiscoveryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse}
 */
proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.ServiceDiscovery;
      reader.readMessage(value,proto.attachment.ServiceDiscovery.deserializeBinaryFromReader);
      msg.addServiceDiscoveries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceDiscoveryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceDiscoveryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceDiscoveriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.ServiceDiscovery.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceDiscovery service_discoveries = 1;
 * @return {!Array<!proto.attachment.ServiceDiscovery>}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.getServiceDiscoveriesList = function() {
  return /** @type{!Array<!proto.attachment.ServiceDiscovery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.ServiceDiscovery, 1));
};


/**
 * @param {!Array<!proto.attachment.ServiceDiscovery>} value
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse} returns this
*/
proto.attachment.GetEvidenceDiscoveryResponse.prototype.setServiceDiscoveriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.ServiceDiscovery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.ServiceDiscovery}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.addServiceDiscoveries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.ServiceDiscovery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse} returns this
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.clearServiceDiscoveriesList = function() {
  return this.setServiceDiscoveriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ServiceDiscovery.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ServiceDiscovery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ServiceDiscovery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ServiceDiscovery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ServiceDiscovery.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiCallsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    discovery: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ServiceDiscovery}
 */
proto.attachment.ServiceDiscovery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ServiceDiscovery;
  return proto.attachment.ServiceDiscovery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ServiceDiscovery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ServiceDiscovery}
 */
proto.attachment.ServiceDiscovery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addApiCalls(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscovery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ServiceDiscovery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ServiceDiscovery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ServiceDiscovery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ServiceDiscovery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiCallsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDiscovery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.attachment.ServiceDiscovery.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string api_calls = 2;
 * @return {!Array<string>}
 */
proto.attachment.ServiceDiscovery.prototype.getApiCallsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.setApiCallsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.addApiCalls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.clearApiCallsList = function() {
  return this.setApiCallsList([]);
};


/**
 * optional string discovery = 3;
 * @return {string}
 */
proto.attachment.ServiceDiscovery.prototype.getDiscovery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.setDiscovery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: (f = msg.getCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    timeRange: (f = msg.getTimeRange()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    documentRequestId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListEvidenceRequest}
 */
proto.attachment.ListEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListEvidenceRequest;
  return proto.attachment.ListEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListEvidenceRequest}
 */
proto.attachment.ListEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCaption(value);
      break;
    case 3:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentRequestId(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getDocumentRequestId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.attachment.ListEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue caption = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.ListEvidenceRequest.prototype.getCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
*/
proto.attachment.ListEvidenceRequest.prototype.setCaption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.clearCaption = function() {
  return this.setCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceRequest.prototype.hasCaption = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.TimeRange time_range = 3;
 * @return {?proto.common.TimeRange}
 */
proto.attachment.ListEvidenceRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 3));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
*/
proto.attachment.ListEvidenceRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string document_request_id = 4;
 * @return {string}
 */
proto.attachment.ListEvidenceRequest.prototype.getDocumentRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.setDocumentRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue audit_id = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.ListEvidenceRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
*/
proto.attachment.ListEvidenceRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListEvidenceGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListEvidenceGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: (f = msg.getCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    dateRangeFilter: (f = msg.getDateRangeFilter()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    controlFilter: (f = msg.getControlFilter()) && proto.attachment.ControlFilter.toObject(includeInstance, f),
    actorFilter: (f = msg.getActorFilter()) && proto.attachment.ActorFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.attachment.EvidenceSort.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    evidenceTypeFilter: (f = msg.getEvidenceTypeFilter()) && proto.attachment.EvidenceTypeFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListEvidenceGroupsRequest}
 */
proto.attachment.ListEvidenceGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListEvidenceGroupsRequest;
  return proto.attachment.ListEvidenceGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListEvidenceGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListEvidenceGroupsRequest}
 */
proto.attachment.ListEvidenceGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCaption(value);
      break;
    case 4:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setDateRangeFilter(value);
      break;
    case 5:
      var value = new proto.attachment.ControlFilter;
      reader.readMessage(value,proto.attachment.ControlFilter.deserializeBinaryFromReader);
      msg.setControlFilter(value);
      break;
    case 6:
      var value = new proto.attachment.ActorFilter;
      reader.readMessage(value,proto.attachment.ActorFilter.deserializeBinaryFromReader);
      msg.setActorFilter(value);
      break;
    case 7:
      var value = new proto.attachment.EvidenceSort;
      reader.readMessage(value,proto.attachment.EvidenceSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 9:
      var value = new proto.attachment.EvidenceTypeFilter;
      reader.readMessage(value,proto.attachment.EvidenceTypeFilter.deserializeBinaryFromReader);
      msg.setEvidenceTypeFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListEvidenceGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListEvidenceGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDateRangeFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getControlFilter();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.attachment.ControlFilter.serializeBinaryToWriter
    );
  }
  f = message.getActorFilter();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.attachment.ActorFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.attachment.EvidenceSort.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEvidenceTypeFilter();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.attachment.EvidenceTypeFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue caption = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setCaption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearCaption = function() {
  return this.setCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasCaption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.TimeRange date_range_filter = 4;
 * @return {?proto.common.TimeRange}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getDateRangeFilter = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 4));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setDateRangeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearDateRangeFilter = function() {
  return this.setDateRangeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasDateRangeFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ControlFilter control_filter = 5;
 * @return {?proto.attachment.ControlFilter}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getControlFilter = function() {
  return /** @type{?proto.attachment.ControlFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.ControlFilter, 5));
};


/**
 * @param {?proto.attachment.ControlFilter|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setControlFilter = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearControlFilter = function() {
  return this.setControlFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasControlFilter = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ActorFilter actor_filter = 6;
 * @return {?proto.attachment.ActorFilter}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getActorFilter = function() {
  return /** @type{?proto.attachment.ActorFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.ActorFilter, 6));
};


/**
 * @param {?proto.attachment.ActorFilter|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setActorFilter = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearActorFilter = function() {
  return this.setActorFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasActorFilter = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional EvidenceSort sort = 7;
 * @return {?proto.attachment.EvidenceSort}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getSort = function() {
  return /** @type{?proto.attachment.EvidenceSort} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceSort, 7));
};


/**
 * @param {?proto.attachment.EvidenceSort|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional EvidenceTypeFilter evidence_type_filter = 9;
 * @return {?proto.attachment.EvidenceTypeFilter}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.getEvidenceTypeFilter = function() {
  return /** @type{?proto.attachment.EvidenceTypeFilter} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceTypeFilter, 9));
};


/**
 * @param {?proto.attachment.EvidenceTypeFilter|undefined} value
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
*/
proto.attachment.ListEvidenceGroupsRequest.prototype.setEvidenceTypeFilter = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceGroupsRequest} returns this
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.clearEvidenceTypeFilter = function() {
  return this.setEvidenceTypeFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceGroupsRequest.prototype.hasEvidenceTypeFilter = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ListEvidenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListEvidenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListEvidenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListEvidenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.Evidence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListEvidenceResponse}
 */
proto.attachment.ListEvidenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListEvidenceResponse;
  return proto.attachment.ListEvidenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListEvidenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListEvidenceResponse}
 */
proto.attachment.ListEvidenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Evidence;
      reader.readMessage(value,proto.attachment.Evidence.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListEvidenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListEvidenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListEvidenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.Evidence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Evidence items = 1;
 * @return {!Array<!proto.attachment.Evidence>}
 */
proto.attachment.ListEvidenceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.Evidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Evidence, 1));
};


/**
 * @param {!Array<!proto.attachment.Evidence>} value
 * @return {!proto.attachment.ListEvidenceResponse} returns this
*/
proto.attachment.ListEvidenceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.Evidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Evidence}
 */
proto.attachment.ListEvidenceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.Evidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ListEvidenceResponse} returns this
 */
proto.attachment.ListEvidenceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ListEvidenceGroupsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListEvidenceGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListEvidenceGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.EvidenceGroup.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListEvidenceGroupsResponse}
 */
proto.attachment.ListEvidenceGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListEvidenceGroupsResponse;
  return proto.attachment.ListEvidenceGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListEvidenceGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListEvidenceGroupsResponse}
 */
proto.attachment.ListEvidenceGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroup;
      reader.readMessage(value,proto.attachment.EvidenceGroup.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListEvidenceGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListEvidenceGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.EvidenceGroup.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated EvidenceGroup items = 1;
 * @return {!Array<!proto.attachment.EvidenceGroup>}
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.EvidenceGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.EvidenceGroup, 1));
};


/**
 * @param {!Array<!proto.attachment.EvidenceGroup>} value
 * @return {!proto.attachment.ListEvidenceGroupsResponse} returns this
*/
proto.attachment.ListEvidenceGroupsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.EvidenceGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.EvidenceGroup}
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.EvidenceGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ListEvidenceGroupsResponse} returns this
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.ListEvidenceGroupsResponse} returns this
 */
proto.attachment.ListEvidenceGroupsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetLatestEvidenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetLatestEvidenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetLatestEvidenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.LatestEvidence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetLatestEvidenceResponse}
 */
proto.attachment.GetLatestEvidenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetLatestEvidenceResponse;
  return proto.attachment.GetLatestEvidenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetLatestEvidenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetLatestEvidenceResponse}
 */
proto.attachment.GetLatestEvidenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LatestEvidence;
      reader.readMessage(value,proto.attachment.LatestEvidence.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetLatestEvidenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetLatestEvidenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LatestEvidence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LatestEvidence items = 1;
 * @return {!Array<!proto.attachment.LatestEvidence>}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.LatestEvidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LatestEvidence, 1));
};


/**
 * @param {!Array<!proto.attachment.LatestEvidence>} value
 * @return {!proto.attachment.GetLatestEvidenceResponse} returns this
*/
proto.attachment.GetLatestEvidenceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LatestEvidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LatestEvidence}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LatestEvidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetLatestEvidenceResponse} returns this
 */
proto.attachment.GetLatestEvidenceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetLatestEvidenceGroupsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetLatestEvidenceGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetLatestEvidenceGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.LatestEvidenceGroup.toObject, includeInstance),
    latestGroupIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    hasEvidence: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetLatestEvidenceGroupsResponse;
  return proto.attachment.GetLatestEvidenceGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetLatestEvidenceGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LatestEvidenceGroup;
      reader.readMessage(value,proto.attachment.LatestEvidenceGroup.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addLatestGroupIds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasEvidence(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetLatestEvidenceGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetLatestEvidenceGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LatestEvidenceGroup.serializeBinaryToWriter
    );
  }
  f = message.getLatestGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getHasEvidence();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated LatestEvidenceGroup items = 1;
 * @return {!Array<!proto.attachment.LatestEvidenceGroup>}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.LatestEvidenceGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LatestEvidenceGroup, 1));
};


/**
 * @param {!Array<!proto.attachment.LatestEvidenceGroup>} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse} returns this
*/
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LatestEvidenceGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LatestEvidenceGroup}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LatestEvidenceGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse} returns this
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated string latest_group_ids = 2;
 * @return {!Array<string>}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.getLatestGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse} returns this
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.setLatestGroupIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse} returns this
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.addLatestGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse} returns this
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.clearLatestGroupIdsList = function() {
  return this.setLatestGroupIdsList([]);
};


/**
 * optional bool has_evidence = 3;
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.getHasEvidence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.GetLatestEvidenceGroupsResponse} returns this
 */
proto.attachment.GetLatestEvidenceGroupsResponse.prototype.setHasEvidence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ModelEvidenceGenerator.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ModelEvidenceGenerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ModelEvidenceGenerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelEvidenceGenerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGeneratorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    controlModelIdentifier: (f = msg.getControlModelIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ModelEvidenceGenerator}
 */
proto.attachment.ModelEvidenceGenerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ModelEvidenceGenerator;
  return proto.attachment.ModelEvidenceGenerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ModelEvidenceGenerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ModelEvidenceGenerator}
 */
proto.attachment.ModelEvidenceGenerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceGeneratorId(value);
      break;
    case 5:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setControlModelIdentifier(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ModelEvidenceGenerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ModelEvidenceGenerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ModelEvidenceGenerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelEvidenceGenerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGeneratorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getControlModelIdentifier();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string evidence_generator_id = 4;
 * @return {string}
 */
proto.attachment.ModelEvidenceGenerator.prototype.getEvidenceGeneratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
 */
proto.attachment.ModelEvidenceGenerator.prototype.setEvidenceGeneratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.Identifier control_model_identifier = 5;
 * @return {?proto.common.Identifier}
 */
proto.attachment.ModelEvidenceGenerator.prototype.getControlModelIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 5));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
*/
proto.attachment.ModelEvidenceGenerator.prototype.setControlModelIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
 */
proto.attachment.ModelEvidenceGenerator.prototype.clearControlModelIdentifier = function() {
  return this.setControlModelIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ModelEvidenceGenerator.prototype.hasControlModelIdentifier = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_enabled = 6;
 * @return {boolean}
 */
proto.attachment.ModelEvidenceGenerator.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
 */
proto.attachment.ModelEvidenceGenerator.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetModelEvidenceGeneratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetModelEvidenceGeneratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGeneratorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controlModelIdentifier: (f = msg.getControlModelIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetModelEvidenceGeneratorRequest;
  return proto.attachment.GetModelEvidenceGeneratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceGeneratorId(value);
      break;
    case 4:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setControlModelIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetModelEvidenceGeneratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetModelEvidenceGeneratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGeneratorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControlModelIdentifier();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional string evidence_generator_id = 3;
 * @return {string}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.getEvidenceGeneratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest} returns this
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.setEvidenceGeneratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.Identifier control_model_identifier = 4;
 * @return {?proto.common.Identifier}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.getControlModelIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 4));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest} returns this
*/
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.setControlModelIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest} returns this
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.clearControlModelIdentifier = function() {
  return this.setControlModelIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.hasControlModelIdentifier = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListConnectedEvidenceGeneratorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: (f = msg.getIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListConnectedEvidenceGeneratorsRequest;
  return proto.attachment.ListConnectedEvidenceGeneratorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListConnectedEvidenceGeneratorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier identifier = 1;
 * @return {?proto.common.Identifier}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.getIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} returns this
*/
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} returns this
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.clearIdentifier = function() {
  return this.setIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.hasIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool connected = 2;
 * @return {boolean}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} returns this
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LatestEvidence.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LatestEvidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LatestEvidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LatestEvidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidence: (f = msg.getEvidence()) && proto.attachment.Evidence.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LatestEvidence}
 */
proto.attachment.LatestEvidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LatestEvidence;
  return proto.attachment.LatestEvidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LatestEvidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LatestEvidence}
 */
proto.attachment.LatestEvidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Evidence;
      reader.readMessage(value,proto.attachment.Evidence.deserializeBinaryFromReader);
      msg.setEvidence(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LatestEvidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LatestEvidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LatestEvidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LatestEvidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Evidence.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Evidence evidence = 1;
 * @return {?proto.attachment.Evidence}
 */
proto.attachment.LatestEvidence.prototype.getEvidence = function() {
  return /** @type{?proto.attachment.Evidence} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Evidence, 1));
};


/**
 * @param {?proto.attachment.Evidence|undefined} value
 * @return {!proto.attachment.LatestEvidence} returns this
*/
proto.attachment.LatestEvidence.prototype.setEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LatestEvidence} returns this
 */
proto.attachment.LatestEvidence.prototype.clearEvidence = function() {
  return this.setEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LatestEvidence.prototype.hasEvidence = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.attachment.LatestEvidence.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LatestEvidence} returns this
 */
proto.attachment.LatestEvidence.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LatestEvidenceGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LatestEvidenceGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LatestEvidenceGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LatestEvidenceGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroup: (f = msg.getEvidenceGroup()) && proto.attachment.EvidenceGroup.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LatestEvidenceGroup}
 */
proto.attachment.LatestEvidenceGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LatestEvidenceGroup;
  return proto.attachment.LatestEvidenceGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LatestEvidenceGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LatestEvidenceGroup}
 */
proto.attachment.LatestEvidenceGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroup;
      reader.readMessage(value,proto.attachment.EvidenceGroup.deserializeBinaryFromReader);
      msg.setEvidenceGroup(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LatestEvidenceGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LatestEvidenceGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LatestEvidenceGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LatestEvidenceGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroup.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional EvidenceGroup evidence_group = 1;
 * @return {?proto.attachment.EvidenceGroup}
 */
proto.attachment.LatestEvidenceGroup.prototype.getEvidenceGroup = function() {
  return /** @type{?proto.attachment.EvidenceGroup} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroup, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroup|undefined} value
 * @return {!proto.attachment.LatestEvidenceGroup} returns this
*/
proto.attachment.LatestEvidenceGroup.prototype.setEvidenceGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LatestEvidenceGroup} returns this
 */
proto.attachment.LatestEvidenceGroup.prototype.clearEvidenceGroup = function() {
  return this.setEvidenceGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LatestEvidenceGroup.prototype.hasEvidenceGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.attachment.LatestEvidenceGroup.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LatestEvidenceGroup} returns this
 */
proto.attachment.LatestEvidenceGroup.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Evidence.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Evidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Evidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Evidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actorEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actorName: (f = msg.getActorName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isAutomated: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    mime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isViaRequest: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    contentId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    discoveryId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    serviceModelId: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Evidence}
 */
proto.attachment.Evidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Evidence;
  return proto.attachment.Evidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Evidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Evidence}
 */
proto.attachment.Evidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorEmail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActorName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutomated(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsViaRequest(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscoveryId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Evidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Evidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Evidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Evidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActorEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActorName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsAutomated();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsViaRequest();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDiscoveryId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.Evidence.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.Evidence} returns this
*/
proto.attachment.Evidence.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string actor_email = 4;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getActorEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setActorEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue actor_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.Evidence.prototype.getActorName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.Evidence} returns this
*/
proto.attachment.Evidence.prototype.setActorName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.clearActorName = function() {
  return this.setActorName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.hasActorName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string control_id = 6;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_automated = 7;
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.getIsAutomated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setIsAutomated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string mime = 8;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp relevant_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.Evidence.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.Evidence} returns this
*/
proto.attachment.Evidence.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_via_request = 10;
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.getIsViaRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setIsViaRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string content_id = 11;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string discovery_id = 12;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getDiscoveryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setDiscoveryId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string service_model_id = 13;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGroupId.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGroupId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGroupId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGroupId.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: (f = msg.getCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    contentId: (f = msg.getContentId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    discoveryId: (f = msg.getDiscoveryId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGroupId}
 */
proto.attachment.EvidenceGroupId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGroupId;
  return proto.attachment.EvidenceGroupId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGroupId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGroupId}
 */
proto.attachment.EvidenceGroupId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCaption(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setContentId(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDiscoveryId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGroupId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGroupId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGroupId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGroupId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getContentId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDiscoveryId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue caption = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.EvidenceGroupId.prototype.getCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.EvidenceGroupId} returns this
*/
proto.attachment.EvidenceGroupId.prototype.setCaption = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceGroupId} returns this
 */
proto.attachment.EvidenceGroupId.prototype.clearCaption = function() {
  return this.setCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceGroupId.prototype.hasCaption = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue content_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.EvidenceGroupId.prototype.getContentId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.EvidenceGroupId} returns this
*/
proto.attachment.EvidenceGroupId.prototype.setContentId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceGroupId} returns this
 */
proto.attachment.EvidenceGroupId.prototype.clearContentId = function() {
  return this.setContentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceGroupId.prototype.hasContentId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue discovery_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.EvidenceGroupId.prototype.getDiscoveryId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.EvidenceGroupId} returns this
*/
proto.attachment.EvidenceGroupId.prototype.setDiscoveryId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceGroupId} returns this
 */
proto.attachment.EvidenceGroupId.prototype.clearDiscoveryId = function() {
  return this.setDiscoveryId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceGroupId.prototype.hasDiscoveryId = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.EvidenceGroups.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGroups.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGroups.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGroups} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGroups.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.EvidenceGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGroups}
 */
proto.attachment.EvidenceGroups.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGroups;
  return proto.attachment.EvidenceGroups.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGroups} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGroups}
 */
proto.attachment.EvidenceGroups.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroup;
      reader.readMessage(value,proto.attachment.EvidenceGroup.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGroups.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGroups.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGroups} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGroups.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.EvidenceGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EvidenceGroup items = 1;
 * @return {!Array<!proto.attachment.EvidenceGroup>}
 */
proto.attachment.EvidenceGroups.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.EvidenceGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.EvidenceGroup, 1));
};


/**
 * @param {!Array<!proto.attachment.EvidenceGroup>} value
 * @return {!proto.attachment.EvidenceGroups} returns this
*/
proto.attachment.EvidenceGroups.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.EvidenceGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.EvidenceGroup}
 */
proto.attachment.EvidenceGroups.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.EvidenceGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.EvidenceGroups} returns this
 */
proto.attachment.EvidenceGroups.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actorEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actorName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isAutomated: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    mime: jspb.Message.getFieldWithDefault(msg, 7, ""),
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    contentId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    discoveryId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 12, ""),
    allExclusions: (f = msg.getAllExclusions()) && proto.attachment.AllExclusions.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    bodySize: jspb.Message.getFieldWithDefault(msg, 16, 0),
    actorId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    fileName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    serviceModelId: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGroup}
 */
proto.attachment.EvidenceGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGroup;
  return proto.attachment.EvidenceGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGroup}
 */
proto.attachment.EvidenceGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorName(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutomated(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscoveryId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 13:
      var value = new proto.attachment.AllExclusions;
      reader.readMessage(value,proto.attachment.AllExclusions.deserializeBinaryFromReader);
      msg.setAllExclusions(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBodySize(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActorEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActorName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsAutomated();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDiscoveryId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAllExclusions();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.attachment.AllExclusions.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBodySize();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getActorId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.EvidenceGroup.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.EvidenceGroup} returns this
*/
proto.attachment.EvidenceGroup.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceGroup.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string actor_email = 4;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getActorEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setActorEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actor_name = 5;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getActorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setActorName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_automated = 6;
 * @return {boolean}
 */
proto.attachment.EvidenceGroup.prototype.getIsAutomated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setIsAutomated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string mime = 7;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Timestamp relevant_date = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.EvidenceGroup.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.EvidenceGroup} returns this
*/
proto.attachment.EvidenceGroup.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceGroup.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string content_id = 9;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string discovery_id = 10;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getDiscoveryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setDiscoveryId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 12;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional AllExclusions all_exclusions = 13;
 * @return {?proto.attachment.AllExclusions}
 */
proto.attachment.EvidenceGroup.prototype.getAllExclusions = function() {
  return /** @type{?proto.attachment.AllExclusions} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AllExclusions, 13));
};


/**
 * @param {?proto.attachment.AllExclusions|undefined} value
 * @return {!proto.attachment.EvidenceGroup} returns this
*/
proto.attachment.EvidenceGroup.prototype.setAllExclusions = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.clearAllExclusions = function() {
  return this.setAllExclusions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.EvidenceGroup.prototype.hasAllExclusions = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string account_id = 14;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 body_size = 16;
 * @return {number}
 */
proto.attachment.EvidenceGroup.prototype.getBodySize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setBodySize = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string actor_id = 17;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getActorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setActorId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string file_name = 18;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string service_model_id = 19;
 * @return {string}
 */
proto.attachment.EvidenceGroup.prototype.getServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGroup} returns this
 */
proto.attachment.EvidenceGroup.prototype.setServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.EvidenceGenerators.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGenerators.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGenerators.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGenerators} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerators.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.EvidenceGenerator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGenerators}
 */
proto.attachment.EvidenceGenerators.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGenerators;
  return proto.attachment.EvidenceGenerators.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGenerators} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGenerators}
 */
proto.attachment.EvidenceGenerators.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGenerator;
      reader.readMessage(value,proto.attachment.EvidenceGenerator.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGenerators.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGenerators.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGenerators} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerators.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.EvidenceGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EvidenceGenerator items = 1;
 * @return {!Array<!proto.attachment.EvidenceGenerator>}
 */
proto.attachment.EvidenceGenerators.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.EvidenceGenerator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.EvidenceGenerator, 1));
};


/**
 * @param {!Array<!proto.attachment.EvidenceGenerator>} value
 * @return {!proto.attachment.EvidenceGenerators} returns this
*/
proto.attachment.EvidenceGenerators.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.EvidenceGenerator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.EvidenceGenerator}
 */
proto.attachment.EvidenceGenerators.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.EvidenceGenerator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.EvidenceGenerators} returns this
 */
proto.attachment.EvidenceGenerators.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGenerator.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGenerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGenerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    evidenceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receptorModelId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGenerator}
 */
proto.attachment.EvidenceGenerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGenerator;
  return proto.attachment.EvidenceGenerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGenerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGenerator}
 */
proto.attachment.EvidenceGenerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGenerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGenerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGenerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEvidenceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceptorModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.EvidenceGenerator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGenerator} returns this
 */
proto.attachment.EvidenceGenerator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string evidence_id = 3;
 * @return {string}
 */
proto.attachment.EvidenceGenerator.prototype.getEvidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGenerator} returns this
 */
proto.attachment.EvidenceGenerator.prototype.setEvidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receptor_model_id = 4;
 * @return {string}
 */
proto.attachment.EvidenceGenerator.prototype.getReceptorModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGenerator} returns this
 */
proto.attachment.EvidenceGenerator.prototype.setReceptorModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deleteAssociatedTests: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    requestId: (f = msg.getRequestId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteDocumentRequest}
 */
proto.attachment.DeleteDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteDocumentRequest;
  return proto.attachment.DeleteDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteDocumentRequest}
 */
proto.attachment.DeleteDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteAssociatedTests(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeleteAssociatedTests();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRequestId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.attachment.DeleteDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
 */
proto.attachment.DeleteDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool delete_associated_tests = 2;
 * @return {boolean}
 */
proto.attachment.DeleteDocumentRequest.prototype.getDeleteAssociatedTests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
 */
proto.attachment.DeleteDocumentRequest.prototype.setDeleteAssociatedTests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue request_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.DeleteDocumentRequest.prototype.getRequestId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
*/
proto.attachment.DeleteDocumentRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
 */
proto.attachment.DeleteDocumentRequest.prototype.clearRequestId = function() {
  return this.setRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.DeleteDocumentRequest.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.PolicyAcknowledgementFormValues.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.PolicyAcknowledgementFormValues} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.PolicyAcknowledgementFormValues.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.PolicyAcknowledgementFormValues}
 */
proto.attachment.PolicyAcknowledgementFormValues.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.PolicyAcknowledgementFormValues;
  return proto.attachment.PolicyAcknowledgementFormValues.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.PolicyAcknowledgementFormValues} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.PolicyAcknowledgementFormValues}
 */
proto.attachment.PolicyAcknowledgementFormValues.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompanyName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.PolicyAcknowledgementFormValues.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.PolicyAcknowledgementFormValues} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.PolicyAcknowledgementFormValues.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string company_name = 1;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.getCompanyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgementFormValues} returns this
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgementFormValues} returns this
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgementFormValues} returns this
 */
proto.attachment.PolicyAcknowledgementFormValues.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetPolicyAcknowledgementFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetPolicyAcknowledgementFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    override: (f = msg.getOverride()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    policyIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    includeText: (f = msg.getIncludeText()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    formData: (f = msg.getFormData()) && proto.attachment.PolicyAcknowledgementFormValues.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetPolicyAcknowledgementFormRequest;
  return proto.attachment.GetPolicyAcknowledgementFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetPolicyAcknowledgementFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOverride(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPolicyIds(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeText(value);
      break;
    case 5:
      var value = new proto.attachment.PolicyAcknowledgementFormValues;
      reader.readMessage(value,proto.attachment.PolicyAcknowledgementFormValues.deserializeBinaryFromReader);
      msg.setFormData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetPolicyAcknowledgementFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetPolicyAcknowledgementFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOverride();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPolicyIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getIncludeText();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getFormData();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.attachment.PolicyAcknowledgementFormValues.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
*/
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue override = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.getOverride = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
*/
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.setOverride = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.clearOverride = function() {
  return this.setOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.hasOverride = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated string policy_ids = 3;
 * @return {!Array<string>}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.getPolicyIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.setPolicyIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.addPolicyIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.clearPolicyIdsList = function() {
  return this.setPolicyIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_text = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.getIncludeText = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
*/
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.setIncludeText = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.clearIncludeText = function() {
  return this.setIncludeText(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.hasIncludeText = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PolicyAcknowledgementFormValues form_data = 5;
 * @return {?proto.attachment.PolicyAcknowledgementFormValues}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.getFormData = function() {
  return /** @type{?proto.attachment.PolicyAcknowledgementFormValues} */ (
    jspb.Message.getWrapperField(this, proto.attachment.PolicyAcknowledgementFormValues, 5));
};


/**
 * @param {?proto.attachment.PolicyAcknowledgementFormValues|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
*/
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.setFormData = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.clearFormData = function() {
  return this.setFormData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgementFormRequest.prototype.hasFormData = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetPolicyAcknowledgementFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetPolicyAcknowledgementFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    missingdocuments: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    form: (f = msg.getForm()) && proto.attachment.PolicyAcknowledgementForm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormResponse}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetPolicyAcknowledgementFormResponse;
  return proto.attachment.GetPolicyAcknowledgementFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetPolicyAcknowledgementFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormResponse}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissingdocuments(value);
      break;
    case 2:
      var value = new proto.attachment.PolicyAcknowledgementForm;
      reader.readMessage(value,proto.attachment.PolicyAcknowledgementForm.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetPolicyAcknowledgementFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetPolicyAcknowledgementFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissingdocuments();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.attachment.PolicyAcknowledgementForm.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool missingDocuments = 1;
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.getMissingdocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormResponse} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.setMissingdocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional PolicyAcknowledgementForm form = 2;
 * @return {?proto.attachment.PolicyAcknowledgementForm}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.getForm = function() {
  return /** @type{?proto.attachment.PolicyAcknowledgementForm} */ (
    jspb.Message.getWrapperField(this, proto.attachment.PolicyAcknowledgementForm, 2));
};


/**
 * @param {?proto.attachment.PolicyAcknowledgementForm|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgementFormResponse} returns this
*/
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgementFormResponse} returns this
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgementFormResponse.prototype.hasForm = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.PolicyAcknowledgementForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.PolicyAcknowledgementForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.PolicyAcknowledgementForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: msg.getBody_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.PolicyAcknowledgementForm}
 */
proto.attachment.PolicyAcknowledgementForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.PolicyAcknowledgementForm;
  return proto.attachment.PolicyAcknowledgementForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.PolicyAcknowledgementForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.PolicyAcknowledgementForm}
 */
proto.attachment.PolicyAcknowledgementForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.PolicyAcknowledgementForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.PolicyAcknowledgementForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.PolicyAcknowledgementForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgementForm} returns this
 */
proto.attachment.PolicyAcknowledgementForm.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mime = 2;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgementForm} returns this
 */
proto.attachment.PolicyAcknowledgementForm.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes body = 3;
 * @return {!(string|Uint8Array)}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes body = 3;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes body = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.attachment.PolicyAcknowledgementForm.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.attachment.PolicyAcknowledgementForm} returns this
 */
proto.attachment.PolicyAcknowledgementForm.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetRiskRegisterCSVResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetRiskRegisterCSVResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetRiskRegisterCSVResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    csv: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetRiskRegisterCSVResponse}
 */
proto.attachment.GetRiskRegisterCSVResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetRiskRegisterCSVResponse;
  return proto.attachment.GetRiskRegisterCSVResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetRiskRegisterCSVResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetRiskRegisterCSVResponse}
 */
proto.attachment.GetRiskRegisterCSVResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetRiskRegisterCSVResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetRiskRegisterCSVResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetRiskRegisterCSVResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCsv();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string csv = 1;
 * @return {string}
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.getCsv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetRiskRegisterCSVResponse} returns this
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.setCsv = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetExclusionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetExclusionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetExclusionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceid: (f = msg.getEvidenceid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetExclusionsRequest}
 */
proto.attachment.GetExclusionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetExclusionsRequest;
  return proto.attachment.GetExclusionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetExclusionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetExclusionsRequest}
 */
proto.attachment.GetExclusionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEvidenceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetExclusionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetExclusionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetExclusionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue evidenceId = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetExclusionsRequest.prototype.getEvidenceid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetExclusionsRequest} returns this
*/
proto.attachment.GetExclusionsRequest.prototype.setEvidenceid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetExclusionsRequest} returns this
 */
proto.attachment.GetExclusionsRequest.prototype.clearEvidenceid = function() {
  return this.setEvidenceid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetExclusionsRequest.prototype.hasEvidenceid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetExclusionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetExclusionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetExclusionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    exclusions: (f = msg.getExclusions()) && proto.attachment.AllExclusions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetExclusionsResponse}
 */
proto.attachment.GetExclusionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetExclusionsResponse;
  return proto.attachment.GetExclusionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetExclusionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetExclusionsResponse}
 */
proto.attachment.GetExclusionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AllExclusions;
      reader.readMessage(value,proto.attachment.AllExclusions.deserializeBinaryFromReader);
      msg.setExclusions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetExclusionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetExclusionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetExclusionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExclusions();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.AllExclusions.serializeBinaryToWriter
    );
  }
};


/**
 * optional AllExclusions exclusions = 1;
 * @return {?proto.attachment.AllExclusions}
 */
proto.attachment.GetExclusionsResponse.prototype.getExclusions = function() {
  return /** @type{?proto.attachment.AllExclusions} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AllExclusions, 1));
};


/**
 * @param {?proto.attachment.AllExclusions|undefined} value
 * @return {!proto.attachment.GetExclusionsResponse} returns this
*/
proto.attachment.GetExclusionsResponse.prototype.setExclusions = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetExclusionsResponse} returns this
 */
proto.attachment.GetExclusionsResponse.prototype.clearExclusions = function() {
  return this.setExclusions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetExclusionsResponse.prototype.hasExclusions = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteControlEvidencesByCaptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteControlEvidencesByCaptionRequest;
  return proto.attachment.DeleteControlEvidencesByCaptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteControlEvidencesByCaptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest} returns this
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest} returns this
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LookUpChangeLogEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    attachmentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attachmentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LookUpChangeLogEntryRequest}
 */
proto.attachment.LookUpChangeLogEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LookUpChangeLogEntryRequest;
  return proto.attachment.LookUpChangeLogEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LookUpChangeLogEntryRequest}
 */
proto.attachment.LookUpChangeLogEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (reader.readEnum());
      msg.setAttachmentType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LookUpChangeLogEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttachmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAttachmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ATTACHMENT_TYPE attachment_type = 1;
 * @return {!proto.attachment.ATTACHMENT_TYPE}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.getAttachmentType = function() {
  return /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.attachment.ATTACHMENT_TYPE} value
 * @return {!proto.attachment.LookUpChangeLogEntryRequest} returns this
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.setAttachmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string attachment_id = 2;
 * @return {string}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.getAttachmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LookUpChangeLogEntryRequest} returns this
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.setAttachmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LookUpChangeLogEntryRequest} returns this
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LookUpChangeLogEntryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LookUpChangeLogEntryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFound: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LookUpChangeLogEntryResponse}
 */
proto.attachment.LookUpChangeLogEntryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LookUpChangeLogEntryResponse;
  return proto.attachment.LookUpChangeLogEntryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LookUpChangeLogEntryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LookUpChangeLogEntryResponse}
 */
proto.attachment.LookUpChangeLogEntryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LookUpChangeLogEntryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LookUpChangeLogEntryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFound();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_found = 1;
 * @return {boolean}
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.getIsFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.LookUpChangeLogEntryResponse} returns this
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.setIsFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.URL.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.URL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.URL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.URL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    headersMap: (f = msg.getHeadersMap()) ? f.toObject(includeInstance, proto.attachment.HeaderValue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.URL}
 */
proto.attachment.URL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.URL;
  return proto.attachment.URL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.URL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.URL}
 */
proto.attachment.URL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 2:
      var value = msg.getHeadersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.attachment.HeaderValue.deserializeBinaryFromReader, "", new proto.attachment.HeaderValue());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.URL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.URL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.URL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.URL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.attachment.HeaderValue.serializeBinaryToWriter);
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.attachment.URL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.URL} returns this
 */
proto.attachment.URL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, HeaderValue> headers = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.attachment.HeaderValue>}
 */
proto.attachment.URL.prototype.getHeadersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.attachment.HeaderValue>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.attachment.HeaderValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.attachment.URL} returns this
 */
proto.attachment.URL.prototype.clearHeadersMap = function() {
  this.getHeadersMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.HeaderValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.HeaderValue.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.HeaderValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.HeaderValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.HeaderValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.HeaderValue}
 */
proto.attachment.HeaderValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.HeaderValue;
  return proto.attachment.HeaderValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.HeaderValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.HeaderValue}
 */
proto.attachment.HeaderValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.HeaderValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.HeaderValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.HeaderValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.HeaderValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.attachment.HeaderValue.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.HeaderValue} returns this
 */
proto.attachment.HeaderValue.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.HeaderValue} returns this
 */
proto.attachment.HeaderValue.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.HeaderValue} returns this
 */
proto.attachment.HeaderValue.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AddOrUpdateEvidenceGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AddOrUpdateEvidenceGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroup: (f = msg.getEvidenceGroup()) && proto.attachment.EvidenceGroup.toObject(includeInstance, f),
    body: msg.getBody_asB64(),
    newCaption: (f = msg.getNewCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    newRelevantDate: (f = msg.getNewRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    linkagesList: jspb.Message.toObjectList(msg.getLinkagesList(),
    proto.attachment.EvidenceLinkage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AddOrUpdateEvidenceGroupRequest;
  return proto.attachment.AddOrUpdateEvidenceGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AddOrUpdateEvidenceGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroup;
      reader.readMessage(value,proto.attachment.EvidenceGroup.deserializeBinaryFromReader);
      msg.setEvidenceGroup(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNewCaption(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setNewRelevantDate(value);
      break;
    case 5:
      var value = new proto.attachment.EvidenceLinkage;
      reader.readMessage(value,proto.attachment.EvidenceLinkage.deserializeBinaryFromReader);
      msg.addLinkages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AddOrUpdateEvidenceGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AddOrUpdateEvidenceGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroup.serializeBinaryToWriter
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getNewCaption();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNewRelevantDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLinkagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.attachment.EvidenceLinkage.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroup evidence_group = 1;
 * @return {?proto.attachment.EvidenceGroup}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getEvidenceGroup = function() {
  return /** @type{?proto.attachment.EvidenceGroup} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroup, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroup|undefined} value
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
*/
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.setEvidenceGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.clearEvidenceGroup = function() {
  return this.setEvidenceGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.hasEvidenceGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes body = 2;
 * @return {!(string|Uint8Array)}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes body = 2;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes body = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue new_caption = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getNewCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
*/
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.setNewCaption = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.clearNewCaption = function() {
  return this.setNewCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.hasNewCaption = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp new_relevant_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getNewRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
*/
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.setNewRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.clearNewRelevantDate = function() {
  return this.setNewRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.hasNewRelevantDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated EvidenceLinkage linkages = 5;
 * @return {!Array<!proto.attachment.EvidenceLinkage>}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.getLinkagesList = function() {
  return /** @type{!Array<!proto.attachment.EvidenceLinkage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.EvidenceLinkage, 5));
};


/**
 * @param {!Array<!proto.attachment.EvidenceLinkage>} value
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
*/
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.setLinkagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.attachment.EvidenceLinkage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.EvidenceLinkage}
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.addLinkages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.attachment.EvidenceLinkage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest} returns this
 */
proto.attachment.AddOrUpdateEvidenceGroupRequest.prototype.clearLinkagesList = function() {
  return this.setLinkagesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.AddOrUpdateEvidenceGroupRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest}
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest;
  return proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest}
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AddOrUpdateEvidenceGroupRequest;
      reader.readMessage(value,proto.attachment.AddOrUpdateEvidenceGroupRequest.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.AddOrUpdateEvidenceGroupRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AddOrUpdateEvidenceGroupRequest items = 1;
 * @return {!Array<!proto.attachment.AddOrUpdateEvidenceGroupRequest>}
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.AddOrUpdateEvidenceGroupRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.AddOrUpdateEvidenceGroupRequest, 1));
};


/**
 * @param {!Array<!proto.attachment.AddOrUpdateEvidenceGroupRequest>} value
 * @return {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest} returns this
*/
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.AddOrUpdateEvidenceGroupRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.AddOrUpdateEvidenceGroupRequest}
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.AddOrUpdateEvidenceGroupRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest} returns this
 */
proto.attachment.BulkAddOrUpdateEvidenceGroupsRequest.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteEvidenceGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteEvidenceGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteEvidenceGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteEvidenceGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.attachment.EvidenceGroupId.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteEvidenceGroupRequest}
 */
proto.attachment.DeleteEvidenceGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteEvidenceGroupRequest;
  return proto.attachment.DeleteEvidenceGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteEvidenceGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteEvidenceGroupRequest}
 */
proto.attachment.DeleteEvidenceGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroupId;
      reader.readMessage(value,proto.attachment.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteEvidenceGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteEvidenceGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteEvidenceGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteEvidenceGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroupId.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroupId id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.attachment.DeleteEvidenceGroupRequest.prototype.getId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.attachment.DeleteEvidenceGroupRequest} returns this
*/
proto.attachment.DeleteEvidenceGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.DeleteEvidenceGroupRequest} returns this
 */
proto.attachment.DeleteEvidenceGroupRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.DeleteEvidenceGroupRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceLinkage.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceLinkage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceLinkage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceLinkage.toObject = function(includeInstance, msg) {
  var f, obj = {
    linkageType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    linkageId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    linkageModelId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceLinkage}
 */
proto.attachment.EvidenceLinkage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceLinkage;
  return proto.attachment.EvidenceLinkage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceLinkage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceLinkage}
 */
proto.attachment.EvidenceLinkage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.attachment.EVIDENCE_LINKAGE_TYPE} */ (reader.readEnum());
      msg.setLinkageType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkageModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceLinkage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceLinkage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceLinkage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceLinkage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLinkageType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLinkageId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLinkageModelId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional EVIDENCE_LINKAGE_TYPE linkage_type = 1;
 * @return {!proto.attachment.EVIDENCE_LINKAGE_TYPE}
 */
proto.attachment.EvidenceLinkage.prototype.getLinkageType = function() {
  return /** @type {!proto.attachment.EVIDENCE_LINKAGE_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.attachment.EVIDENCE_LINKAGE_TYPE} value
 * @return {!proto.attachment.EvidenceLinkage} returns this
 */
proto.attachment.EvidenceLinkage.prototype.setLinkageType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string linkage_id = 2;
 * @return {string}
 */
proto.attachment.EvidenceLinkage.prototype.getLinkageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceLinkage} returns this
 */
proto.attachment.EvidenceLinkage.prototype.setLinkageId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string linkage_model_id = 3;
 * @return {string}
 */
proto.attachment.EvidenceLinkage.prototype.getLinkageModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceLinkage} returns this
 */
proto.attachment.EvidenceLinkage.prototype.setLinkageModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.UnlinkEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.UnlinkEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.UnlinkEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.UnlinkEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceId: (f = msg.getEvidenceId()) && proto.attachment.EvidenceGroupId.toObject(includeInstance, f),
    linkage: (f = msg.getLinkage()) && proto.attachment.EvidenceLinkage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.UnlinkEvidenceRequest}
 */
proto.attachment.UnlinkEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.UnlinkEvidenceRequest;
  return proto.attachment.UnlinkEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.UnlinkEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.UnlinkEvidenceRequest}
 */
proto.attachment.UnlinkEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroupId;
      reader.readMessage(value,proto.attachment.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setEvidenceId(value);
      break;
    case 2:
      var value = new proto.attachment.EvidenceLinkage;
      reader.readMessage(value,proto.attachment.EvidenceLinkage.deserializeBinaryFromReader);
      msg.setLinkage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.UnlinkEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.UnlinkEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.UnlinkEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.UnlinkEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroupId.serializeBinaryToWriter
    );
  }
  f = message.getLinkage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.attachment.EvidenceLinkage.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroupId evidence_id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.attachment.UnlinkEvidenceRequest.prototype.getEvidenceId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.attachment.UnlinkEvidenceRequest} returns this
*/
proto.attachment.UnlinkEvidenceRequest.prototype.setEvidenceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.UnlinkEvidenceRequest} returns this
 */
proto.attachment.UnlinkEvidenceRequest.prototype.clearEvidenceId = function() {
  return this.setEvidenceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.UnlinkEvidenceRequest.prototype.hasEvidenceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional EvidenceLinkage linkage = 2;
 * @return {?proto.attachment.EvidenceLinkage}
 */
proto.attachment.UnlinkEvidenceRequest.prototype.getLinkage = function() {
  return /** @type{?proto.attachment.EvidenceLinkage} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceLinkage, 2));
};


/**
 * @param {?proto.attachment.EvidenceLinkage|undefined} value
 * @return {!proto.attachment.UnlinkEvidenceRequest} returns this
*/
proto.attachment.UnlinkEvidenceRequest.prototype.setLinkage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.UnlinkEvidenceRequest} returns this
 */
proto.attachment.UnlinkEvidenceRequest.prototype.clearLinkage = function() {
  return this.setLinkage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.UnlinkEvidenceRequest.prototype.hasLinkage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.BulkUnlinkEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.BulkUnlinkEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkUnlinkEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linkage: (f = msg.getLinkage()) && proto.attachment.EvidenceLinkage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.BulkUnlinkEvidenceRequest}
 */
proto.attachment.BulkUnlinkEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.BulkUnlinkEvidenceRequest;
  return proto.attachment.BulkUnlinkEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.BulkUnlinkEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.BulkUnlinkEvidenceRequest}
 */
proto.attachment.BulkUnlinkEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 2:
      var value = new proto.attachment.EvidenceLinkage;
      reader.readMessage(value,proto.attachment.EvidenceLinkage.deserializeBinaryFromReader);
      msg.setLinkage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.BulkUnlinkEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.BulkUnlinkEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkUnlinkEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinkage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.attachment.EvidenceLinkage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string caption = 1;
 * @return {string}
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.BulkUnlinkEvidenceRequest} returns this
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional EvidenceLinkage linkage = 2;
 * @return {?proto.attachment.EvidenceLinkage}
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.getLinkage = function() {
  return /** @type{?proto.attachment.EvidenceLinkage} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceLinkage, 2));
};


/**
 * @param {?proto.attachment.EvidenceLinkage|undefined} value
 * @return {!proto.attachment.BulkUnlinkEvidenceRequest} returns this
*/
proto.attachment.BulkUnlinkEvidenceRequest.prototype.setLinkage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkUnlinkEvidenceRequest} returns this
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.clearLinkage = function() {
  return this.setLinkage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkUnlinkEvidenceRequest.prototype.hasLinkage = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupControlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupControlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupControlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroupId: (f = msg.getEvidenceGroupId()) && proto.attachment.EvidenceGroupId.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupControlsRequest}
 */
proto.attachment.GetEvidenceGroupControlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupControlsRequest;
  return proto.attachment.GetEvidenceGroupControlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupControlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupControlsRequest}
 */
proto.attachment.GetEvidenceGroupControlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroupId;
      reader.readMessage(value,proto.attachment.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setEvidenceGroupId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupControlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupControlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupControlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroupId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroupId.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroupId evidence_group_id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.getEvidenceGroupId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupControlsRequest} returns this
*/
proto.attachment.GetEvidenceGroupControlsRequest.prototype.setEvidenceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupControlsRequest} returns this
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.clearEvidenceGroupId = function() {
  return this.setEvidenceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.hasEvidenceGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupControlsRequest} returns this
*/
proto.attachment.GetEvidenceGroupControlsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupControlsRequest} returns this
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupControlsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceGroupControlsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupControlsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupControlsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupControlsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    viaRequestIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse}
 */
proto.attachment.GetEvidenceGroupControlsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupControlsResponse;
  return proto.attachment.GetEvidenceGroupControlsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupControlsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse}
 */
proto.attachment.GetEvidenceGroupControlsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addViaRequestIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupControlsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupControlsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupControlsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getViaRequestIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string control_ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse} returns this
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse} returns this
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse} returns this
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * repeated string via_request_ids = 2;
 * @return {!Array<string>}
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.getViaRequestIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse} returns this
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.setViaRequestIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse} returns this
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.addViaRequestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceGroupControlsResponse} returns this
 */
proto.attachment.GetEvidenceGroupControlsResponse.prototype.clearViaRequestIdsList = function() {
  return this.setViaRequestIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupDRLRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupDRLRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDRLRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroupId: (f = msg.getEvidenceGroupId()) && proto.attachment.EvidenceGroupId.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupDRLRequest}
 */
proto.attachment.GetEvidenceGroupDRLRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupDRLRequest;
  return proto.attachment.GetEvidenceGroupDRLRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupDRLRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupDRLRequest}
 */
proto.attachment.GetEvidenceGroupDRLRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroupId;
      reader.readMessage(value,proto.attachment.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setEvidenceGroupId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupDRLRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupDRLRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDRLRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroupId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroupId.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroupId evidence_group_id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.getEvidenceGroupId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupDRLRequest} returns this
*/
proto.attachment.GetEvidenceGroupDRLRequest.prototype.setEvidenceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupDRLRequest} returns this
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.clearEvidenceGroupId = function() {
  return this.setEvidenceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.hasEvidenceGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupDRLRequest} returns this
*/
proto.attachment.GetEvidenceGroupDRLRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupDRLRequest} returns this
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupDRLRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceGroupDRLResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupDRLResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupDRLResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupDRLResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDRLResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupDRLResponse}
 */
proto.attachment.GetEvidenceGroupDRLResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupDRLResponse;
  return proto.attachment.GetEvidenceGroupDRLResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupDRLResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupDRLResponse}
 */
proto.attachment.GetEvidenceGroupDRLResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequestIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupDRLResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupDRLResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupDRLResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDRLResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string request_ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.GetEvidenceGroupDRLResponse.prototype.getRequestIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetEvidenceGroupDRLResponse} returns this
 */
proto.attachment.GetEvidenceGroupDRLResponse.prototype.setRequestIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetEvidenceGroupDRLResponse} returns this
 */
proto.attachment.GetEvidenceGroupDRLResponse.prototype.addRequestIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceGroupDRLResponse} returns this
 */
proto.attachment.GetEvidenceGroupDRLResponse.prototype.clearRequestIdsList = function() {
  return this.setRequestIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroupId: (f = msg.getEvidenceGroupId()) && proto.attachment.EvidenceGroupId.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsRequest}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupDocumentsRequest;
  return proto.attachment.GetEvidenceGroupDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsRequest}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroupId;
      reader.readMessage(value,proto.attachment.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setEvidenceGroupId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroupId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroupId.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroupId evidence_group_id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.getEvidenceGroupId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupDocumentsRequest} returns this
*/
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.setEvidenceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsRequest} returns this
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.clearEvidenceGroupId = function() {
  return this.setEvidenceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.hasEvidenceGroupId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupDocumentsRequest} returns this
*/
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsRequest} returns this
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupDocumentsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsResponse}
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupDocumentsResponse;
  return proto.attachment.GetEvidenceGroupDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsResponse}
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDocumentIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string document_ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.prototype.getDocumentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetEvidenceGroupDocumentsResponse} returns this
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.prototype.setDocumentIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetEvidenceGroupDocumentsResponse} returns this
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.prototype.addDocumentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceGroupDocumentsResponse} returns this
 */
proto.attachment.GetEvidenceGroupDocumentsResponse.prototype.clearDocumentIdsList = function() {
  return this.setDocumentIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.attachment.EvidenceGroupId.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupRequest}
 */
proto.attachment.GetEvidenceGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupRequest;
  return proto.attachment.GetEvidenceGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupRequest}
 */
proto.attachment.GetEvidenceGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroupId;
      reader.readMessage(value,proto.attachment.EvidenceGroupId.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroupId.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroupId id = 1;
 * @return {?proto.attachment.EvidenceGroupId}
 */
proto.attachment.GetEvidenceGroupRequest.prototype.getId = function() {
  return /** @type{?proto.attachment.EvidenceGroupId} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroupId, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroupId|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupRequest} returns this
*/
proto.attachment.GetEvidenceGroupRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupRequest} returns this
 */
proto.attachment.GetEvidenceGroupRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetEvidenceGroupRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupRequest} returns this
*/
proto.attachment.GetEvidenceGroupRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupRequest} returns this
 */
proto.attachment.GetEvidenceGroupRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGroup: (f = msg.getEvidenceGroup()) && proto.attachment.EvidenceGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceGroupResponse}
 */
proto.attachment.GetEvidenceGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceGroupResponse;
  return proto.attachment.GetEvidenceGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceGroupResponse}
 */
proto.attachment.GetEvidenceGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGroup;
      reader.readMessage(value,proto.attachment.EvidenceGroup.deserializeBinaryFromReader);
      msg.setEvidenceGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.EvidenceGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceGroup evidence_group = 1;
 * @return {?proto.attachment.EvidenceGroup}
 */
proto.attachment.GetEvidenceGroupResponse.prototype.getEvidenceGroup = function() {
  return /** @type{?proto.attachment.EvidenceGroup} */ (
    jspb.Message.getWrapperField(this, proto.attachment.EvidenceGroup, 1));
};


/**
 * @param {?proto.attachment.EvidenceGroup|undefined} value
 * @return {!proto.attachment.GetEvidenceGroupResponse} returns this
*/
proto.attachment.GetEvidenceGroupResponse.prototype.setEvidenceGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceGroupResponse} returns this
 */
proto.attachment.GetEvidenceGroupResponse.prototype.clearEvidenceGroup = function() {
  return this.setEvidenceGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceGroupResponse.prototype.hasEvidenceGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * @enum {number}
 */
proto.attachment.ACTOR_TYPE = {
  USER: 0,
  RECEPTOR: 1,
  TRUSTERO: 2,
  LINKER: 3
};

/**
 * @enum {number}
 */
proto.attachment.DOCUMENT_TYPE = {
  POLICYDOC: 0,
  TEST: 1,
  EVIDENCE: 2,
  COMMENT: 3,
  REPORT: 4,
  USER_ACTION: 5,
  COMPANY_INFO: 6,
  KNOWLEDGE_BASE: 7,
  GRC_DOCUMENT: 8
};

/**
 * @enum {number}
 */
proto.attachment.ATTACHMENT_TYPE = {
  AUDITPERIOD: 0,
  DOCUMENT: 1,
  TRACER: 2,
  TESTRESULT: 3,
  CONTROL: 4,
  POLICY: 5,
  REQUEST: 6,
  RISK: 7,
  AUDIT_RUN: 8,
  QUESTIONNAIRE_ANSWER: 9,
  ATTESTATION: 10,
  VENDOR: 11
};

/**
 * @enum {number}
 */
proto.attachment.EVIDENCE_SORT_COLUMN = {
  NAME: 0,
  RELEVANT_DATE: 1,
  CREATED_AT: 2
};

/**
 * @enum {number}
 */
proto.attachment.EVIDENCE_LINKAGE_TYPE = {
  CONTROL_LINK: 0,
  REQUEST_LINK: 1
};

goog.object.extend(exports, proto.attachment);
