import React from 'react'
import log from 'loglevel'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { faHourglass } from '@fortawesome/free-regular-svg-icons'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import {
  AuditRunSummary,
  ControlTest,
  PRE_AUDIT_RESULT,
  PreAudit,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { TaskManagerPromiseClient } from '@trustero/trustero-api-web/lib/task/task_grpc_web_pb'
import { TaskID } from '@trustero/trustero-api-web/lib/task/task_pb'
import { GrpcClient } from 'src/adapter/grpcClient'
import { AUDIT_RESULT } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { AUDIT_STATUS } from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { ThrobberContextState } from 'src/Throbber/ThrobberContext'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { formatTimestampAllowUndefined } from 'src/Utils/formatDate'
import { getDownloadCSVFunc } from 'src/components/ModalForms/Reusable/UploadCSVModal.helpers'
import { CsvTemplateType } from 'src/Utils/csv/csv.constants'
import {
  CANCEL_SECTIONS,
  CANCEL_TEXT_MAP,
  IN_PROGRESS,
} from './AuditBot.constants'
import { AuditBotThrobber } from './modals/AuditBotThrobber/AuditBotThrobber'
import { AUDITBOT_ANIMATION_DURATION } from './modals/AuditBotModal.constants'
import {
  AuditBotCancelledIconLarge,
  AuditBotCancellingIconLarge,
} from './AuditBot.styles'
import { AuditBotControlChecksType } from './accordion/subsection/ControlChecks/AuditBotControlChecks.constants'
import {
  getReasonText,
  getSingleCheckResultText,
  processControlChecks,
} from './accordion/subsection/ControlChecks/AuditBotControlChecks.helpers'

export const getIsValidAuditBotAudit = (
  audit: AuditRecord | undefined,
): boolean => {
  if (!audit) {
    return false
  }
  return !audit.getIsClosed()
}

export const sortAuditRuns = (
  auditRuns: AuditRunSummary[],
): AuditRunSummary[] => {
  return auditRuns.sort((a, b) => {
    const aStarttime = a.getStartTime()?.getSeconds() || 0
    const bStarttime = b.getStartTime()?.getSeconds() || 0
    return bStarttime - aStarttime
  })
}

export const updateProgressState = async (
  taskIds: string[],
  taskClient: GrpcClient<TaskManagerPromiseClient>,
  setInProgressState: React.Dispatch<React.SetStateAction<IN_PROGRESS>>,
  stopPolling: () => void,
  mutate: () => Promise<void>,
): Promise<void> => {
  for (const taskId of taskIds) {
    try {
      const request = new TaskID().setTaskId(taskId)
      const runProgress = await taskClient.getProgress(request)
      const progress = runProgress.getProgress()
      const progressMax = runProgress.getProgressMax()

      if (progress >= progressMax) {
        stopPolling()
        return
      }
      setInProgressState((prevState) => ({
        ...prevState,
        [taskId]: {
          progress,
          progressMax,
        },
      }))
      await mutate()
    } catch (e) {
      log.error(
        `Error updating progress on audit run index page for taskId ${taskId}`,
        e,
      )
    }
  }
}

export const getCanCancel = (auditRunSummary: AuditRunSummary): boolean => {
  const status = auditRunSummary.getStatus()
  return status === AUDIT_STATUS.INITIATED
}

export const runAuditBotAnimation = (
  setThrobberState: React.Dispatch<React.SetStateAction<ThrobberContextState>>,
  text: string,
): void => {
  setThrobberState({
    isShown: true,
    Display: <AuditBotThrobber text={text} />,
  })
  setTimeout(() => {
    setThrobberState({
      isShown: false,
      Display: <></>,
    })
  }, AUDITBOT_ANIMATION_DURATION)
}

export const getCancelScanIcon = (
  status: AUDIT_STATUS | undefined,
): JSX.Element => {
  switch (status) {
    case AUDIT_STATUS.CANCELLING:
      return <AuditBotCancellingIconLarge icon={faHourglass} />
    case AUDIT_STATUS.STOPPED:
    default:
      return <AuditBotCancelledIconLarge icon={faCircleXmark} />
  }
}

export const getCancelStatusText = (
  status: AUDIT_STATUS | undefined,
  section: CANCEL_SECTIONS,
): string => {
  switch (status) {
    case AUDIT_STATUS.CANCELLING:
      return CANCEL_TEXT_MAP[AUDIT_STATUS.CANCELLING][section]
    case AUDIT_STATUS.STOPPED:
    default:
      return CANCEL_TEXT_MAP[AUDIT_STATUS.STOPPED][section]
  }
}

export const getAuditbotPrecheckCounts = (
  preAuditChecks?: PreAudit,
): {
  ready: number
  notReady: number
  total: number
} => {
  const progress = { ready: 0, notReady: 0, total: 0 }
  if (!preAuditChecks) return progress
  const resultMap = preAuditChecks.getPerSectionResultsMap()
  for (const [_, val] of resultMap.entries()) {
    val === PRE_AUDIT_RESULT.PRE_AUDIT_PASS
      ? progress.ready++
      : progress.notReady++
    progress.total++
  }
  return progress
}

export const getAuditbotControlTestCounts = (
  controlTests: ControlTest[],
): {
  ready: number
  notReady: number
  total: number
} => {
  const progress = { ready: 0, notReady: 0, total: 0 }
  if (!controlTests) return progress
  const processedControlChecks = processControlChecks(controlTests)
  const perControlTests = Object.values(processedControlChecks)
  for (const test of perControlTests) {
    test.checks.every((check) => check.getResult() === AUDIT_RESULT.PASS)
      ? progress.ready++
      : progress.notReady++
    progress.total++
  }
  return progress
}

export const getAuditScanTitle = ({
  auditName,
  sampleStartDate,
  sampleEndDate,
  isShowPage,
}: {
  auditName?: string
  sampleStartDate?: Timestamp
  sampleEndDate?: Timestamp
  isShowPage?: boolean
}): string => {
  if (auditName) {
    // return isShowPage ? `Audit: ${auditName}` : auditName
    return isShowPage ? `Audit: ${auditName}` : auditName
  }

  const startDate = formatTimestampAllowUndefined(
    sampleStartDate,
    DATE_FORMATS.ISO,
  )
  const endDate = formatTimestampAllowUndefined(sampleEndDate, DATE_FORMATS.ISO)

  return isShowPage
    ? `Continuous Compliance: ${startDate} to ${endDate}`
    : `Continuous Compliance`
}

export const downloadAuditRun = (
  data: Record<string, AuditBotControlChecksType>,
  name: string,
): void => {
  const downloadData: CsvTemplateType[] = []

  Object.values(data).forEach((controlTests) => {
    const checks = controlTests.checks.map((elem) => elem.toObject())

    const item: CsvTemplateType = {
      'Control ID': controlTests.modelId,
    }

    checks.forEach((check) => {
      item[`${check.testName} Result`] = getSingleCheckResultText(check.result)
      item[`${check.testName} Reason`] = getReasonText(check.reason)
    })

    downloadData.push(item)
  })

  const downloadFunc = getDownloadCSVFunc(downloadData, name)
  downloadFunc()
}
