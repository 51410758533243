import { getAllReceptorTemplatesFiltered } from 'src/Utils/Receptors/getAllReceptorTemplatesFiltered'
import { RECEPTOR_TYPE } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { NONE_ID, NONE_NAME } from 'src/Utils/globalConstants'
import { Receptor } from 'src/xgenerated'
import { DropdownItemType } from './FilterDropdown.constants'

export const getReceptorFilterValues = (
  type: RECEPTOR_TYPE,
): DropdownItemType[] => {
  const receptors = getAllReceptorTemplatesFiltered(type).sort((a, b) =>
    a.name.localeCompare(b.name),
  )
  const noneValue = {
    value: NONE_ID,
    label: NONE_NAME,
  }
  return [
    noneValue,
    ...receptors.map((receptor: Receptor) => ({
      value: receptor.modelid,
      label: receptor.name,
    })),
  ]
}
