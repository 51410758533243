import React from 'react'
import {
  AUDIT_RESULT,
  ControlTest,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'

export type AuditBotControlChecksType = {
  modelId: string
  name: string
  checks: ControlTest[]
  isNotApplicable?: boolean
}

interface ControlResultCountConfig {
  count: number
  text: string
  key?: AUDIT_RESULT
}

export type ControlResultCountsType = {
  [AUDIT_RESULT.PASS]: ControlResultCountConfig
  [AUDIT_RESULT.FAIL]: ControlResultCountConfig
  [AUDIT_RESULT.NOT_APPLICABLE]: ControlResultCountConfig
  [AUDIT_RESULT.UNKNOWN]: ControlResultCountConfig
  [AUDIT_RESULT.MISSING_SUGGESTIONS]: ControlResultCountConfig
} & { SUCCESSFUL: ControlResultCountConfig }

// TODO: Move this enum to Ntrced side to ensure we always use the proper values and avoid duplicate enums/constants
export enum CONTROL_TEST_NAMES {
  POLICY_MATCH = 'Control-Policy Material Difference',
  COMPLETENESS = 'Evidence Completeness',
  ONBOARDING = 'Onboarding',
  OFFBOARDING = 'Offboarding',
  ONGOING_EMPLOYEES = 'Ongoing Employees',
  SPOT_CHECK = 'Operating Effectiveness Check',
}

export const PASSED_CONTROL_CHECK_TEXT = Object.freeze({
  [CONTROL_TEST_NAMES.POLICY_MATCH]:
    'Do the control objective and the parent policy align or are there material differences?',
  [CONTROL_TEST_NAMES.COMPLETENESS]: (
    <span>
      Does this control appear to have all <em>required evidence</em> attached
      to it?
    </span>
  ),
  [CONTROL_TEST_NAMES.ONBOARDING]:
    'Control requirements fulfilled for all new employees during this period',
  [CONTROL_TEST_NAMES.OFFBOARDING]:
    'Offboarded employees track across control requirement',
  [CONTROL_TEST_NAMES.ONGOING_EMPLOYEES]:
    'Employee list matches control requirement',
  [CONTROL_TEST_NAMES.SPOT_CHECK]: (
    <span>
      Does the attached evidence pass the <em>test procedures?</em>
    </span>
  ),
})
