import { RECEPTOR_TYPE } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { GatekeeperFlag, IsGatekeeperEnabled } from '../../context/Gatekeeper'
import { getAllReceptorTemplates, Receptor } from '../../xgenerated'

export const getAllReceptorTemplatesFiltered = (
  type: RECEPTOR_TYPE,
): Receptor[] => {
  let receptorTemplates: Receptor[] = getAllReceptorTemplates()
  // code to filter out receptor by modelid
  if (!IsGatekeeperEnabled(GatekeeperFlag.MIRADORE_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-miradore',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.JUMPCLOUD_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-jumpcloud',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.KNOWBE4_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-knowbe4',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.SLACK_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-slack',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.SONARQUBE_CLOUD_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-sonarqubecloud',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.FRESHDESK_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-freshdesk',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.JSM_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-jsm',
    )
  }
  if (!IsGatekeeperEnabled(GatekeeperFlag.ZENDESK_RECEPTOR)) {
    receptorTemplates = receptorTemplates.filter(
      (receptorTemplate) => receptorTemplate.modelid !== 'trr-zendesk',
    )
  }
  receptorTemplates = receptorTemplates.filter(
    (receptorTemplate) => receptorTemplate.type === type,
  )
  return receptorTemplates
}
