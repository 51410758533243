import React, { useEffect, useState } from 'react'
import { useModalState } from 'src/Modal/ModalStateContext'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { PercentageComplete } from 'src/components/Reusable/ProgressCircle/ProgressCircle'
import { InfoButton } from 'src/components/PageLayout/InfoButton'
import { useComplianceFrameworks } from 'src/components/async/complianceframework'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  RoadMapWidgetCheckRow,
  RoadMapWidgetRowText,
  RoadmapSectionTitle,
  RoadmapWidgetCheckContainer,
  RoadmapWidgetCheckContent,
  RoadmapWidgetFinishedCount,
  RoadmapWidgetLinkContent,
  RoadmapWidgetSectionContainer,
  RoadmapWidgetSectionIconRow,
  RoadmapWidgetSectionRatio,
  RoadmapWidgetTotalCount,
  RoadmapWidgetCardLink,
  RoadmapWidgetTotalLink,
  RoadmapWidgetCardButton,
  RoadmapWidgetTitleContainer,
} from './RoadmapWidgets.styles'
import {
  COUNT_PLACEHOLDER,
  ROADMAP_WIDGET_SECTION,
  ROADMAP_WIDGET_SECTION_CHECK,
  ROADMAP_WIDGET_SECTION_CONFIG_TYPE,
  ROADMAP_WIDGET_SECTION_DATA,
} from './RoadmapWidgets.constants'
import {
  getCorrectTotalFromCheck,
  getSectionCheckConfig,
} from './RoadmapWidgets.helpers'

export const RoadMapWidgetCheck = ({
  section,
  check,
  pageContext,
}: {
  section: ROADMAP_WIDGET_SECTION
  check: ROADMAP_WIDGET_SECTION_CHECK
  pageContext: string
}): JSX.Element => {
  const { data } = useComplianceFrameworks(true)

  const frameworkModelIds =
    data?.getItemsList().map((e) => e.getModelId()) || []

  const { text } = getSectionCheckConfig(
    section,
    check,
    pageContext,
    frameworkModelIds,
  )
  const count = check.getCount()

  return (
    <RoadMapWidgetCheckRow>
      <RoadMapWidgetRowText>
        <p>{text}</p>
        <p>{count}</p>
      </RoadMapWidgetRowText>
    </RoadMapWidgetCheckRow>
  )
}

export const RoadMapWidgetLinkText = ({
  text,
  count,
}: {
  text: string
  count: string | number
}): JSX.Element => {
  return (
    <RoadMapWidgetRowText>
      <p>{text}</p>
      <p>{count.toString()}</p>
    </RoadMapWidgetRowText>
  )
}

export const RoadMapWidgetLink = ({
  section,
  check,
  totalCount,
  pageContext,
  ownerId,
  ownerEmail,
}: {
  section: ROADMAP_WIDGET_SECTION
  check: ROADMAP_WIDGET_SECTION_CHECK
  totalCount: number
  pageContext: string
  ownerId?: string
  ownerEmail?: string
}): JSX.Element => {
  const { openModal } = useModalState()
  const { data } = useComplianceFrameworks(true)

  const frameworkModelIds =
    data?.getItemsList().map((e) => e.getModelId()) || []

  const {
    hoverText,
    link,
    modal,
    modalId,
    useFinishedCount,
    requiredPermissions,
  } = getSectionCheckConfig(
    section,
    check,
    pageContext,
    frameworkModelIds,
    ownerId,
    ownerEmail,
  )

  const hasRequiredPermissions = useHasRequiredPermissions(requiredPermissions)

  const total = getCorrectTotalFromCheck(section, check, totalCount)
  const count = check.getCount()
  const linkCount = useFinishedCount ? count : total - count
  const showLink = linkCount > 0
  const isLink = !!link
  const isModal = !!modalId && !!modal

  return !showLink ? (
    <></>
  ) : (
    <RoadMapWidgetCheckRow>
      {isLink && (
        <RoadmapWidgetCardLink
          to={link}
          text={<RoadMapWidgetLinkText text={hoverText} count={linkCount} />}
          $isDisabled={!hasRequiredPermissions}
        />
      )}
      {isModal && (
        <>
          <RoadmapWidgetCardButton
            text={<RoadMapWidgetLinkText text={hoverText} count={linkCount} />}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              openModal(modalId)
            }}
          />
          {modal}
        </>
      )}
    </RoadMapWidgetCheckRow>
  )
}

export const RoadmapWidgetSection = ({
  section,
  config,
  widgetData,
  ownerId,
  ownerEmail,
}: {
  section: ROADMAP_WIDGET_SECTION
  config: ROADMAP_WIDGET_SECTION_CONFIG_TYPE | null
  widgetData: ROADMAP_WIDGET_SECTION_DATA | null
  ownerId?: string
  ownerEmail?: string
}): JSX.Element => {
  const [finished, setFinished] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [checks, setChecks] = useState<ROADMAP_WIDGET_SECTION_CHECK[]>([])
  const hasRequiredPermissions = useHasRequiredPermissions(
    config?.requiredPermissions || [],
  )

  useEffect(() => {
    if (!widgetData) return
    const { finishedCount, totalCount, checks } = widgetData
    setFinished(finishedCount)
    setTotal(totalCount)
    setChecks(checks)
  }, [widgetData])

  const progress = finished / total

  const showDenominator = section !== ROADMAP_WIDGET_SECTION.SERVICE

  if (!config) {
    return <></>
  }
  const { title, icon, path, pageContext } = config

  const totalLink = `/${pageContext}/${path}`
  const showFinished = widgetData ? finished.toString() : COUNT_PLACEHOLDER
  const showTotal = widgetData ? total.toString() : COUNT_PLACEHOLDER

  return (
    <RoadmapWidgetSectionContainer>
      <RoadmapWidgetTitleContainer>
        <RoadmapSectionTitle>{title}</RoadmapSectionTitle>
        {config.tooltip}
      </RoadmapWidgetTitleContainer>
      <RoadmapWidgetSectionIconRow>
        <PercentageComplete
          percentage={progress * 100}
          innerRadius={20}
          outerRadius={28}
          icon={icon}
          strokeWidth={5}
        />
        <RoadmapWidgetSectionRatio>
          <RoadmapWidgetFinishedCount>
            {showFinished}
          </RoadmapWidgetFinishedCount>
          {showDenominator && (
            <RoadmapWidgetTotalCount>/{showTotal}</RoadmapWidgetTotalCount>
          )}
        </RoadmapWidgetSectionRatio>
      </RoadmapWidgetSectionIconRow>
      <RoadmapWidgetCheckContainer>
        <RoadmapWidgetCheckContent>
          {checks.map((check: ROADMAP_WIDGET_SECTION_CHECK) => (
            <RoadMapWidgetCheck
              key={`${title}-${check.getCheckType()}`}
              section={section}
              check={check}
              pageContext={pageContext}
            />
          ))}
        </RoadmapWidgetCheckContent>
        <RoadmapWidgetLinkContent>
          {checks.map((check: ROADMAP_WIDGET_SECTION_CHECK) => (
            <RoadMapWidgetLink
              key={`${title}-${check.getCheckType()}-link`}
              section={section}
              check={check}
              totalCount={total}
              pageContext={pageContext}
              ownerId={ownerId}
              ownerEmail={ownerEmail}
            />
          ))}
        </RoadmapWidgetLinkContent>
        <RoadmapWidgetTotalLink
          $isDisabled={!hasRequiredPermissions}
          to={totalLink}
          text={<RoadMapWidgetLinkText text="Total" count={showTotal} />}
        />
      </RoadmapWidgetCheckContainer>
    </RoadmapWidgetSectionContainer>
  )
}

export const ServiceRolesWidgetTooltip = (): JSX.Element => (
  <Tooltip
    id="service-roles-widget-tooltip"
    placement={TooltipPositions.right}
    overlayType={TooltipOverlayType.popover}
    tooltipBody="Tracks whether you've selected the services you use for critical information security activities."
  >
    <InfoButton className="widget-info-button" />
  </Tooltip>
)
