import { DOCUMENT_REQUEST_STATUS } from '@trustero/trustero-api-web/lib/request/request_pb'
import {
  AUDIT_RESULT,
  AUDIT_STATUS,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import palette from 'src/designSystem/variables/palette'

export enum STATUS_TYPES {
  REQUEST = 'request',
  AUDITBOT_RUN_RESULT = 'auditbot run result',
  AUDITBOT_RUN_STATUS = 'auditbot run status',
  QUESTIONNAIRE = 'ai grc q&a result',
  DATE_LIMIT = 'date limit',
  AUDIT_SCAN_FREQUENCY = 'ai examination and test frequency',
}

export enum DATE_LIMIT_STATUS {
  NEUTRAL = 0,
}

export enum AUDIT_SCAN_FREQUENCY_STATUS {
  NOT_RECOMMENDED = 0,
  NEUTRAL = 1,
  BETTER = 2,
  RECOMMENDED = 3,
}

export const REQUEST_STATUS_COLORS: Record<DOCUMENT_REQUEST_STATUS, string> = {
  [DOCUMENT_REQUEST_STATUS.OPEN]: palette.neutral['400'],
  [DOCUMENT_REQUEST_STATUS.READY]: palette.green['400'],
  [DOCUMENT_REQUEST_STATUS.ACCEPTED]: palette.blue['400'],
  [DOCUMENT_REQUEST_STATUS.RETURNED]: palette.magenta['400'],
}

export const AUDITBOT_RESULT_COLORS: Record<AUDIT_RESULT, string> = {
  [AUDIT_RESULT.UNKNOWN]: palette.orange['400'],
  [AUDIT_RESULT.PASS]: palette.green['400'],
  [AUDIT_RESULT.FAIL]: palette.magenta['400'],
  [AUDIT_RESULT.NOT_APPLICABLE]: palette.neutral['400'],
  [AUDIT_RESULT.MISSING_SUGGESTIONS]: palette.neutral['400'],
  [AUDIT_RESULT.NOT_RUN]: palette.neutral['400'],
  [AUDIT_RESULT.LAMBDA_ERROR]: palette.orange['400'],
}

export const AUDITBOT_STATUS_COLORS: Record<AUDIT_STATUS, string> = {
  [AUDIT_STATUS.INITIATED]: '',
  [AUDIT_STATUS.ERRORED]: '',
  [AUDIT_STATUS.STOPPED]: '',
  [AUDIT_STATUS.COMPLETED]: '',
  [AUDIT_STATUS.CANCELLING]: palette.neutral['900'],
}

export const DATE_LIMIT_STATUS_COLORS: Record<DATE_LIMIT_STATUS, string> = {
  [DATE_LIMIT_STATUS.NEUTRAL]: palette.neutral['600'],
}

export const AUDIT_SCAN_FREQUENCY_STATUS_COLORS: Record<
  AUDIT_SCAN_FREQUENCY_STATUS,
  string
> = {
  [AUDIT_SCAN_FREQUENCY_STATUS.NOT_RECOMMENDED]: palette.magenta['400'],
  [AUDIT_SCAN_FREQUENCY_STATUS.NEUTRAL]: palette.neutral['600'],
  [AUDIT_SCAN_FREQUENCY_STATUS.BETTER]: palette.neutral['200'],
  [AUDIT_SCAN_FREQUENCY_STATUS.RECOMMENDED]: palette.blue['400'],
}
