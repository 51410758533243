import React from 'react'
import { PercentageComplete } from 'src/components/Reusable/ProgressCircle/ProgressCircle'
import { RequestsCircleIcon } from '../Roadmap_v2/widgets/RoadmapWidgets.styles'
import { QuestionnaireAcceptanceContainer } from './securityQuestionnaire.styles'

export const SecurityQuestionnaireProgressCircle = ({
  percentage,
}: {
  percentage: number
}): JSX.Element => (
  <PercentageComplete
    outerRadius={32}
    innerRadius={25}
    percentage={percentage}
    fontSize={20}
    icon={<RequestsCircleIcon />}
  />
)

export const QuestionnaireAcceptance = ({
  isAccepted,
}: {
  isAccepted: boolean
}): JSX.Element => (
  <QuestionnaireAcceptanceContainer $isAccepted={isAccepted}>
    {isAccepted ? 'Accepted' : 'In Progress'}
  </QuestionnaireAcceptanceContainer>
)
