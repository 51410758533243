import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { mergeReceptorData } from 'src/pages/Receptors/receptors.helpers'
import { RECEPTOR_TYPE } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { Receptor } from 'src/xgenerated'
import { getAllReceptorTemplatesFiltered } from 'src/Utils/Receptors/getAllReceptorTemplatesFiltered'
import { useReceptor } from '../receptors.hooks'

type ReceptorsContextType = {
  receptor: Receptor | null
  isLoading: boolean
  error: Error | null
}

const generateTemplateMap = (): Record<string, Receptor> => {
  return getAllReceptorTemplatesFiltered(RECEPTOR_TYPE.RECEPTOR).reduce(
    (acc, template) => {
      acc[template.modelid] = template
      return acc
    },
    {} as Record<string, Receptor>,
  )
}

const ReceptorsContext = React.createContext<ReceptorsContextType>({
  receptor: null,
  isLoading: false,
  error: null,
})

export const useReceptorsContext = (): ReceptorsContextType =>
  useContext(ReceptorsContext)

export const ReceptorsContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const { receptorId } = useParams() as { receptorId: string }
  const { data, isLoading, error } = useReceptor({
    receptorId,
    shouldFetch: true,
  })
  const templateMap = useRef(generateTemplateMap())
  const [receptor, setReceptor] = useState<Receptor | null>(
    data
      ? mergeReceptorData(data, templateMap.current[data.getModelid()])
      : null,
  )

  useEffect(() => {
    if (!data || isLoading || error) return
    const populatedReceptor = mergeReceptorData(
      data,
      templateMap.current[data.getModelid()],
    )
    setReceptor(populatedReceptor)
  }, [data, isLoading, error])

  return (
    <ReceptorsContext.Provider
      value={{
        receptor,
        isLoading,
        error,
      }}
    >
      {children}
    </ReceptorsContext.Provider>
  )
}
