import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { ModelEvidenceGenerator } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import React from 'react'
import { useConfirmationModal } from '../../../../components/ModalForms'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { DeleteButton } from '../../../../components/Reusable/Buttons'
import { Spinner } from '../../../../Throbber'
import { useEvidenceGenerators } from '../../../../components/async/attachment/useEvidenceGenerators'

type Props = {
  evidenceGeneratorId: string
  controlId: string
}

const bodytext =
  'will no longer post to this control. Evidence posted here will remain unless you choose to delete it.'

export const DisableConnectionButton = ({
  controlId,
  evidenceGeneratorId,
}: Props): JSX.Element => {
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const evidenceGenerators = useEvidenceGenerators({
    modelType: MODEL_TYPE.CONTROL,
    modelId: controlId,
    connected: true,
  })

  const evidenceGenerator = evidenceGenerators.data
    ?.getItemsList()
    .find(
      (evidenceGenerator) => evidenceGenerator.getId() === evidenceGeneratorId,
    )
    ?.toObject()

  const modalBody = evidenceGenerator ? (
    `${evidenceGenerator?.evidenceId || 'Automatic Evidence'} ${bodytext}`
  ) : (
    <Spinner size="s" color="primary" />
  )
  const deleteWithConfirmation = useConfirmationModal({
    title: 'Are you sure you want to disconnect?',
    body: modalBody,
    confirmText: 'Disconnect',
    onConfirmCB: async () => {
      await disableConnection(client, { controlId, evidenceGeneratorId })
      await evidenceGenerators.mutate()
    },
  })

  return <DeleteButton onClick={deleteWithConfirmation} />
}

const disableConnection = async (
  client: AttachmentPromiseClient,
  {
    controlId,
    evidenceGeneratorId,
  }: { evidenceGeneratorId: string; controlId: string },
): Promise<void> => {
  const request = new ModelEvidenceGenerator()
    .setControlModelIdentifier(
      new Identifier().setModeltype(MODEL_TYPE.CONTROL).setModelid(controlId),
    )
    .setEvidenceGeneratorId(evidenceGeneratorId)
    .setIsEnabled(false)
  await client.updateModelEvidenceGenerator(request)
}
