import {
  AUDIT_RESULT,
  AUDIT_STATUS,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { FRAMEWORK_MODEL_IDS } from 'src/Utils/globalEnums'

export const AUDITBOT_SUPPORTED_FRAMEWORKS = [FRAMEWORK_MODEL_IDS.SOC2]

export const CONTROL_NOT_APPLICABLE_REASON =
  'This control was not evaluated because it was marked not applicable.'

export const POLICY_NOT_APPLICABLE_REASON =
  'This policy was not evaluated because it was marked not applicable.'

export const LAMBDA_FAILURE_ERROR = 'Error invoking auditbot lambda function'

export const LAMBDA_FAILURE_ERROR_REASON =
  'Trustero AI had an issue and could not evaluate this control. We are working on a solution.'

export type IN_PROGRESS = {
  [key: string]: {
    progress: number
    progressMax: number
  }
}

export type GET_PROGRESS_TYPE = {
  intervalId: number
  checkForProgress: (taskIds: string[]) => Promise<void>
  stopPolling: () => void
}

export enum CANCEL_SECTIONS {
  HEADER = 0,
  CONTROL_CHECKS = 1,
}

export enum AUDITBOT_SECTIONS {
  SUMMARY = 'summary',
  PREAUDIT = 'preaudit',
  CONTROL_CHECKS = 'controlChecks',
}

export type CANCEL_TEXT = {
  [CANCEL_SECTIONS.HEADER]: string
  [CANCEL_SECTIONS.CONTROL_CHECKS]: string
}

export const CANCEL_TEXT_MAP = Object.freeze({
  [AUDIT_STATUS.CANCELLING]: {
    [CANCEL_SECTIONS.HEADER]: 'Cancelling...',
    [CANCEL_SECTIONS.CONTROL_CHECKS]: 'Cancelling...',
  },
  [AUDIT_STATUS.STOPPED]: {
    [CANCEL_SECTIONS.HEADER]: 'Cancelled AI Examination and Test',
    [CANCEL_SECTIONS.CONTROL_CHECKS]: 'Cancelled.',
  },
})

export type AUDITBOT_SECTION_CONFIG = {
  summary?: JSX.Element
  summaryText: string
  explanation: string
  isScanning: boolean
  accordion: boolean
  showOverride: boolean
}

export type GetAuditHasControlsParams = {
  auditId: string
  excludeFromAudit?: boolean
  isDismissed?: boolean
  complianceFrameworkIds?: string[]
}

export enum AUDITBOT_STATUS {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  COMPLETED = 2,
}

export enum AUDITBOT_RUN_RESULTS {
  ALL_PASSING = 'all_passing',
  DOCS_NOT_READY = 'docs_not_ready',
  CONTROLS_NOT_READY = 'controls_not_ready',
  CONTROLS_NOT_SCANNED = 'controls_not_examined_or_tested',
  DEFAULT = 'default',
}

export enum PREAUDIT_SECTION_TYPE {
  READY = 0,
  NOT_READY = 1,
}

export enum CONTROL_CHECK_ERRORS {
  ERROR = 'Error',
  JSON_ERROR = 'JSON Schema Validation Error',
}

export enum HIDE_CONTROL_CHECK_REASONS {
  NOT_APPLICABLE = 'Not Applicable',
  NOT_APPLICABLE_AUDIT = 'This control was not evaluated because it was marked not applicable.',
  NOT_APPLICABLE_NON_OCCURRENCE = 'Not applicable, no new employees were hired during the audit period.',
  NOT_IMPLEMENTED = 'Test not implemented',
  LAMBDA_FAILURE = 'Error invoking auditbot lambda function',
}

export const GET_PROGRESS_INTERVAL = 30000

export const SUMMARY_SECTION_CONFIG: Record<string, AUDITBOT_SECTION_CONFIG> =
  Object.freeze({
    [AUDITBOT_RUN_RESULTS.ALL_PASSING]: {
      summaryText: "Congrats! You're good to go!",
      explanation:
        'Your prerequisites have been satisfied and your controls passed our tests with flying colors!',
      isScanning: false,
      accordion: false,
      showOverride: false,
    },
    [AUDITBOT_RUN_RESULTS.DOCS_NOT_READY]: {
      summaryText: 'More work needed',
      explanation:
        'Your prerequisites are not yet satisfied. You may want to fix this first, then focus on satisfying controls.',
      isScanning: false,
      accordion: false,
      showOverride: false,
    },
    [AUDITBOT_RUN_RESULTS.CONTROLS_NOT_SCANNED]: {
      summaryText: 'More work needed',
      explanation:
        'Your prerequisites are not yet satisfied. You may want to fix this first, then focus on satisfying controls.',
      isScanning: false,
      accordion: false,
      showOverride: true,
    },
    [AUDITBOT_RUN_RESULTS.CONTROLS_NOT_READY]: {
      summaryText: 'More work might be needed',
      explanation:
        'Your prerequisites have been satisfied but your controls may not have all the evidence they need.',
      isScanning: false,
      accordion: false,
      showOverride: false,
    },
  })

export const AUDITBOT_DOCUMENTS_READY_TEXT = Object.freeze({
  [AUDIT_RESULT.PASS]: 'Prerequisites satisfied',
  [AUDIT_RESULT.FAIL]: 'Prerequisites not satisfied',
})

export enum CONTROL_RESULT_TYPE {
  READY = 'ready',
  NOT_READY = 'not ready',
  NOT_SCANNED = 'not examined or tested',
  ERROR = 'error',
}

export enum CONTROL_STATUS_TYPE {
  SCANNING = 'examining and testing',
  CANCELLING = 'cancelling',
  CANCELLED = 'cancelled',
}

export const AUDITBOT_CONTROLS_READY_TEXT = Object.freeze({
  [CONTROL_RESULT_TYPE.READY]: 'Controls ready',
  [CONTROL_RESULT_TYPE.NOT_READY]: 'Controls not ready',
  [CONTROL_RESULT_TYPE.NOT_SCANNED]: 'Controls not examined or tested',
  [CONTROL_RESULT_TYPE.ERROR]: 'Trustero AI had trouble',
  [CONTROL_STATUS_TYPE.CANCELLING]: 'Cancelling...',
  [CONTROL_STATUS_TYPE.CANCELLED]: 'Cancelled',
})

export const CONTROL_CHECK_ERROR_REASON =
  "Trustero AI had an issue and couldn't complete this test. Trustero is working on a solution."

export const CONTROL_CHECK_ERROR_MAP: Record<string, string> = Object.freeze({
  [CONTROL_CHECK_ERRORS.ERROR]: CONTROL_CHECK_ERROR_REASON,
  [CONTROL_CHECK_ERRORS.JSON_ERROR]: CONTROL_CHECK_ERROR_REASON,
})

export const HIDE_CONTROL_CHECK_REASON_MAP = Object.freeze({
  [HIDE_CONTROL_CHECK_REASONS.NOT_APPLICABLE]: true,
  [HIDE_CONTROL_CHECK_REASONS.NOT_IMPLEMENTED]: true,
  [HIDE_CONTROL_CHECK_REASONS.NOT_APPLICABLE_AUDIT]: true,
  [HIDE_CONTROL_CHECK_REASONS.NOT_APPLICABLE_NON_OCCURRENCE]: true,
  [HIDE_CONTROL_CHECK_REASONS.LAMBDA_FAILURE]: true,
})

export const NO_TIME_DEFAULT = 'N/A'

export enum AuditBotCost {
  ControlTest = 3,
}

export enum AuditbotCostTypes {
  Label = 'label',
}
