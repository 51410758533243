import React, { useEffect, useMemo, useState } from 'react'
import { Range } from 'react-date-range'
import { ModalFormId } from 'src/components/ModalForms/ModalForm'
import { RelevantDateForm } from 'src/pages/Evidence/modals/forms/RelevantDateForm'
import isFunction from 'lodash/isFunction'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { useCurrentAudit } from 'src/components/async/model/audit'
import { stripFileExtension } from 'src/components/Reusable/Forms/FileSelector/FileSelector.helpers'
import { formatTimestamp } from 'src/Utils/formatDate'
import { isWebUri } from 'valid-url'
import {
  AddEvidenceCaption,
  AddEvidenceDetails,
  FilesLoading,
} from './AddEvidenceForm.components'
import { AddEvidenceError, AddEvidenceFormBody } from './AddEvidenceForm.styles'
import {
  AddEvidenceErrors,
  EvidenceErrorInitState,
  EvidenceFormData,
  EvidenceFormDataInitState,
  EvidenceType,
  MAX_UPLOAD_MB,
} from './AddEvidenceForm.constants'

export const AddEvidenceForm = ({
  modalId,
  onSubmitFunc,
  controlId,
  isShown,
}: {
  modalId: ModalFormId
  onSubmitFunc: (
    caption: string,
    formData: EvidenceFormData,
    formDataList: EvidenceFormData[],
    date: Timestamp,
    type: EvidenceType,
    uploadSize?: number,
  ) => Promise<void>
  controlId?: string
  isShown: boolean
}): JSX.Element => {
  const { data } = useCurrentAudit()
  const [formData, formDataSet] = useState<EvidenceFormData>(
    EvidenceFormDataInitState,
  )
  const [formDataList, setFormDataList] = useState<EvidenceFormData[]>([])
  const [errors, setErrors] = useState<AddEvidenceErrors>(
    EvidenceErrorInitState,
  )
  const [caption, setCaption] = useState<string>('')
  const [type, typeSet] = useState<EvidenceType>(EvidenceType.NULL)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [range, setRange] = useState<Range>({
    startDate: undefined,
    endDate: new Date(),
  })
  const [uploadSize, setUploadSize] = useState<number>(0)

  const auditStart = useMemo(() => data?.getStartDate() || undefined, [data])
  const auditEnd = useMemo(() => data?.getEndDate() || undefined, [data])

  const clearFormData = () => {
    formDataSet(EvidenceFormDataInitState)
    setFormDataList([])
    setCaption('')
    setErrors(EvidenceErrorInitState)
    setRange({
      startDate: undefined,
      endDate: new Date(),
    })
    setUploadSize(0)
  }

  useEffect(() => {
    if (!isShown) {
      clearFormData()
    }
  }, [isShown])

  useEffect(() => {
    const now = new Date()
    let endDate
    if (auditStart && auditEnd) {
      if (auditStart.toDate() > now) {
        endDate = auditStart.toDate()
      } else if (auditEnd.toDate() < now) {
        endDate = auditEnd.toDate()
      } else {
        endDate = now
      }
      setRange({
        startDate: undefined,
        endDate,
      })
    }
  }, [auditStart, auditEnd])

  const createCaption = (captionString: string) => {
    setCaption(captionString)
    setErrors((state) => {
      const newState = {
        ...state,
        captionError: '',
      }
      return newState
    })
  }

  const updateUploadSize = (totalSizeInMB: number) => {
    setUploadSize(totalSizeInMB)
  }

  const validateForm = () => {
    let isValid = true
    if (!formDataList.length && !formData.body) {
      setErrors((state) => {
        const newState = {
          ...state,
          evidenceError: 'Please add evidence.',
        }
        return newState
      })
      isValid = false
    }
    if (type !== EvidenceType.FILE || formDataList.length < 2) {
      // If there is only one file, the caption is required
      // If there are multiple files, the caption is not required, the caption will be the file name
      if (!caption.trim().length) {
        setErrors((state) => {
          const newState = {
            ...state,
            captionError: 'Please enter a caption.',
          }
          return newState
        })
        isValid = false
      }
    }
    if (auditStart && auditEnd) {
      if (
        (auditEnd && range.endDate && range.endDate > auditEnd.toDate()) ||
        (auditStart && range.endDate && range.endDate < auditStart.toDate())
      ) {
        setErrors((state) => {
          const newState = {
            ...state,
            dateError: `Select a date within the range of your current audit so evidence is included in this audit. This audit's date range: ${formatTimestamp(
              auditStart,
            )} to ${auditEnd}`,
          }
          return newState
        })
        isValid = false
      }
    }
    if (uploadSize > MAX_UPLOAD_MB) {
      setErrors((state) => {
        const newState = {
          ...state,
          evidenceError: 'Please ensure total size of upload is 100 MB or less',
        }
        return newState
      })
      isValid = false
    }
    if (formDataList.some((formData) => !formData.body)) {
      setErrors((state) => {
        const newState = {
          ...state,
          evidenceError: 'All files must have a body',
        }
        return newState
      })
      isValid = false
    }
    if (type === EvidenceType.LINK && !isWebUri(formData.body as string)) {
      isValid = false
    }
    return isValid
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isValid = validateForm()
    if (!isValid) return
    setIsSubmitting(true)
    isFunction(onSubmitFunc) &&
      (await onSubmitFunc(
        caption,
        formData,
        formDataList,
        Timestamp.fromDate(range.endDate || new Date()),
        type,
        uploadSize,
      ))
    setIsSubmitting(false)
  }

  const updateEvidence = (mime: MIME_TYPE, type: EvidenceType) => {
    formDataSet({
      mime,
      body: '',
    })
    typeSet(type)
    setErrors((state) => {
      const newState = {
        ...state,
        evidenceError: '',
      }
      return newState
    })
  }
  const updateEvidenceFiles = (
    files: EvidenceFormData[],
    type: EvidenceType,
  ) => {
    if (files.length > 1) {
      files.forEach((file) => {
        if (file.body instanceof File) {
          file.caption = stripFileExtension(file.body.name)
        }
      })
    }
    setFormDataList(files)
    typeSet(type)
    setErrors((state) => {
      const newState = {
        ...state,
        evidenceError: '',
      }
      return newState
    })
  }

  return (
    <>
      {isSubmitting ? (
        <FilesLoading
          type={type}
          numFiles={formDataList.length}
          uploadSize={uploadSize}
        />
      ) : (
        <AddEvidenceFormBody id={modalId} onSubmit={onSubmit}>
          <AddEvidenceCaption
            numFiles={formDataList.length}
            caption={caption}
            error={errors.captionError}
            isError={() => !!errors.captionError}
            createCaption={createCaption}
          />
          <br />
          <RelevantDateForm
            range={range}
            setRange={setRange}
            errorSection={
              <AddEvidenceError showError={!!errors.dateError}>
                {errors.dateError}
              </AddEvidenceError>
            }
            controlIds={controlId ? [controlId] : []}
          />
          <br />
          <AddEvidenceDetails
            type={type}
            formData={formData}
            formDataList={formDataList}
            updateEvidence={updateEvidence}
            updateEvidenceFiles={updateEvidenceFiles}
            updateUploadSize={updateUploadSize}
            formDataSet={formDataSet}
            errors={errors}
            modalId={modalId}
          />
        </AddEvidenceFormBody>
      )}
    </>
  )
}
