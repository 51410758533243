import React from 'react'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from 'src/components/ModalForms'
import { ControlsAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { NotCoveredContainer, NotCoveredNames } from './NotCoveredModal.styles'
import { NotCoveredButton } from './NotCoveredModal.components'

// This may need to be updated to be more generic when we add AI Examination and Test support for other frameworks
export const FrameworksNotCoveredModal = ({
  frameworks,
}: {
  frameworks: string[]
}): JSX.Element => {
  const show = useIsShowModal(ModalFormId.FRAMEWORKS_NOT_COVERED)
  const onHide = useHideModal({ modalId: ModalFormId.FRAMEWORKS_NOT_COVERED })

  return (
    <ModalForm
      size="lg"
      formId={ModalFormId.FRAMEWORKS_NOT_COVERED}
      title="These Criteria Are Not Covered"
      description="The following criteria are not covered by any controls. Link some controls to these criteria."
      show={show}
      hide={onHide}
      customFooter={
        <NotCoveredButton
          onHide={onHide}
          route={ControlsAbsoluteRoutes.INDEX}
          text="Link Controls"
        />
      }
      hideCancel
    >
      <form id={ModalFormId.FRAMEWORKS_NOT_COVERED}>
        <NotCoveredContainer>
          <NotCoveredNames>
            {frameworks.map((framework) => (
              <div key={framework}>{framework}</div>
            ))}
          </NotCoveredNames>
        </NotCoveredContainer>
      </form>
    </ModalForm>
  )
}
