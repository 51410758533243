import {
  ANSWER_TYPE,
  QUESTIONNAIRE_TYPE,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'

export const QuestionnaireGuidance = Object.freeze({
  EXPLAIN_ADD_TO_KB:
    'By adding your AI GRC Q&A result to your knowledge base, these answers can be used to answer future security questions.',
  EXPLAIN_ACCEPT_QUESTIONNAIRE:
    'Accepting your AI GRC Q&A result prevents it from being edited and allows you to add it to your knowledge base.',
})

export const getReadableAnswerType = (
  answerType: ANSWER_TYPE,
  questionnaireType?: QUESTIONNAIRE_TYPE, // should always be passed in. Only optional for loading state
): string => {
  switch (answerType) {
    case ANSWER_TYPE.KB_ANSWER:
      return ' using AI GRC Q&A - Basic'
    case ANSWER_TYPE.PLATFORM_ANSWER:
      return ' using AI GRC Q&A - Advanced'
    case ANSWER_TYPE.BOTH_ANSWER:
      if (!questionnaireType) {
        return ''
      }
      switch (questionnaireType) {
        case QUESTIONNAIRE_TYPE.QC_BASIC:
          return ' using AI GRC Q&A - Advanced'
        case QUESTIONNAIRE_TYPE.QC_ADVANCED:
          return ' using AI GRC Q&A - Basic'
        default:
          return ''
      }
    default:
      return ''
  }
}
