// source: roadmap/roadmap.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var service_service_pb = require('../service/service_pb.js');
goog.object.extend(proto, service_service_pb);
goog.exportSymbol('proto.roadmap.COMPANY_INFO_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.CONTROL_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.CompanyInfoCheck', null, global);
goog.exportSymbol('proto.roadmap.CompanyInfoWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.CompanyInfoWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.ComplianceRoadmap', null, global);
goog.exportSymbol('proto.roadmap.ControlCheck', null, global);
goog.exportSymbol('proto.roadmap.ControlWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.ControlWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.FRAMEWORK_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.FrameworkCheck', null, global);
goog.exportSymbol('proto.roadmap.FrameworkWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.FrameworkWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.FrameworksSelected', null, global);
goog.exportSymbol('proto.roadmap.GetInitialScopingRequest', null, global);
goog.exportSymbol('proto.roadmap.GetInitialScopingResponse', null, global);
goog.exportSymbol('proto.roadmap.GetLiveAuditRunsResponse', null, global);
goog.exportSymbol('proto.roadmap.GetOrCreateComplianceRoadmapRequest', null, global);
goog.exportSymbol('proto.roadmap.GetOrCreateComplianceRoadmapResponse', null, global);
goog.exportSymbol('proto.roadmap.GetRiskResponse', null, global);
goog.exportSymbol('proto.roadmap.GetRoadmapWidgetCountsRequest', null, global);
goog.exportSymbol('proto.roadmap.GetRoadmapWidgetCountsResponse', null, global);
goog.exportSymbol('proto.roadmap.GetScopeRequest', null, global);
goog.exportSymbol('proto.roadmap.GetScopeResponse', null, global);
goog.exportSymbol('proto.roadmap.LiveAuditRun', null, global);
goog.exportSymbol('proto.roadmap.POLICY_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.PolicyCheck', null, global);
goog.exportSymbol('proto.roadmap.PolicyWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.PolicyWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.REQUEST_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.RISK_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.RequestCheck', null, global);
goog.exportSymbol('proto.roadmap.RequestWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.RequestWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.RiskCheck', null, global);
goog.exportSymbol('proto.roadmap.RiskWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.RiskWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.SERVICE_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.SERVICE_ROLE_CHECKS', null, global);
goog.exportSymbol('proto.roadmap.ServiceCheck', null, global);
goog.exportSymbol('proto.roadmap.ServiceRoleCheck', null, global);
goog.exportSymbol('proto.roadmap.ServiceRoleWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.ServiceRoleWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.ServiceWidgetRequestConfig', null, global);
goog.exportSymbol('proto.roadmap.ServiceWidgetResponse', null, global);
goog.exportSymbol('proto.roadmap.ToggleRequiredDocumentsRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ServiceRoleWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ServiceRoleWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ServiceRoleWidgetRequestConfig.displayName = 'proto.roadmap.ServiceRoleWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ServiceWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ServiceWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ServiceWidgetRequestConfig.displayName = 'proto.roadmap.ServiceWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ControlWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ControlWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ControlWidgetRequestConfig.displayName = 'proto.roadmap.ControlWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.PolicyWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.PolicyWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.PolicyWidgetRequestConfig.displayName = 'proto.roadmap.PolicyWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.FrameworkWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.FrameworkWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.FrameworkWidgetRequestConfig.displayName = 'proto.roadmap.FrameworkWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.CompanyInfoWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.CompanyInfoWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.CompanyInfoWidgetRequestConfig.displayName = 'proto.roadmap.CompanyInfoWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.RiskWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.RiskWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.RiskWidgetRequestConfig.displayName = 'proto.roadmap.RiskWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.RequestWidgetRequestConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.RequestWidgetRequestConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.RequestWidgetRequestConfig.displayName = 'proto.roadmap.RequestWidgetRequestConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetRoadmapWidgetCountsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetRoadmapWidgetCountsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetRoadmapWidgetCountsRequest.displayName = 'proto.roadmap.GetRoadmapWidgetCountsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetRoadmapWidgetCountsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetRoadmapWidgetCountsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetRoadmapWidgetCountsResponse.displayName = 'proto.roadmap.GetRoadmapWidgetCountsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ServiceRoleCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.ServiceRoleCheck.repeatedFields_, null);
};
goog.inherits(proto.roadmap.ServiceRoleCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ServiceRoleCheck.displayName = 'proto.roadmap.ServiceRoleCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ServiceRoleWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.ServiceRoleWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.ServiceRoleWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ServiceRoleWidgetResponse.displayName = 'proto.roadmap.ServiceRoleWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ServiceCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ServiceCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ServiceCheck.displayName = 'proto.roadmap.ServiceCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ServiceWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.ServiceWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.ServiceWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ServiceWidgetResponse.displayName = 'proto.roadmap.ServiceWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ControlCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ControlCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ControlCheck.displayName = 'proto.roadmap.ControlCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ControlWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.ControlWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.ControlWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ControlWidgetResponse.displayName = 'proto.roadmap.ControlWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.PolicyCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.PolicyCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.PolicyCheck.displayName = 'proto.roadmap.PolicyCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.PolicyWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.PolicyWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.PolicyWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.PolicyWidgetResponse.displayName = 'proto.roadmap.PolicyWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.CompanyInfoCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.CompanyInfoCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.CompanyInfoCheck.displayName = 'proto.roadmap.CompanyInfoCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.CompanyInfoWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.CompanyInfoWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.CompanyInfoWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.CompanyInfoWidgetResponse.displayName = 'proto.roadmap.CompanyInfoWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.FrameworkCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.FrameworkCheck.repeatedFields_, null);
};
goog.inherits(proto.roadmap.FrameworkCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.FrameworkCheck.displayName = 'proto.roadmap.FrameworkCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.FrameworkWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.FrameworkWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.FrameworkWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.FrameworkWidgetResponse.displayName = 'proto.roadmap.FrameworkWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.RiskCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.RiskCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.RiskCheck.displayName = 'proto.roadmap.RiskCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.RiskWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.RiskWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.RiskWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.RiskWidgetResponse.displayName = 'proto.roadmap.RiskWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.RequestCheck = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.RequestCheck, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.RequestCheck.displayName = 'proto.roadmap.RequestCheck';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.RequestWidgetResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.RequestWidgetResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.RequestWidgetResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.RequestWidgetResponse.displayName = 'proto.roadmap.RequestWidgetResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetScopeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetScopeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetScopeRequest.displayName = 'proto.roadmap.GetScopeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetScopeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetScopeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetScopeResponse.displayName = 'proto.roadmap.GetScopeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetRiskResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetRiskResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetRiskResponse.displayName = 'proto.roadmap.GetRiskResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetInitialScopingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetInitialScopingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetInitialScopingRequest.displayName = 'proto.roadmap.GetInitialScopingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetInitialScopingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.GetInitialScopingResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.GetInitialScopingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetInitialScopingResponse.displayName = 'proto.roadmap.GetInitialScopingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.FrameworksSelected = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.FrameworksSelected, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.FrameworksSelected.displayName = 'proto.roadmap.FrameworksSelected';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetOrCreateComplianceRoadmapRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetOrCreateComplianceRoadmapRequest.displayName = 'proto.roadmap.GetOrCreateComplianceRoadmapRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ComplianceRoadmap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ComplianceRoadmap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ComplianceRoadmap.displayName = 'proto.roadmap.ComplianceRoadmap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.GetOrCreateComplianceRoadmapResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetOrCreateComplianceRoadmapResponse.displayName = 'proto.roadmap.GetOrCreateComplianceRoadmapResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.ToggleRequiredDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.ToggleRequiredDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.ToggleRequiredDocumentsRequest.displayName = 'proto.roadmap.ToggleRequiredDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.LiveAuditRun = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.roadmap.LiveAuditRun, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.LiveAuditRun.displayName = 'proto.roadmap.LiveAuditRun';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.roadmap.GetLiveAuditRunsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.roadmap.GetLiveAuditRunsResponse.repeatedFields_, null);
};
goog.inherits(proto.roadmap.GetLiveAuditRunsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.roadmap.GetLiveAuditRunsResponse.displayName = 'proto.roadmap.GetLiveAuditRunsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ServiceRoleWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ServiceRoleWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeRoleFulfilled: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ServiceRoleWidgetRequestConfig}
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ServiceRoleWidgetRequestConfig;
  return proto.roadmap.ServiceRoleWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ServiceRoleWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ServiceRoleWidgetRequestConfig}
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeRoleFulfilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ServiceRoleWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ServiceRoleWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeRoleFulfilled();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_role_fulfilled = 1;
 * @return {boolean}
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.prototype.getIncludeRoleFulfilled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ServiceRoleWidgetRequestConfig} returns this
 */
proto.roadmap.ServiceRoleWidgetRequestConfig.prototype.setIncludeRoleFulfilled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ServiceWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ServiceWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ServiceWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeServiceSelected: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ServiceWidgetRequestConfig}
 */
proto.roadmap.ServiceWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ServiceWidgetRequestConfig;
  return proto.roadmap.ServiceWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ServiceWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ServiceWidgetRequestConfig}
 */
proto.roadmap.ServiceWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeServiceSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ServiceWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ServiceWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ServiceWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeServiceSelected();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_service_selected = 1;
 * @return {boolean}
 */
proto.roadmap.ServiceWidgetRequestConfig.prototype.getIncludeServiceSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ServiceWidgetRequestConfig} returns this
 */
proto.roadmap.ServiceWidgetRequestConfig.prototype.setIncludeServiceSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ControlWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ControlWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ControlWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeHasAssignee: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeHasPolicy: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeHasFramework: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeHasEvidence: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    includeHasPassingStatus: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    includeHasTestProcedures: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ControlWidgetRequestConfig}
 */
proto.roadmap.ControlWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ControlWidgetRequestConfig;
  return proto.roadmap.ControlWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ControlWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ControlWidgetRequestConfig}
 */
proto.roadmap.ControlWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasAssignee(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasPolicy(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasFramework(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasEvidence(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasPassingStatus(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasTestProcedures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ControlWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ControlWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ControlWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeHasAssignee();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeHasPolicy();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeHasFramework();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeHasEvidence();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIncludeHasPassingStatus();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIncludeHasTestProcedures();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional bool include_has_assignee = 1;
 * @return {boolean}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.getIncludeHasAssignee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ControlWidgetRequestConfig} returns this
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.setIncludeHasAssignee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_has_policy = 2;
 * @return {boolean}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.getIncludeHasPolicy = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ControlWidgetRequestConfig} returns this
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.setIncludeHasPolicy = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_has_framework = 3;
 * @return {boolean}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.getIncludeHasFramework = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ControlWidgetRequestConfig} returns this
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.setIncludeHasFramework = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_has_evidence = 4;
 * @return {boolean}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.getIncludeHasEvidence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ControlWidgetRequestConfig} returns this
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.setIncludeHasEvidence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool include_has_passing_status = 5;
 * @return {boolean}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.getIncludeHasPassingStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ControlWidgetRequestConfig} returns this
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.setIncludeHasPassingStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool include_has_test_procedures = 6;
 * @return {boolean}
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.getIncludeHasTestProcedures = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ControlWidgetRequestConfig} returns this
 */
proto.roadmap.ControlWidgetRequestConfig.prototype.setIncludeHasTestProcedures = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.PolicyWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.PolicyWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.PolicyWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeHasAssignee: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeHasLinkedControls: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeHasDocumentation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeIsFilledIn: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.PolicyWidgetRequestConfig}
 */
proto.roadmap.PolicyWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.PolicyWidgetRequestConfig;
  return proto.roadmap.PolicyWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.PolicyWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.PolicyWidgetRequestConfig}
 */
proto.roadmap.PolicyWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasAssignee(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasLinkedControls(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasDocumentation(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeIsFilledIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.PolicyWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.PolicyWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.PolicyWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeHasAssignee();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeHasLinkedControls();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeHasDocumentation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeIsFilledIn();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool include_has_assignee = 1;
 * @return {boolean}
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.getIncludeHasAssignee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.PolicyWidgetRequestConfig} returns this
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.setIncludeHasAssignee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_has_linked_controls = 2;
 * @return {boolean}
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.getIncludeHasLinkedControls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.PolicyWidgetRequestConfig} returns this
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.setIncludeHasLinkedControls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_has_documentation = 3;
 * @return {boolean}
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.getIncludeHasDocumentation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.PolicyWidgetRequestConfig} returns this
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.setIncludeHasDocumentation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_is_filled_in = 4;
 * @return {boolean}
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.getIncludeIsFilledIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.PolicyWidgetRequestConfig} returns this
 */
proto.roadmap.PolicyWidgetRequestConfig.prototype.setIncludeIsFilledIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.FrameworkWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.FrameworkWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.FrameworkWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworkWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeHasLinkedControls: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.FrameworkWidgetRequestConfig}
 */
proto.roadmap.FrameworkWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.FrameworkWidgetRequestConfig;
  return proto.roadmap.FrameworkWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.FrameworkWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.FrameworkWidgetRequestConfig}
 */
proto.roadmap.FrameworkWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasLinkedControls(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.FrameworkWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.FrameworkWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.FrameworkWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworkWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeHasLinkedControls();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_has_linked_controls = 1;
 * @return {boolean}
 */
proto.roadmap.FrameworkWidgetRequestConfig.prototype.getIncludeHasLinkedControls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.FrameworkWidgetRequestConfig} returns this
 */
proto.roadmap.FrameworkWidgetRequestConfig.prototype.setIncludeHasLinkedControls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.CompanyInfoWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.CompanyInfoWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeHasDocumentation: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeIsFilledIn: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.CompanyInfoWidgetRequestConfig}
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.CompanyInfoWidgetRequestConfig;
  return proto.roadmap.CompanyInfoWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.CompanyInfoWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.CompanyInfoWidgetRequestConfig}
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasDocumentation(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeIsFilledIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.CompanyInfoWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.CompanyInfoWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeHasDocumentation();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeIsFilledIn();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool include_has_documentation = 1;
 * @return {boolean}
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.prototype.getIncludeHasDocumentation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.CompanyInfoWidgetRequestConfig} returns this
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.prototype.setIncludeHasDocumentation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_is_filled_in = 2;
 * @return {boolean}
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.prototype.getIncludeIsFilledIn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.CompanyInfoWidgetRequestConfig} returns this
 */
proto.roadmap.CompanyInfoWidgetRequestConfig.prototype.setIncludeIsFilledIn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.RiskWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.RiskWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RiskWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeHasAssignee: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeInherentRiskDetermined: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeResidualRiskDetermined: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeHasPassingStatus: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.RiskWidgetRequestConfig}
 */
proto.roadmap.RiskWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.RiskWidgetRequestConfig;
  return proto.roadmap.RiskWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.RiskWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.RiskWidgetRequestConfig}
 */
proto.roadmap.RiskWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasAssignee(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeInherentRiskDetermined(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeResidualRiskDetermined(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasPassingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.RiskWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.RiskWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RiskWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeHasAssignee();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeInherentRiskDetermined();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeResidualRiskDetermined();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeHasPassingStatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool include_has_assignee = 1;
 * @return {boolean}
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.getIncludeHasAssignee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RiskWidgetRequestConfig} returns this
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.setIncludeHasAssignee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_inherent_risk_determined = 2;
 * @return {boolean}
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.getIncludeInherentRiskDetermined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RiskWidgetRequestConfig} returns this
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.setIncludeInherentRiskDetermined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_residual_risk_determined = 3;
 * @return {boolean}
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.getIncludeResidualRiskDetermined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RiskWidgetRequestConfig} returns this
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.setIncludeResidualRiskDetermined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_has_passing_status = 4;
 * @return {boolean}
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.getIncludeHasPassingStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RiskWidgetRequestConfig} returns this
 */
proto.roadmap.RiskWidgetRequestConfig.prototype.setIncludeHasPassingStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.RequestWidgetRequestConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.RequestWidgetRequestConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RequestWidgetRequestConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeHasAssignee: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeHasEvidence: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    includeHasLinkedControls: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    includeHasPassingStatus: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.RequestWidgetRequestConfig}
 */
proto.roadmap.RequestWidgetRequestConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.RequestWidgetRequestConfig;
  return proto.roadmap.RequestWidgetRequestConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.RequestWidgetRequestConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.RequestWidgetRequestConfig}
 */
proto.roadmap.RequestWidgetRequestConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasAssignee(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasEvidence(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasLinkedControls(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeHasPassingStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.RequestWidgetRequestConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.RequestWidgetRequestConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RequestWidgetRequestConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeHasAssignee();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeHasEvidence();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIncludeHasLinkedControls();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getIncludeHasPassingStatus();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional bool include_has_assignee = 1;
 * @return {boolean}
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.getIncludeHasAssignee = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RequestWidgetRequestConfig} returns this
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.setIncludeHasAssignee = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_has_evidence = 2;
 * @return {boolean}
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.getIncludeHasEvidence = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RequestWidgetRequestConfig} returns this
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.setIncludeHasEvidence = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool include_has_linked_controls = 3;
 * @return {boolean}
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.getIncludeHasLinkedControls = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RequestWidgetRequestConfig} returns this
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.setIncludeHasLinkedControls = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool include_has_passing_status = 4;
 * @return {boolean}
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.getIncludeHasPassingStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.RequestWidgetRequestConfig} returns this
 */
proto.roadmap.RequestWidgetRequestConfig.prototype.setIncludeHasPassingStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetRoadmapWidgetCountsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetRoadmapWidgetCountsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    ownerEmail: (f = msg.getOwnerEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    serviceChecks: (f = msg.getServiceChecks()) && proto.roadmap.ServiceWidgetRequestConfig.toObject(includeInstance, f),
    controlChecks: (f = msg.getControlChecks()) && proto.roadmap.ControlWidgetRequestConfig.toObject(includeInstance, f),
    policyChecks: (f = msg.getPolicyChecks()) && proto.roadmap.PolicyWidgetRequestConfig.toObject(includeInstance, f),
    frameworkChecks: (f = msg.getFrameworkChecks()) && proto.roadmap.FrameworkWidgetRequestConfig.toObject(includeInstance, f),
    companyInfoChecks: (f = msg.getCompanyInfoChecks()) && proto.roadmap.CompanyInfoWidgetRequestConfig.toObject(includeInstance, f),
    riskChecks: (f = msg.getRiskChecks()) && proto.roadmap.RiskWidgetRequestConfig.toObject(includeInstance, f),
    requestChecks: (f = msg.getRequestChecks()) && proto.roadmap.RequestWidgetRequestConfig.toObject(includeInstance, f),
    serviceRoleChecks: (f = msg.getServiceRoleChecks()) && proto.roadmap.ServiceRoleWidgetRequestConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetRoadmapWidgetCountsRequest;
  return proto.roadmap.GetRoadmapWidgetCountsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetRoadmapWidgetCountsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOwnerEmail(value);
      break;
    case 3:
      var value = new proto.roadmap.ServiceWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.ServiceWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setServiceChecks(value);
      break;
    case 4:
      var value = new proto.roadmap.ControlWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.ControlWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setControlChecks(value);
      break;
    case 5:
      var value = new proto.roadmap.PolicyWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.PolicyWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setPolicyChecks(value);
      break;
    case 6:
      var value = new proto.roadmap.FrameworkWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.FrameworkWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setFrameworkChecks(value);
      break;
    case 7:
      var value = new proto.roadmap.CompanyInfoWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.CompanyInfoWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setCompanyInfoChecks(value);
      break;
    case 8:
      var value = new proto.roadmap.RiskWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.RiskWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setRiskChecks(value);
      break;
    case 9:
      var value = new proto.roadmap.RequestWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.RequestWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setRequestChecks(value);
      break;
    case 10:
      var value = new proto.roadmap.ServiceRoleWidgetRequestConfig;
      reader.readMessage(value,proto.roadmap.ServiceRoleWidgetRequestConfig.deserializeBinaryFromReader);
      msg.setServiceRoleChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetRoadmapWidgetCountsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetRoadmapWidgetCountsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOwnerEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getServiceChecks();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.roadmap.ServiceWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getControlChecks();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.roadmap.ControlWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getPolicyChecks();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.roadmap.PolicyWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getFrameworkChecks();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.roadmap.FrameworkWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getCompanyInfoChecks();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.roadmap.CompanyInfoWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getRiskChecks();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.roadmap.RiskWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getRequestChecks();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.roadmap.RequestWidgetRequestConfig.serializeBinaryToWriter
    );
  }
  f = message.getServiceRoleChecks();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.roadmap.ServiceRoleWidgetRequestConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue owner_email = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getOwnerEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearOwnerEmail = function() {
  return this.setOwnerEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasOwnerEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ServiceWidgetRequestConfig service_checks = 3;
 * @return {?proto.roadmap.ServiceWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getServiceChecks = function() {
  return /** @type{?proto.roadmap.ServiceWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ServiceWidgetRequestConfig, 3));
};


/**
 * @param {?proto.roadmap.ServiceWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setServiceChecks = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearServiceChecks = function() {
  return this.setServiceChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasServiceChecks = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ControlWidgetRequestConfig control_checks = 4;
 * @return {?proto.roadmap.ControlWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getControlChecks = function() {
  return /** @type{?proto.roadmap.ControlWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ControlWidgetRequestConfig, 4));
};


/**
 * @param {?proto.roadmap.ControlWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setControlChecks = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearControlChecks = function() {
  return this.setControlChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasControlChecks = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PolicyWidgetRequestConfig policy_checks = 5;
 * @return {?proto.roadmap.PolicyWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getPolicyChecks = function() {
  return /** @type{?proto.roadmap.PolicyWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.PolicyWidgetRequestConfig, 5));
};


/**
 * @param {?proto.roadmap.PolicyWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setPolicyChecks = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearPolicyChecks = function() {
  return this.setPolicyChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasPolicyChecks = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FrameworkWidgetRequestConfig framework_checks = 6;
 * @return {?proto.roadmap.FrameworkWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getFrameworkChecks = function() {
  return /** @type{?proto.roadmap.FrameworkWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.FrameworkWidgetRequestConfig, 6));
};


/**
 * @param {?proto.roadmap.FrameworkWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setFrameworkChecks = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearFrameworkChecks = function() {
  return this.setFrameworkChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasFrameworkChecks = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional CompanyInfoWidgetRequestConfig company_info_checks = 7;
 * @return {?proto.roadmap.CompanyInfoWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getCompanyInfoChecks = function() {
  return /** @type{?proto.roadmap.CompanyInfoWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.CompanyInfoWidgetRequestConfig, 7));
};


/**
 * @param {?proto.roadmap.CompanyInfoWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setCompanyInfoChecks = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearCompanyInfoChecks = function() {
  return this.setCompanyInfoChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasCompanyInfoChecks = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional RiskWidgetRequestConfig risk_checks = 8;
 * @return {?proto.roadmap.RiskWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getRiskChecks = function() {
  return /** @type{?proto.roadmap.RiskWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.RiskWidgetRequestConfig, 8));
};


/**
 * @param {?proto.roadmap.RiskWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setRiskChecks = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearRiskChecks = function() {
  return this.setRiskChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasRiskChecks = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional RequestWidgetRequestConfig request_checks = 9;
 * @return {?proto.roadmap.RequestWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getRequestChecks = function() {
  return /** @type{?proto.roadmap.RequestWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.RequestWidgetRequestConfig, 9));
};


/**
 * @param {?proto.roadmap.RequestWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setRequestChecks = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearRequestChecks = function() {
  return this.setRequestChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasRequestChecks = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional ServiceRoleWidgetRequestConfig service_role_checks = 10;
 * @return {?proto.roadmap.ServiceRoleWidgetRequestConfig}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.getServiceRoleChecks = function() {
  return /** @type{?proto.roadmap.ServiceRoleWidgetRequestConfig} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ServiceRoleWidgetRequestConfig, 10));
};


/**
 * @param {?proto.roadmap.ServiceRoleWidgetRequestConfig|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.setServiceRoleChecks = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsRequest} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.clearServiceRoleChecks = function() {
  return this.setServiceRoleChecks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsRequest.prototype.hasServiceRoleChecks = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetRoadmapWidgetCountsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetRoadmapWidgetCountsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    services: (f = msg.getServices()) && proto.roadmap.ServiceWidgetResponse.toObject(includeInstance, f),
    controls: (f = msg.getControls()) && proto.roadmap.ControlWidgetResponse.toObject(includeInstance, f),
    policies: (f = msg.getPolicies()) && proto.roadmap.PolicyWidgetResponse.toObject(includeInstance, f),
    companyInfo: (f = msg.getCompanyInfo()) && proto.roadmap.CompanyInfoWidgetResponse.toObject(includeInstance, f),
    frameworks: (f = msg.getFrameworks()) && proto.roadmap.FrameworkWidgetResponse.toObject(includeInstance, f),
    risks: (f = msg.getRisks()) && proto.roadmap.RiskWidgetResponse.toObject(includeInstance, f),
    requests: (f = msg.getRequests()) && proto.roadmap.RequestWidgetResponse.toObject(includeInstance, f),
    serviceRoles: (f = msg.getServiceRoles()) && proto.roadmap.ServiceRoleWidgetResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetRoadmapWidgetCountsResponse;
  return proto.roadmap.GetRoadmapWidgetCountsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetRoadmapWidgetCountsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.roadmap.ServiceWidgetResponse;
      reader.readMessage(value,proto.roadmap.ServiceWidgetResponse.deserializeBinaryFromReader);
      msg.setServices(value);
      break;
    case 2:
      var value = new proto.roadmap.ControlWidgetResponse;
      reader.readMessage(value,proto.roadmap.ControlWidgetResponse.deserializeBinaryFromReader);
      msg.setControls(value);
      break;
    case 3:
      var value = new proto.roadmap.PolicyWidgetResponse;
      reader.readMessage(value,proto.roadmap.PolicyWidgetResponse.deserializeBinaryFromReader);
      msg.setPolicies(value);
      break;
    case 4:
      var value = new proto.roadmap.CompanyInfoWidgetResponse;
      reader.readMessage(value,proto.roadmap.CompanyInfoWidgetResponse.deserializeBinaryFromReader);
      msg.setCompanyInfo(value);
      break;
    case 5:
      var value = new proto.roadmap.FrameworkWidgetResponse;
      reader.readMessage(value,proto.roadmap.FrameworkWidgetResponse.deserializeBinaryFromReader);
      msg.setFrameworks(value);
      break;
    case 6:
      var value = new proto.roadmap.RiskWidgetResponse;
      reader.readMessage(value,proto.roadmap.RiskWidgetResponse.deserializeBinaryFromReader);
      msg.setRisks(value);
      break;
    case 7:
      var value = new proto.roadmap.RequestWidgetResponse;
      reader.readMessage(value,proto.roadmap.RequestWidgetResponse.deserializeBinaryFromReader);
      msg.setRequests(value);
      break;
    case 8:
      var value = new proto.roadmap.ServiceRoleWidgetResponse;
      reader.readMessage(value,proto.roadmap.ServiceRoleWidgetResponse.deserializeBinaryFromReader);
      msg.setServiceRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetRoadmapWidgetCountsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetRoadmapWidgetCountsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServices();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.roadmap.ServiceWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getControls();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.roadmap.ControlWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getPolicies();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.roadmap.PolicyWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getCompanyInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.roadmap.CompanyInfoWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getFrameworks();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.roadmap.FrameworkWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getRisks();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.roadmap.RiskWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getRequests();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.roadmap.RequestWidgetResponse.serializeBinaryToWriter
    );
  }
  f = message.getServiceRoles();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.roadmap.ServiceRoleWidgetResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServiceWidgetResponse services = 1;
 * @return {?proto.roadmap.ServiceWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getServices = function() {
  return /** @type{?proto.roadmap.ServiceWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ServiceWidgetResponse, 1));
};


/**
 * @param {?proto.roadmap.ServiceWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setServices = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearServices = function() {
  return this.setServices(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasServices = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ControlWidgetResponse controls = 2;
 * @return {?proto.roadmap.ControlWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getControls = function() {
  return /** @type{?proto.roadmap.ControlWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ControlWidgetResponse, 2));
};


/**
 * @param {?proto.roadmap.ControlWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setControls = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearControls = function() {
  return this.setControls(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasControls = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PolicyWidgetResponse policies = 3;
 * @return {?proto.roadmap.PolicyWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getPolicies = function() {
  return /** @type{?proto.roadmap.PolicyWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.PolicyWidgetResponse, 3));
};


/**
 * @param {?proto.roadmap.PolicyWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setPolicies = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearPolicies = function() {
  return this.setPolicies(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasPolicies = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CompanyInfoWidgetResponse company_info = 4;
 * @return {?proto.roadmap.CompanyInfoWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getCompanyInfo = function() {
  return /** @type{?proto.roadmap.CompanyInfoWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.CompanyInfoWidgetResponse, 4));
};


/**
 * @param {?proto.roadmap.CompanyInfoWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setCompanyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearCompanyInfo = function() {
  return this.setCompanyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasCompanyInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FrameworkWidgetResponse frameworks = 5;
 * @return {?proto.roadmap.FrameworkWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getFrameworks = function() {
  return /** @type{?proto.roadmap.FrameworkWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.FrameworkWidgetResponse, 5));
};


/**
 * @param {?proto.roadmap.FrameworkWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setFrameworks = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearFrameworks = function() {
  return this.setFrameworks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasFrameworks = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RiskWidgetResponse risks = 6;
 * @return {?proto.roadmap.RiskWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getRisks = function() {
  return /** @type{?proto.roadmap.RiskWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.RiskWidgetResponse, 6));
};


/**
 * @param {?proto.roadmap.RiskWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setRisks = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearRisks = function() {
  return this.setRisks(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasRisks = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional RequestWidgetResponse requests = 7;
 * @return {?proto.roadmap.RequestWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getRequests = function() {
  return /** @type{?proto.roadmap.RequestWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.RequestWidgetResponse, 7));
};


/**
 * @param {?proto.roadmap.RequestWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setRequests = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearRequests = function() {
  return this.setRequests(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasRequests = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ServiceRoleWidgetResponse service_roles = 8;
 * @return {?proto.roadmap.ServiceRoleWidgetResponse}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.getServiceRoles = function() {
  return /** @type{?proto.roadmap.ServiceRoleWidgetResponse} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ServiceRoleWidgetResponse, 8));
};


/**
 * @param {?proto.roadmap.ServiceRoleWidgetResponse|undefined} value
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
*/
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.setServiceRoles = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetRoadmapWidgetCountsResponse} returns this
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.clearServiceRoles = function() {
  return this.setServiceRoles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetRoadmapWidgetCountsResponse.prototype.hasServiceRoles = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.ServiceRoleCheck.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ServiceRoleCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ServiceRoleCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ServiceRoleCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceRoleCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    unfulfilledRolesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ServiceRoleCheck}
 */
proto.roadmap.ServiceRoleCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ServiceRoleCheck;
  return proto.roadmap.ServiceRoleCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ServiceRoleCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ServiceRoleCheck}
 */
proto.roadmap.ServiceRoleCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.SERVICE_ROLE_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addUnfulfilledRoles(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ServiceRoleCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ServiceRoleCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ServiceRoleCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceRoleCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getUnfulfilledRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
};


/**
 * optional SERVICE_ROLE_CHECKS check_type = 1;
 * @return {!proto.roadmap.SERVICE_ROLE_CHECKS}
 */
proto.roadmap.ServiceRoleCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.SERVICE_ROLE_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.SERVICE_ROLE_CHECKS} value
 * @return {!proto.roadmap.ServiceRoleCheck} returns this
 */
proto.roadmap.ServiceRoleCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.ServiceRoleCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ServiceRoleCheck} returns this
 */
proto.roadmap.ServiceRoleCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated service.SERVICE_ROLE unfulfilled_roles = 3;
 * @return {!Array<!proto.service.SERVICE_ROLE>}
 */
proto.roadmap.ServiceRoleCheck.prototype.getUnfulfilledRolesList = function() {
  return /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.service.SERVICE_ROLE>} value
 * @return {!proto.roadmap.ServiceRoleCheck} returns this
 */
proto.roadmap.ServiceRoleCheck.prototype.setUnfulfilledRolesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.service.SERVICE_ROLE} value
 * @param {number=} opt_index
 * @return {!proto.roadmap.ServiceRoleCheck} returns this
 */
proto.roadmap.ServiceRoleCheck.prototype.addUnfulfilledRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.ServiceRoleCheck} returns this
 */
proto.roadmap.ServiceRoleCheck.prototype.clearUnfulfilledRolesList = function() {
  return this.setUnfulfilledRolesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.ServiceRoleWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ServiceRoleWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ServiceRoleWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceRoleWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    serviceRoleChecksList: jspb.Message.toObjectList(msg.getServiceRoleChecksList(),
    proto.roadmap.ServiceRoleCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ServiceRoleWidgetResponse}
 */
proto.roadmap.ServiceRoleWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ServiceRoleWidgetResponse;
  return proto.roadmap.ServiceRoleWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ServiceRoleWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ServiceRoleWidgetResponse}
 */
proto.roadmap.ServiceRoleWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.ServiceRoleCheck;
      reader.readMessage(value,proto.roadmap.ServiceRoleCheck.deserializeBinaryFromReader);
      msg.addServiceRoleChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ServiceRoleWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ServiceRoleWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceRoleWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getServiceRoleChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.ServiceRoleCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ServiceRoleWidgetResponse} returns this
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ServiceRoleWidgetResponse} returns this
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ServiceRoleCheck service_role_checks = 3;
 * @return {!Array<!proto.roadmap.ServiceRoleCheck>}
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.getServiceRoleChecksList = function() {
  return /** @type{!Array<!proto.roadmap.ServiceRoleCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.ServiceRoleCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.ServiceRoleCheck>} value
 * @return {!proto.roadmap.ServiceRoleWidgetResponse} returns this
*/
proto.roadmap.ServiceRoleWidgetResponse.prototype.setServiceRoleChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.ServiceRoleCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.ServiceRoleCheck}
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.addServiceRoleChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.ServiceRoleCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.ServiceRoleWidgetResponse} returns this
 */
proto.roadmap.ServiceRoleWidgetResponse.prototype.clearServiceRoleChecksList = function() {
  return this.setServiceRoleChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ServiceCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ServiceCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ServiceCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ServiceCheck}
 */
proto.roadmap.ServiceCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ServiceCheck;
  return proto.roadmap.ServiceCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ServiceCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ServiceCheck}
 */
proto.roadmap.ServiceCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.SERVICE_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ServiceCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ServiceCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ServiceCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional SERVICE_CHECKS check_type = 1;
 * @return {!proto.roadmap.SERVICE_CHECKS}
 */
proto.roadmap.ServiceCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.SERVICE_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.SERVICE_CHECKS} value
 * @return {!proto.roadmap.ServiceCheck} returns this
 */
proto.roadmap.ServiceCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.ServiceCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ServiceCheck} returns this
 */
proto.roadmap.ServiceCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.ServiceWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ServiceWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ServiceWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ServiceWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    serviceChecksList: jspb.Message.toObjectList(msg.getServiceChecksList(),
    proto.roadmap.ServiceCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ServiceWidgetResponse}
 */
proto.roadmap.ServiceWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ServiceWidgetResponse;
  return proto.roadmap.ServiceWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ServiceWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ServiceWidgetResponse}
 */
proto.roadmap.ServiceWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.ServiceCheck;
      reader.readMessage(value,proto.roadmap.ServiceCheck.deserializeBinaryFromReader);
      msg.addServiceChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ServiceWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ServiceWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ServiceWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ServiceWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getServiceChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.ServiceCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.ServiceWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ServiceWidgetResponse} returns this
 */
proto.roadmap.ServiceWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.ServiceWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ServiceWidgetResponse} returns this
 */
proto.roadmap.ServiceWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ServiceCheck service_checks = 3;
 * @return {!Array<!proto.roadmap.ServiceCheck>}
 */
proto.roadmap.ServiceWidgetResponse.prototype.getServiceChecksList = function() {
  return /** @type{!Array<!proto.roadmap.ServiceCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.ServiceCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.ServiceCheck>} value
 * @return {!proto.roadmap.ServiceWidgetResponse} returns this
*/
proto.roadmap.ServiceWidgetResponse.prototype.setServiceChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.ServiceCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.ServiceCheck}
 */
proto.roadmap.ServiceWidgetResponse.prototype.addServiceChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.ServiceCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.ServiceWidgetResponse} returns this
 */
proto.roadmap.ServiceWidgetResponse.prototype.clearServiceChecksList = function() {
  return this.setServiceChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ControlCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ControlCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ControlCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ControlCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ControlCheck}
 */
proto.roadmap.ControlCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ControlCheck;
  return proto.roadmap.ControlCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ControlCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ControlCheck}
 */
proto.roadmap.ControlCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.CONTROL_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ControlCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ControlCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ControlCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ControlCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional CONTROL_CHECKS check_type = 1;
 * @return {!proto.roadmap.CONTROL_CHECKS}
 */
proto.roadmap.ControlCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.CONTROL_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.CONTROL_CHECKS} value
 * @return {!proto.roadmap.ControlCheck} returns this
 */
proto.roadmap.ControlCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.ControlCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ControlCheck} returns this
 */
proto.roadmap.ControlCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.ControlWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ControlWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ControlWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ControlWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ControlWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    controlChecksList: jspb.Message.toObjectList(msg.getControlChecksList(),
    proto.roadmap.ControlCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ControlWidgetResponse}
 */
proto.roadmap.ControlWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ControlWidgetResponse;
  return proto.roadmap.ControlWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ControlWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ControlWidgetResponse}
 */
proto.roadmap.ControlWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.ControlCheck;
      reader.readMessage(value,proto.roadmap.ControlCheck.deserializeBinaryFromReader);
      msg.addControlChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ControlWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ControlWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ControlWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ControlWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getControlChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.ControlCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.ControlWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ControlWidgetResponse} returns this
 */
proto.roadmap.ControlWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.ControlWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.ControlWidgetResponse} returns this
 */
proto.roadmap.ControlWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated ControlCheck control_checks = 3;
 * @return {!Array<!proto.roadmap.ControlCheck>}
 */
proto.roadmap.ControlWidgetResponse.prototype.getControlChecksList = function() {
  return /** @type{!Array<!proto.roadmap.ControlCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.ControlCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.ControlCheck>} value
 * @return {!proto.roadmap.ControlWidgetResponse} returns this
*/
proto.roadmap.ControlWidgetResponse.prototype.setControlChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.ControlCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.ControlCheck}
 */
proto.roadmap.ControlWidgetResponse.prototype.addControlChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.ControlCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.ControlWidgetResponse} returns this
 */
proto.roadmap.ControlWidgetResponse.prototype.clearControlChecksList = function() {
  return this.setControlChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.PolicyCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.PolicyCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.PolicyCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.PolicyCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.PolicyCheck}
 */
proto.roadmap.PolicyCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.PolicyCheck;
  return proto.roadmap.PolicyCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.PolicyCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.PolicyCheck}
 */
proto.roadmap.PolicyCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.POLICY_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.PolicyCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.PolicyCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.PolicyCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.PolicyCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional POLICY_CHECKS check_type = 1;
 * @return {!proto.roadmap.POLICY_CHECKS}
 */
proto.roadmap.PolicyCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.POLICY_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.POLICY_CHECKS} value
 * @return {!proto.roadmap.PolicyCheck} returns this
 */
proto.roadmap.PolicyCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.PolicyCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.PolicyCheck} returns this
 */
proto.roadmap.PolicyCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_count = 3;
 * @return {number}
 */
proto.roadmap.PolicyCheck.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.PolicyCheck} returns this
 */
proto.roadmap.PolicyCheck.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.PolicyWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.PolicyWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.PolicyWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.PolicyWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.PolicyWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    policyChecksList: jspb.Message.toObjectList(msg.getPolicyChecksList(),
    proto.roadmap.PolicyCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.PolicyWidgetResponse}
 */
proto.roadmap.PolicyWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.PolicyWidgetResponse;
  return proto.roadmap.PolicyWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.PolicyWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.PolicyWidgetResponse}
 */
proto.roadmap.PolicyWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.PolicyCheck;
      reader.readMessage(value,proto.roadmap.PolicyCheck.deserializeBinaryFromReader);
      msg.addPolicyChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.PolicyWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.PolicyWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.PolicyWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.PolicyWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPolicyChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.PolicyCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.PolicyWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.PolicyWidgetResponse} returns this
 */
proto.roadmap.PolicyWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.PolicyWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.PolicyWidgetResponse} returns this
 */
proto.roadmap.PolicyWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated PolicyCheck policy_checks = 3;
 * @return {!Array<!proto.roadmap.PolicyCheck>}
 */
proto.roadmap.PolicyWidgetResponse.prototype.getPolicyChecksList = function() {
  return /** @type{!Array<!proto.roadmap.PolicyCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.PolicyCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.PolicyCheck>} value
 * @return {!proto.roadmap.PolicyWidgetResponse} returns this
*/
proto.roadmap.PolicyWidgetResponse.prototype.setPolicyChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.PolicyCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.PolicyCheck}
 */
proto.roadmap.PolicyWidgetResponse.prototype.addPolicyChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.PolicyCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.PolicyWidgetResponse} returns this
 */
proto.roadmap.PolicyWidgetResponse.prototype.clearPolicyChecksList = function() {
  return this.setPolicyChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.CompanyInfoCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.CompanyInfoCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.CompanyInfoCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.CompanyInfoCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.CompanyInfoCheck}
 */
proto.roadmap.CompanyInfoCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.CompanyInfoCheck;
  return proto.roadmap.CompanyInfoCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.CompanyInfoCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.CompanyInfoCheck}
 */
proto.roadmap.CompanyInfoCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.COMPANY_INFO_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.CompanyInfoCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.CompanyInfoCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.CompanyInfoCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.CompanyInfoCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional COMPANY_INFO_CHECKS check_type = 1;
 * @return {!proto.roadmap.COMPANY_INFO_CHECKS}
 */
proto.roadmap.CompanyInfoCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.COMPANY_INFO_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.COMPANY_INFO_CHECKS} value
 * @return {!proto.roadmap.CompanyInfoCheck} returns this
 */
proto.roadmap.CompanyInfoCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.CompanyInfoCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.CompanyInfoCheck} returns this
 */
proto.roadmap.CompanyInfoCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_count = 3;
 * @return {number}
 */
proto.roadmap.CompanyInfoCheck.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.CompanyInfoCheck} returns this
 */
proto.roadmap.CompanyInfoCheck.prototype.setTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.CompanyInfoWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.CompanyInfoWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.CompanyInfoWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.CompanyInfoWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    companyInfoChecksList: jspb.Message.toObjectList(msg.getCompanyInfoChecksList(),
    proto.roadmap.CompanyInfoCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.CompanyInfoWidgetResponse}
 */
proto.roadmap.CompanyInfoWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.CompanyInfoWidgetResponse;
  return proto.roadmap.CompanyInfoWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.CompanyInfoWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.CompanyInfoWidgetResponse}
 */
proto.roadmap.CompanyInfoWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.CompanyInfoCheck;
      reader.readMessage(value,proto.roadmap.CompanyInfoCheck.deserializeBinaryFromReader);
      msg.addCompanyInfoChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.CompanyInfoWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.CompanyInfoWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.CompanyInfoWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCompanyInfoChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.CompanyInfoCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.CompanyInfoWidgetResponse} returns this
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.CompanyInfoWidgetResponse} returns this
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated CompanyInfoCheck company_info_checks = 3;
 * @return {!Array<!proto.roadmap.CompanyInfoCheck>}
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.getCompanyInfoChecksList = function() {
  return /** @type{!Array<!proto.roadmap.CompanyInfoCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.CompanyInfoCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.CompanyInfoCheck>} value
 * @return {!proto.roadmap.CompanyInfoWidgetResponse} returns this
*/
proto.roadmap.CompanyInfoWidgetResponse.prototype.setCompanyInfoChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.CompanyInfoCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.CompanyInfoCheck}
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.addCompanyInfoChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.CompanyInfoCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.CompanyInfoWidgetResponse} returns this
 */
proto.roadmap.CompanyInfoWidgetResponse.prototype.clearCompanyInfoChecksList = function() {
  return this.setCompanyInfoChecksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.FrameworkCheck.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.FrameworkCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.FrameworkCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.FrameworkCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworkCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notCoveredFrameworksList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.FrameworkCheck}
 */
proto.roadmap.FrameworkCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.FrameworkCheck;
  return proto.roadmap.FrameworkCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.FrameworkCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.FrameworkCheck}
 */
proto.roadmap.FrameworkCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.FRAMEWORK_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotCoveredFrameworks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.FrameworkCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.FrameworkCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.FrameworkCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworkCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNotCoveredFrameworksList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional FRAMEWORK_CHECKS check_type = 1;
 * @return {!proto.roadmap.FRAMEWORK_CHECKS}
 */
proto.roadmap.FrameworkCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.FRAMEWORK_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.FRAMEWORK_CHECKS} value
 * @return {!proto.roadmap.FrameworkCheck} returns this
 */
proto.roadmap.FrameworkCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.FrameworkCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.FrameworkCheck} returns this
 */
proto.roadmap.FrameworkCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string not_covered_frameworks = 3;
 * @return {!Array<string>}
 */
proto.roadmap.FrameworkCheck.prototype.getNotCoveredFrameworksList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.roadmap.FrameworkCheck} returns this
 */
proto.roadmap.FrameworkCheck.prototype.setNotCoveredFrameworksList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.roadmap.FrameworkCheck} returns this
 */
proto.roadmap.FrameworkCheck.prototype.addNotCoveredFrameworks = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.FrameworkCheck} returns this
 */
proto.roadmap.FrameworkCheck.prototype.clearNotCoveredFrameworksList = function() {
  return this.setNotCoveredFrameworksList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.FrameworkWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.FrameworkWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.FrameworkWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.FrameworkWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworkWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    frameworkChecksList: jspb.Message.toObjectList(msg.getFrameworkChecksList(),
    proto.roadmap.FrameworkCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.FrameworkWidgetResponse}
 */
proto.roadmap.FrameworkWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.FrameworkWidgetResponse;
  return proto.roadmap.FrameworkWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.FrameworkWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.FrameworkWidgetResponse}
 */
proto.roadmap.FrameworkWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.FrameworkCheck;
      reader.readMessage(value,proto.roadmap.FrameworkCheck.deserializeBinaryFromReader);
      msg.addFrameworkChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.FrameworkWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.FrameworkWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.FrameworkWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworkWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getFrameworkChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.FrameworkCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.FrameworkWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.FrameworkWidgetResponse} returns this
 */
proto.roadmap.FrameworkWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.FrameworkWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.FrameworkWidgetResponse} returns this
 */
proto.roadmap.FrameworkWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated FrameworkCheck framework_checks = 3;
 * @return {!Array<!proto.roadmap.FrameworkCheck>}
 */
proto.roadmap.FrameworkWidgetResponse.prototype.getFrameworkChecksList = function() {
  return /** @type{!Array<!proto.roadmap.FrameworkCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.FrameworkCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.FrameworkCheck>} value
 * @return {!proto.roadmap.FrameworkWidgetResponse} returns this
*/
proto.roadmap.FrameworkWidgetResponse.prototype.setFrameworkChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.FrameworkCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.FrameworkCheck}
 */
proto.roadmap.FrameworkWidgetResponse.prototype.addFrameworkChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.FrameworkCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.FrameworkWidgetResponse} returns this
 */
proto.roadmap.FrameworkWidgetResponse.prototype.clearFrameworkChecksList = function() {
  return this.setFrameworkChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.RiskCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.RiskCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.RiskCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RiskCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.RiskCheck}
 */
proto.roadmap.RiskCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.RiskCheck;
  return proto.roadmap.RiskCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.RiskCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.RiskCheck}
 */
proto.roadmap.RiskCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.RISK_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.RiskCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.RiskCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.RiskCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RiskCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional RISK_CHECKS check_type = 1;
 * @return {!proto.roadmap.RISK_CHECKS}
 */
proto.roadmap.RiskCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.RISK_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.RISK_CHECKS} value
 * @return {!proto.roadmap.RiskCheck} returns this
 */
proto.roadmap.RiskCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.RiskCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.RiskCheck} returns this
 */
proto.roadmap.RiskCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.RiskWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.RiskWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.RiskWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.RiskWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RiskWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    riskChecksList: jspb.Message.toObjectList(msg.getRiskChecksList(),
    proto.roadmap.RiskCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.RiskWidgetResponse}
 */
proto.roadmap.RiskWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.RiskWidgetResponse;
  return proto.roadmap.RiskWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.RiskWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.RiskWidgetResponse}
 */
proto.roadmap.RiskWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.RiskCheck;
      reader.readMessage(value,proto.roadmap.RiskCheck.deserializeBinaryFromReader);
      msg.addRiskChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.RiskWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.RiskWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.RiskWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RiskWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRiskChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.RiskCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.RiskWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.RiskWidgetResponse} returns this
 */
proto.roadmap.RiskWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.RiskWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.RiskWidgetResponse} returns this
 */
proto.roadmap.RiskWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated RiskCheck risk_checks = 3;
 * @return {!Array<!proto.roadmap.RiskCheck>}
 */
proto.roadmap.RiskWidgetResponse.prototype.getRiskChecksList = function() {
  return /** @type{!Array<!proto.roadmap.RiskCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.RiskCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.RiskCheck>} value
 * @return {!proto.roadmap.RiskWidgetResponse} returns this
*/
proto.roadmap.RiskWidgetResponse.prototype.setRiskChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.RiskCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.RiskCheck}
 */
proto.roadmap.RiskWidgetResponse.prototype.addRiskChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.RiskCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.RiskWidgetResponse} returns this
 */
proto.roadmap.RiskWidgetResponse.prototype.clearRiskChecksList = function() {
  return this.setRiskChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.RequestCheck.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.RequestCheck.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.RequestCheck} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RequestCheck.toObject = function(includeInstance, msg) {
  var f, obj = {
    checkType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.RequestCheck}
 */
proto.roadmap.RequestCheck.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.RequestCheck;
  return proto.roadmap.RequestCheck.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.RequestCheck} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.RequestCheck}
 */
proto.roadmap.RequestCheck.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.roadmap.REQUEST_CHECKS} */ (reader.readEnum());
      msg.setCheckType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.RequestCheck.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.RequestCheck.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.RequestCheck} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RequestCheck.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheckType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional REQUEST_CHECKS check_type = 1;
 * @return {!proto.roadmap.REQUEST_CHECKS}
 */
proto.roadmap.RequestCheck.prototype.getCheckType = function() {
  return /** @type {!proto.roadmap.REQUEST_CHECKS} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.roadmap.REQUEST_CHECKS} value
 * @return {!proto.roadmap.RequestCheck} returns this
 */
proto.roadmap.RequestCheck.prototype.setCheckType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.roadmap.RequestCheck.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.RequestCheck} returns this
 */
proto.roadmap.RequestCheck.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.RequestWidgetResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.RequestWidgetResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.RequestWidgetResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.RequestWidgetResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RequestWidgetResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    finished: jspb.Message.getFieldWithDefault(msg, 1, 0),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requestChecksList: jspb.Message.toObjectList(msg.getRequestChecksList(),
    proto.roadmap.RequestCheck.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.RequestWidgetResponse}
 */
proto.roadmap.RequestWidgetResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.RequestWidgetResponse;
  return proto.roadmap.RequestWidgetResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.RequestWidgetResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.RequestWidgetResponse}
 */
proto.roadmap.RequestWidgetResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFinished(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new proto.roadmap.RequestCheck;
      reader.readMessage(value,proto.roadmap.RequestCheck.deserializeBinaryFromReader);
      msg.addRequestChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.RequestWidgetResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.RequestWidgetResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.RequestWidgetResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.RequestWidgetResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFinished();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRequestChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.roadmap.RequestCheck.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 finished = 1;
 * @return {number}
 */
proto.roadmap.RequestWidgetResponse.prototype.getFinished = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.RequestWidgetResponse} returns this
 */
proto.roadmap.RequestWidgetResponse.prototype.setFinished = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.roadmap.RequestWidgetResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.roadmap.RequestWidgetResponse} returns this
 */
proto.roadmap.RequestWidgetResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated RequestCheck request_checks = 3;
 * @return {!Array<!proto.roadmap.RequestCheck>}
 */
proto.roadmap.RequestWidgetResponse.prototype.getRequestChecksList = function() {
  return /** @type{!Array<!proto.roadmap.RequestCheck>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.RequestCheck, 3));
};


/**
 * @param {!Array<!proto.roadmap.RequestCheck>} value
 * @return {!proto.roadmap.RequestWidgetResponse} returns this
*/
proto.roadmap.RequestWidgetResponse.prototype.setRequestChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.roadmap.RequestCheck=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.RequestCheck}
 */
proto.roadmap.RequestWidgetResponse.prototype.addRequestChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.roadmap.RequestCheck, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.RequestWidgetResponse} returns this
 */
proto.roadmap.RequestWidgetResponse.prototype.clearRequestChecksList = function() {
  return this.setRequestChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetScopeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetScopeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetScopeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetScopeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetScopeRequest}
 */
proto.roadmap.GetScopeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetScopeRequest;
  return proto.roadmap.GetScopeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetScopeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetScopeRequest}
 */
proto.roadmap.GetScopeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetScopeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetScopeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetScopeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetScopeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.roadmap.GetScopeRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.GetScopeRequest} returns this
 */
proto.roadmap.GetScopeRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetScopeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetScopeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetScopeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetScopeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptors: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    services: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    companyName: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    address: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    deviceType: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    serviceRolesFulfilled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    frameworks: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    audits: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    archDiagram: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    orgChart: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    systemDesc: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    providersIdentified: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    companyWebsite: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    companySize: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    industry: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    country: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    teamLocation: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    deviceOwner: jspb.Message.getBooleanFieldWithDefault(msg, 18, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetScopeResponse}
 */
proto.roadmap.GetScopeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetScopeResponse;
  return proto.roadmap.GetScopeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetScopeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetScopeResponse}
 */
proto.roadmap.GetScopeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReceptors(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServices(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanyName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceType(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServiceRolesFulfilled(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFrameworks(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAudits(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArchDiagram(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOrgChart(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSystemDesc(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProvidersIdentified(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanyWebsite(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanySize(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndustry(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCountry(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTeamLocation(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeviceOwner(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetScopeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetScopeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetScopeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetScopeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptors();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getServices();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCompanyName();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDeviceType();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getServiceRolesFulfilled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getFrameworks();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getAudits();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getArchDiagram();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getOrgChart();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getSystemDesc();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProvidersIdentified();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCompanyWebsite();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getCompanySize();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIndustry();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getCountry();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getTeamLocation();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getDeviceOwner();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
};


/**
 * optional bool receptors = 1;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getReceptors = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setReceptors = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool services = 2;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getServices = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setServices = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool company_name = 3;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getCompanyName = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setCompanyName = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool address = 4;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getAddress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool device_type = 5;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getDeviceType = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setDeviceType = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool service_roles_fulfilled = 6;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getServiceRolesFulfilled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setServiceRolesFulfilled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool frameworks = 7;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getFrameworks = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setFrameworks = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool audits = 8;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getAudits = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setAudits = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool arch_diagram = 9;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getArchDiagram = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setArchDiagram = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool org_chart = 10;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getOrgChart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setOrgChart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool system_desc = 11;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getSystemDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setSystemDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool providers_identified = 12;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getProvidersIdentified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setProvidersIdentified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool company_website = 13;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getCompanyWebsite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setCompanyWebsite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional bool company_size = 14;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getCompanySize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setCompanySize = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool industry = 15;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getIndustry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setIndustry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool country = 16;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getCountry = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setCountry = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool team_location = 17;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getTeamLocation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setTeamLocation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional bool device_owner = 18;
 * @return {boolean}
 */
proto.roadmap.GetScopeResponse.prototype.getDeviceOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetScopeResponse} returns this
 */
proto.roadmap.GetScopeResponse.prototype.setDeviceOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetRiskResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetRiskResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetRiskResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetRiskResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    risksStarted: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetRiskResponse}
 */
proto.roadmap.GetRiskResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetRiskResponse;
  return proto.roadmap.GetRiskResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetRiskResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetRiskResponse}
 */
proto.roadmap.GetRiskResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRisksStarted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetRiskResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetRiskResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetRiskResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetRiskResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRisksStarted();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool risks_started = 1;
 * @return {boolean}
 */
proto.roadmap.GetRiskResponse.prototype.getRisksStarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetRiskResponse} returns this
 */
proto.roadmap.GetRiskResponse.prototype.setRisksStarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetInitialScopingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetInitialScopingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetInitialScopingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetInitialScopingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetInitialScopingRequest}
 */
proto.roadmap.GetInitialScopingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetInitialScopingRequest;
  return proto.roadmap.GetInitialScopingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetInitialScopingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetInitialScopingRequest}
 */
proto.roadmap.GetInitialScopingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetInitialScopingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetInitialScopingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetInitialScopingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetInitialScopingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.roadmap.GetInitialScopingRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.GetInitialScopingRequest} returns this
 */
proto.roadmap.GetInitialScopingRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.GetInitialScopingResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetInitialScopingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetInitialScopingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetInitialScopingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetInitialScopingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyNameEntered: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    frameworksSelectedList: jspb.Message.toObjectList(msg.getFrameworksSelectedList(),
    proto.roadmap.FrameworksSelected.toObject, includeInstance),
    auditSetup: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    servicesSelected: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    receptorsSetup: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    policiesStarted: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    controlsStarted: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    risksStarted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    serviceRolesFulfilled: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetInitialScopingResponse}
 */
proto.roadmap.GetInitialScopingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetInitialScopingResponse;
  return proto.roadmap.GetInitialScopingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetInitialScopingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetInitialScopingResponse}
 */
proto.roadmap.GetInitialScopingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompanyNameEntered(value);
      break;
    case 2:
      var value = new proto.roadmap.FrameworksSelected;
      reader.readMessage(value,proto.roadmap.FrameworksSelected.deserializeBinaryFromReader);
      msg.addFrameworksSelected(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAuditSetup(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServicesSelected(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReceptorsSetup(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPoliciesStarted(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setControlsStarted(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRisksStarted(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setServiceRolesFulfilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetInitialScopingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetInitialScopingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetInitialScopingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetInitialScopingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyNameEntered();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getFrameworksSelectedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.roadmap.FrameworksSelected.serializeBinaryToWriter
    );
  }
  f = message.getAuditSetup();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getServicesSelected();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getReceptorsSetup();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPoliciesStarted();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getControlsStarted();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getRisksStarted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getServiceRolesFulfilled();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional bool company_name_entered = 1;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getCompanyNameEntered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setCompanyNameEntered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * repeated FrameworksSelected frameworks_selected = 2;
 * @return {!Array<!proto.roadmap.FrameworksSelected>}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getFrameworksSelectedList = function() {
  return /** @type{!Array<!proto.roadmap.FrameworksSelected>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.FrameworksSelected, 2));
};


/**
 * @param {!Array<!proto.roadmap.FrameworksSelected>} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
*/
proto.roadmap.GetInitialScopingResponse.prototype.setFrameworksSelectedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.roadmap.FrameworksSelected=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.FrameworksSelected}
 */
proto.roadmap.GetInitialScopingResponse.prototype.addFrameworksSelected = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.roadmap.FrameworksSelected, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.clearFrameworksSelectedList = function() {
  return this.setFrameworksSelectedList([]);
};


/**
 * optional bool audit_setup = 3;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getAuditSetup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setAuditSetup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool services_selected = 4;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getServicesSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setServicesSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool receptors_setup = 5;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getReceptorsSetup = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setReceptorsSetup = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool policies_started = 6;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getPoliciesStarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setPoliciesStarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool controls_started = 7;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getControlsStarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setControlsStarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool risks_started = 8;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getRisksStarted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setRisksStarted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool service_roles_fulfilled = 9;
 * @return {boolean}
 */
proto.roadmap.GetInitialScopingResponse.prototype.getServiceRolesFulfilled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.GetInitialScopingResponse} returns this
 */
proto.roadmap.GetInitialScopingResponse.prototype.setServiceRolesFulfilled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.FrameworksSelected.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.FrameworksSelected.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.FrameworksSelected} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworksSelected.toObject = function(includeInstance, msg) {
  var f, obj = {
    frameworkName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.FrameworksSelected}
 */
proto.roadmap.FrameworksSelected.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.FrameworksSelected;
  return proto.roadmap.FrameworksSelected.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.FrameworksSelected} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.FrameworksSelected}
 */
proto.roadmap.FrameworksSelected.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrameworkName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.FrameworksSelected.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.FrameworksSelected.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.FrameworksSelected} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.FrameworksSelected.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrameworkName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string framework_name = 1;
 * @return {string}
 */
proto.roadmap.FrameworksSelected.prototype.getFrameworkName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.FrameworksSelected} returns this
 */
proto.roadmap.FrameworksSelected.prototype.setFrameworkName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_id = 2;
 * @return {string}
 */
proto.roadmap.FrameworksSelected.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.FrameworksSelected} returns this
 */
proto.roadmap.FrameworksSelected.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetOrCreateComplianceRoadmapRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapRequest}
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetOrCreateComplianceRoadmapRequest;
  return proto.roadmap.GetOrCreateComplianceRoadmapRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapRequest}
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetOrCreateComplianceRoadmapRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapRequest} returns this
 */
proto.roadmap.GetOrCreateComplianceRoadmapRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ComplianceRoadmap.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ComplianceRoadmap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ComplianceRoadmap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ComplianceRoadmap.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hasSupportingDocuments: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    approvedSupportDocuments: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    policiesApproved: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    businessContinuityContingency: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    hasSupportingDocumentsSop: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ComplianceRoadmap}
 */
proto.roadmap.ComplianceRoadmap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ComplianceRoadmap;
  return proto.roadmap.ComplianceRoadmap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ComplianceRoadmap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ComplianceRoadmap}
 */
proto.roadmap.ComplianceRoadmap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSupportingDocuments(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApprovedSupportDocuments(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPoliciesApproved(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBusinessContinuityContingency(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSupportingDocumentsSop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ComplianceRoadmap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ComplianceRoadmap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ComplianceRoadmap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ComplianceRoadmap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHasSupportingDocuments();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getApprovedSupportDocuments();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPoliciesApproved();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getBusinessContinuityContingency();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHasSupportingDocumentsSop();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.roadmap.ComplianceRoadmap.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.roadmap.ComplianceRoadmap.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool has_supporting_documents = 3;
 * @return {boolean}
 */
proto.roadmap.ComplianceRoadmap.prototype.getHasSupportingDocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setHasSupportingDocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool approved_support_documents = 4;
 * @return {boolean}
 */
proto.roadmap.ComplianceRoadmap.prototype.getApprovedSupportDocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setApprovedSupportDocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool policies_approved = 5;
 * @return {boolean}
 */
proto.roadmap.ComplianceRoadmap.prototype.getPoliciesApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setPoliciesApproved = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool business_continuity_contingency = 6;
 * @return {boolean}
 */
proto.roadmap.ComplianceRoadmap.prototype.getBusinessContinuityContingency = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setBusinessContinuityContingency = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool has_supporting_documents_sop = 7;
 * @return {boolean}
 */
proto.roadmap.ComplianceRoadmap.prototype.getHasSupportingDocumentsSop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.roadmap.ComplianceRoadmap} returns this
 */
proto.roadmap.ComplianceRoadmap.prototype.setHasSupportingDocumentsSop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetOrCreateComplianceRoadmapResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceRoadmap: (f = msg.getComplianceRoadmap()) && proto.roadmap.ComplianceRoadmap.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapResponse}
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetOrCreateComplianceRoadmapResponse;
  return proto.roadmap.GetOrCreateComplianceRoadmapResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapResponse}
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.roadmap.ComplianceRoadmap;
      reader.readMessage(value,proto.roadmap.ComplianceRoadmap.deserializeBinaryFromReader);
      msg.setComplianceRoadmap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetOrCreateComplianceRoadmapResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetOrCreateComplianceRoadmapResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceRoadmap();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.roadmap.ComplianceRoadmap.serializeBinaryToWriter
    );
  }
};


/**
 * optional ComplianceRoadmap compliance_roadmap = 1;
 * @return {?proto.roadmap.ComplianceRoadmap}
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.prototype.getComplianceRoadmap = function() {
  return /** @type{?proto.roadmap.ComplianceRoadmap} */ (
    jspb.Message.getWrapperField(this, proto.roadmap.ComplianceRoadmap, 1));
};


/**
 * @param {?proto.roadmap.ComplianceRoadmap|undefined} value
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapResponse} returns this
*/
proto.roadmap.GetOrCreateComplianceRoadmapResponse.prototype.setComplianceRoadmap = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.GetOrCreateComplianceRoadmapResponse} returns this
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.prototype.clearComplianceRoadmap = function() {
  return this.setComplianceRoadmap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.GetOrCreateComplianceRoadmapResponse.prototype.hasComplianceRoadmap = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.ToggleRequiredDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.ToggleRequiredDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ToggleRequiredDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hasSupportingDocuments: (f = msg.getHasSupportingDocuments()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    approvedSupportDocuments: (f = msg.getApprovedSupportDocuments()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    policiesApproved: (f = msg.getPoliciesApproved()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    businessContinuityContingency: (f = msg.getBusinessContinuityContingency()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hasSupportingDocumentsSop: (f = msg.getHasSupportingDocumentsSop()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.ToggleRequiredDocumentsRequest;
  return proto.roadmap.ToggleRequiredDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.ToggleRequiredDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasSupportingDocuments(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setApprovedSupportDocuments(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPoliciesApproved(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setBusinessContinuityContingency(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasSupportingDocumentsSop(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.ToggleRequiredDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.ToggleRequiredDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.ToggleRequiredDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHasSupportingDocuments();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getApprovedSupportDocuments();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPoliciesApproved();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getBusinessContinuityContingency();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHasSupportingDocumentsSop();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string audit_id = 1;
 * @return {string}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue has_supporting_documents = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.getHasSupportingDocuments = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
*/
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.setHasSupportingDocuments = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.clearHasSupportingDocuments = function() {
  return this.setHasSupportingDocuments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.hasHasSupportingDocuments = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue approved_support_documents = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.getApprovedSupportDocuments = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
*/
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.setApprovedSupportDocuments = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.clearApprovedSupportDocuments = function() {
  return this.setApprovedSupportDocuments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.hasApprovedSupportDocuments = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue policies_approved = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.getPoliciesApproved = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
*/
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.setPoliciesApproved = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.clearPoliciesApproved = function() {
  return this.setPoliciesApproved(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.hasPoliciesApproved = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue business_continuity_contingency = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.getBusinessContinuityContingency = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
*/
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.setBusinessContinuityContingency = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.clearBusinessContinuityContingency = function() {
  return this.setBusinessContinuityContingency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.hasBusinessContinuityContingency = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.BoolValue has_supporting_documents_sop = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.getHasSupportingDocumentsSop = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
*/
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.setHasSupportingDocumentsSop = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.roadmap.ToggleRequiredDocumentsRequest} returns this
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.clearHasSupportingDocumentsSop = function() {
  return this.setHasSupportingDocumentsSop(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.roadmap.ToggleRequiredDocumentsRequest.prototype.hasHasSupportingDocumentsSop = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.LiveAuditRun.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.LiveAuditRun.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.LiveAuditRun} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.LiveAuditRun.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditRunId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userEmail: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlIdsString: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controlTestsString: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.LiveAuditRun}
 */
proto.roadmap.LiveAuditRun.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.LiveAuditRun;
  return proto.roadmap.LiveAuditRun.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.LiveAuditRun} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.LiveAuditRun}
 */
proto.roadmap.LiveAuditRun.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditRunId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlIdsString(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlTestsString(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.LiveAuditRun.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.LiveAuditRun.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.LiveAuditRun} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.LiveAuditRun.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditRunId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlIdsString();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControlTestsString();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string audit_run_id = 1;
 * @return {string}
 */
proto.roadmap.LiveAuditRun.prototype.getAuditRunId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.LiveAuditRun} returns this
 */
proto.roadmap.LiveAuditRun.prototype.setAuditRunId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_email = 2;
 * @return {string}
 */
proto.roadmap.LiveAuditRun.prototype.getUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.LiveAuditRun} returns this
 */
proto.roadmap.LiveAuditRun.prototype.setUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string control_ids_string = 3;
 * @return {string}
 */
proto.roadmap.LiveAuditRun.prototype.getControlIdsString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.LiveAuditRun} returns this
 */
proto.roadmap.LiveAuditRun.prototype.setControlIdsString = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string control_tests_string = 4;
 * @return {string}
 */
proto.roadmap.LiveAuditRun.prototype.getControlTestsString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.roadmap.LiveAuditRun} returns this
 */
proto.roadmap.LiveAuditRun.prototype.setControlTestsString = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.roadmap.GetLiveAuditRunsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.roadmap.GetLiveAuditRunsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.roadmap.GetLiveAuditRunsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.roadmap.GetLiveAuditRunsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetLiveAuditRunsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    liveAuditRunsList: jspb.Message.toObjectList(msg.getLiveAuditRunsList(),
    proto.roadmap.LiveAuditRun.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.roadmap.GetLiveAuditRunsResponse}
 */
proto.roadmap.GetLiveAuditRunsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.roadmap.GetLiveAuditRunsResponse;
  return proto.roadmap.GetLiveAuditRunsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.roadmap.GetLiveAuditRunsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.roadmap.GetLiveAuditRunsResponse}
 */
proto.roadmap.GetLiveAuditRunsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.roadmap.LiveAuditRun;
      reader.readMessage(value,proto.roadmap.LiveAuditRun.deserializeBinaryFromReader);
      msg.addLiveAuditRuns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.roadmap.GetLiveAuditRunsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.roadmap.GetLiveAuditRunsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.roadmap.GetLiveAuditRunsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.roadmap.GetLiveAuditRunsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiveAuditRunsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.roadmap.LiveAuditRun.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LiveAuditRun live_audit_runs = 1;
 * @return {!Array<!proto.roadmap.LiveAuditRun>}
 */
proto.roadmap.GetLiveAuditRunsResponse.prototype.getLiveAuditRunsList = function() {
  return /** @type{!Array<!proto.roadmap.LiveAuditRun>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.roadmap.LiveAuditRun, 1));
};


/**
 * @param {!Array<!proto.roadmap.LiveAuditRun>} value
 * @return {!proto.roadmap.GetLiveAuditRunsResponse} returns this
*/
proto.roadmap.GetLiveAuditRunsResponse.prototype.setLiveAuditRunsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.roadmap.LiveAuditRun=} opt_value
 * @param {number=} opt_index
 * @return {!proto.roadmap.LiveAuditRun}
 */
proto.roadmap.GetLiveAuditRunsResponse.prototype.addLiveAuditRuns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.roadmap.LiveAuditRun, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.roadmap.GetLiveAuditRunsResponse} returns this
 */
proto.roadmap.GetLiveAuditRunsResponse.prototype.clearLiveAuditRunsList = function() {
  return this.setLiveAuditRunsList([]);
};


/**
 * @enum {number}
 */
proto.roadmap.SERVICE_ROLE_CHECKS = {
  ROLE_FULFILLED: 0
};

/**
 * @enum {number}
 */
proto.roadmap.SERVICE_CHECKS = {
  SERVICE_SELECTED: 0
};

/**
 * @enum {number}
 */
proto.roadmap.POLICY_CHECKS = {
  POLICY_HAS_ASSIGNEE: 0,
  POLICY_HAS_LINKED_CONTROLS: 1,
  POLICY_HAS_DOCUMENTATION: 2,
  POLICY_IS_FILLED_IN: 3
};

/**
 * @enum {number}
 */
proto.roadmap.CONTROL_CHECKS = {
  CONTROL_HAS_ASSIGNEE: 0,
  CONTROL_HAS_POLICY: 1,
  CONTROL_HAS_FRAMEWORK: 2,
  CONTROL_HAS_EVIDENCE: 3,
  CONTROL_HAS_PASSING_STATUS: 4,
  CONTROL_HAS_PROCEDURES: 5
};

/**
 * @enum {number}
 */
proto.roadmap.COMPANY_INFO_CHECKS = {
  COMPANY_INFO_HAS_DOCUMENTATION: 0,
  COMPANY_INFO_IS_FILLED_IN: 1
};

/**
 * @enum {number}
 */
proto.roadmap.FRAMEWORK_CHECKS = {
  FRAMEWORK_HAS_LINKED_CONTROLS: 0
};

/**
 * @enum {number}
 */
proto.roadmap.RISK_CHECKS = {
  RISK_HAS_ASSIGNEE: 0,
  RISK_INHERENT_RISK_DETERMINED: 1,
  RISK_RESIDUAL_RISK_DETERMINED: 2,
  RISK_HAS_PASSING_STATUS: 3
};

/**
 * @enum {number}
 */
proto.roadmap.REQUEST_CHECKS = {
  REQUEST_HAS_ASSIGNEE: 0,
  REQUEST_HAS_EVIDENCE: 1,
  REQUEST_HAS_LINKED_CONTROLS: 2,
  REQUEST_HAS_PASSING_STATUS: 3
};

goog.object.extend(exports, proto.roadmap);
