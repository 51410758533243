import React, { CSSProperties, useContext } from 'react'
import { MarginProps } from 'styled-system'
import { SCHEDULER_EMAIL } from 'src/Utils/globalConstants'
import md5 from 'md5'
import { UserRecord } from '@trustero/trustero-api-web/lib/account/account_pb'
import ContentContext from '../../context/ContentContext'
import { ContentStaticHelper } from '../../context/Content/statichelpers'
import { AutomateDocumentIcon } from '../Icons/FileType'
import {
  DefaultIcon,
  GravatarImg,
  GravatarParent,
  TrusteroAutomationIcon,
  UnassignedIcon,
} from './styles'

const baseUrl = 'https://www.gravatar.com/avatar/'

type GravatarProps = {
  email?: string | null
  emptyIsUnassigned?: boolean
  magicIcon?: boolean
  size?: number
  style?: CSSProperties
} & MarginProps

export function Gravatar({
  email,
  emptyIsUnassigned = true,
  magicIcon = false,
  size = 24,
  style,
  ...marginProps
}: GravatarProps): JSX.Element {
  const { content } = useContext(ContentContext)
  // ! Warning: user might be deactivated, so there might not be a user object
  const user: UserRecord.AsObject | null = ContentStaticHelper.getObjById(
    content,
    email,
  )

  const marginPropsWithStyle = { ...{ style, ...marginProps } }
  const marginPropsWithStyleAndSize = { ...{ size, ...marginPropsWithStyle } }

  // Guard clause
  if (!email || !email.includes('@')) {
    if (emptyIsUnassigned) {
      return <UnassignedIcon {...{ size, style, ...marginProps }} />
    }
    if (magicIcon) {
      return (
        <AutomateDocumentIcon
          width="20px"
          height="20px"
          {...marginPropsWithStyle}
        />
      )
    } else {
      const ReceptorLogo = ContentStaticHelper.getObjById(content, email)?.logo
      return ReceptorLogo ? (
        <ReceptorLogo width="24px" height="24px" {...marginPropsWithStyle} />
      ) : (
        <TrusteroAutomationIcon {...marginPropsWithStyleAndSize} />
      )
    }
  } else if (email === SCHEDULER_EMAIL) {
    return <TrusteroAutomationIcon {...marginPropsWithStyleAndSize} />
  }

  const hash = md5(email.trim().toLowerCase())
  const src = `${baseUrl}${hash}?s=${size}&d=blank`

  const names = user?.name
    ? user.name.split(' ')
    : email?.split('@')[0].split('.')
  const initials = names.map((name) => name[0]?.toUpperCase() || '').join('')

  return (
    <GravatarParent {...marginPropsWithStyleAndSize}>
      <DefaultIcon size={size}>{initials}</DefaultIcon>
      <GravatarImg src={src} alt="gravatar" size={size} />
    </GravatarParent>
  )
}
