import React, { useState, MouseEvent, useCallback, useEffect } from 'react'
import isFunction from 'lodash/isFunction'
import Dropdown from 'react-bootstrap/Dropdown'
import FormCheckInput from 'react-bootstrap/FormCheckInput'
import palette from 'src/designSystem/variables/palette'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { StyledDropdownToggle } from '../StandardDropdown/StandardDropdown.styles'
import { CountChip } from '../Chips/BasicChip'
import { DropdownLabel } from '../Dropdown/styles'
import { FlexAlign, FlexRow } from '../Flex'
import { StyledInputError } from '../Inputs/TextInput/styles'
import {
  DropdownContainer,
  RowLabel,
  SelectDropdownLabel,
  SelectDropDownSelected,
  SelectMenu,
} from './SelectDropdown.styles'
import {
  SelectItem,
  SelectDropdownProps,
  DropdownAutoClose,
} from './SelectDropdown.constants'

export const SelectDropdown = ({
  items,
  label,
  labelButton,
  defaultValues,
  placeholder,
  isMultiSelect,
  isRequired,
  errMsg,
  isWarning,
  onSelectCb,
  formatLabel,
  alwaysShowError,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
  isDisabled,
  isBoldLabel,
}: SelectDropdownProps): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)
  // We assume name is unique for our dropdown lists. Value breaks for the Provider use-case since
  // custom Providers all share the same "Other" enum value.

  const [selected, setSelected] = useState<SelectItem[]>([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<
    Set<string | number>
  >(new Set())
  const hasSelected = selected.length > 0
  const showChip = isMultiSelect && selected.length > 0
  const autoCloseSetting: DropdownAutoClose = isMultiSelect ? 'outside' : true
  const selectedValue = selected.length
    ? isMultiSelect
      ? selected.map((item: SelectItem) => item.name).join(', ')
      : selected[0].name
    : placeholder || 'Select an Item'
  const showError = alwaysShowError || (isRequired && !hasSelected)

  useEffect(() => {
    // If default values change, be sure to update the selected state
    const preSelected = defaultValues ? [...defaultValues] : []
    const preSelectedSet = new Set(
      preSelected.map((item: SelectItem) => item.name),
    )
    setSelected(preSelected)
    isMultiSelect && setSelectedCheckboxes(preSelectedSet)
  }, [defaultValues, isMultiSelect])

  const onSelectItem = useCallback(
    (item: SelectItem) => {
      if (!isMultiSelect) {
        setSelected([item])
        onSelectCb([item])
      } else {
        const isChecked = selectedCheckboxes.has(item.name)
        const selectedItems = isChecked
          ? selected.filter(
              (selectedItem: SelectItem) => item.name !== selectedItem.name,
            )
          : [...selected, item]
        setSelectedCheckboxes((newCheckboxes) => {
          const newSet = new Set(newCheckboxes)
          isChecked ? newSet.delete(item.name) : newSet.add(item.name)
          return newSet
        })
        setSelected(selectedItems)
        onSelectCb(selectedItems)
      }
    },
    [isMultiSelect, onSelectCb, selected, selectedCheckboxes],
  )

  // There is a weird bug where if you make toggles and hit the escape key, the component crashes
  const toggleOption = (item: SelectItem, e?: MouseEvent) => {
    e?.preventDefault()
    isMultiSelect && e?.stopPropagation() // Prevents dropdown from closing when clicking on checkbox
    onSelectItem(item)
  }

  const displayLabel = isFunction(formatLabel) ? formatLabel(label) : label

  return (
    <DropdownContainer $hasSelected={hasSelected} $isDisabled={isDisabled}>
      <DropdownLabel>
        {labelButton ? (
          <FlexRow align={FlexAlign.CENTER} justify={FlexAlign.SPACE_BETWEEN}>
            <span>{displayLabel}</span>
            {labelButton}
          </FlexRow>
        ) : (
          <SelectDropdownLabel $isbold={isBoldLabel}>
            {displayLabel}
          </SelectDropdownLabel>
        )}
        <Dropdown id={`select-dropdown-${label}`} autoClose={autoCloseSetting}>
          <Dropdown.Toggle
            as={StyledDropdownToggle}
            color={palette.neutral[400]}
            borderColor={palette.neutral[100]}
            width="100%"
            disabled={isDisabled || (!isMultiSelect && !hasPermission)}
          >
            <FlexRow justify={FlexAlign.SPACE_BETWEEN}>
              <SelectDropDownSelected className="selected-value">
                {selectedValue}
              </SelectDropDownSelected>
              {showChip && (
                <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
                  {selected.length}
                </CountChip>
              )}
            </FlexRow>
          </Dropdown.Toggle>
          <SelectMenu>
            {items?.map((item: SelectItem) => {
              const isChecked = selectedCheckboxes.has(item.name)
              return (
                <Dropdown.Item
                  key={`dropdown-item-${item.name}`}
                  eventKey={`dropdown-item-${item.name}`}
                  onClick={(e: MouseEvent) => toggleOption(item, e)}
                  disabled={!hasPermission}
                >
                  <FlexRow
                    align={FlexAlign.CENTER}
                    justify={FlexAlign.FLEX_START}
                    onClick={(e: MouseEvent) => toggleOption(item, e)}
                  >
                    {isMultiSelect && (
                      <FormCheckInput
                        id={`check-input-dropdown-item-${item.name}`}
                        onChange={() => toggleOption(item)}
                        checked={isChecked}
                        disabled={!hasPermission}
                      />
                    )}
                    <RowLabel $isMultiSelect={isMultiSelect}>
                      {item.name}
                    </RowLabel>
                  </FlexRow>
                </Dropdown.Item>
              )
            })}
          </SelectMenu>
        </Dropdown>
      </DropdownLabel>
      <StyledInputError showError={!!showError} isWarning={isWarning}>
        {errMsg}
      </StyledInputError>
    </DropdownContainer>
  )
}
