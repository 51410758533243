import React from 'react'
import { RECEPTOR_TYPE } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'
import { getReceptorFilterValues } from './FilterDropdown.helpers'

export const ReceptorFilterDropdown = (): JSX.Element => {
  const filterValues = getReceptorFilterValues(RECEPTOR_TYPE.RECEPTOR)

  return (
    <FilterDropdown
      filterParam={FilterParam.RECEPTOR}
      filterName={FilterName.RECEPTOR}
      filterValues={filterValues}
    />
  )
}
