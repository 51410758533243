import { useCallback } from 'react'
import { ComplianceFrameworks } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { AuditPromiseClient } from '@trustero/trustero-api-web/lib/audit/audit_grpc_web_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  GetComplianceFrameworksRequest,
  GetHasComplianceFrameworksRequest,
  GetHasComplianceFrameworksResponse,
} from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import {
  EnableComplianceFrameworksRequest,
  TemplateFilter,
  TemplateSelected,
  CreateComplianceFrameworkRequest,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { FRAMEWORK_MODEL_IDS } from 'src/Utils/globalEnums'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { useGrpcRevalidateByMethod } from '../useGrpcMutate'
import { useAuthorizedGrpcClient } from '../../../adapter/grpcClient'
import { useInvalidatePoliciesCache } from '../policy/usePolicies'
import { useInvalidateListControlIdsCache } from '../model/control/useListControlIds'
import { useFrameworkWizard } from '../../../context/FormContext'

// Invalidates the cache for getComplianceFrameworks requests and getHasComplianceFrameworks requests
export const useInvalidateComplianceFrameworksCache =
  (): (() => Promise<void>) => {
    const mutateFunc = useGrpcRevalidateByMethod()

    return useCallback(async () => {
      await Promise.all([
        mutateFunc(AuditPromiseClient.prototype.getComplianceFrameworks),
        mutateFunc(AuditPromiseClient.prototype.getHasComplianceFrameworks),
      ])
    }, [mutateFunc])
  }

// Get all compliance frameworks with the option to filter by if they are enabled on the current account
export const useComplianceFrameworks = (
  excludeDisabled?: boolean,
): GrpcResponse<ComplianceFrameworks> => {
  const request = new GetComplianceFrameworksRequest()
  if (excludeDisabled) {
    request.setExcludeDisabled(true)
  }
  const { response } = useSwrImmutableGrpc(
    AuditPromiseClient.prototype.getComplianceFrameworks,
    request,
  )
  return NewGrpcResponse(response)
}

// Returns boolean telling if current account has enabled any compliance frameworks
export const useHasComplianceFramework = (
  modelId?: string,
): GrpcResponse<GetHasComplianceFrameworksResponse> => {
  const request = new GetHasComplianceFrameworksRequest()

  if (modelId) {
    request.setModelId(modelId)
  }

  const { response } = useSwrImmutableGrpc(
    AuditPromiseClient.prototype.getHasComplianceFrameworks,
    request,
  )
  return NewGrpcResponse(response)
}

export const useHasSoc2 = (): boolean => {
  const { data } = useHasComplianceFramework(FRAMEWORK_MODEL_IDS.SOC2)

  return data?.getHasenabled() ?? false
}

// Enable a compliance framework - pulls data from the useFrameworkWizard context
export const useEnableComplianceFramework = (): (() => Promise<void>) => {
  const client = useAuthorizedGrpcClient(AccountPromiseClient)
  const frameworkMutator = useInvalidateComplianceFrameworksCache()
  const controlMutator = useInvalidateListControlIdsCache()
  const policyMutator = useInvalidatePoliciesCache()

  const {
    controlsState: { suggestedControls, selectedControls },
    policiesState: { suggestedPolicies, selectedPolicies },
    frameworkState: { framework },
  } = useFrameworkWizard()

  return useCallback(async () => {
    const finalSelectedControls = new Set(selectedControls)
    const finalSelectedPolicies = new Set(selectedPolicies)
    framework &&
      (await client.enableComplianceFrameworks(
        new EnableComplianceFrameworksRequest()
          .addComplianceFrameworkIds(framework.getModelId())
          .setTemplateFilter(
            new TemplateFilter()
              .setControlFilterList(
                suggestedControls.map((control) => {
                  return new TemplateSelected()
                    .setObjective(control.getObjective())
                    .setSelected(
                      finalSelectedControls.has(control.getObjective()),
                    )
                }),
              )
              .setPolicyFilterList(
                suggestedPolicies.map((policy) => {
                  return new TemplateSelected()
                    .setModelId(policy.getModelId())
                    .setSelected(finalSelectedPolicies.has(policy.getModelId()))
                }),
              ),
          ),
      ))
    await frameworkMutator()
    await controlMutator()
    await policyMutator()
  }, [
    client,
    controlMutator,
    framework,
    frameworkMutator,
    policyMutator,
    selectedControls,
    selectedPolicies,
    suggestedControls,
    suggestedPolicies,
  ])
}

/**
 * This hook does not have error handling. It is up to the caller to handle errors.
 * See AddCustomFrameworkModal.tsx for an example
 * @returns function that takes a name and creates a compliance framework
 */
export const useCreateComplianceFramework = (): ((
  name: string,
) => Promise<Empty>) => {
  const client = useAuthorizedGrpcClient(AccountPromiseClient)
  const frameworkMutator = useInvalidateComplianceFrameworksCache()

  return useCallback(
    async (name: string): Promise<Empty> => {
      const response = await client.createComplianceFramework(
        new CreateComplianceFrameworkRequest().setName(name),
      )
      await frameworkMutator()
      return response
    },
    [client, frameworkMutator],
  )
}
