/**
 * @fileoverview gRPC-Web generated client stub for account
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: account/account.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var provider_provider_pb = require('../provider/provider_pb.js')

var service_service_pb = require('../service/service_pb.js')

var common_collections_pb = require('../common/collections_pb.js')
const proto = {};
proto.account = require('./account_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.account.AccountClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.account.AccountPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.AccountRecord>}
 */
const methodDescriptor_Account_Get = new grpc.web.MethodDescriptor(
  '/account.Account/Get',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.AccountRecord,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AccountRecord.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AccountRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AccountRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/Get',
      request,
      metadata || {},
      methodDescriptor_Account_Get,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AccountRecord>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/Get',
      request,
      metadata || {},
      methodDescriptor_Account_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.GetAccountsResponse>}
 */
const methodDescriptor_Account_GetAccounts = new grpc.web.MethodDescriptor(
  '/account.Account/GetAccounts',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.GetAccountsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.GetAccountsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.GetAccountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.GetAccountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getAccounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetAccounts',
      request,
      metadata || {},
      methodDescriptor_Account_GetAccounts,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.GetAccountsResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getAccounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetAccounts',
      request,
      metadata || {},
      methodDescriptor_Account_GetAccounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.UpdateAccount,
 *   !proto.account.AccountRecord>}
 */
const methodDescriptor_Account_Update = new grpc.web.MethodDescriptor(
  '/account.Account/Update',
  grpc.web.MethodType.UNARY,
  proto.account.UpdateAccount,
  proto.account.AccountRecord,
  /**
   * @param {!proto.account.UpdateAccount} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AccountRecord.deserializeBinary
);


/**
 * @param {!proto.account.UpdateAccount} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AccountRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AccountRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/Update',
      request,
      metadata || {},
      methodDescriptor_Account_Update,
      callback);
};


/**
 * @param {!proto.account.UpdateAccount} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AccountRecord>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/Update',
      request,
      metadata || {},
      methodDescriptor_Account_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.AcctID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_Close = new grpc.web.MethodDescriptor(
  '/account.Account/Close',
  grpc.web.MethodType.UNARY,
  proto.account.AcctID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.AcctID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.AcctID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.close =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/Close',
      request,
      metadata || {},
      methodDescriptor_Account_Close,
      callback);
};


/**
 * @param {!proto.account.AcctID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.close =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/Close',
      request,
      metadata || {},
      methodDescriptor_Account_Close);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.AcctID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_UnClose = new grpc.web.MethodDescriptor(
  '/account.Account/UnClose',
  grpc.web.MethodType.UNARY,
  proto.account.AcctID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.AcctID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.AcctID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.unClose =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/UnClose',
      request,
      metadata || {},
      methodDescriptor_Account_UnClose,
      callback);
};


/**
 * @param {!proto.account.AcctID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.unClose =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/UnClose',
      request,
      metadata || {},
      methodDescriptor_Account_UnClose);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_Delete = new grpc.web.MethodDescriptor(
  '/account.Account/Delete',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/Delete',
      request,
      metadata || {},
      methodDescriptor_Account_Delete,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/Delete',
      request,
      metadata || {},
      methodDescriptor_Account_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.SignupRequest,
 *   !proto.account.Call>}
 */
const methodDescriptor_Account_Signup = new grpc.web.MethodDescriptor(
  '/account.Account/Signup',
  grpc.web.MethodType.UNARY,
  proto.account.SignupRequest,
  proto.account.Call,
  /**
   * @param {!proto.account.SignupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.Call.deserializeBinary
);


/**
 * @param {!proto.account.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.Call)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.Call>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.signup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/Signup',
      request,
      metadata || {},
      methodDescriptor_Account_Signup,
      callback);
};


/**
 * @param {!proto.account.SignupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.Call>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.signup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/Signup',
      request,
      metadata || {},
      methodDescriptor_Account_Signup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.Call,
 *   !proto.account.AuthenticatedUser>}
 */
const methodDescriptor_Account_ConfirmSignup = new grpc.web.MethodDescriptor(
  '/account.Account/ConfirmSignup',
  grpc.web.MethodType.UNARY,
  proto.account.Call,
  proto.account.AuthenticatedUser,
  /**
   * @param {!proto.account.Call} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AuthenticatedUser.deserializeBinary
);


/**
 * @param {!proto.account.Call} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AuthenticatedUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AuthenticatedUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.confirmSignup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/ConfirmSignup',
      request,
      metadata || {},
      methodDescriptor_Account_ConfirmSignup,
      callback);
};


/**
 * @param {!proto.account.Call} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AuthenticatedUser>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.confirmSignup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/ConfirmSignup',
      request,
      metadata || {},
      methodDescriptor_Account_ConfirmSignup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.SendSignInEmailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_SendSignInEmail = new grpc.web.MethodDescriptor(
  '/account.Account/SendSignInEmail',
  grpc.web.MethodType.UNARY,
  proto.account.SendSignInEmailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.SendSignInEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.SendSignInEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.sendSignInEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/SendSignInEmail',
      request,
      metadata || {},
      methodDescriptor_Account_SendSignInEmail,
      callback);
};


/**
 * @param {!proto.account.SendSignInEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.sendSignInEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/SendSignInEmail',
      request,
      metadata || {},
      methodDescriptor_Account_SendSignInEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.SendWelcomeEmailRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_SendSaasBuyerWelcomeEmail = new grpc.web.MethodDescriptor(
  '/account.Account/SendSaasBuyerWelcomeEmail',
  grpc.web.MethodType.UNARY,
  proto.account.SendWelcomeEmailRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.SendWelcomeEmailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.SendWelcomeEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.sendSaasBuyerWelcomeEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/SendSaasBuyerWelcomeEmail',
      request,
      metadata || {},
      methodDescriptor_Account_SendSaasBuyerWelcomeEmail,
      callback);
};


/**
 * @param {!proto.account.SendWelcomeEmailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.sendSaasBuyerWelcomeEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/SendSaasBuyerWelcomeEmail',
      request,
      metadata || {},
      methodDescriptor_Account_SendSaasBuyerWelcomeEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.RefreshTokenRequest,
 *   !proto.account.BearerToken>}
 */
const methodDescriptor_Account_RefreshToken = new grpc.web.MethodDescriptor(
  '/account.Account/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.account.RefreshTokenRequest,
  proto.account.BearerToken,
  /**
   * @param {!proto.account.RefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.BearerToken.deserializeBinary
);


/**
 * @param {!proto.account.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.BearerToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.BearerToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_Account_RefreshToken,
      callback);
};


/**
 * @param {!proto.account.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.BearerToken>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_Account_RefreshToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.Email,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_ResendSignupEmail = new grpc.web.MethodDescriptor(
  '/account.Account/ResendSignupEmail',
  grpc.web.MethodType.UNARY,
  proto.account.Email,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.Email} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.Email} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.resendSignupEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/ResendSignupEmail',
      request,
      metadata || {},
      methodDescriptor_Account_ResendSignupEmail,
      callback);
};


/**
 * @param {!proto.account.Email} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.resendSignupEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/ResendSignupEmail',
      request,
      metadata || {},
      methodDescriptor_Account_ResendSignupEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.Invitation,
 *   !proto.account.Call>}
 */
const methodDescriptor_Account_InviteUser = new grpc.web.MethodDescriptor(
  '/account.Account/InviteUser',
  grpc.web.MethodType.UNARY,
  proto.account.Invitation,
  proto.account.Call,
  /**
   * @param {!proto.account.Invitation} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.Call.deserializeBinary
);


/**
 * @param {!proto.account.Invitation} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.Call)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.Call>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.inviteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/InviteUser',
      request,
      metadata || {},
      methodDescriptor_Account_InviteUser,
      callback);
};


/**
 * @param {!proto.account.Invitation} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.Call>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.inviteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/InviteUser',
      request,
      metadata || {},
      methodDescriptor_Account_InviteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.CallAndPassword,
 *   !proto.account.AuthenticatedUser>}
 */
const methodDescriptor_Account_ConfirmInvite = new grpc.web.MethodDescriptor(
  '/account.Account/ConfirmInvite',
  grpc.web.MethodType.UNARY,
  proto.account.CallAndPassword,
  proto.account.AuthenticatedUser,
  /**
   * @param {!proto.account.CallAndPassword} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AuthenticatedUser.deserializeBinary
);


/**
 * @param {!proto.account.CallAndPassword} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AuthenticatedUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AuthenticatedUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.confirmInvite =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/ConfirmInvite',
      request,
      metadata || {},
      methodDescriptor_Account_ConfirmInvite,
      callback);
};


/**
 * @param {!proto.account.CallAndPassword} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AuthenticatedUser>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.confirmInvite =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/ConfirmInvite',
      request,
      metadata || {},
      methodDescriptor_Account_ConfirmInvite);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.AgentRegistrationToken>}
 */
const methodDescriptor_Account_GetAgentRegistrationToken = new grpc.web.MethodDescriptor(
  '/account.Account/GetAgentRegistrationToken',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.AgentRegistrationToken,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AgentRegistrationToken.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AgentRegistrationToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AgentRegistrationToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getAgentRegistrationToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetAgentRegistrationToken',
      request,
      metadata || {},
      methodDescriptor_Account_GetAgentRegistrationToken,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AgentRegistrationToken>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getAgentRegistrationToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetAgentRegistrationToken',
      request,
      metadata || {},
      methodDescriptor_Account_GetAgentRegistrationToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.ModelID,
 *   !proto.account.ReceptorToken>}
 */
const methodDescriptor_Account_GetReceptorToken = new grpc.web.MethodDescriptor(
  '/account.Account/GetReceptorToken',
  grpc.web.MethodType.UNARY,
  proto.account.ModelID,
  proto.account.ReceptorToken,
  /**
   * @param {!proto.account.ModelID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.ReceptorToken.deserializeBinary
);


/**
 * @param {!proto.account.ModelID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.ReceptorToken)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.ReceptorToken>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getReceptorToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetReceptorToken',
      request,
      metadata || {},
      methodDescriptor_Account_GetReceptorToken,
      callback);
};


/**
 * @param {!proto.account.ModelID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.ReceptorToken>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getReceptorToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetReceptorToken',
      request,
      metadata || {},
      methodDescriptor_Account_GetReceptorToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.NewUser,
 *   !proto.account.AuthenticatedUser>}
 */
const methodDescriptor_Account_AddUser = new grpc.web.MethodDescriptor(
  '/account.Account/AddUser',
  grpc.web.MethodType.UNARY,
  proto.account.NewUser,
  proto.account.AuthenticatedUser,
  /**
   * @param {!proto.account.NewUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AuthenticatedUser.deserializeBinary
);


/**
 * @param {!proto.account.NewUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AuthenticatedUser)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AuthenticatedUser>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.addUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/AddUser',
      request,
      metadata || {},
      methodDescriptor_Account_AddUser,
      callback);
};


/**
 * @param {!proto.account.NewUser} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AuthenticatedUser>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.addUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/AddUser',
      request,
      metadata || {},
      methodDescriptor_Account_AddUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_DeleteTemplateContent = new grpc.web.MethodDescriptor(
  '/account.Account/DeleteTemplateContent',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.deleteTemplateContent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/DeleteTemplateContent',
      request,
      metadata || {},
      methodDescriptor_Account_DeleteTemplateContent,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.deleteTemplateContent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/DeleteTemplateContent',
      request,
      metadata || {},
      methodDescriptor_Account_DeleteTemplateContent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.EnableComplianceFrameworksRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_EnableComplianceFrameworks = new grpc.web.MethodDescriptor(
  '/account.Account/EnableComplianceFrameworks',
  grpc.web.MethodType.UNARY,
  proto.account.EnableComplianceFrameworksRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.EnableComplianceFrameworksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.EnableComplianceFrameworksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.enableComplianceFrameworks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/EnableComplianceFrameworks',
      request,
      metadata || {},
      methodDescriptor_Account_EnableComplianceFrameworks,
      callback);
};


/**
 * @param {!proto.account.EnableComplianceFrameworksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.enableComplianceFrameworks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/EnableComplianceFrameworks',
      request,
      metadata || {},
      methodDescriptor_Account_EnableComplianceFrameworks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.CreateComplianceFrameworkRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_CreateComplianceFramework = new grpc.web.MethodDescriptor(
  '/account.Account/CreateComplianceFramework',
  grpc.web.MethodType.UNARY,
  proto.account.CreateComplianceFrameworkRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.CreateComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.CreateComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.createComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/CreateComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Account_CreateComplianceFramework,
      callback);
};


/**
 * @param {!proto.account.CreateComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.createComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/CreateComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Account_CreateComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.FeatureAccessRequest,
 *   !proto.account.FeatureAccessResponse>}
 */
const methodDescriptor_Account_CheckFeatureAccess = new grpc.web.MethodDescriptor(
  '/account.Account/CheckFeatureAccess',
  grpc.web.MethodType.UNARY,
  proto.account.FeatureAccessRequest,
  proto.account.FeatureAccessResponse,
  /**
   * @param {!proto.account.FeatureAccessRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.FeatureAccessResponse.deserializeBinary
);


/**
 * @param {!proto.account.FeatureAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.FeatureAccessResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.FeatureAccessResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.checkFeatureAccess =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/CheckFeatureAccess',
      request,
      metadata || {},
      methodDescriptor_Account_CheckFeatureAccess,
      callback);
};


/**
 * @param {!proto.account.FeatureAccessRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.FeatureAccessResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.checkFeatureAccess =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/CheckFeatureAccess',
      request,
      metadata || {},
      methodDescriptor_Account_CheckFeatureAccess);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.GetScopingConfigResponse>}
 */
const methodDescriptor_Account_GetScopingConfig = new grpc.web.MethodDescriptor(
  '/account.Account/GetScopingConfig',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.GetScopingConfigResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.GetScopingConfigResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.GetScopingConfigResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.GetScopingConfigResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getScopingConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetScopingConfig',
      request,
      metadata || {},
      methodDescriptor_Account_GetScopingConfig,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.GetScopingConfigResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getScopingConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetScopingConfig',
      request,
      metadata || {},
      methodDescriptor_Account_GetScopingConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.UpdateScopingConfigRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_UpdateScopingConfig = new grpc.web.MethodDescriptor(
  '/account.Account/UpdateScopingConfig',
  grpc.web.MethodType.UNARY,
  proto.account.UpdateScopingConfigRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.UpdateScopingConfigRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.UpdateScopingConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.updateScopingConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/UpdateScopingConfig',
      request,
      metadata || {},
      methodDescriptor_Account_UpdateScopingConfig,
      callback);
};


/**
 * @param {!proto.account.UpdateScopingConfigRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.updateScopingConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/UpdateScopingConfig',
      request,
      metadata || {},
      methodDescriptor_Account_UpdateScopingConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.PlgDetails,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_UpdatePlgDetails = new grpc.web.MethodDescriptor(
  '/account.Account/UpdatePlgDetails',
  grpc.web.MethodType.UNARY,
  proto.account.PlgDetails,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.PlgDetails} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.PlgDetails} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.updatePlgDetails =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/UpdatePlgDetails',
      request,
      metadata || {},
      methodDescriptor_Account_UpdatePlgDetails,
      callback);
};


/**
 * @param {!proto.account.PlgDetails} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.updatePlgDetails =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/UpdatePlgDetails',
      request,
      metadata || {},
      methodDescriptor_Account_UpdatePlgDetails);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.AttemptEmailSignupRequest,
 *   !proto.account.AttemptEmailSignupResponse>}
 */
const methodDescriptor_Account_AttemptEmailSignup = new grpc.web.MethodDescriptor(
  '/account.Account/AttemptEmailSignup',
  grpc.web.MethodType.UNARY,
  proto.account.AttemptEmailSignupRequest,
  proto.account.AttemptEmailSignupResponse,
  /**
   * @param {!proto.account.AttemptEmailSignupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.AttemptEmailSignupResponse.deserializeBinary
);


/**
 * @param {!proto.account.AttemptEmailSignupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.AttemptEmailSignupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.AttemptEmailSignupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.attemptEmailSignup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/AttemptEmailSignup',
      request,
      metadata || {},
      methodDescriptor_Account_AttemptEmailSignup,
      callback);
};


/**
 * @param {!proto.account.AttemptEmailSignupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.AttemptEmailSignupResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.attemptEmailSignup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/AttemptEmailSignup',
      request,
      metadata || {},
      methodDescriptor_Account_AttemptEmailSignup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.ReceiveCognitoVerificationCodeRequest,
 *   !proto.account.ReceiveCognitoVerificationCodeResponse>}
 */
const methodDescriptor_Account_ReceiveCognitoVerificationCode = new grpc.web.MethodDescriptor(
  '/account.Account/ReceiveCognitoVerificationCode',
  grpc.web.MethodType.UNARY,
  proto.account.ReceiveCognitoVerificationCodeRequest,
  proto.account.ReceiveCognitoVerificationCodeResponse,
  /**
   * @param {!proto.account.ReceiveCognitoVerificationCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.ReceiveCognitoVerificationCodeResponse.deserializeBinary
);


/**
 * @param {!proto.account.ReceiveCognitoVerificationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.ReceiveCognitoVerificationCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.ReceiveCognitoVerificationCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.receiveCognitoVerificationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/ReceiveCognitoVerificationCode',
      request,
      metadata || {},
      methodDescriptor_Account_ReceiveCognitoVerificationCode,
      callback);
};


/**
 * @param {!proto.account.ReceiveCognitoVerificationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.ReceiveCognitoVerificationCodeResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.receiveCognitoVerificationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/ReceiveCognitoVerificationCode',
      request,
      metadata || {},
      methodDescriptor_Account_ReceiveCognitoVerificationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.ResendVerificationCodeRequest,
 *   !proto.account.ResendVerificationCodeResponse>}
 */
const methodDescriptor_Account_ResendCognitoVerificationCode = new grpc.web.MethodDescriptor(
  '/account.Account/ResendCognitoVerificationCode',
  grpc.web.MethodType.UNARY,
  proto.account.ResendVerificationCodeRequest,
  proto.account.ResendVerificationCodeResponse,
  /**
   * @param {!proto.account.ResendVerificationCodeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.ResendVerificationCodeResponse.deserializeBinary
);


/**
 * @param {!proto.account.ResendVerificationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.ResendVerificationCodeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.ResendVerificationCodeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.resendCognitoVerificationCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/ResendCognitoVerificationCode',
      request,
      metadata || {},
      methodDescriptor_Account_ResendCognitoVerificationCode,
      callback);
};


/**
 * @param {!proto.account.ResendVerificationCodeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.ResendVerificationCodeResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.resendCognitoVerificationCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/ResendCognitoVerificationCode',
      request,
      metadata || {},
      methodDescriptor_Account_ResendCognitoVerificationCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.GetAccountsResponse>}
 */
const methodDescriptor_Account_GetReadOnlyAccounts = new grpc.web.MethodDescriptor(
  '/account.Account/GetReadOnlyAccounts',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.GetAccountsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.GetAccountsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.GetAccountsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.GetAccountsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getReadOnlyAccounts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetReadOnlyAccounts',
      request,
      metadata || {},
      methodDescriptor_Account_GetReadOnlyAccounts,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.GetAccountsResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getReadOnlyAccounts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetReadOnlyAccounts',
      request,
      metadata || {},
      methodDescriptor_Account_GetReadOnlyAccounts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.JoinReadOnlyAccountRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Account_JoinReadOnlyAccount = new grpc.web.MethodDescriptor(
  '/account.Account/JoinReadOnlyAccount',
  grpc.web.MethodType.UNARY,
  proto.account.JoinReadOnlyAccountRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.JoinReadOnlyAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.JoinReadOnlyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.joinReadOnlyAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/JoinReadOnlyAccount',
      request,
      metadata || {},
      methodDescriptor_Account_JoinReadOnlyAccount,
      callback);
};


/**
 * @param {!proto.account.JoinReadOnlyAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.joinReadOnlyAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/JoinReadOnlyAccount',
      request,
      metadata || {},
      methodDescriptor_Account_JoinReadOnlyAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.SystemDescriptionStatus>}
 */
const methodDescriptor_Account_GetSystemDescriptionStatus = new grpc.web.MethodDescriptor(
  '/account.Account/GetSystemDescriptionStatus',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.SystemDescriptionStatus,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.SystemDescriptionStatus.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.SystemDescriptionStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.SystemDescriptionStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getSystemDescriptionStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetSystemDescriptionStatus',
      request,
      metadata || {},
      methodDescriptor_Account_GetSystemDescriptionStatus,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.SystemDescriptionStatus>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getSystemDescriptionStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetSystemDescriptionStatus',
      request,
      metadata || {},
      methodDescriptor_Account_GetSystemDescriptionStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.GenerateSystemDescriptionDocResponse>}
 */
const methodDescriptor_Account_GenerateSystemDescriptionDoc = new grpc.web.MethodDescriptor(
  '/account.Account/GenerateSystemDescriptionDoc',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.GenerateSystemDescriptionDocResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.GenerateSystemDescriptionDocResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.GenerateSystemDescriptionDocResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.GenerateSystemDescriptionDocResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.generateSystemDescriptionDoc =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GenerateSystemDescriptionDoc',
      request,
      metadata || {},
      methodDescriptor_Account_GenerateSystemDescriptionDoc,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.GenerateSystemDescriptionDocResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.generateSystemDescriptionDoc =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GenerateSystemDescriptionDoc',
      request,
      metadata || {},
      methodDescriptor_Account_GenerateSystemDescriptionDoc);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.GetUsageMetricsRequest,
 *   !proto.account.GetUsageMetricsResponse>}
 */
const methodDescriptor_Account_GetUsageMetrics = new grpc.web.MethodDescriptor(
  '/account.Account/GetUsageMetrics',
  grpc.web.MethodType.UNARY,
  proto.account.GetUsageMetricsRequest,
  proto.account.GetUsageMetricsResponse,
  /**
   * @param {!proto.account.GetUsageMetricsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.GetUsageMetricsResponse.deserializeBinary
);


/**
 * @param {!proto.account.GetUsageMetricsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.GetUsageMetricsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.GetUsageMetricsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getUsageMetrics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_Account_GetUsageMetrics,
      callback);
};


/**
 * @param {!proto.account.GetUsageMetricsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.GetUsageMetricsResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getUsageMetrics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetUsageMetrics',
      request,
      metadata || {},
      methodDescriptor_Account_GetUsageMetrics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.UsageYearsResponse>}
 */
const methodDescriptor_Account_GetUsageYears = new grpc.web.MethodDescriptor(
  '/account.Account/GetUsageYears',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.UsageYearsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.UsageYearsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.UsageYearsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.UsageYearsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.AccountClient.prototype.getUsageYears =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.Account/GetUsageYears',
      request,
      metadata || {},
      methodDescriptor_Account_GetUsageYears,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.UsageYearsResponse>}
 *     Promise that resolves to the response
 */
proto.account.AccountPromiseClient.prototype.getUsageYears =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.Account/GetUsageYears',
      request,
      metadata || {},
      methodDescriptor_Account_GetUsageYears);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.account.UserClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.account.UserPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.GetRequest,
 *   !proto.account.UserRecord>}
 */
const methodDescriptor_User_Get = new grpc.web.MethodDescriptor(
  '/account.User/Get',
  grpc.web.MethodType.UNARY,
  proto.account.GetRequest,
  proto.account.UserRecord,
  /**
   * @param {!proto.account.GetRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.UserRecord.deserializeBinary
);


/**
 * @param {!proto.account.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.UserRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.UserRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/Get',
      request,
      metadata || {},
      methodDescriptor_User_Get,
      callback);
};


/**
 * @param {!proto.account.GetRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.UserRecord>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/Get',
      request,
      metadata || {},
      methodDescriptor_User_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.account.Users>}
 */
const methodDescriptor_User_GetUsersInAccount = new grpc.web.MethodDescriptor(
  '/account.User/GetUsersInAccount',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.account.Users,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.Users.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.Users)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.Users>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.getUsersInAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/GetUsersInAccount',
      request,
      metadata || {},
      methodDescriptor_User_GetUsersInAccount,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.Users>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.getUsersInAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/GetUsersInAccount',
      request,
      metadata || {},
      methodDescriptor_User_GetUsersInAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.UpdateUser,
 *   !proto.account.UserRecord>}
 */
const methodDescriptor_User_Update = new grpc.web.MethodDescriptor(
  '/account.User/Update',
  grpc.web.MethodType.UNARY,
  proto.account.UpdateUser,
  proto.account.UserRecord,
  /**
   * @param {!proto.account.UpdateUser} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.UserRecord.deserializeBinary
);


/**
 * @param {!proto.account.UpdateUser} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.UserRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.UserRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.update =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/Update',
      request,
      metadata || {},
      methodDescriptor_User_Update,
      callback);
};


/**
 * @param {!proto.account.UpdateUser} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.UserRecord>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.update =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/Update',
      request,
      metadata || {},
      methodDescriptor_User_Update);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.UserID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_User_Activate = new grpc.web.MethodDescriptor(
  '/account.User/Activate',
  grpc.web.MethodType.UNARY,
  proto.account.UserID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.UserID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.UserID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.activate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/Activate',
      request,
      metadata || {},
      methodDescriptor_User_Activate,
      callback);
};


/**
 * @param {!proto.account.UserID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.activate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/Activate',
      request,
      metadata || {},
      methodDescriptor_User_Activate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.UserID,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_User_Deactivate = new grpc.web.MethodDescriptor(
  '/account.User/Deactivate',
  grpc.web.MethodType.UNARY,
  proto.account.UserID,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.account.UserID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.account.UserID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.deactivate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/Deactivate',
      request,
      metadata || {},
      methodDescriptor_User_Deactivate,
      callback);
};


/**
 * @param {!proto.account.UserID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.deactivate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/Deactivate',
      request,
      metadata || {},
      methodDescriptor_User_Deactivate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.Email,
 *   !proto.account.Call>}
 */
const methodDescriptor_User_RequestPasswordReset = new grpc.web.MethodDescriptor(
  '/account.User/RequestPasswordReset',
  grpc.web.MethodType.UNARY,
  proto.account.Email,
  proto.account.Call,
  /**
   * @param {!proto.account.Email} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.Call.deserializeBinary
);


/**
 * @param {!proto.account.Email} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.Call)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.Call>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.requestPasswordReset =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/RequestPasswordReset',
      request,
      metadata || {},
      methodDescriptor_User_RequestPasswordReset,
      callback);
};


/**
 * @param {!proto.account.Email} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.Call>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.requestPasswordReset =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/RequestPasswordReset',
      request,
      metadata || {},
      methodDescriptor_User_RequestPasswordReset);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.account.ResetPasswordRequest,
 *   !proto.account.ResetPasswordReply>}
 */
const methodDescriptor_User_ResetPassword = new grpc.web.MethodDescriptor(
  '/account.User/ResetPassword',
  grpc.web.MethodType.UNARY,
  proto.account.ResetPasswordRequest,
  proto.account.ResetPasswordReply,
  /**
   * @param {!proto.account.ResetPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.account.ResetPasswordReply.deserializeBinary
);


/**
 * @param {!proto.account.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.account.ResetPasswordReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.account.ResetPasswordReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.account.UserClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/account.User/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_User_ResetPassword,
      callback);
};


/**
 * @param {!proto.account.ResetPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.account.ResetPasswordReply>}
 *     Promise that resolves to the response
 */
proto.account.UserPromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/account.User/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_User_ResetPassword);
};


module.exports = proto.account;

