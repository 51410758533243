import React from 'react'
import queryString, { ParsedQuery } from 'query-string'
import {
  RECEPTOR_TYPE,
  ReceptorRecord,
} from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { getAllReceptorTemplatesFiltered } from 'src/Utils/Receptors/getAllReceptorTemplatesFiltered'
import { Receptor, Service, getReceptorTemplate } from 'src/xgenerated'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { CUSTOM_RECEPTOR_ID } from 'src/Utils/Receptors/receptors.constants'
import { TabType } from 'src/components/Reusable/Tabs'
import { ControlsIndexGrid } from '../Controls/ControlsIndexPage/ControlsIndexGrid'
import {
  NON_INDEX_RECEPTOR_MODEL_IDS,
  ReceptorStatusLabels,
} from './receptors.constants'
import { CustomReceptorDetails, ServicesTabGrid } from './receptors.components'

// TODO: Clean this up/consolidate some of these helper functions
export const getReceptorTemplates = (): Receptor[] => {
  return getAllReceptorTemplatesFiltered(RECEPTOR_TYPE.RECEPTOR).sort((a, b) =>
    a.name.localeCompare(b.name),
  )
}

// merge receptor instance data from db with receptor template data
export const mergeReceptorData = (
  receptorRecord: ReceptorRecord,
  receptorTemplate: Receptor,
): Receptor => ({
  ...receptorRecord.toObject(),
  ...receptorTemplate,
  id: receptorRecord.getId(),
  isenabled: receptorRecord.getIsenabled(),
  iscredvalid: receptorRecord.getIscredvalid(),
  tenantid: receptorRecord.getTenantid(),
  credentials: receptorRecord.getCredentials(),
  config: receptorRecord.getConfig(),
  updatedat: receptorRecord.getUpdatedat(),
  automatedcontrolsList: receptorRecord.getAutomatedcontrolsList(),
  lastrun: receptorRecord.getLastrun(),
  exceptions: receptorRecord.getExceptions(),
})

const updateTemplateReceptors = (
  receptorRecords: ReceptorRecord[],
): Receptor[] =>
  receptorRecords.map((receptorRecord: ReceptorRecord) => {
    const receptorTemplate = getReceptorTemplate(receptorRecord.getModelid())
    return mergeReceptorData(receptorRecord, receptorTemplate)
  })

export const sortReceptors = (receptors: Receptor[]): Receptor[] => {
  return receptors.sort((a, b) => a.name.localeCompare(b.name))
}

export const getAccountReceptors = (
  receptorRecords: ReceptorRecord[],
  locSearch?: string,
): Receptor[] => {
  const receptors = updateTemplateReceptors(receptorRecords)
  const sortedReceptors = sortReceptors(receptors)
  return applyFrontendReceptorFilters(locSearch || '', sortedReceptors)
}

export const getActiveReceptors = (
  receptorRecords: ReceptorRecord[],
): Receptor[] => {
  const receptors = getAccountReceptors(receptorRecords)
  return receptors.filter((receptor) => receptor.isenabled)
}

export const applyFrontendReceptorFilters = (
  locSearch: string,
  receptors: Receptor[],
): Receptor[] => {
  const queryParams: ParsedQuery<string> = queryString.parse(locSearch, {
    arrayFormat: 'bracket',
  })
  let filteredReceptors = receptors
  // Apply all frontend filters for each parameter in URL
  Object.values(FilterParam).forEach((filterType: FilterParam) => {
    const filterParams = queryParams[filterType] as string[]

    if (!filterParams?.length) {
      return
    }
    if (filterType === FilterParam.STATUS) {
      const filterParamSet = new Set(filterParams)
      if (
        filterParamSet.has(ReceptorStatusLabels.ACTIVE) &&
        filterParamSet.has(ReceptorStatusLabels.DEACTIVATED)
      ) {
        return
      } else if (filterParamSet.has(ReceptorStatusLabels.ACTIVE)) {
        filteredReceptors = filteredReceptors.filter(
          (receptor) => receptor.isenabled,
        )
      } else if (filterParamSet.has(ReceptorStatusLabels.DEACTIVATED)) {
        filteredReceptors = filteredReceptors.filter(
          (receptor) => !receptor.isenabled,
        )
      }
    }
  })
  return filteredReceptors
}

export const getServicesTab = (services: Service[]): TabType | undefined => {
  if (!services.length) {
    return
  }
  return {
    title: 'Services',
    toolTipBody: `A SaaS vendor may offer many services.  Each service you're using for service delivery should be included.  In addition, you can exclude instances of a service that's not used for service delivery.  For example, S3 buckets used for developer experimentation should be excluded.`,
    count: services.length,
    body: <ServicesTabGrid services={services} />,
  }
}

export const getControlsTab = (
  modelId: string,
  controlsList: string[],
): TabType | undefined => {
  if (modelId === CUSTOM_RECEPTOR_ID || controlsList.length === 0) {
    return
  }
  return {
    title: 'Automatable Controls',
    toolTipBody:
      'The receptor will generate evidence for this list of controls.',
    body: <ControlsIndexGrid controlIds={controlsList || []} />,
    count: controlsList.length,
  }
}

export const getCustomReceptorTab = (receptorId: string): TabType => ({
  title: 'Custom Receptor Config',
  toolTipBody: 'Configuration details for your custom receptor.',
  body: <CustomReceptorDetails receptorId={receptorId} />,
})

export const getFilteredReceptorRecords = (
  receptors: ReceptorRecord[],
): ReceptorRecord[] => {
  return receptors.filter(
    (receptor) => !NON_INDEX_RECEPTOR_MODEL_IDS.includes(receptor.getModelid()),
  )
}
