import React from 'react'
import styled, { DefaultTheme, StyledComponent } from 'styled-components/macro'
import {
  border,
  compose,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  margin,
  padding,
  shadow,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'
import { RISK_STATUS } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { RISK_STATUS_LABEL } from 'src/pages/Risks'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { QUESTIONNAIRE_ACCEPTANCE_LABEL } from 'src/pages/SecurityQuestionnaire/securityquestionnaire.constants'
import { QUESTIONNAIRE_ACCEPTANCE } from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { CheckSVG, DropdownSVG } from '../Icons/Basic'
import palette from '../../designSystem/variables/palette'
import { controlStatusTitle } from '../../lib/common/types'
import { STATUS_KEYS } from './StatusDropdown.constants'
import { getStatusKey } from './StatusDropdown.helpers'

type StatusCssType = {
  color: string
  bg: string
  hover: string
  hoverText: string
}

export const StatusStyles: Record<STATUS_KEYS, StatusCssType> = {
  // Controls Statuses
  [STATUS_KEYS.CONTROL_NEEDS_ATTENTION]: {
    color: palette.neutral['900'],
    bg: palette.neutral['50'],
    hover: palette.blue['50'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.CONTROL_READY_FOR_REVIEW]: {
    color: palette.neutral.white,
    bg: palette.blue['400'],
    hover: palette.blue['50'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.CONTROL_AUDIT_READY]: {
    color: palette.neutral.white,
    bg: palette.neutral['400'],
    hover: palette.blue['50'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.CONTROL_ACCEPTED]: {
    color: palette.neutral.white,
    bg: palette.green['500'],
    hover: palette.blue['50'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.CONTROL_FAILED_TEST]: {
    color: palette.neutral.white,
    bg: palette.magenta['500'],
    hover: palette.blue['50'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.CONTROL_NOT_APPLICABLE]: {
    color: palette.neutral.white,
    bg: palette.neutral['600'],
    hover: palette.blue['50'],
    hoverText: palette.neutral['900'],
  },

  // Risk Statuses
  [STATUS_KEYS.RISK_TODO]: {
    color: palette.neutral.black,
    bg: palette.neutral['50'],
    hover: palette.neutral['100'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.RISK_IN_PROGRESS]: {
    color: palette.blue['800'],
    bg: palette.blue['50'],
    hover: palette.blue['100'],
    hoverText: palette.blue['800'],
  },
  [STATUS_KEYS.RISK_PENDING]: {
    color: palette.neutral.white,
    bg: palette.neutral['400'],
    hover: palette.neutral['100'],
    hoverText: palette.neutral['900'],
  },
  [STATUS_KEYS.RISK_COMPLETED]: {
    color: palette.neutral.white,
    bg: palette.green['500'],
    hover: palette.green['100'],
    hoverText: palette.neutral['900'],
  },

  // AI GRC Q&A Result Acceptance
  [STATUS_KEYS.QUESTIONNAIRE_IN_PROGRESS]: {
    color: palette.blue['800'],
    bg: palette.blue['50'],
    hover: palette.blue['100'],
    hoverText: palette.blue['800'],
  },
  [STATUS_KEYS.QUESTIONNAIRE_ACCEPTED]: {
    color: palette.neutral.white,
    bg: palette.green['500'],
    hover: palette.green['100'],
    hoverText: palette.neutral['900'],
  },
}

export const StatusDropdownContainer = styled.div<{ isLarge?: boolean }>`
  position: relative;
  font-weight: 500;
  font-size: ${({ isLarge }) => (isLarge ? '14px' : '12px')};
  letter-spacing: 0.2em;
  border-radius: 2px;
  cursor: pointer;
  margin-right: ${({ isLarge }) => (isLarge ? '4px' : 'unset')};
`

interface StatusDropdownMenuContainerProps {
  readonly isVisible: boolean
}

export const StatusDropdownMenuContainer = styled.div.attrs({
  border: '1px solid',
  borderColor: 'border.neutral.light',
  boxShadow: 'outer.base',
})<StatusDropdownMenuContainerProps>`
  ${compose(border, shadow)}
  margin-top: 30px;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  width: max-content;
  background-color: white;
  cursor: pointer;
`

export type StatusDropdownToggleProps<T = CONTROL_STATUS> = Pick<
  LayoutProps,
  'width' | 'height'
> &
  FlexboxProps & {
    isVisible?: boolean
    status: T
    isLarge?: boolean
    modelType: MODEL_TYPE
  }

export const StatusDropdownToggle = <T,>(): StyledComponent<
  'button',
  DefaultTheme,
  StatusDropdownToggleProps<T>,
  never
> => styled.button.attrs<StatusDropdownToggleProps<T>>((props) => ({
  children: (
    <>
      {props.children}
      {!props.disabled && <DropdownSVG />}
    </>
  ),
}))<StatusDropdownToggleProps<T>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ disabled, isLarge }) =>
    !disabled ? (isLarge ? '195px' : '185px') : '165px'};
  height: ${({ isLarge }) => (isLarge ? '29px' : '24px')};
  padding: 8px 12px;
  border: none;
  color: ${({ status, modelType }) =>
    StatusStyles[getStatusKey(status, modelType)].color};
  background-color: ${({ status, modelType }) =>
    StatusStyles[getStatusKey(status, modelType)].bg};
  text-align: left;

  :hover {
    color: ${({ status, modelType, disabled }) =>
      !disabled
        ? StatusStyles[getStatusKey(status, modelType)].hoverText
        : null};
    background-color: ${({ disabled, status, modelType }) =>
      !disabled
        ? StatusStyles[getStatusKey(status, modelType)].hover
        : StatusStyles[getStatusKey(status, modelType)].bg};
    svg {
      fill: ${({ status, modelType }) =>
        StatusStyles[getStatusKey(status, modelType)].hoverText};
    }
  }

  svg {
    fill: ${({ status, modelType }) =>
      StatusStyles[getStatusKey(status, modelType)].color};
    transform: ${({ isVisible }) => {
      return isVisible ? 'scaleY(-1)' : 'scaleY(1)'
    }};
    transition: transform 0.35s;
  }

  ${compose(layout, flexbox)}
`

type StatusDropdownItemProps<T> = Omit<
  StatusDropdownToggleProps<T>,
  'isVisible'
> & {
  status: T
  selected: T
  modelType: MODEL_TYPE
}

export const StatusDropdownItem = <T,>(): StyledComponent<
  'button',
  DefaultTheme,
  StatusDropdownItemProps<T>,
  never
> => styled.button.attrs<StatusDropdownItemProps<T>>((props) => {
  const StatusDropdownItemChipComponent = StatusDropdownItemChip<T>()

  const getLabel = (modelType: MODEL_TYPE) => {
    switch (modelType) {
      case MODEL_TYPE.RISK:
        return RISK_STATUS_LABEL[props.status as unknown as RISK_STATUS]
      case MODEL_TYPE.CONTROL:
        return controlStatusTitle[props.status as unknown as CONTROL_STATUS]
      case MODEL_TYPE.QUESTIONNAIRE:
        return QUESTIONNAIRE_ACCEPTANCE_LABEL[
          props.status as unknown as QUESTIONNAIRE_ACCEPTANCE
        ]
      default:
        return 'Status'
    }
  }

  return {
    pl: 's',
    children: (
      <>
        <StatusDropdownItemChipComponent
          status={props.status}
          modelType={props.modelType}
        >
          {getLabel(props.modelType)}
        </StatusDropdownItemChipComponent>
        <CheckSVG
          height="20px"
          fill={
            props.status === props.selected
              ? 'text.icon.default'
              : 'transparent'
          }
        />
        {props.children}
      </>
    ),
  }
})<StatusDropdownItemProps<T>>`
  ${padding}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: none;
  color: ${({ status, modelType }) =>
    StatusStyles[getStatusKey(status, modelType)].color};
  text-align: left;

  background-color: white;

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.text.icon.hover')};
    outline: none;
  }
`

export const StatusDropdownItemChip = <T,>(): StyledComponent<
  'div',
  DefaultTheme,
  Omit<StatusDropdownToggleProps<T>, 'isVisible'>,
  never
> => styled.div.attrs({
  mr: 'xs',
})<Omit<StatusDropdownToggleProps<T>, 'isVisible'>>`
  ${margin}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 24px;
  padding: 8px 12px;
  border: none;
  background-color: ${({ status, modelType }) =>
    StatusStyles[getStatusKey(status, modelType)].bg};
  color: ${({ status, modelType }) =>
    StatusStyles[getStatusKey(status, modelType)].color};
  text-align: left;
`
