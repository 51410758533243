import React from 'react'
import { RECEPTOR_TYPE } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { useUsers } from 'src/components/async/Users'
import { useHasAnecdotesReceptor } from 'src/context/Gatekeeper'
import { FilterName, FilterParam } from '../FilterBar.types'
import { FilterDropdown } from './FilterDropdown'
import { NestedFilterType } from './FilterDropdown.constants'
import { getReceptorFilterValues } from './FilterDropdown.helpers'
import { LoadingFilterDropdown } from './FilterDropdown.components'

export enum SourceFilterValues {
  RECEPTOR = 'receptor',
  USER = 'user',
  GRC_PLATFORM = 'grc_platform',
  DOCUMENT_MANAGEMENT_SYSTEM = 'document_management_system',
}

enum SourceFilterNames {
  RECEPTOR = 'Receptor',
  USER = 'User',
  GRC_PLATFORM = 'GRC Platform',
  DOCUMENT_MANAGEMENT_SYSTEM = 'Document Management System',
}

const sourceFilterNameToValue = Object.freeze({
  [SourceFilterNames.RECEPTOR]: SourceFilterValues.RECEPTOR,
  [SourceFilterNames.USER]: SourceFilterValues.USER,
  [SourceFilterNames.GRC_PLATFORM]: SourceFilterValues.GRC_PLATFORM,
  [SourceFilterNames.DOCUMENT_MANAGEMENT_SYSTEM]:
    SourceFilterValues.DOCUMENT_MANAGEMENT_SYSTEM,
})

export const SourceFilterDropdown = (): JSX.Element => {
  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError,
  } = useUsers()
  const {
    data: hasAnecdotesData,
    isLoading: hasAnecdotesLoading,
    error: hasAnecdotesError,
  } = useHasAnecdotesReceptor()
  if (usersError || hasAnecdotesError) return <></>
  if (!usersData || usersLoading || !hasAnecdotesData || hasAnecdotesLoading) {
    return <LoadingFilterDropdown filterName={FilterName.SOURCE} />
  }
  const hasAnecdotes = hasAnecdotesData.getAllowAccess()

  const users = usersData.getUsersList()

  const userFilterValues = users.map((user) => {
    return {
      value: `${user.getEmail()?.toLowerCase()}`,
      label: user.getName() || user.getEmail(),
    }
  })

  const sourceFilterNameToNestedFilter: Record<
    SourceFilterNames,
    NestedFilterType
  > = {
    [SourceFilterNames.RECEPTOR]: {
      filterParam: FilterParam.RECEPTOR,
      filterValues: getReceptorFilterValues(RECEPTOR_TYPE.RECEPTOR),
    },
    [SourceFilterNames.USER]: {
      filterParam: FilterParam.OWNER,
      filterValues: userFilterValues,
    },
    [SourceFilterNames.GRC_PLATFORM]: {
      filterParam: FilterParam.GRC_PLATFORM,
      filterValues: getReceptorFilterValues(RECEPTOR_TYPE.GRC_PLATFORM),
    },
    [SourceFilterNames.DOCUMENT_MANAGEMENT_SYSTEM]: {
      filterParam: FilterParam.DOCUMENT_MANAGEMENT_SYSTEM,
      filterValues: getReceptorFilterValues(RECEPTOR_TYPE.COLLABORATION_SYSTEM),
    },
  }
  const filterValues = Object.values(SourceFilterNames)
    .filter(
      (filterName: SourceFilterNames) =>
        hasAnecdotes || filterName !== SourceFilterNames.GRC_PLATFORM,
    )
    .map((filterName: SourceFilterNames) => {
      const filterValue = {
        value: sourceFilterNameToValue[filterName],
        label: filterName,
        nestedFilter: sourceFilterNameToNestedFilter[filterName],
      }
      return filterValue
    })
  return (
    <FilterDropdown
      filterParam={FilterParam.SOURCE}
      filterName={FilterName.SOURCE}
      filterValues={filterValues}
      hasNested
    />
  )
}
