import { CONTROL_TEST_NAMES } from '../AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'

export interface RoadmapIndexRowType {
  title: string
  description: string
  link: string
  percentage: number
}

export type RoadmapApprovalType = {
  title: string
  subtitle: string
  description: string
  value: boolean
  toggleValue: (type?: RoadmapToggles) => void
  toggleType: RoadmapToggles
  isToggling: boolean
}

export type FRACTION_ELEMENTS = {
  numerator: number
  denominator: number
}

export type RoadmapIndexConfig = Record<RoadmapSteps, RoadmapIndexRowType>

export enum RoadmapSteps {
  Scope = 'scope',
  RiskAssessment = 'risk-assessment',
  Design = 'design',
  Implementation = 'implementation',
  HandleRequests = 'handle-requests',
  OperatingEffectiveness = 'operating-effectiveness',
}

export enum RoadmapToggles {
  BusinessContinuityContingency = 'businessContinuityContingency',
  SupportingDocumentsSop = 'supportingDocumentsSop',
  PoliciesApproved = 'policiesApproved',
}

export const OPERATING_EFFECTIVENESS_CHECKS = [
  RoadmapToggles.SupportingDocumentsSop,
]

export const IMPLEMENTATION_CHECKS = [
  RoadmapToggles.BusinessContinuityContingency,
  RoadmapToggles.PoliciesApproved,
]

export const RoadmapShowStaticText = Object.freeze({
  Scope: {
    title: 'Scope',
    subtitle:
      'Your first challenge. Get started on the basics, allowing everyone involved to understand what the basic structures will be and what systems are in scope.',
  },
  RiskAssessment: {
    title: 'Risk Assessment',
    subtitle: 'Identify and track your organization’s risks.',
  },
  Design: {
    title: 'Design',
    subtitle:
      'Finalize your policies and control language so you know exactly what compliance should look like for your company.',
  },
  Implementation: {
    title: 'Implementation',
    subtitle: 'Define and delegate the work of proving compliance.',
  },
  HandleRequests: {
    title: 'Handle Requests',
    subtitle:
      "Use your auditor's document requests to link evidence to the relevant controls.",
  },
  OperatingEffectiveness: {
    title: 'Operating Effectiveness',
    subtitle:
      'Use Trustero AI to check that all controls should pass your audit. This involves many tests of your evidence on each control, ensuring that it proves you are doing what your control require.',
  },
})

export const RoadmapApprovalText = Object.freeze({
  Implementation: {
    PolicyApprovals: {
      title: 'POLICIES APPROVED',
      subtitle:
        'Ensure that each policy document in the platform has been approved and is ready for audit',
      description: 'My organization has approved the policies',
    },
    BusinessContinuityContingency: {
      title: 'BUSINESS CONTINUITY AND CONTINGENCY PLANS',
      subtitle:
        'Ensure that all plans (e.g., Business Impact Analysis [BIA], Business Continuity Plan [BCP], Disaster Recovery Plan [DRP] and Security Incident Response Plan [SIRP]) have been customized for your specific environment and approved by management.',
      description: 'All plans have been customized and approved by management.',
    },
  },
  Design: {},
  OperatingEffectiveness: {
    SupportingDocuments: {
      title: 'SUPPORTING DOCUMENTS: STANDARD OPERATING PROCEDURES',
      subtitle:
        'Ensure that all operational activities (e.g., Laptop Protection & Management, Vulnerability Management, Documented InfoSec Operational Activities segmented by departmental workflows) that are required for maintaining data security and achieving continual compliance are up-to-date, effectively documented and easily accessible by relevant team members.',
      description:
        'All information security practices are up-to-date, effectively documented and easily accessible by relevant team members.',
    },
  },
})

export const ROADMAP_MODAL = {
  TITLE: 'Control-Policy Material Difference: Select Controls',
  DESCRIPTION:
    'AI Examination and Test will check these controls. You will need all controls passing for your audit.',
  SUBMIT: 'Examine and Test',
}

export const DESIGN_TESTS = ['Control-Policy Material Difference']

export type SmartCheckCounts = {
  passing: string[]
  issues: string[]
  notScanned: string[]
}

export const AUDIT_READINESS_TESTS = [
  CONTROL_TEST_NAMES.COMPLETENESS,
  CONTROL_TEST_NAMES.SPOT_CHECK,
]

export const DATE_LIMIT_WARNING =
  'This is over a year ago. Consider updating this date in your settings.'
