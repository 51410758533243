import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  BulkUnlinkEvidenceRequest,
  DeleteControlEvidencesByCaptionRequest,
  DeleteEvidenceGroupRequest,
  EVIDENCE_LINKAGE_TYPE,
  EvidenceGroupId,
  EvidenceLinkage,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import log from 'loglevel'
import { formatEvidenceCaption } from 'src/Utils/helpers/string.helpers'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useInvalidateEvidence } from 'src/Utils/swrCacheInvalidation'
import { useHardEvidenceInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateEvidence'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { useConfirmationModal } from 'src/components/ModalForms'

export const useBulkDeleteEvidenceModal = ({
  controlId,
  caption,
  totalEvidenceCount,
}: {
  controlId: string
  caption: string
  totalEvidenceCount: number
}): (() => void) => {
  const mutate = useInvalidateEvidence()
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const evidenceBulkDelete = async () => {
    try {
      const request = new DeleteControlEvidencesByCaptionRequest()
        .setControlId(controlId)
        .setCaption(caption)
      await client.deleteControlEvidencesByCaption(request)
      await mutate()
    } catch (error) {
      log.error(
        `Error when bulk deleting evidence with caption ${caption} for control id ${controlId}`,
        error,
      )
    }
  }

  const confirmationModalProps = {
    title: 'Are you sure you want to delete this stack of evidence?',
    body: `This will delete all ${totalEvidenceCount} pieces of "${formatEvidenceCaption(
      caption,
      true,
    )}" evidence in this stack. This cannot be undone.`,
    confirmText: 'Delete Stack of Evidence',
    onConfirmCB: evidenceBulkDelete,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useBulkUnlinkEvidenceModal = ({
  linkageId,
  linkageType,
  caption,
  totalEvidenceCount,
}: {
  linkageId: string
  linkageType: EVIDENCE_LINKAGE_TYPE
  caption: string
  totalEvidenceCount: number
}): (() => void) => {
  const mutate = useHardEvidenceInvalidation()
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const linkageTypeString =
    linkageType === EVIDENCE_LINKAGE_TYPE.CONTROL_LINK ? 'control' : 'request'

  const evidenceBulkUnlink = async () => {
    try {
      const request = new BulkUnlinkEvidenceRequest()
        .setCaption(caption)
        .setLinkage(
          new EvidenceLinkage()
            .setLinkageId(linkageId)
            .setLinkageType(linkageType),
        )
      await client.bulkUnlinkEvidence(request)
      await mutate()
    } catch (error) {
      log.error(
        `Error when bulk unlinking evidence with caption ${caption} from ${linkageTypeString} id ${linkageId}`,
        error,
      )
      showInfoToast('Failed to unlink evidence')
    }
  }

  const confirmationModalProps = {
    title: 'Are you sure you want to unlink this stack of evidence?',
    body: `This will unlink all ${totalEvidenceCount} pieces of "${formatEvidenceCaption(
      caption,
      true,
    )}" evidence from this ${linkageTypeString}.`,
    confirmText: 'Unlink Stack of Evidence',
    onConfirmCB: evidenceBulkUnlink,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useBulkDeleteEvidenceGroupModal = ({
  caption,
  totalEvidenceCount,
}: {
  caption: string
  totalEvidenceCount: number
}): (() => void) => {
  const mutate = useInvalidateEvidence()
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)

  const evidenceGroupBulkDelete = async () => {
    try {
      const request = new DeleteEvidenceGroupRequest().setId(
        new EvidenceGroupId().setCaption(new StringValue().setValue(caption)),
      )
      await client.deleteEvidenceGroup(request)
      await mutate()
    } catch (error) {
      log.error(
        `Error when bulk deleting evidence with caption ${caption}`,
        error,
      )
      showInfoToast('Failed to delete evidence')
    }
  }

  const confirmationModalProps = {
    title: 'Are you sure you want to delete this stack of evidence?',
    body: `This will delete all ${totalEvidenceCount} pieces of "${formatEvidenceCaption(
      caption,
      true,
    )}" evidence. This cannot be undone.`,
    confirmText: 'Delete Stack of Evidence',
    onConfirmCB: evidenceGroupBulkDelete,
  }

  return useConfirmationModal(confirmationModalProps)
}
