import {
  ControlFilter,
  EvidenceGroup,
  GetLatestEvidenceGroupsRequest,
  GetLatestEvidenceRequest,
  ListEvidenceGroupsRequest,
  ListEvidenceRequest,
  Evidence as EvidenceProto,
  Excluded,
  ListEvidenceResponse,
  ListEvidenceGroupsResponse,
  FileTypeFilter,
  ActorFilter,
  EvidenceSort,
  ServiceDiscovery,
  AutomatedEvidenceFilter,
  EvidenceTypeFilter,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import queryString from 'query-string'
import {
  Evidence,
  Source,
  Sources,
  Struct,
} from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { ParsedQuery } from 'query-string'
import { PaginationConfig } from '@trustero/trustero-api-web/lib/common/pagination_pb'
import {
  DiscoveriesToMarkdown,
  ExclusionsToMarkdown,
  getColumnWidths,
  getNoEvidenceMessage,
} from 'src/components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import {
  GridColumnSortEvidence,
  GridColumnSortType,
  SORT_ORDER,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { FilterParam } from 'src/components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import {
  getEvidenceSources,
  tabulate,
} from 'src/Utils/Evidence/evidence.helpers'
import {
  formatTimestamp,
  getTimestampFromUnixMilli,
} from 'src/Utils/formatDate'
import { getServiceTemplate } from 'src/xgenerated/service'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  FileTypeFilterValues,
  FileTypeFilterValueToMimeTypes,
} from 'src/components/Reusable/IndexPage/FilterBar/FilterDropdowns/EvidenceTypeFilterDropdown'
import { generatePermalink } from 'src/components/PageLayout/Permalink'
import { UrlFragments } from 'src/Utils/globalEnums'
import {
  EVIDENCE_INDEX_PAGE_SIZE,
  EVIDENCE_SORT_COLUMNS,
} from './evidence.constants'

export const applyEvidenceFilters = (
  req: ListEvidenceRequest | GetLatestEvidenceRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }

  switch (filterType) {
    case FilterParam.CONTROL: {
      // for now, set only one control id
      req.setControlId(filterParams[0])
      break
    }
    case FilterParam.REQUEST: {
      // for now, set only one request id
      req.setDocumentRequestId(filterParams[0])
      break
    }

    default:
      break
  }
}

export const getShowLatestFromQueryParams = (
  queryParams: ParsedQuery<string>,
): boolean => {
  const showLatest: string[] = queryParams['showLatest'] as string[]
  if (!showLatest || showLatest.length !== 1) {
    return false
  }
  return showLatest[0] === 'true'
}

export const getControlIdForEvidence = (
  queryParams: ParsedQuery<string>,
): string => {
  const controlIds = queryParams[FilterParam.CONTROL]
  if (!controlIds || controlIds.length !== 1) {
    return ''
  }
  return controlIds[0]
}

export const getRequestIdForEvidence = (
  queryParams: ParsedQuery<string>,
): string => {
  const requestIds = queryParams[FilterParam.REQUEST]
  if (!requestIds || requestIds.length !== 1) {
    return ''
  }
  return requestIds[0]
}

export const isRelevantDateInAudit = (
  audit: AuditRecord,
  date?: Date,
): boolean => {
  const startDate = audit.getStartDate()
  const endDate = audit.getEndDate()
  if (!date || !startDate || !endDate) {
    return false
  }
  return date >= startDate.toDate() && date <= endDate.toDate()
}

export const applyEvidenceGroupFilters = (
  req: ListEvidenceGroupsRequest | GetLatestEvidenceGroupsRequest,
  queryParams: ParsedQuery<string>,
  filterType: FilterParam,
): void => {
  const filterParams = queryParams[filterType] as string[]

  if (!filterParams?.length) {
    return
  }
  switch (filterType) {
    case FilterParam.RECEPTOR:
    case FilterParam.OWNER:
    case FilterParam.DOCUMENT_MANAGEMENT_SYSTEM:
    case FilterParam.GRC_PLATFORM: {
      if (filterParams.length) {
        const actorFilter = req.getActorFilter() || new ActorFilter()
        for (const param of filterParams) {
          actorFilter.addActors(param)
        }
        req.setActorFilter(actorFilter)
      }
      break
    }
    case FilterParam.AUTOMATED: {
      if (filterParams.length) {
        const evidenceTypeFilter =
          req.getEvidenceTypeFilter() || new EvidenceTypeFilter()
        evidenceTypeFilter.setAutomatedEvidenceFilter(
          new AutomatedEvidenceFilter().setEvidenceIdsList(filterParams),
        )
        req.setEvidenceTypeFilter(evidenceTypeFilter)
      }
      break
    }
    case FilterParam.FILE_TYPE: {
      if (filterParams.length) {
        const evidenceTypeFilter =
          req.getEvidenceTypeFilter() || new EvidenceTypeFilter()
        const mimeTypes: string[] = []
        filterParams.forEach((param) => {
          const mimes = FileTypeFilterValueToMimeTypes[
            param as FileTypeFilterValues
          ] || [param]
          Array.isArray(mimes) && mimeTypes.push(...mimes)
        })
        const fileTypeFilter = new FileTypeFilter().setMimeTypesList(mimeTypes)
        evidenceTypeFilter.setFileTypeFilter(fileTypeFilter)
        req.setEvidenceTypeFilter(evidenceTypeFilter)
      }
      break
    }
    case FilterParam.DATE_RANGE: {
      if (filterParams.length) {
        const dates = filterParams[0].split('-')
        const dateRange = new TimeRange()
        const startMillis = parseInt(dates[0], 10)
        dateRange.setSince(getTimestampFromUnixMilli(startMillis))
        const endMillis = parseInt(dates[1], 10)
        dateRange.setUntil(getTimestampFromUnixMilli(endMillis))
        req.setDateRangeFilter(dateRange)
      }
      break
    }
    case FilterParam.CONTROL: {
      if (filterParams.length) {
        const controlFilter = new ControlFilter().setControlIdsList([
          ...filterParams,
        ])
        req.setControlFilter(controlFilter)
      }
      break
    }
    case FilterParam.PAGE: {
      if (
        req instanceof GetLatestEvidenceGroupsRequest &&
        filterParams.length
      ) {
        const pagination = new PaginationConfig()
        const pageNumber = parseInt(filterParams[0], 10)
        pagination.setPageNumber(pageNumber)
        pagination.setPageSize(EVIDENCE_INDEX_PAGE_SIZE)
        req.setPagination(pagination)
      }
      break
    }
    default:
      break
  }
}

export const applyEvidenceGroupSort = (
  req: ListEvidenceGroupsRequest | GetLatestEvidenceGroupsRequest,
  queryParams: ParsedQuery<string>,
): void => {
  const sortOrder =
    (queryParams.sort_by && (queryParams.sort_by[0] as GridColumnSortType)) ||
    undefined
  const sortCol =
    (queryParams.sort_col &&
      (queryParams.sort_col[0] as GridColumnSortEvidence)) ||
    undefined
  const shouldApplySort = sortOrder && sortCol
  if (!shouldApplySort) {
    return
  }
  const sort = new EvidenceSort()
  sort.setSortOrder(SORT_ORDER[sortOrder])
  sort.setSortColumn(EVIDENCE_SORT_COLUMNS[sortCol])
  req.setSort(sort)
}

export const generateEvidencePdfWithExclusions = async (
  body: Uint8Array | string,
  caption: string,
  exclusionsList: Excluded[],
): Promise<TDocumentDefinitions> => {
  const evidence = Evidence.deserializeBinary(body as Uint8Array) // should always be Uint8Array from ContentStore
  const evidenceSources = getEvidenceSources(evidence)
  const apiSections = evidenceSources.map((source) => [
    {
      text: 'API Call',
      style: 'subHeading',
    },
    {
      text: source.getApiCallsList().join('\n'),
      style: 'body',
    },
    {
      text: 'API Response',
      style: 'subHeading',
    },
    {
      text: JSON.stringify(JSON.parse(source.getDiscovery() || '{}'), null, 2),
      preserveLeadingSpaces: true,
      style: 'json',
    },
  ])
  const evidenceStruct: Struct = evidence.getStruct() ?? new Struct()
  const noEvidence = evidenceStruct.getRowsList().length === 0
  const noEvidenceMessage = getNoEvidenceMessage(evidence)
  const evidenceTable = tabulate(evidenceStruct)
  const exclusions = ExclusionsToMarkdown(
    exclusionsList,
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
  )
  const evidenceSection = noEvidence
    ? { text: [noEvidenceMessage], style: 'body' }
    : {
        table: {
          widths: getColumnWidths(evidenceTable),
          body: [
            evidenceTable.headers,
            ...evidenceTable.body.map((row) =>
              row.map((cell) => cell.replaceAll(':heavy_check_mark:', 'Y')),
            ),
          ],
          headerRows: 1,
          layout: 'fit',
        },
        style: 'table',
      }

  return {
    content: [
      { text: caption, style: 'title' },
      { text: 'Evidence', style: 'heading' },
      evidenceSection,
      { text: 'Exclusions', style: 'heading' },
      { text: [exclusions], style: 'body' },
      { text: 'Sources', style: 'heading' },
      ...apiSections,
    ],
    styles: {
      title: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
      heading: { fontSize: 16, bold: true, margin: [0, 10, 0, 5] },
      subHeading: { fontSize: 12, bold: true, margin: [0, 5, 0, 5] },
      body: { fontSize: 10, margin: [0, 0, 0, 10] },
      table: { fontSize: 10, margin: [0, 0, 0, 10] },
      json: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
      },
    },
  }
}

export const getEvidenceVersionDropdownOptions = (
  data?: ListEvidenceResponse | ListEvidenceGroupsResponse,
): SelectItem[] => {
  if (!data) return []
  return data
    ?.getItemsList()
    .sort((a, b) => {
      const aCreatedAt = a.getCreatedAt()
      const bCreatedAt = b.getCreatedAt()
      if (!aCreatedAt || !bCreatedAt) return 0
      return bCreatedAt.toDate().getTime() - aCreatedAt.toDate().getTime()
    })
    .map((evidence: EvidenceProto | EvidenceGroup) => ({
      name: formatTimestamp(
        evidence.getCreatedAt() || new Timestamp(),
        DATE_FORMATS.ISO_WITH_TIME,
      ),
      value: evidence.getId(),
    })) as SelectItem[]
}

export const getManualEvidenceDiscoveries = (
  sources: Source[],
): ServiceDiscovery[] => {
  const discoveries: ServiceDiscovery[] = []
  sources.forEach((source) => {
    discoveries.push(
      new ServiceDiscovery()
        .setApiCallsList([source.getRawApiRequest()])
        .setDiscovery(source.getRawApiResponse()),
    )
  })
  return discoveries
}

export const getSourcesMarkdown = (
  evidenceBody: Uint8Array,
  isMultipart: boolean,
): string => {
  const discoveries: ServiceDiscovery[] = []
  if (isMultipart) {
    const sources = Sources.deserializeBinary(evidenceBody)
    const evidenceDiscoveries = getManualEvidenceDiscoveries(
      sources.getSourcesList(),
    )
    discoveries.push(...evidenceDiscoveries)
  } else {
    const evidence = Evidence.deserializeBinary(evidenceBody)
    const evidenceDiscoveries = getEvidenceSources(evidence)
    discoveries.push(...evidenceDiscoveries)
  }
  const sourcesMarkdown = DiscoveriesToMarkdown(discoveries)
  return sourcesMarkdown
}

export const getEvidenceLink = (
  evidenceId: string,
  search: string,
  showLatest: boolean,
  pageContext?: string,
  controlId?: string,
  requestId?: string,
): string => {
  const searchParams = queryString.parse(search, {
    arrayFormat: 'bracket',
  })
  const evidencePermalink = generatePermalink({
    pageContext: pageContext as string,
    modelType: MODEL_TYPE.EVIDENCE,
    modelId: encodeURIComponent(evidenceId),
    isInternalLink: true,
  })
  const evidenceBaseQuery = showLatest
    ? {
        ...searchParams,
        showLatest: [showLatest.toString()],
      }
    : { ...searchParams }
  const evidenceQuery = controlId
    ? {
        ...evidenceBaseQuery,
        [FilterParam.CONTROL]: [controlId],
      }
    : requestId
    ? {
        ...evidenceBaseQuery,
        [FilterParam.REQUEST]: [requestId],
      }
    : evidenceBaseQuery

  const fragment = controlId
    ? `#${UrlFragments.CONTROLS}`
    : requestId
    ? `#${UrlFragments.REQUESTS}`
    : ''

  return `${queryString.stringifyUrl(
    {
      url: evidencePermalink,
      query: evidenceQuery,
    },
    { arrayFormat: 'bracket' },
  )}${fragment}`
}

export const getEvidenceGroupId = (
  caption: string,
  contentId: string,
  discoveryId?: string,
): string => {
  const parts = [caption, contentId]
  if (discoveryId) {
    parts.push(discoveryId)
  }
  const urlFriendlyId = encodeURIComponent(
    parts.join('-').replaceAll(' - ', '-').replaceAll(' ', '-'),
  )
  return urlFriendlyId
}
